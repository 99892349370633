import { Component, OnInit } from '@angular/core';
import { faAngleDoubleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { BuyerService } from '../services/buyer.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';

declare const $: any;

@Component({
    selector: 'app-edit-address',
    templateUrl: './edit-address.component.html',
    styleUrls: ['./edit-address.component.css']
})


export class EditAddressComponent implements OnInit {
    faAngleDoubleRight = faAngleDoubleRight;
    faCircle = faCircle;
    editAddId: any;
    dial_code: string = '+1';
    country: string = 'United States';
    country_code: string = 'US';
    mobile: any;
    editCode: any = 'US';
    addressForm: FormGroup;
    initialCountrySet = "US"
    provinceList: any = [];
    municipalitiesDataList: any = [];
    provinceChanged: any = 0;


    constructor(
        private commonService: CommonService,
        private buyerService: BuyerService,
        private router: Router,
        private fb: FormBuilder,
        private translate: TranslateService,
        private authService: AuthService,

    ) { translate.use(this.commonService.getLanguage()); }

    ngOnInit() {

        this.commonService.scrollToTop();
        this.commonService.setTitle('Mercadoba | Edit Address'); // set title 
        this.getProvinceList();

        //For Address form validation 
        this.addressForm = this.fb.group({
            name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]+')]],
            phone_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[1-9]+')]],
            house_number: ['', [Validators.required]],
            add_province: ['', [Validators.maxLength(50), Validators.required]],
            municipalities: ['', [Validators.maxLength(50), Validators.required]],

            // street: ['', [Validators.required]],
            city: ['', [Validators.required, Validators.minLength(2)]],
            // pin_code: ['', [Validators.minLength(3), Validators.maxLength(20), Validators.required, Validators.pattern('[0-9]+')]],
        });
        // End


        // For petch address form value
        if (this.buyerService.userAddress) {
            this.getMunicipalities(this.buyerService.userAddress.province);
            this.editAddId = this.buyerService.userAddress.addressID;
            this.dial_code = this.buyerService.userAddress.phone_dial_code;
            this.country = this.buyerService.userAddress.country;
            this.country_code = this.buyerService.userAddress.country_code;
            this.editCode = this.buyerService.userAddress.country_code.toLowerCase();
            this.mobile = this.buyerService.userAddress.mobile_number;
            this.initialCountrySet = this.country_code;

            this.addressForm.patchValue({
                'name': this.buyerService.userAddress.name,
                'phone_number': this.buyerService.userAddress.mobile_number,
                'house_number': this.buyerService.userAddress.house_number,
                'street': this.buyerService.userAddress.locality,
                'city': this.buyerService.userAddress.city,
                'pin_code': this.buyerService.userAddress.zip_code,
                'add_province': this.buyerService.userAddress.province,
                'municipalities': this.buyerService.userAddress.municipalities
            });


        } else {
            this.router.navigate(['/my-account/address']);
        }
    }
    // End

    // For address form validation
    get name() { return this.addressForm.get('name'); }
    get phone_number() { return this.addressForm.get('phone_number'); }
    get house_number() { return this.addressForm.get('house_number'); }
    // get street() { return this.addressForm.get('street'); }
    get city() { return this.addressForm.get('city'); }
    // get pin_code() { return this.addressForm.get('pin_code'); }
    get add_province() { return this.addressForm.get('add_province'); }
    get municipalities() { return this.addressForm.get('municipalities'); }
    // End

    // For form validation check
    validateAllFormFields(formGroup: FormGroup) {         //{1}
        Object.keys(formGroup.controls).forEach(field => {  //{2}
            const control = formGroup.get(field);             //{3}
            if (control instanceof FormControl) {             //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        //{5}
                this.validateAllFormFields(control);            //{6}
            }
        });
    }


    //set auto detect country
    telInputObject(obj) {
        this.initialCountrySet = this.country_code
        // $.getJSON('https://ipapi.co/json/', function (data) {
        // 	this.initialCountrySet = data.country_code.toLowerCase();
        // 	this.country_code = data.country_code;
        // 	this.dial_code = data.country_calling_code;
        obj.setCountry(this.initialCountrySet);
        // });
    }



    // For get province list
    getProvinceList() {
        this.authService.getProvinceList()
            .subscribe(provinceData => {
                this.commonService.hideLoader();

                if (provinceData.status == 'success') {
                    this.provinceList = provinceData.data.province;
                } else {
                    this.provinceList = [];
                }

                console.log(' this.provinceList', this.provinceList);

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
    }
    // End


    //       //set auto detect country
    //   telInputObject(obj) {

    // 	var initialCountrySet  = this.country_code;

    // 	if(initialCountrySet == "" || initialCountrySet == null){
    // 		initialCountrySet  = this.country_code;	

    // 	}else{
    // 		initialCountrySet  ='US';

    // 	}


    //     $.getJSON('https://ipapi.co/json/', function (data) {

    //     this.initialCountrySet = initialCountrySet;

    //       // this.initialCountrySet = data.country_code.toLowerCase();
    //       // this.country_code = data.country_code;
    //       // this.dial_code = data.country_calling_code;
    //       obj.setCountry(this.initialCountrySet);
    //     });
    //   }

    trimValue(formControl) {
        ((typeof formControl.value) && formControl.value.trim() == "") ?
            formControl.setValue("") : formControl.setValue(formControl.value);
    }
    // End

    hasError($event) {
        if ($event) {
            this.addressForm.patchValue({ phone_number: "1" });
        } else {
            this.addressForm.patchValue({ phone_number: "" });
        }
    }

    // For country change 
    onCountryChange($event) {
        this.dial_code = `+${$event.dialCode}`;
        this.country = $event.name.split('(')[0];
        this.country_code = $event.iso2.toUpperCase();
    }
    // End

    // For get number value
    getNumber($event) {
        this.addressForm.patchValue({ phone_number: $event.split(this.dial_code)[1] });
    }
    // End

    // For edit address form 
    editAddSubmit() {
        
        if (this.addressForm.valid) {
            this.commonService.showLoader();
            let formData = `name=${this.addressForm.get('name').value}&phone_number=${this.addressForm.get('phone_number').value}&house_number=${this.addressForm.get('house_number').value}&city=${this.addressForm.get('city').value}&add_province=${this.addressForm.get('add_province').value}&municipalities=${this.addressForm.get('municipalities').value}&dial_code=${escape(this.dial_code)}&country=${this.country}&country_code=${this.country_code}&`;

            this.buyerService.updateAddress(this.editAddId, formData).subscribe((data) => {
                this.commonService.hideLoader();

                if (data.status == 'fail') {
                    this.commonService.toastError(data.message);
                } else {
                    this.commonService.toastSuccess(data.message);
                    this.router.navigate(['/my-account/address']);
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        } else {
            // validate all form fields
            this.validateAllFormFields(this.addressForm); //{7}
        }
    }
    // End

    // For set enter only number for mobile number
    numberOnly(event): boolean {
        const charCode = (event.which) ? event.which : event.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
            return false;
        }
        return true;

    }

    // get provice id on change province 
    onProvinceChange(event: Event): void {
        const selectedProvinceID = (event.target as HTMLSelectElement).value;
        this.getMunicipalities(selectedProvinceID);
        this.provinceChanged = 1;
        this.addressForm.get('municipalities').setValue('');
    }

    // For get municipalities list 
    getMunicipalities(province_ids) {

        this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
            this.commonService.hideLoader();
            if (municipalitiesData.status == 'success') {
                this.municipalitiesDataList = municipalitiesData.data.municipalities;
            } else {
                this.municipalitiesDataList = [];
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });
    }
    // End

}
