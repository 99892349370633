import { Component, OnInit } from '@angular/core';

import { WalletService } from '../services/wallet.service';
import { ActivatedRoute } from '@angular/router';

import { CommonService } from '../../services/common.service';

@Component({
	selector: 'app-wallet-detail',
	templateUrl: './wallet-detail.component.html',
	styleUrls: ['./wallet-detail.component.css']
})
export class WalletDetailComponent implements OnInit {
	id: any;
	walletDetail: any = {};
	fileExtension: any;
	image: any;
	imageType: string;
	datee: string;

	constructor(private walletService: WalletService,
		private route: ActivatedRoute,
		private commonService: CommonService) { }

	ngOnInit() {

		//  get id from url 
		this.route.paramMap.subscribe(params => {
			this.id = params.get("id");
		});

		this.getDetail(); // get details function 
	}


	// get wallet details function for api calling 
	getDetail() {
		this.commonService.showLoader();  // show loader 
		this.walletService.getwalletDetail(this.id).subscribe(data => {

			this.commonService.hideLoader();
			this.walletDetail = data.data;

			// let yourDate = new Date(this.walletDetail.created_date)+"UTC";
          	// this.datee = yourDate.toString();

			if (this.walletDetail.payment_receipt != null) {
				this.fileExtension = this.walletDetail.payment_receipt.split('.').pop();
				if (this.fileExtension == "webp") {
					this.image = 1;

				} else {

					this.image = 0;

				}


			}




		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})
	}

// For order time 
getDateTime(dateTime) {

	return this.commonService.getDateTime1(dateTime);
}

}
