import { Component, OnInit } from '@angular/core';
import { faCommentDots, faAngleDoubleRight, faRedo, faEdit, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SellerService } from '../services/seller.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;

@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.component.html',
	styleUrls: ['./my-orders.component.css']
})

export class MyOrdersComponent implements OnInit {
	faAngleDoubleRight = faAngleDoubleRight;
	faCommentDots = faCommentDots;
	faAngleRight = faAngleRight;
	faRedo = faRedo;
	faEdit = faEdit;
	search_term: string = "";
	productloader: boolean = true;
	myOrderIndex: any;
	myOrderList: any = [];
	myOrderCount: number;
	limitOfMyOrder: number = 10;
	offsetOfMyOrder: number = 0;
	nextOrderStatusNumber: any;
	trackingStatus: any = {};
	orderId: any;
	siteUrl = environment.siteUrl;

	// order tranking status
	trackImages: any = [
		{ icon: this.siteUrl + '/assets/img/common/ordered.png', orderStatus: 'Order Placed' },
		{ icon: this.siteUrl + '/assets/img/common/placed.png', orderStatus: 'Approved' },
		{ icon: this.siteUrl + '/assets/img/common/packed.png', orderStatus: 'Packed' },
		{ icon: this.siteUrl + '/assets/img/common/shipped.png', orderStatus: 'Shipped' },
		{ icon: this.siteUrl + '/assets/img/common/zone_icon.png', orderStatus: 'Zone' },
		{ icon: this.siteUrl + '/assets/img/common/delivered.png', orderStatus: 'Delivered' },
		{ icon: this.siteUrl + '/assets/img/common/return.png', orderStatus: 'Return in Process' },
		{ icon: this.siteUrl + '/assets/img/common/refund.png', orderStatus: 'Returned & Refunded' }
	]
	//End

	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private sellerService: SellerService,
		private translate: TranslateService
	) { translate.use(this.commonService.getLanguage()); } // get user language

	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | My Orders'); // set title 
		this.getMyOrderList(this.limitOfMyOrder, this.offsetOfMyOrder); //  get my order list 
	}




	searchTimer: any;
	doneSearchInterval: any = 800;

	// function for search my order
	search() {
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this.resetMyOrderList(); // resrt searching
			this.getMyOrderList(this.limitOfMyOrder, this.offsetOfMyOrder); // get my order list
		}, this.doneSearchInterval); // search inteval 
	} // end function

	// resrt limit and offset 
	resetMyOrderList() {
		this.myOrderList = [];
		this.limitOfMyOrder = 10;
		this.offsetOfMyOrder = 0;
	} // end function

	// function for load more 
	myOrderLoadMore() { //load more 
		this.offsetOfMyOrder = this.offsetOfMyOrder + Number(this.limitOfMyOrder);
		this.getMyOrderList(this.limitOfMyOrder, this.offsetOfMyOrder);
	} // end function

	// function for get time
	getOrderTimeShow(dateTime, string) {
		return this.commonService.getOrderTime(dateTime, string);
	} // end function


	// get My product list api calling
	//@param limit , offset , search loader
	getMyOrderList(limit, offset) {

		if (this.offsetOfMyOrder == 0) {
			this.commonService.showLoader(); // show loader 
		} else {
			this.productloader = false;
		}
		// api calling 
		this.sellerService.getMyOrderList(limit, offset, this.search_term)
			.subscribe(productData => {

				this.productloader = true;
				this.commonService.hideLoader(); // hide loader

				// check  api response sucess or not

				if (productData.status == 'success') {

					if (productData.data.data_found) {

						if (this.offsetOfMyOrder == 0) { // check refresh then show starting

							productData.data.my_order_list.forEach(element => {

								element.orderStatusText = this.commonService.paymentStatus(element.current_status_number); // get payment status

								this.myOrderList.push(element); // push data in list 
								this.myOrderCount = productData.data.total_records; //total product
							});

						} else {

							productData.data.my_order_list.forEach(element => {
								element.orderStatusText = this.commonService.paymentStatus(element.current_status_number);// get payment status 
								this.myOrderList.push(element); // push data in list
								this.myOrderCount = productData.data.total_records; //total product    
							});

						}

					} else {
						this.myOrderCount = 0; // reset count 
						this.myOrderList = []; // empty list
					}


				} else {
					this.myOrderCount = 0; // reset count
					this.myOrderList = []; // empty list
				}

			}, err => {
				this.commonService.hideLoader(); // hide loader
				this.commonService.errorhandler(err); // err handle
			});
	}// end getMyProductList

	//function for show  traking status modal 
	showOrderStatus(index, myOrder) {
		$('#statusModal').modal('show');
		this.myOrderIndex = index;
		this.orderId = myOrder.orderID;
		this.nextOrderStatusNumber = Number(myOrder.current_status_number) + Number(1);
		this.trackingStatus = myOrder.tracking_status;
	} // end function 

	// function for change order traking status  api calling 

	//@param orderId, sttaus
	changeOrderStatus(nextOrderStatusNumber) {

		let statusData = `status=${escape(nextOrderStatusNumber)}`;

		this.commonService.showLoader();

		// api calling 
		this.sellerService.changeOrderStatus(this.orderId, statusData).subscribe(orderData => {
			this.commonService.hideLoader();

			if (orderData.status == 'success') {
				$('#statusModal').modal('hide');
				this.myOrderList[this.myOrderIndex].current_status_number = nextOrderStatusNumber;
				this.myOrderList[this.myOrderIndex].tracking_status = orderData.data.tracking_status;
				this.myOrderList[this.myOrderIndex].orderStatusText = this.commonService.paymentStatus(nextOrderStatusNumber.toString());
			}
		}, err => {
			this.commonService.hideLoader(); // hide loader
			this.commonService.errorhandler(err); // err  handle
		});

	} // end function

}
