import { Component, OnInit } from '@angular/core';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../services/common.service';
import { ShopService } from '../services/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { formatDate } from '@angular/common';


@Component({
  selector: 'app-term-condition',
  templateUrl: './term-condition.component.html',
  styleUrls: ['./term-condition.component.css']
})
export class TermConditionComponent implements OnInit {
  content:any;
  faBars = faBars;
  lang: string;
  year:any = formatDate(new Date(), 'yyyy', 'en');
  web: any =0;


  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private shopService: ShopService,
    private router: Router,

  ) { }

  ngOnInit() {
    const str = this.router.url;
    const regex = RegExp('/termandconditions/');
    if (regex.test(str) == true) {
      this.web = 1;
    }else{
      this.web = 0;

    }

    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Term and Condition');

    this.activatedRoute.paramMap.subscribe(params => {
      this.lang  = params.get("en");
    })
    this.getContent();
  }

  getContent(){
    
    this.shopService.getContent().subscribe((data) => {
      if(data.status == "success"){

        if(this.lang == "en"){
          this.content = data.content.termAndCondition[0].option_value;
          return false;

        }else {
          this.content = data.content.termAndCondition[1].option_value;
        }
        if(this.lang == "es"){
          this.content = data.content.termAndCondition[1].option_value;

        }else {
          this.content = data.content.termAndCondition[1].option_value;
        }
      }

    },err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

}
