import { Component, OnInit } from '@angular/core';
import { faAngleDoubleRight, faCommentDots, faCheck, faTimes, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { SellerService } from '../services/seller.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
declare var $: any;

@Component({
  selector: 'app-new-orders',
  templateUrl: './new-orders.component.html',
  styleUrls: ['./new-orders.component.css']
})

export class NewOrdersComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  faAngleRight = faAngleRight;
  faCommentDots = faCommentDots;
  faCheck = faCheck;
  faTimes = faTimes;
  searchData: any = "";
  productloader: boolean = true;
  newOrderList = [];
  newOrderCount: number;
  limitOfNewOrder: number = 10;
  offsetOfNewOrder: number = 0;

  constructor(
    private commonService: CommonService,
    private sellerService: SellerService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private router: Router,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | New Orders'); //For title
    this.getNewOrderList(this.limitOfNewOrder, this.offsetOfNewOrder); //For order list api
  }



	ngOnDestroy(): void {
  
    Swal.close();
  }

  searchTimer: any;
  doneSearchInterval: any = 800;

  // For search filter
  search() {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.resetNewOrder();
      this.getNewOrderList(this.limitOfNewOrder, this.offsetOfNewOrder); //For get order list
    }, this.doneSearchInterval);
  }
  // End

  // For reset order search
  resetNewOrder() {
    this.newOrderList = []; //Order list empty
    this.limitOfNewOrder = 10; //For limit of order
    this.offsetOfNewOrder = 0; //For Offset
  }
  // End

  // For load more button 
  newOrderLoadMore() { //load more 
    this.offsetOfNewOrder = this.offsetOfNewOrder + Number(this.limitOfNewOrder);
    this.getNewOrderList(this.limitOfNewOrder, this.offsetOfNewOrder); //Get order list
  }
  // End




  // Function for get order list
  getNewOrderList(limit, offset) {
    if (this.offsetOfNewOrder == 0) {
      this.commonService.showLoader();//For loader
    } else {
      this.productloader = false;
    }

    this.sellerService.getNewOrderList(limit, offset, this.searchData).subscribe(newOrderData => {

      this.productloader = true;
      this.commonService.hideLoader();

      if (newOrderData.status == 'success') {

        if (newOrderData.data.data_found) {

          if (this.offsetOfNewOrder == 0) { // check refresh then show starting

            newOrderData.data.new_order_list.forEach(element => {
              this.newOrderList.push(element);
              this.newOrderCount = newOrderData.data.total_records; //total product
            });

          } else {

            newOrderData.data.new_order_list.forEach(element => {
              this.newOrderList.push(element);
              this.newOrderCount = newOrderData.data.total_records; //total product    
            });

          }

        } else {
          this.resetNewOrder(); //Reset order list
        }
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

  // Confirm order (Accept & Reject order)
  confirmationOrder(status, orderId, orderNumber, licenseStatus) {
    if (status == '0') {//Swal modal for cancel
      Swal.fire({
        title: this.translate.instant('MYORDER.are_you_sure'),
        text: this.translate.instant('MYORDER.You_want_to_reject_this_order') + orderNumber,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('MYORDER.yes_reject_it'),
        cancelButtonText: this.translate.instant('MYORDER.no_keep_it'),
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.acceptReject(status, orderId, licenseStatus);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            this.translate.instant('SELLER_SIDE.cancelled'),
            this.translate.instant('MYORDER.your_order_is_safe'),
            this.translate.instant('MYORDER.error_txt')
          )
        }
      });
    } else {//Swal modal for accept
      Swal.fire({
        title: this.translate.instant('MYORDER.are_you_sure'),
        text: this.translate.instant('MYORDER.you_want_to_accept_this_order') + orderNumber,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('MYORDER.yes_accept_it'),
        cancelButtonText: this.translate.instant('MYORDER.no_keep_it'),
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.acceptReject(status, orderId, licenseStatus);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            this.translate.instant('SELLER_SIDE.cancelled'),
            this.translate.instant('MYORDER.your_order_not_accept'),
            this.translate.instant('MYORDER.error_txt')
          )
        }
      });
    }

  }

  confirmationOrderCancel(orderId, orderNumber) {
		Swal.fire({
		  title: this.translate.instant('MYORDER.are_you_sure'),
		  text: this.translate.instant('MYORDER.You_want_to_cancel_this_order') + orderNumber,
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: this.translate.instant('FOOTER.ok_button'),
		  cancelButtonText: this.translate.instant('COMMON.cancel'),
		  reverseButtons: true
		}).then((result) => {
		  if (result.value) {
			  var id = parseFloat(orderId)
			  this.cancelOrder(orderId)
		  //   this.acceptReject(status, orderId, licenseStatus);
		  } 
		});
	 
  
	}
  // End


  cancelOrder(id){
		// alert('hello')

		const formData = new FormData();
		formData.append('order_id', id);
		formData.append('cancel_by', "seller");
		formData.append('note', "");
		this.commonService.showLoader();  // show loader
		this.sellerService.cancelOrder(formData).subscribe(data => {

			if (data.status == 'success') {
				this.commonService.hideLoader();
				// this.commonService.toastSuccess(data.message);
        location.reload();
        // this.getNewOrderList(this.limitOfNewOrder, this.offsetOfNewOrder); //Get order list
			}else{
				this.commonService.hideLoader();
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}

  // For accept & reject order
  acceptReject(status, orderId, licenseStatus) {


    if (licenseStatus == '0' && status == 1 || licenseStatus == '2' && status == 1) {

      if (licenseStatus == '0') {
        Swal.fire({
          text: this.translate.instant('NEW_ORDER.your_license_is_not_verified'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: this.translate.instant('FOOTER.ok_button'),
          reverseButtons: false
        }).then((result) => {
          if (result.value == true) {
            this.commonService.hideLoader();

            this.router.navigate(['seller/seller-account']);

          }


        })


      }
      else if (licenseStatus == '2' && status == 1) {

        Swal.fire({
          text: this.translate.instant('NEW_ORDER.your_license_is_not_verified'),
          icon: 'warning',
          showCancelButton: false,
          confirmButtonText: this.translate.instant('FOOTER.ok_button'),
          reverseButtons: false
        }).then((result) => {
          if (result.value == true) {
            this.router.navigate(['seller/seller-account']);

          }


        })


      }

      // else {
      //   this.commonService.toastError(this.translate.instant('NEW_ORDER.your_license_is_rejected'));
      // }


    } else {

      let statusData = `status=${escape(status)}`;

      this.commonService.showLoader();
      this.sellerService.acceptReject(orderId, statusData).subscribe(orderData => {
        this.commonService.hideLoader();

        if (orderData.status == 'success') {
          this.commonService.toastSuccess(orderData.message);

          this.resetNewOrder();
          this.ngOnInit();
        }
      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

    }
  }
  // End

  // For Firebase 
  orderRemoveFirebase(orderId) {
    const result = this.newOrderList.find(({ orderID }) => orderID === orderId);
    let orderData = {
      sellerId: this.authService.getCurrentUserId(),
      buyerId: result.ordered_by_user_id,
      orderId: orderId
    }
    this.firebaseService.orderRemoveFirebase(orderData);
  }

  // For order time 
	getOrderTimeShow(dateTime, string) {

		return this.commonService.getOrderTime(dateTime, string);
	}

	getDisputeTimeShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}
  // End

}
