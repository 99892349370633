import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SellerService {
  apiUrl = environment.apiUrl;
  apiUrlVersionTwo = environment.apiUrlVersionTwo;

  constructor(private http: HttpClient) { }

  userAddress: any = "";

  getCategoryList(isAddProduct) {
    return this.http.get<any>(`${this.apiUrl}category/${isAddProduct}`);
  }

  getComissionList() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/admin-commission`);
  }

  getWarrantyList() {
    return this.http.get<any>(`${this.apiUrl}warranty`);
  }

  getVariantList(categoryId) {
    return this.http.get<any>(`${this.apiUrl}variant/${categoryId}`);
  }

  getBrandList(categoryId) {
    return this.http.get<any>(`${this.apiUrl}brand/${categoryId}`);
  }

  getSellerProfile() {
    return this.http.get<any>(`${this.apiUrl}seller/my-profile`);
  }

  // seller profile payment option and delivery option update 
  updateOptions(updateData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}seller/on-off-profiles`, updateData);
  }

  // get profile details 
  getUserprofileDetails(seller_id,) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/seller-info?seller_id=${seller_id}`);
  }
  // get profile details 
  getProfileOptions(seller_id,) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/seller-info?seller_id=${seller_id},&authData=1`);
  }

  // updateProfile(sellerData) {
  //   return this.http.post<any>(`${this.apiUrl}seller/profile`, sellerData);
  // }
  updateProfile(sellerData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}seller/profile`, sellerData);
  }

  updateAvatar(sellerData) {
    return this.http.post<any>(`${this.apiUrl}seller/update-avatar`, sellerData);
  }

  //feedback
  writefeedback(formData) {
    return this.http.post<any>(`${this.apiUrl}user/feedback`, formData);
  }

  //shipping charges add 
  shippingChargesAdd(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}seller/shipping-charges`, formData);
  }


  //shipping charges add 
  shippingChargesUpdate(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}seller/update-shipping-charges`, formData);
  }


  //shipping charges list
  shippingChargesget(shipping_id, offset) {
    // return this.http.get<any>(`${this.apiUrlVersionTwo}seller/`);
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/shipping-charges-list?shipping_id=${shipping_id}&offset=${offset}`);

  }

  //shipping charges delete
  shippingChargeDelete(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}seller/delete-shipping-charges`, formData);

  }

  //change password
  changePassword(formData) {
    return this.http.post<any>(`${this.apiUrl}user/change-password`, formData);
  }

  addBizInfo(bizInfo) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}seller/biz-info`, bizInfo);
  }

  addProduct(productData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product`, productData);
  }

  // attachments(productId, imageData) {
  //   return this.http.post<any>(`${this.apiUrl}product/${productId}/attachments`, imageData);
  // }

  attachments(productId, imageData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/attachments`, imageData);
  }

  attachmentsProduct(productId, imageData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/product-attachments`, imageData);
  }

  // deleteGalleyImage(productId) {
  //   return this.http.delete<any>(`${this.apiUrl}product/attachments/${productId}`);
  // }

  deleteGalleyImage(imageData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/delete-attachments`, imageData);
  }
  deleteProductGalleyImage(imageData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/delete-product-attachments`, imageData);
  }

  deleteProduct(productId) {
    return this.http.delete<any>(`${this.apiUrl}product/${productId}`);
  }

  getMyProductList(limit, offset, searchTerm) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/my-product?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  }

  getRecentDeleteProductList(limit, offset, searchTerm) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/recent-list?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  }

  // getNewOrderList(limit, offset, searchTerm) {
  //   return this.http.get<any>(`${this.apiUrl}seller/new-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  // }



  getNewOrderList(limit, offset, searchTerm) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/new-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  }


  getNewOrderDetail(orderId) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}order/order-detail/${orderId}`);
  }

  // acceptReject(orderId, status) {
  //   return this.http.patch<any>(`${this.apiUrl}order/accept-reject/${orderId}`, status);
  // }
  acceptReject(orderId, status) {
    return this.http.patch<any>(`${this.apiUrlVersionTwo}order/accept-reject/${orderId}`, status);
  }
  // getMyOrderList(limit, offset, searchTerm) {
  //   return this.http.get<any>(`${this.apiUrl}seller/my-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  // }


  // getMyOrderList(limit, offset, searchTerm) {
  //   return this.http.get<any>(`${this.apiUrl}seller/my-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  // }
  getMyOrderList(limit, offset, searchTerm) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/my-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  }



  // changeOrderStatus(orderId, status) {
  //   return this.http.patch<any>(`${this.apiUrl}seller/change-status/${orderId}`, status);
  // }


  changeOrderStatus(orderId, status) {
    return this.http.patch<any>(`${this.apiUrlVersionTwo}seller/change-status/${orderId}`, status);
  }

  addSignature(signatureData) {
    return this.http.post<any>(`${this.apiUrl}order/add-signature`, signatureData);
  }

  disputeCreate(disputeData) {
    return this.http.post<any>(`${this.apiUrl}dispute/dispute-info`, disputeData);
  }

  updateProduct(productData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}product/update`, productData);
  }

  getMyOrderDetail(orderId) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}order/order-detail/${orderId}`);
  }

  getProductDetail(id, userId) {
    return this.http.get<any>(`${this.apiUrl}product/${id}?userID=${userId}`);
  }

  recentDeleted(productId, status) {
    return this.http.patch<any>(`${this.apiUrl}product/recent-delete/${productId}`, status);
  }

  getDisputeList() {
    return this.http.get<any>(`${this.apiUrl}dispute/title-list`);
  }
  //Check seller subscriptionn
  getSellerSubscription() {
    return this.http.get<any>(`${this.apiUrl}seller/check-subscription`);
  }

  //get product offer
  productOfferDetails(product_id, variant_id, offer_id) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}offer/offer-variant-detail?product_id=${product_id}&variant_id=${variant_id}&offerItem_id=${offer_id}`);
  }

  // Rejected order list
  rejectedOrderList(limit, offset, searchTerm) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/reject-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  }

  // Rejected order details 
  rejectedOrderDetail(orderId) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}order/order-detail/${orderId}`);
  }

  // Delete user profile
  deleteUser() {
    return this.http.delete<any>(`${this.apiUrlVersionTwo}delete-user`);
  }

  // Delete user profile
  cancelOrder(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}cancel-order`, formData);
  }

  //add warehouse address
  addWarehouseAddress(formData: any) {

    return this.http.post<any>(`${this.apiUrlVersionTwo}seller/warehouse-address`, formData);
  }

  //get warehouse address
  getWarehouseAddress(province,municipalities,limit,offset) {

    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/warehouse-address-list?province=${province}&municipalities=${municipalities}&limit=${limit}&offset=${offset}`);
  }

  //delete warehouse address
  deleteWarehouseAddress(addressId) {
  
    return this.http.delete<any>(`${this.apiUrlVersionTwo}seller/warehouse-address-delete/${addressId}`);
  }

  //update warehouse address
  updateWarehouseAddress(formData) {
    return this.http.put<any>(`${this.apiUrlVersionTwo}seller/warehouse-address-edit`, formData);
  }

    //get warehouse address
    getWarehouseAddresses(formData) {

      return this.http.post<any>(`${this.apiUrlVersionTwo}seller/warehouse-addresses`, formData);
    }

    //get warehouse address by province
    getWarehouseAddressByProvince(formData: any) {

      return this.http.post<any>(`${this.apiUrlVersionTwo}seller/warehouse-addresses`, formData);
    }

  // Delete User Api End
}
