import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { faAngleDoubleRight, faStar, faStarHalfAlt, faShoppingCart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { faCommentDots, faStar as faStarBlank, faHeart } from '@fortawesome/free-regular-svg-icons';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { ShopService } from '../../services/shop.service';
import { HeaderBuyerComponent } from '../../common/header-buyer/header-buyer.component';
import { FirebaseService } from '../services/firebase.service';
import { FirebaseService as BuyerFbService } from '../../buyer/services/firebase.service';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { BuyerService } from '../../buyer/services/buyer.service';
import { map } from 'rxjs/operators';

declare const $: any;

@Component({
	selector: 'app-product-offers-details',
	templateUrl: './product-offers-details.component.html',
	styleUrls: ['./product-offers-details.component.css']

})
export class ProductOffersDetailsComponent implements OnInit {


	faAngleDoubleRight = faAngleDoubleRight;
	faStar = faStar;
	faStarBlank = faStarBlank;
	faStarHalfAlt = faStarHalfAlt;
	faCommentDots = faCommentDots;
	faShoppingCart = faShoppingCart;
	faCheck = faCheck;
	faHearts = faHeart;
	faHeartr = faHeart;
	productId: string = '';
	variantId: string = '';
	productDetail: any = {};
	currentUserId: any;
	similarProduct: any = [];
	galleryOptions: NgxGalleryOptions[];
	galleryImages: any = [];
	galleryImage: any = [];
	variants: any = [];
	variantListData: any = [];
	variant: string = '';
	offerPrice: any;
	firebaseToken: any = '';
	keyFeatureList: any = [];
	productPrice: any = 0;
	productPriceTemp: any = 0;
	discount_percent: any = 0;
	productVariantID: any = 0;
	is_wishlist: any = 0;
	is_qvazon_warranty_applied: any = 0;
	qvazon_warranty_percent: any = 0;
	isCorrectVariantCombination: boolean = false;
	qvazon_warranty_use: any = 0;
	seller_province: any = [];
	productRegularPrice: any = 0;
	previewHide: boolean = true;
	imagesPreview: any;
	questionForm: FormGroup;
	loginForm: FormGroup;
	questionAnswer: any;
	variantIdGet: any;
	variant_price: any;
	varQuantity: any;
	makeOfferForm: FormGroup;
	reportForm: FormGroup;
	reportTitle: any = [];

	variant_id;
	variantDataForCard;
	key = "0";
	variantDAtaList: any = [];
	duplicateids: any = [];
	flag = 0;
	showQuestionAnsButton: any;
	remaingQuestion: any;

	ProductSliderOptions: OwlOptions = {
		loop: false,
		margin: 30,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		nav: true,
		dots: false,
		navSpeed: 700,
		autoWidth:true,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		// responsive: {
		// 	0: {
		// 		items: 1,
		// 		autoWidth: false,
		// 	},
		// 	480: {
		// 		items: 2
		// 	},
		// 	767: {
		// 		items: 3
		// 	},
		// 	992: {
		// 		items: 4
		// 	}
		// }
		responsive: {
			0: {
				items: 1
			},
			767: {
				items: 2
			},
			992: {
				items: 4
			}
		},
	}

	@ViewChild('header', { static: true }) header: HeaderBuyerComponent;
	@ViewChild('myForm', { static: true }) form: any;
	@ViewChild('offerForm', { static: true }) offerForm: any;

	packages: any;
	third: any[];
	isPackage: boolean = false;
	axz: any;
	fruits: any = [];
	fruits_new: any = [];
	variantsnew: any = [];
	ids: any = [];
	product_variant_id: any;
	checked: false;
	productVariantIDWithVAriant: any;
	productOfferPrice: any;
	productSellingPrice: any;
	discountPercent: any;
	offerData: any = [];
	offerDataPrice: any;
	offerItemID: any;
	offerItemId: string;
	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private authService: AuthService,
		private shopService: ShopService,
		private firebaseService: FirebaseService,
		private buyerFbService: BuyerFbService,
		private formBuilder: FormBuilder,
		private router: Router,
		private translate: TranslateService,
		private buyerService: BuyerService
	) { translate.use(this.commonService.getLanguage()); }

	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Product Detail');
		this.currentUserId = this.authService.getCurrentUserId(); //current user ID
		this.setConfigImageGallery();

		this.activatedRoute.paramMap.subscribe(params => {
			this.productId = params.get("productID");
			this.variantId = params.get("variantID");
			this.offerItemId = params.get("offerItemID");

			this.galleryImages = [];
			this.getProductDetail(this.productId, this.variantId, this.offerItemId);
			// this.reportTitleget();

			if (this.authService.isLoggedIn()) {

				this.reportTitleget();
			}

		});

		// Add questions form validation
		this.questionForm = this.formBuilder.group({
			description: ['', [Validators.required,Validators.maxLength(200)]],

		});
		// End

		// Report form validation 
		this.reportForm = this.formBuilder.group({
			report_title: ['', [Validators.required]],
			report_description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
		})
		// End

		// For offer form validation
		this.makeOfferForm = this.formBuilder.group({
			offer_price: ['', [Validators.required]],
		})
		// End

	}




	get description() { return this.questionForm.get('description'); } //Question form validation
	get offer_price() { return this.makeOfferForm.get('offer_price'); } //offer form validation

	// report form validation
	get report_title(): any { return this.reportForm.get('report_title'); }
	get report_description(): any { return this.reportForm.get('report_description'); }
	// End

	// Function For see more ans & ques navigate new page 
	questionAnswerLoad() {
		this.router.navigate(['/question-answer', this.productId]);
	}
	// End

	// For product variants images 
	setConfigImageGallery() {
		this.galleryOptions = [
			{
				width: '350px',
				height: '550px',
				thumbnailsColumns: 4,
				imageAnimation: NgxGalleryAnimation.Slide,
				previewZoom: true,
			},
			// max-width 800
			{
				breakpoint: 800,
				width: '100%',
				height: '600px',
				imagePercent: 80,
				thumbnailsPercent: 20,
				thumbnailsMargin: 20,
				thumbnailMargin: 20
			},
			// max-width 400
			{
				breakpoint: 300,
				preview: false
			}
		];
	}
	// End



	// Function for rating 
	//Create rating
	createRatingRange(number) {
		return this.commonService.createRatingRange(number);
	}//End

	// Rating blank
	createRatingBlank(number) {
		return this.commonService.createRatingBlank(number);
	}//End

	isFloat(number) {
		return this.commonService.isFloat(number);
	}
	// End


	getDisputeTimeShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}

	getDate(dateTime) {

		return this.commonService.getDateTime2(dateTime);
	}

	// For varinats data 
	variantDataaa(index) {
		this.variantDAtaList = [];
		this.galleryImages = [];
		this.variant_id = this.productDetail.variant_list[index].productVariantID;
		this.variantDataForCard = this.productDetail.variant_list[index];

		this.galleryImage = this.productDetail.variant_list[index].variant_image;

		var image;
		this.productDetail.variant_list.forEach(element => {
			var image = JSON.parse(element.image_json);
			element.image1 = image[0];
			this.variantDAtaList.push(element);

		});


		this.galleryImage.forEach(element => {
			let galleryImagesData = {
				small: `${element.variant_image_url}${element.variant_image}`,
				medium: `${element.variant_image_url}${element.variant_image}`,
				big: `${element.variant_image_url}${element.variant_image}`
			};
			this.galleryImages.push(galleryImagesData);


		});
	}
	// End

	//Get product Detail
	getProductDetail(productId, variantId, offerItemId) {

		this.commonService.showLoader();
		this.buyerService.productOfferDetails(productId, variantId, offerItemId).subscribe((productDetailData) => {


			this.commonService.hideLoader();
			if (productDetailData.status == 'success') {
				this.commonService.scrollToTop();
				this.productDetail = productDetailData.data.product_detail;
				this.productPrice = productDetailData.data.product_detail.regular_price;
				this.productRegularPrice = productDetailData.data.product_detail.regular_price;

				var questionAnswer = this.productDetail.question;

				if (questionAnswer.length <= 4) {
					this.showQuestionAnsButton = 0

				} else {
					this.showQuestionAnsButton = 1
					this.remaingQuestion = questionAnswer.length - 4;
				}


				var filtered = questionAnswer.filter(function (value, index, arr) {
					return index <= 3;

				});

				this.questionAnswer = filtered;
				this.variants = this.productDetail.variants_options;
				this.variantsnew = this.productDetail.variants_options;
				this.productOfferPrice = this.productDetail.offer_price;
				this.productSellingPrice = this.productDetail.selling_price;
				// this.discountPercent = Math.round(this.productDetail.discount_percent);

				this.offerData = productDetailData.data.product_detail.variant_list[0];
				this.discountPercent = this.offerData.discount_percent;
				this.offerDataPrice = this.offerData.product_offer_price;
				this.offerItemID = this.offerData.offerItemID;
				this.similarProduct =[];

				this.productDetail.similar_products.forEach(element => {
					element.discount_percent = Math.round(element.discount_percent);
					this.similarProduct.push(element);
				});
				

			
				this.keyFeatureList = (this.productDetail.key_features) ? JSON.parse(this.productDetail.key_features) : [];
				this.packages = (this.productDetail.packages) ? JSON.parse(this.productDetail.packages) : [];
				if (this.packages.length == 0) {

					this.isPackage = false;

				} else {
					this.isPackage = true;

				}

				this.seller_province = this.productDetail.seller_province.split(',');
				this.productDetail.variant_list.forEach(element1 => {
					let newKey = "";
					this.variantIdGet = element1.productVariantID;
					this.variant_price = element1.variant_product_price;
					this.varQuantity = element1.quantity;

					element1.variant_value.forEach(element2 => {
						newKey += element2.variantValueID;
					});

					element1.variantValuesString = newKey;
				});

				if (this.similarProduct.length > 4) {
					this.ProductSliderOptions.loop = true;
				}


				if (this.similarProduct.length > 4) {
					this.ProductSliderOptions.loop = true;
				}


				// this.galleryImage = this.productDetail.variant_list[0].variant_image;

				if(this.productDetail.is_no_variants == 0){
					this.galleryImage = this.productDetail.variant_list[0].variant_image;
		  
				  }else{
					this.galleryImage = this.productDetail.gallery_images;
		  
				  }
				this.variantDataForCard = this.productDetail.variant_list[0];

				this.variant_id = this.productDetail.variant_list[0].productVariantID;

				var image;
				this.productDetail.variant_list.forEach(element => {

					var image = JSON.parse(element.image_json);
					element.image1 = image[0];
					this.variantDAtaList.push(element);

				});


				console.log('galleyImage',this.galleryImage );

				if( this.productDetail.is_no_variants == 0){
					this.galleryImage.forEach(element => {
					  let galleryImagesData = {
						small: `${element.variant_image_url}${element.variant_image}`,
						medium: `${element.variant_image_url}${element.variant_image}`,
						big: `${element.variant_image_url}${element.variant_image}`
					  };
					  this.galleryImages.push(galleryImagesData);
			
			
					});
			
				  }else{
					this.galleryImage.forEach(element => {
					  let galleryImagesData = {
						small: `${element.gallery_image_url}${element.gallery_image}`,
						medium: `${element.gallery_image_url}${element.gallery_image}`,
						big: `${element.gallery_image_url}${element.gallery_image}`
					  };
					  this.galleryImages.push(galleryImagesData);
			
			
					});
				  }


				console.log('galleyImage2',this.galleryImage );


				this.getFirebaseToken();
				// this.setVariantData();

				this.setViewCount();
			} else {

				this.productDetail = [];
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	relaredProduct(name, id) {
		this.variantDAtaList = [];
		this.galleryImages = [];
		let navigate = "/product-detail/" + name + "/" + id;;
		this.router.navigate([navigate]);
	}


	setViewCount() {
		if (this.authService.isLoggedIn()) {
			const formData = new FormData();
			formData.append('product_id', this.productId);
			formData.append('user_type', 'buyer');

			this.shopService.viewCount(formData).subscribe((data) => {
				this.commonService.hideLoader();
				if (data.status == "fail") {
					this.commonService.toastError(data.message);
				}
			}, err => {
				// this.commonService.errorhandler(err);
			});

		}
	}


	// For delivery duration
	getDeliveryDuration(key) {
		switch (key) {
			case '1':
				return "1 Day";
				break;
			case '2':
				return "2 Days";
				break;
			case '3':
				return "3 Days";
				break;
			case '4':
				return "7 Days";
				break;
			case '5':
				return "More than 7 days";
				break;
		}
	}
	// End

	// For variant data set
	setVariantData() {

		let data = this.productDetail.variant_list.find(object => { return object.productVariantID == this.variantId });

		if (this.variantId != null) {

			this.productDetail.variants_options.forEach(element1 => {
				element1.selected = false;

				element1.variant_value.forEach(element2 => {
					data.variant_value.forEach(element3 => {
						if (element3.variantValueID == element2.variantValueID) {
							element1.selected = element2.variantValueID;
							this.productPrice = data.price;
							this.productPriceTemp = data.price;
							this.discount_percent = data.discount_percent;
							this.isCorrectVariantCombination = true;
							this.productVariantID = this.variantId;
							this.is_wishlist = data.is_wishlist;
							this.is_qvazon_warranty_applied = data.is_qvazon_warranty_applied;
							this.qvazon_warranty_percent = data.qvazon_warranty_percent;
						}
					});
				});
			});

		}

	}
	// End

	// For Select variant 
	selectVariant(form: NgForm) {
		if (form.valid) {
			let selectVariantValuesArray = Object.values(form.value);
			let selectVariantValues = selectVariantValuesArray.join('');
			let variantMatch = this.productDetail.variant_list.some(element => {
				if (element.variantValuesString == selectVariantValues) {
					this.productPrice = element.price;
					this.productPriceTemp = element.price;
					this.productVariantID = element.productVariantID;
					this.is_wishlist = element.is_wishlist;
					this.is_qvazon_warranty_applied = element.is_qvazon_warranty_applied;
					this.qvazon_warranty_percent = element.qvazon_warranty_percent;
					this.isCorrectVariantCombination = true;
					return true;
				}
			});

			if (variantMatch === false) {
				this.isCorrectVariantCombination = false;
				this.commonService.toastError(this.translate.instant('SHOP.product_variant_combination_not'));
				this.productPrice = this.productDetail.regular_price;
				this.productVariantID = 0;
				this.is_wishlist = 0;
				this.is_qvazon_warranty_applied = 0;
				this.qvazon_warranty_percent = 0;
				this.productPriceTemp = 0;
			}

		}
	}
	// End

	// For reset form
	resetForm() {
		this.form.reset();
		this.qvazon_warranty_use = 0;
		this.is_qvazon_warranty_applied = 0;
		this.is_wishlist = 0;
	}
	// End

	// Function for checkbox value get 
	onCheckboxChange(e) {
		if (e.target.checked) {
			this.qvazon_warranty_use = 1;
			let prAmount = Number(Number(this.productPrice) * Number(this.qvazon_warranty_percent) / 100);
			this.productPrice = Number(Number(this.productPrice) + Number(prAmount)).toFixed(2);
		} else {
			this.qvazon_warranty_use = 0;
			this.productPrice = this.productPriceTemp;
		}
	}
	// End

	addToCart(form: NgForm) {
		if (this.flag == 0) {
			this.commonService.toastError(this.translate.instant('SHOP.please_select_variants_first'));
			return false;
		}


		// For check user is loggedin or not
		this.productOfferPrice = this.productVariantIDWithVAriant.offer_price_with_admin_commission;
		this.productSellingPrice = this.productVariantIDWithVAriant.selling_price;
		if (this.authService.isLoggedIn()) {
			if (this.productDetail.province.includes(this.authService.getUserDetails().province) != true) {
				this.commonService.toastError(this.translate.instant('SHOP.you_cant_add_this_product_to_cart'));
			} else {

				const formData = new FormData();
				// formData.append('product_varint_id', this.productVariantID);
				formData.append('product_varint_id', this.productVariantIDWithVAriant.productVariantID);
				formData.append('product_id', this.productId);

				if (this.productDetail.is_offer == 1) {
					formData.append('selling_price', this.productOfferPrice);

				} else {

					formData.append('selling_price', this.productSellingPrice);

				}

				formData.append('product_price', this.productVariantIDWithVAriant.price);
				formData.append('variant', '20');
				formData.append('product_quantity', '1');
				formData.append('qvazon_warranty_applied', this.qvazon_warranty_use.toString());
				formData.append('is_offer', '0');

				if (this.qvazon_warranty_use == '1') {
					formData.append('warranty_percent', this.qvazon_warranty_percent);
					formData.append('with_warranty_product_amount', this.productPrice);
				} else {
					formData.append('warranty_percent', '0');
					formData.append('with_warranty_product_amount', '0');
				}


				this.commonService.showLoader();

				this.shopService.addToCart(formData).subscribe((data) => {
					this.commonService.hideLoader();
					if (data.status == "fail") {
						this.commonService.toastError(data.message);
						this.resetForm();
						// this.ngOnInit();

					} else {
						this.commonService.toastSuccess(data.message);

						this.header.getCartCount();// for increse card count
						this.resetForm();
						// this.ngOnInit();
					}

				}, err => {
					this.commonService.hideLoader();
					this.commonService.errorhandler(err);
				});

			}

		} else {
			this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_product_add_in_cart'));
			this.router.navigate(['/login']);
		}

		// }

	}

	//For add wishlist
	addWishlist(form: NgForm) {

		// For check user is login or not
		if (this.authService.isLoggedIn()) {
			this.commonService.showLoader();

			const formData = new FormData();
			formData.append('product_id', this.productId);

			this.shopService.addUserWishlist(formData).subscribe((data) => {
				this.commonService.hideLoader();
				this.header.getWishCount();// for decrease card count

				if (data.status == "fail") {
					// this.commonService.toastError(data.message);
				} else {
					// this.commonService.toastSuccess(data.message);
					this.resetForm();
					this.ngOnInit();
					this.is_wishlist = (this.variantId != null) ? data.data.is_wishlist : 0;
				}

				this.isCorrectVariantCombination = false;

				//Redirect to solve selected varinat got empty after action
				if (this.activatedRoute.snapshot.url.length == 3) {  //get url array and length of array
					let urlSuffix = '/' + this.productVariantID;
					let navigate = this.router.url + urlSuffix;
					this.router.navigate([navigate]);
				} else if (this.activatedRoute.snapshot.url.length == 4) {
					let lastIndexOfUrl = this.activatedRoute.snapshot.url[3].path;
					let popIndex = '/' + lastIndexOfUrl.toString();
					let pushIndex = '/' + this.productVariantID;
					let toStringArr = this.activatedRoute.snapshot.url.toString()
					let stringToArr = toStringArr.replace(popIndex, pushIndex);
					let finalArr = stringToArr.split(',');
					//let urlSuffix = '/'+finalArr[3];
					let urlSuffix = '/' + lastIndexOfUrl;
					let redirectTo = this.router.url.replace(urlSuffix, pushIndex);
					this.router.navigate([redirectTo]);

				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_product_add_in_cart'));
			this.router.navigate(['/login']);
		}
		// }
	}
	// End

	// Function for offer model
	makeAnOfferModal(form: NgForm) {
		if (this.authService.isLoggedIn()) {

			if (this.flag == 0) {
				this.commonService.toastError(this.translate.instant('SHOP.please_select_variants_first'));
				return false;
			}

			$('#makeOffer').modal('show');

		} else {
			// this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_make_an_offer'));
			this.router.navigate(['/login']);
		}

	}
	// End

	// Function for delete offer
	deleteExistOffer(data) {
		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('CHAT.you_want_to_delete_existing_offer'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('SELLER_SIDE.yes_delete_it'),
			cancelButtonText: this.translate.instant('SELLER_SIDE.no_keep_it'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				// $('#makeOffer').modal('show');
				this.deleteExistingOffer(data);
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					this.translate.instant('SELLER_SIDE.cancelled'),
					this.translate.instant('CHAT.your_offer_is_safe'),
					this.translate.instant('MYORDER.error_txt')
				)
			}
		});
	}
	// End

	// For offer form reset
	resetOfferForm() {
		this.makeOfferForm.reset();
		this.variantListData = [];
		this.variant = '';
	}
	// End 

	// For delete existing offer
	deleteExistingOffer(chatdata) {

		this.shopService.offerDelete(this.productId, this.productVariantID).subscribe((data) => {
			this.commonService.hideLoader();
			if (data.status == "fail") {
				this.commonService.toastError(data.message);
			} else {
				// this.firebaseService.deleteOfferExist(chatdata)
				$('#makeOffer').modal('show');
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End



	checkNumberLimit(e) {
		if (e.target.value.length > 10 || e.keyCode == 45) {
			e.preventDefault();
			return false;
		}
	}

	// For make an offer 
	makeAnOffer(offerForm: NgForm) {

		if (offerForm.valid) {
			if (Number(this.productPrice) <= Number(offerForm.value.offerPrice)) {
				this.commonService.toastError(this.translate.instant('SHOP.offer_price_should_be_less_than_product_price'));
				return false;
			}

			if (offerForm.value.offerPrice.match('^[0-9]{1,7}((\.[0-9]{1,2}))?$') == null) {
				this.commonService.toastError(this.translate.instant('SHOP.offer_price_only_allowed_decimal_values')); return false;
			}
			let offerPriceString = parseFloat(offerForm.value.offerPrice).toFixed(2);

			let offerData = {
				sellerId: this.productDetail.seller_id,
				buyerId: this.currentUserId,
				productId: this.productDetail.productID,
				productVariantId: this.productVariantID,
				currency: this.productDetail.currency_symbol,
				offerPrice: offerPriceString.toString(),
				productPrice: this.productPrice,
				size: "",
				color: "",
				quantity: "1",
			}

			this.firebaseService.makeAnOfferStore(offerData);
			$('#makeOffer').modal('hide');
			this.firstMessageSend();
			this.resetOfferForm();
			this.ngOnInit();
			this.commonService.toastSuccess(this.translate.instant('SHOP.offer_submitted_successfully'));
			//Redirect to solve selected variant empty after action
			if (this.activatedRoute.snapshot.url.length == 3) {  //get url array and length of array
				let urlSuffix = '/' + this.productVariantID;
				let navigate = this.router.url + urlSuffix;
				this.router.navigate([navigate]);
			} else if (this.activatedRoute.snapshot.url.length == 4) {
				let lastIndexOfUrl = this.activatedRoute.snapshot.url[3].path;
				let popIndex = '/' + lastIndexOfUrl.toString();
				let pushIndex = '/' + this.productVariantID;
				let toStringArr = this.activatedRoute.snapshot.url.toString()
				let stringToArr = toStringArr.replace(popIndex, pushIndex);
				let finalArr = stringToArr.split(',');
				//let urlSuffix = '/'+finalArr[3];
				let urlSuffix = '/' + lastIndexOfUrl;
				let redirectTo = this.router.url.replace(urlSuffix, pushIndex);
				this.router.navigate([redirectTo]);
			}

		} else {
			if (typeof offerForm.value.offerPrice == 'undefined' || offerForm.value.offerPrice == null) {
				this.commonService.toastError(this.translate.instant('SHOP.offer_price_is_required'));
			} else if (offerForm.value.offerPrice.match(/^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/igm) == null) {
				this.commonService.toastError(this.translate.instant('SHOP.offer_price_must_be_vaild'));
			}
		}
	}
	// End

	// For firebase token
	getFirebaseToken() {
		this.buyerFbService.getFirebaseToken(this.productDetail.seller_id).subscribe(userData => {
			let token;
			if (userData.payload.val()) {
				token = userData.payload.val();
				this.firebaseToken = token.firebase_token;
			}
		});
	}
	// End

	firstMessageSend() {
		let chatData = {
			sellerId: this.productDetail.seller_id,
			buyerId: this.currentUserId,
			buyerName: this.authService.getUserDetails().full_name,
			productId: this.productDetail.productID,
			productName: this.productDetail.name,
			firebaseToken: this.firebaseToken,
			isOffer: 1,
			message: this.translate.instant('SHOP.you_have_new_offer_request')
		}

		this.buyerFbService.sendProductMsg(chatData);
	}

	goToChatDashborad(productId) {
		// For check user is login or not
		if (this.authService.isLoggedIn()) {
			this.router.navigate(['/chat/product/', productId]);
		} else {
			this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_chat_with_seller'));
			this.router.navigate(['/login']);
		}

	}

	// For router navigation
	getSellerProfile(seller_id) {
		this.router.navigate(['seller-review', seller_id]);
	}
	// End

	// Add questions form
	addQuestions() {
		if (this.authService.isLoggedIn()) {

			if (this.questionForm.valid) {
				this.commonService.showLoader();
				const formData = new FormData();
				formData.append('product_id', this.productDetail.productID);
				formData.append('description', this.questionForm.get('description').value);

				this.shopService.askQuestions(formData).subscribe((res) => {
					this.commonService.hideLoader();
					this.getProductDetail(this.productId, this.variantId, this.offerItemId)
					this.commonService.toastSuccess(res.message);
					this.questionForm.reset();

				}, err => {
					this.commonService.errorhandler(err);
					this.questionForm.reset();
				});
			} else {
				this.validateAllFormFields(this.questionForm); //{7}

			}
		} else {
			this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_chat_with_seller'));
			this.router.navigate(['/login']);

		}

	}
	// End


	// Function for check validate form 
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
	// End


	getVAriantssssss(value) {
	}

	// For variants value 
	getValueColor(variantsss, index, e, varnm) {
		this.flag = 0;
		var id;
		var product_variant_id;
		var ind = index + 1;

		if (this.variants.length != ind) {

			id = this.variants[ind].variantID;
			product_variant_id = ""

			if (product_variant_id == undefined) {
				product_variant_id = 0;
			}

			this.key = '1';
			this.fruits = [];
			$(`.sp${index}`).removeClass('specialClass');
			this.commonService.showLoader();
			if (this.authService.isLoggedIn()) {
				var userId = this.currentUserId
			} else {
				userId = 0;
			}
			this.buyerService.getVariant(this.productId, variantsss, id, product_variant_id, userId).subscribe((res) => {
				this.axz = res.data
				this.axz.map((val) => {
					val.variant_value.map(async (valuee) => {
						this.fruits.push(valuee);
					});
				})

				var result = this.fruits.reduce((unique, o) => {
					if (!unique.some(obj => obj.variantValueID === o.variantValueID && obj.variantValueID === o.variantValueID)) {
						unique.push(o);
					}
					return unique;
				}, []);


				this.fruits = result;
				this.variants[ind].variant_value = this.fruits;
				this.commonService.hideLoader();

			}, err => {
				this.commonService.errorhandler(err);
				// this.makeOfferForm.reset();
			});


		} else {



			if (this.productDetail.variants_options.length == 1) {
				this.product_variant_id = this.productDetail.variant_list[varnm].productVariantID;
				this.flag = 1;

			} else {
				this.fruits.map((values) => {
					if (values.variantValueID == variantsss) {
						this.product_variant_id = values.product_variant_id;
					}
				})
			}


			if (this.product_variant_id == undefined) {
				return false;
			}

			$(`.sp${index}`).removeClass('specialClass');

			this.flag = 1;

			id = "";
			if (this.authService.isLoggedIn()) {
				var userId = this.currentUserId
			} else {
				userId = 0;
			}
			this.buyerService.getVariant(this.productId, variantsss, id, this.product_variant_id, userId).subscribe((productDetailData) => {
				this.productVariantIDWithVAriant = productDetailData.data[0];
				if (productDetailData.status == 'success') {
					this.commonService.scrollToTop();

					this.galleryImages = [];
					this.galleryImage = this.productVariantIDWithVAriant.variant_image;
					this.productOfferPrice = this.productVariantIDWithVAriant.offer_price_with_admin_commission;
					this.productSellingPrice = this.productVariantIDWithVAriant.selling_price;
					this.discountPercent = Math.round(this.productVariantIDWithVAriant.discount_percent);


					this.galleryImage.forEach(element => {
						let galleryImagesData = {
							small: `${element.variant_image_url}${element.variant_image}`,
							medium: `${element.variant_image_url}${element.variant_image}`,
							big: `${element.variant_image_url}${element.variant_image}`
						};
						this.galleryImages.push(galleryImagesData);

					});

					this.getFirebaseToken();
					this.setViewCount();
				} else {
					this.productDetail = [];
				}



			}, err => {
				this.commonService.errorhandler(err);
				// this.makeOfferForm.reset();
			});




		}


	}
	// End


	// selectedShow(e) {
	// }


	// For report tittle dropdown option list
	reportTitleget() {
		this.shopService.reportTitle().subscribe((data) => {
			this.commonService.showLoader();
			this.commonService.hideLoader();
			if (data.status == "fail") {
				this.commonService.toastError(data.message);
			} else {
				this.reportTitle = data.data;
			}
		}, err => {
			this.commonService.errorhandler(err);
		});

	}
	// End

	// Function For Report
	reportFormSubmit() {
		if (this.reportForm.valid) {
			const formData = new FormData();

			formData.append('product_id', this.productId);
			formData.append('description', this.reportForm.get('report_description').value);
			formData.append('title_id', this.reportForm.get('report_title').value);

			// return false;
			this.commonService.showLoader();

			this.shopService.reportProduct(formData).subscribe((data) => {
				this.commonService.showLoader();

				this.commonService.hideLoader();
				if (data.status == "fail") {
					this.commonService.toastError(data.message);
				} else {
					$('#reportmodel').modal('hide');
					this.reportForm.reset();


					this.commonService.toastSuccess(data.message);

				}
			}, err => {
				this.commonService.errorhandler(err);

			});
		} else {
			this.validateAllFormFields(this.reportForm); //{7}
		}
	}
	// End

	// Function for hide report modal
	closeReportModel() {
		$('#reportmodel').modal('hide');
		this.reportForm.reset();
	}
	// End

	// make offer function
	makeOfferSubmit() {

		if (this.makeOfferForm.valid) {
			if (this.makeOfferForm.get('offer_price').value <= 0) {
				this.commonService.toastError(this.translate.instant('SHOP.please_add_valid_price')); return false;
			}


			const formData = new FormData();
			formData.append('offer_price', this.makeOfferForm.get('offer_price').value);
			formData.append('product_variant_id', this.productVariantIDWithVAriant.productVariantID);
			formData.append('product_id', this.productId);


			if (this.productDetail.is_offer == 1) {
				formData.append('product_variant_price', this.productOfferPrice);
			} else {
				formData.append('product_variant_price', this.productSellingPrice);
			}

			formData.append('seller_id', this.productDetail.seller_id);
			formData.append('product_counter_price', '');
			formData.append('quantity', '1');


			this.commonService.showLoader();
			this.buyerService.makeAnOffer(formData).subscribe((res) => {
				this.commonService.hideLoader();
				this.commonService.toastSuccess(res.message);
				this.makeOfferForm.reset();
				$('#makeOffer').modal('hide');

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
				this.makeOfferForm.reset();
			});
		} else {
			this.validateAllFormFields(this.makeOfferForm); //{7}

		}
	}


		// empty value validation 
		trimValue(formControl) {
			((typeof formControl.value) && formControl.value.trim() == "") ?
				formControl.setValue("") : formControl.setValue(formControl.value);
		}
//open popup for delete
		confirmation() {
			$('#deleteItem').modal('hide');
			// this.ngOnInit();
			this.router.navigate(['/product-offers']);

		}

		confirmationPopup() {
			$('#deleteItem').modal('show');

		}
	checkData(){

		this.currentUserId = this.authService.getCurrentUserId(); //current user ID

		this.buyerService.checkProduct(this.currentUserId, 1,'1').subscribe(
			(productCheck) => {

					
						if (productCheck.flag == 0) {
							this.confirmationPopup();
						} else {
							this.router.navigate(['/checkout/offer', this.offerItemID]);
						}
					
				
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});


	}
	// End


	    // End
		redirectFunction(detail, name,id1){
			var name1 = (name.split(' ').join(''));
		
	
			var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
				return '-';
			});
	
			var removeSpace = (url.split('%').join('-'));
			var validUrl = detail+'/'+removeSpace+'/'+id1;
			this.router.navigate([validUrl]);
		
		  }
	
}
