import { Component, OnInit } from '@angular/core';
import { Crisp } from "crisp-sdk-web";

Crisp.configure('6e71762d-9f1b-4b05-98bc-6001f3dc91c5', {
  autoload: false
});


@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {

  constructor() { }

  ngOnInit() {


    Crisp.load();

    
  }

  public ngOnDestroy(): void {

  }
}
