import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

declare const getTimeZone: any;

@Injectable()

export class AuthInterceptor implements HttpInterceptor {

    constructor(
        private authService: AuthService,
        private commonService: CommonService
    ) { }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        /* 
        * Common header data pass
        */

        let language = this.commonService.getLanguage();

        req = req.clone({
            setHeaders:
            {
                'Device-Id': this.authService.getDeviceId(),
                'Device-Type': '3',
                'Device-Timezone': getTimeZone(),
                'Device-Language': language,
            }
        }
        );

        /* 
        * Authorization data appned when user logged in
        */
        if (this.authService.isLoggedIn()) {
            const authToken = this.authService.getAuthorizationToken();
            let language = this.authService.getLanguage();

            req = req.clone({
                setHeaders: {
                    Authorization: `Bearer ${authToken}`,
                    'Device-Language': language,
                }
            });
        }

        /*
        * Check method and appned header according to conditions
        */
        if (req.method == 'PUT' || req.method == 'PATCH') {
            req = req.clone({ setHeaders: { 'Content-Type': 'application/x-www-form-urlencoded' } });
        }

        return next.handle(req);
    }
}