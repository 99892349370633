import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgxNumberSpinModule } from 'ngx-number-spin';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ImageCropperModule } from 'ngx-image-cropper';
import { HeaderBuyerComponent } from '../common/header-buyer/header-buyer.component';
import { FooterBuyerComponent } from '../common/footer-buyer/footer-buyer.component';
import { BuyerRoutingModule } from './buyer-routing.module';
import { MyAccountComponent } from './my-account/my-account.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { CartListComponent } from './cart-list/cart-list.component';
import { UserAddressComponent } from './user-address/user-address.component';
import { CardListComponent } from './card-list/card-list.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentOptionComponent } from './payment-option/payment-option.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyOrderDetailComponent } from './my-order-detail/my-order-detail.component';
import { ChatComponent } from './chat/chat.component';
import { OfferCartComponent } from './offer-cart/offer-cart.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { CompleteSwitchProfileComponent } from './complete-switch-profile/complete-switch-profile.component';
import { DisputeComponent } from './dispute/dispute.component';
import { TrashChatComponent } from './trash-chat/trash-chat.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { NgxGalleryModule } from 'ngx-gallery';
import { CarouselModule } from 'ngx-owl-carousel-o';



//For Localization
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ProductOffersComponent } from './product-offers/product-offers.component';
import { ProductOffersDetailsComponent } from './product-offers-details/product-offers-details.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';
import { NoCommaPipe } from './no-comma.pipe';

// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/language/', '.json');
}

@NgModule({
  declarations: [HeaderBuyerComponent, FooterBuyerComponent, MyAccountComponent, WishListComponent, CartListComponent, UserAddressComponent, CardListComponent, CheckoutComponent, PaymentOptionComponent, ConfirmationComponent, MyOrdersComponent, MyOrderDetailComponent, ChatComponent, OfferCartComponent, EditAddressComponent, CompleteSwitchProfileComponent, DisputeComponent, TrashChatComponent, ProductOffersComponent, ProductOffersDetailsComponent, QuestionAnswerComponent, NoCommaPipe],
  imports: [
    CommonModule,
    BuyerRoutingModule,
    FontAwesomeModule,
    FormsModule,
    ReactiveFormsModule,
    NgxNumberSpinModule,
    Ng2TelInputModule,
    NgbModule,
    ImageCropperModule,
    GooglePlaceModule,
    NgMultiSelectDropDownModule,
    SignaturePadModule,
    NgxGalleryModule,
    CarouselModule,


    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }) //For Localization
  ],
  exports: [
    HeaderBuyerComponent,
    FooterBuyerComponent
  ]
})
export class BuyerModule { }
