import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WalletService {
  apiUrl = environment.apiUrl;
  apiUrlVersionTwo = environment.apiUrlVersionTwo;


  constructor(private http: HttpClient) { }

  getWalletAmount() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}wallet/wallet-amount`);
  }

  getWalletList(limit, offset, status, payment_mode) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}wallet/seller-request-list?limit=${limit}&offset=${offset}&status=${status}&payment_mode=${payment_mode}`);
  }

  getwalletDetail(id) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}wallet/seller-request-detail?id=${id}`)
  }


  deleteWalletRequest(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}wallet/seller-request-delete`, formData)
  }


  // Get provience
  getProvienceList() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}wallet/proviince`);
  }

  // Post withdraw request
  withdrawRequstPost(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}wallet/withdraw-request`, formData);
  }

  // Wallet setting api
  getWalletType() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}wallet`);
  }





}
