import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { AuthService as SocialService } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { FirebaseService } from '../services/firebase.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
	selector: 'app-mobile-varification',
	templateUrl: './mobile-varification.component.html',
	styleUrls: ['./mobile-varification.component.css']
})
export class MobileVarificationComponent implements OnInit {
	otpVerificationForm: FormGroup;
	userdata: any;
	countryName: any;
	countryCode: any;
	phoneNum;
	dialCode;
	isNumberShow;
	userDetailsData: any;
	emailGet: any;
	getEmailData;
	verifyPhone: any;
	profileDataGet: any;
	constructor(
		private router: Router,
		private authService: AuthService,
		private commonService: CommonService,
		private fb: FormBuilder,
		private socialService: SocialService,
		private firebaseService: FirebaseService,
		private translate: TranslateService
	) { translate.use(this.commonService.getLanguage()); }

	ngOnInit() {


		if(this.authService.isLoggedIn() == false){
			this.router.navigate(['/']);
			return false;
	  
		}

		var userDetails = this.authService.getUserDetails();

		if (userDetails.is_email_verified == '1' && userDetails.is_phone_verified == '1') {
			this.router.navigate(['/seller/add-business']); return false;
		}

		this.userdata = JSON.parse(localStorage.getItem('userDetails'));
		this.phoneNum = this.userdata.data.userDetail.phone_number;

		this.userdata = JSON.parse(localStorage.getItem('userDetails'));
		this.phoneNum = this.userdata.data.userDetail.phone_number;
		this.dialCode = this.userdata.data.userDetail.dial_code;
		this.countryName = localStorage.getItem('countryName');
		this.countryCode = localStorage.getItem('countryCode');
		this.isNumberShow = localStorage.getItem('showNumber');

		this.otpVerificationForm = this.fb.group({
			otp: ['', [Validators.required, Validators.minLength(6)]],
			otpVerificationForm: ['']
		});

		this.getEmailData = JSON.parse(localStorage.getItem(environment.dataStore.userData));
		this.emailGet = this.getEmailData.user_detail.email;

	}

	get otp() { return this.otpVerificationForm.get('otp'); }


	otpVerify() {

		if (this.otpVerificationForm.valid) {
			const formData = new FormData();
			formData.append('phone_number', this.userdata.data.userDetail.phone_number);
			formData.append('dial_code', this.userdata.data.userDetail.dial_code);
			formData.append('country', this.countryName);
			formData.append('country_code', this.countryCode);
			formData.append('otp', this.otpVerificationForm.get('otp').value);
			formData.append('is_phone_show', this.isNumberShow);


			this.commonService.showLoader();

			this.authService.verifySendOtp(formData).subscribe(res => {
				this.getEmailData = JSON.parse(localStorage.getItem(environment.dataStore.userData));
				this.commonService.hideLoader();
				this.commonService.toastSuccess(res.message);
				this.otpVerificationForm.reset();
				this.userDetailsData = res.data.user_detail;

				//  local storage data update
				this.getEmailData.user_detail.mobile_number = this.userdata.data.userDetail.phone_number;
				this.getEmailData.user_detail.is_phone_verified = "1";
				// this.getEmailData.user_detail.mobile_number = this.userdata.data.userDetail.phone_number;
				let userdatasetLocal = localStorage.setItem(environment.dataStore.userData, JSON.stringify(this.getEmailData));
				// email and mobile verified router navigate page
				if (this.userDetailsData.is_email_verified == 1 && this.userDetailsData.is_phone_verified == 1) {

					this.router.navigate(['seller/add-business']);
				}


			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
				this.otpVerificationForm.reset();
			});
		}else{
			this.validateAllFormFields(this.otpVerificationForm); //{7}

		}
	}



	// Function for check form validate 
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}



	
	// End

	reSendOtp() {
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('email', this.emailGet);

		this.authService.reSendOtp(formData).subscribe(data => {
			this.commonService.hideLoader();
			this.commonService.toastSuccess(data.message);
		}, err => {
			this.commonService.errorhandler(err);
		});
	}

	sendMobileOtp() {
		this.commonService.showLoader();

		const formData = new FormData();
		formData.append('phone_number', this.userdata.data.userDetail.phone_number);
		formData.append('dial_code', this.userdata.data.userDetail.dial_code);
		formData.append('is_phone_show', this.userdata.data.userDetail.is_phone_show);

		this.authService.otpSendMobile(formData).subscribe(data => {
			this.commonService.hideLoader();

			this.commonService.toastSuccess(data.message);
		}, err => {
			this.commonService.errorhandler(err);
		});

	}

}
