import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faAngleDoubleRight, faCamera, faTrashAlt, faEdit, faStar, faEye, faStarHalfAlt, faSkating } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarBlank } from '@fortawesome/free-regular-svg-icons';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { SellerService } from '../services/seller.service';
import { GooglePlaceDirective } from "ngx-google-places-autocomplete";
import { FirebaseService } from '../services/firebase.service';
import { BuyerService } from '../../buyer/services/buyer.service';
import { TranslateService } from '@ngx-translate/core';
import { element } from 'protractor';
import { stringify } from 'querystring';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare const $: any;
declare var Stripe: any;

@Component({
	selector: 'app-seller-account',
	templateUrl: './seller-account.component.html',
	styleUrls: ['./seller-account.component.css']
})
export class SellerAccountComponent implements OnInit {
	faAngleDoubleRight = faAngleDoubleRight;
	faCamera = faCamera;
	faTrashAlt = faTrashAlt;
	faEdit = faEdit;

	faStar = faStar;
	faStarBlank = faStarBlank;
	faStarHalfAlt = faStarHalfAlt;
	faEye = faEye;
	editprofileForm: FormGroup;
	changePasswordForm: FormGroup;
	feedbackForm: FormGroup;
	editWarehouseAddressForm: FormGroup;
	isPassword: any;
	userProfile: any = {};
	sellerRating: any = {};
	sellerCommission: any = {};
	avatar: any = "";
	sellerName: any = "";
	is_seller_verified: any = "";
	sellerEmail: any = "";
	country_code: string = 'US';
	initialCountrySet: string = 'US';
	profile_country_code: string = 'US';
	warehouse_country_code: string = 'US';
	ShowFilter = false;
	disabled = false;
	// dropdownProvinceSettings: any = {};
	provinceList: any = [];
	selectedItems = [];
	isPlan: any = 0;
	sellerPlan: any = {};
	cardList: any = [];
	cardListCount;
	stripe;
	card;
	cardErrors;
	verifyPrice: any = "";
	cardID: any;
	dial_code: string = '+1';
	warehouse_dial_code: string = '+1';
	country: string = 'United States';
	warehouse_country_name: string = 'United States';
	userIdGet: any = [];
	getUserProfileData: any = {};
	categoryName: string = '';
	nameSeller: any;
	emailSeller: any;
	phoneSeller: any;
	reviewSeller: any;
	totalRating: any;
	sellerImage: any = "";
	sellerVerified: any = "";
	phone_dial_code: any;
	is_email_verified: any;
	is_phone_verified: any;
	social_id: any;
	emailGet: any;
	profileResponse: any;
	profileEmail: any;
	profilePhone: any;
	dialCode: any;
	otpVerificationForm: FormGroup;
	getEmailData: any;
	emailPro: any;
	verifyOt: any;
	getUserProfileInfoLocal: any;
	userInfoLocal: any;
	getValue: any;
	isChecked: any;
	isPickup: any;
	payMethod: any;
	directPay: any;
	isDirectPay: any;

	@ViewChild("placesRef", { static: false }) placesRef: GooglePlaceDirective;
	@ViewChild('cardElement', { static: false }) cardElement: ElementRef;
	shippingForm: FormGroup;
	shiipingChargesList: any = [];
	shiiping_id: any;
	shippingListCount: number;
	flag: any = 0;
	index: any;
	shippingIds: any[];
	comissionData: any;
	commission_range: any;
	isCheckedEmail: any;
	isCheckedphone: any;
	isPhoneShow: any;
	comissionDataCount: any;
	sellerCategory: any;
	sellerCategoryid: any;
	userIdGet1: any;
	municipalitiesAddDataList: any;
    municipalitiesDataList: any = [];
    provinceChanged: any = 0;

	warehouseAddressList: any = [];
    warehouseAddressListCount: any;
	currentUserId: any;
	selectedMunicipality: number | null = null;
	selectedMunicipalityId: any;
	selectedProvinceId: any;
	loadMoreBtnShow: number;

	limitOfAddressList: number = 10;
    offsetOfAddressList: number = 0;
    addressListCount: number;

	constructor(
		private router: Router,
		private authService: AuthService,
		private commonService: CommonService,
		private fb: FormBuilder,
		private sellerService: SellerService,
		private firebaseService: FirebaseService,
		private buyerService: BuyerService,
		private translate: TranslateService,
		private toastr: ToastrService,
		private authenticationService: AuthenticationService,


	) { translate.use(this.commonService.getLanguage()); }

	ngOnInit() {

		const str = this.router.url;
		const regex = RegExp('seller/seller-account/commission');
		if (regex.test(str) == true) {
			$('a[href="#csTab4"]').trigger('click');
		}

		// const str1 = this.router.url;
		const regex1 = RegExp('seller/seller-account/shipping');
		if (regex1.test(str) == true) {
			$('a[href="#csTab33"]').trigger('click');
		}

		const regexWarehouse = RegExp('seller/seller-account/warehouse');
		if (regexWarehouse.test(str) == true) {
			$('a[href="#csTab5"]').trigger('click');
		}
		// this.initialCountrySet = this.authService.getUserDetails().profile_country_code;	



		// window.location.reload();
		// For edit profile
		this.editprofileForm = this.fb.group({
			full_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
			email: ['', [Validators.required, Validators.email]],
			business_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
			license: [''],
			address: [''],
			latitude: [''],
			longitude: [''],
			profile_country: ['', Validators.required],
			province: [''],
			mobile: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')]],
		});
		// End


		let emaildata = JSON.parse(localStorage.getItem(environment.dataStore.userData));
		this.userIdGet1 = emaildata.user_detail.userID;


		// For shipping price form validation
		this.shippingForm = this.fb.group({
			province_shipping: ['', [Validators.required]],
			province_id: ['', [Validators.required]],
			add_municipalities: ['', [Validators.required]],
			update_province_shipping: [''],
		})
		// End



		// OTP Verify form data
		this.otpVerificationForm = this.fb.group({
			otp: ['', [Validators.required, Validators.minLength(6)]],
			otpVerificationForm: ['']
		});
		// End Code


		//For change Password
		if (this.isPassword != "" && this.isPassword != null) {
			this.changePasswordForm = this.fb.group({
				oldPass: ['', [Validators.required, Validators.maxLength(50)]],
				newPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
				cnfPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],

				// newPass: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^(?  =.*?[A-Za-z])(?=.*?[0-9]).{8,}$')]],
				// cnfPass: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('^(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}$')]]

			});
		} else {
			this.changePasswordForm = this.fb.group({
				oldPass: [''],
				newPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],
				cnfPass: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(50)]],

			});
		}
		// End

		//For Write Feedback form validation
		this.feedbackForm = this.fb.group({
			feedback: ['', [Validators.required]],
		});


		// End
		this.getUserData(this.userIdGet1);
		this.getProvinceList(); //For province list
		// this.sellerSubscription(); //For subscription
		this.getCardList(); //For card list 
		this.getShippingList(); //For shipping price list
		this.getComissionList(); // get commision List

		this.isPassword = this.authService.getUserDetails().password;
		this.getUserProfileInfoLocal = this.authService.getUserDetails();
		this.emailPro = emaildata.user_detail.email;
		this.userInfoLocal = JSON.parse(localStorage.getItem(environment.dataStore.userData));
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | My Account');

		//For Warehouse Address form validation 
		this.editWarehouseAddressForm = this.fb.group({

			address: ['', [Validators.required]],
			add_province: ['', [Validators.maxLength(50), Validators.required]],
			municipalities: ['', [Validators.maxLength(50), Validators.required]],
			city: ['', [Validators.required, Validators.minLength(2)]],
			// warehouse_country: ['', Validators.required],
			business_zip_code: ['', [Validators.required, Validators.minLength(5)]],
			warehouse_mobile_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')]],
			name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]]

		});
		// End

		this.getWarehouseAddress();  //For get warehouse address
	}

	// For address form reset 
	resetForm() {
		this.editWarehouseAddressForm.reset();
	}
	// End

	//For get warehouse address list
	getWarehouseAddress(loadMore = '') {
		this.commonService.showLoader(); // show loader

		this.selectedProvinceId = this.selectedProvinceId === undefined ? '' : this.selectedProvinceId;
		this.selectedMunicipalityId = this.selectedMunicipalityId === undefined ? '' : this.selectedMunicipalityId;

		this.sellerService.getWarehouseAddress(this.selectedProvinceId,this.selectedMunicipalityId,this.limitOfAddressList,this.offsetOfAddressList).subscribe( // call api for get warehouse address 
			(warehouseAddressList) => {
								
				this.commonService.hideLoader(); // hide loader 
				if (warehouseAddressList.status == 'success') {

					if (warehouseAddressList.data.warehouseAddress.list.length === 0) {

						this.warehouseAddressListCount = 0; // assign address count is 0 when list is empty
					}else{
						
						this.warehouseAddressListCount = 1; // assign address count is 1 when list is not empty
					}
					
					// console.log('warehouseAddressList 1111',warehouseAddressList.data.warehouseAddress.nextOffset);
					// nextOffset totalCount



					if(warehouseAddressList.data.warehouseAddress.nextOffset != 0){
						// this.offsetOfAddressList = Number(this.offsetOfAddressList) + Number(this.limitOfAddressList);
						this.offsetOfAddressList = warehouseAddressList.data.warehouseAddress.nextOffset;

						this.loadMoreBtnShow = 1;
						
						$('.loadMoreBtn').show(); // This will hide the div
					}else{
						this.loadMoreBtnShow = 0;
						$('.loadMoreBtn').hide(); // This will hide the div
					}

					console.log('aaasssddd',this.offsetOfAddressList);
					

					// Append new data to the existing list
					if (this.warehouseAddressList && Array.isArray(this.warehouseAddressList) && loadMore != '') {
						this.warehouseAddressList = [
							...this.warehouseAddressList, // previous data
							...warehouseAddressList.data.warehouseAddress.list, // new data
						];
					} else {
						// Initialize the list if not already defined
						this.warehouseAddressList = warehouseAddressList.data.warehouseAddress.list;
					}

				}
			}, err => {
				this.commonService.hideLoader(); // hide loader 
				this.commonService.errorhandler(err); // handle error 
		});
	}
	// End

	clearFilter(){

		(document.getElementById('provinceDropdown') as HTMLSelectElement).value = '';
		(document.getElementById('municipalityDropdown') as HTMLSelectElement).value = '';

		this.selectedProvinceId = '';
		this.selectedMunicipalityId = '';
		this.warehouseAddressListCount = 1;

		this.offsetOfAddressList = 0;
		this.getWarehouseAddress();  //For get warehouse address
	}

	loadMoreAddresses(){

		this.getWarehouseAddress('1');  //For get warehouse address
	}

	// For edit address data
	editAddData(addData) {
		this.sellerService.userAddress = addData;
		this.router.navigate(['edit-warehouse-address']);
	}
	// End

	// For delete address 
	deleteWarehouseAddress(addressId) {

		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('COMMON.delete_addres'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('FOOTER.ok_button'),
			cancelButtonText: this.translate.instant('COMMON.cancel'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.commonService.showLoader();

				this.sellerService.deleteWarehouseAddress(addressId).subscribe((data) => {
					this.commonService.hideLoader();

					if (data.status == 'fail') {
						this.commonService.toastError(data.message);
					} else {
						this.commonService.toastSuccess(data.message);
						this.offsetOfAddressList = 0;
						// get all warehouse address list
						this.getWarehouseAddress();
					}

				}, err => {
					this.commonService.hideLoader();
					this.commonService.errorhandler(err);
				});
			}
		});
	}
	// End
	
	// For address form validation
	get address() { return this.editWarehouseAddressForm.get('address'); }
	get add_province() { return this.editWarehouseAddressForm.get('add_province'); }
	get municipalities() { return this.editWarehouseAddressForm.get('municipalities'); }
	get city() { return this.editWarehouseAddressForm.get('city'); }
	// get warehouse_country() { return this.editWarehouseAddressForm.get('warehouse_country'); }
	get business_zip_code() { return this.editWarehouseAddressForm.get('business_zip_code'); }
	get warehouse_mobile_number() { return this.editWarehouseAddressForm.get('warehouse_mobile_number'); }
	get name() { return this.editWarehouseAddressForm.get('name'); }
	// End

	// For edit warehouse address form 
	editWarehouseAddressSubmit() {

		if (this.editWarehouseAddressForm.valid) {
			this.commonService.showLoader();

			var formData = new FormData();
			formData.append('address', this.editWarehouseAddressForm.get('address').value);
			// formData.append('country', this.editWarehouseAddressForm.get('warehouse_country').value);
			formData.append('zip_code', this.editWarehouseAddressForm.get('business_zip_code').value);
			formData.append('city', this.editWarehouseAddressForm.get('city').value);
			formData.append('province', this.editWarehouseAddressForm.get('add_province').value);
			formData.append('municipalities', this.editWarehouseAddressForm.get('municipalities').value);
			formData.append('country_code', this.warehouse_country_code);
			formData.append('phone_dial_code', this.warehouse_dial_code);
			formData.append('country', this.warehouse_country_name);
			formData.append('mobile_number', this.editWarehouseAddressForm.get('warehouse_mobile_number').value);
			formData.append('full_name', this.editWarehouseAddressForm.get('name').value);


			// formData.forEach((value, key) => {
			// 	console.log(key + " " + value)
			// });
			// return false;
			this.sellerService.addWarehouseAddress( formData).subscribe((data) => {
				
				this.commonService.hideLoader();
				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					
					this.offsetOfAddressList = 0;
					(document.getElementById('provinceDropdown') as HTMLSelectElement).value = '';
					(document.getElementById('municipalityDropdown') as HTMLSelectElement).value = '';
					this.selectedProvinceId = '';
					this.selectedMunicipalityId = '';

                    this.getWarehouseAddress();
                    this.warehouseAddressListCount = 1;
                    this.resetForm();
                    this.getProvinceList();
                    this.municipalitiesAddDataList = [];
                    $('#addWarehouseAddress').modal('hide');
					// Reload the page
					location.reload();
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			// validate all form fields
			this.validateAllFormFields(this.editWarehouseAddressForm); //{7}
			// return false;
		}
	}
	// End

	// get provice id on change province 
	onProvinceChange(event: Event): void {
		const selectedProvinceID = (event.target as HTMLSelectElement).value;
		this.selectedProvinceId = selectedProvinceID;
		this.getMunicipalities(selectedProvinceID);
		this.provinceChanged = 1;
		this.editWarehouseAddressForm.get('municipalities').setValue('');
		(document.getElementById('municipalityDropdown') as HTMLSelectElement).value = '';
		this.offsetOfAddressList = 0;
		this.selectedMunicipalityId = '';
		this.getWarehouseAddress();
	}

	// For get municipalities list 
	getMunicipalities(province_ids) {

		this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
			this.commonService.hideLoader();
			if (municipalitiesData.status == 'success') {
				this.municipalitiesDataList = municipalitiesData.data.municipalities;
			} else {
				this.municipalitiesDataList = [];
			}

			// console.log('this.municipalitiesDataList---',this.municipalitiesDataList);
			

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End 

	onSelectionChange(): void {
        console.log('Selected Municipality ID:', this.selectedMunicipality);
		this.selectedMunicipalityId = this.selectedMunicipality;

		this.offsetOfAddressList = 0;
		this.getWarehouseAddress();
    }







	// get provice id on change province 
	onAddProvinceChange(event: Event): void {
		const selectedAddProvinceID = (event.target as HTMLSelectElement).value;
		this.getAddMunicipalities(selectedAddProvinceID);
		this.shippingForm.get('add_municipalities').setValue('');
	}
	// For get municipalities list 
	getAddMunicipalities(province_ids) {

		this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
			this.commonService.hideLoader();
			if (municipalitiesData.status == 'success') {
				this.municipalitiesAddDataList = municipalitiesData.data.municipalities;
			} else {
				this.municipalitiesAddDataList = [];
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}

	// function for reset verification
	resetVerificationForm() {
		$("#otpVerify").modal("hide");
		this.ngOnInit();
	}
	// function for reset verification
	resetEmailForm() {
		$("#emailVerify").modal("hide");
		this.ngOnInit();
	}

	ngOnDestroy(): void {
		$('#cardVerifyModal').modal('hide');
		$("#numberChangePopUp").modal("hide");
		$("#otpVerify").modal("hide");
		$('#addAddress').modal('hide');
		$("#changePassword").modal("hide");
		$("#deleteShippingModel").modal("hide");
		$("#profileDelete").modal("hide");
		$('#cardVerifyModal').modal('hide');
		$('#addCard').modal('hide');
		$("#leaveFeedback").modal("hide");
		$("#otpVerify").modal("hide");
		$("#emailVerify").modal("hide");
		$("#deleteShippingModel").modal("hide");

		Swal.close();


		// this.disputeSubscription1.unsubscribe();
	}


	// For shipping price form reset
	cancalShippingUpdate(index) {
		this.shiipingChargesList[index].index_value = "hide";
	}
	// End

	// For shipping price list 
	getShippingList() {
		this.shippingIds = [];

		// this.commonService.showLoader();
		var shipping_id = '';
		var offset = '';

		this.sellerService.shippingChargesget(shipping_id, offset).subscribe(
			(data) => {
				this.commonService.hideLoader();
				if (data.status == 'success') {
					this.shiipingChargesList = data.data


					if (this.shiipingChargesList.length == 0) {
						this.shippingListCount = 0;
					} else {
						this.shippingListCount = 1;
					}

					this.shiipingChargesList.forEach(object => {
						object.index_value = 'hide';
						this.shippingIds.push(object.province_id);
					});

					// this.provinceList = this.provinceList.filter((val) => !this.shippingIds.includes(val.provinceID));



				} else {
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End

	// For update shipping price 
	editShippingForm(flag, index) {
		this.flag = 1;
		this.shiipingChargesList[index].index_value = "show"
	}
	// End 

	checkChar(e) {
		e.preventDefault();
	}

	// For update shipping price form
	updateShippingForm(index, shipping_id, province_id,municipalities) {
		var value = $('#shippingPrice').val();

		if (value == "") {

			this.commonService.toastError(this.translate.instant('SHIPPINGPRICE.price'));
			return false;

		}

		if (value < 0 || value < 0.00) {

			// this.toastr.error(this.translate.instant('PROFILE.shipping_price_issue'));
			this.commonService.toastError(this.translate.instant('PROFILE.shipping_price_issue'));
			return false;

		}


		// if (this.shippingForm.valid) {
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('province_id', province_id);
		formData.append('municipalities',municipalities);
		formData.append('shipping_charges', value);
		formData.append('shipping_id', shipping_id);

		this.sellerService.shippingChargesUpdate(formData).subscribe(
			(data) => {
				+

					this.commonService.hideLoader();

				if (data.status == 'success') {
					this.commonService.toastSuccess(data.message);
					this.getShippingList();

				} else {
					this.commonService.toastError(data.message);
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End

	// For shipping price delete confirmation modal open
	shiipingChargeModel(index, id) {

		this.shiiping_id = id;
		this.index = index;



		Swal.fire({
			// title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('PROFILE.are_you_sure_yo_want_to_delete'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('COMMON.ok'),
			cancelButtonText: this.translate.instant('COMMON.cancel'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.shippingChargeDelete();
			}
		});

		// $('#deleteShippingModel').modal({
		// 	backdrop: 'static',
		// 	keyboard: false
		// })

	}

	// show Email checkbox value get on click (usertype == seller)
	getValueShowemail(event) {
		if (this.isCheckedEmail == 0) {
			this.isCheckedEmail = 1;
		}
		else {
			this.isCheckedEmail = 0;
		}
		this.isCheckedEmail;
	}
	// show Email checkbox value get on click (usertype == seller)
	getValueShowphone(event) {
		if (this.isPhoneShow == 0) {
			this.isPhoneShow = 1;
		}
		else {
			this.isPhoneShow = 0;
		}
		this.isPhoneShow;
	}

	getComissionList() {
		this.sellerService.getComissionList().subscribe(comissionData => {

			if (comissionData.data.commission_range.length > 0) {
				this.comissionDataCount = 1;
			} else {
				this.comissionDataCount = 0;

			}




			this.commonService.hideLoader();
			if (comissionData.status == 'success') {
				this.comissionData = comissionData.data;
				this.commission_range = comissionData.data.commission_range;
				this.sellerCommission = this.comissionData.commission_data;

			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For shipping charges list delete 
	shippingChargeDelete() {
		this.commonService.showLoader();

		const formData = new FormData();
		formData.append('shipping_id', this.shiiping_id);

		this.sellerService.shippingChargeDelete(formData).subscribe(
			(data) => {
				var index = this.provinceList.findIndex(x => x.productID === this.shiiping_id);
				this.shippingIds.slice(index, 1);
				this.getProvinceList();
				this.getShippingList();
				this.commonService.hideLoader();

				if (data.status == 'success') {
					this.commonService.toastSuccess(data.message);
					$("#deleteShippingModel").modal("hide");
				} else {
					this.commonService.toastError(data.message);
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

	}
	// End

	// For shipping charges add according provience
	ShippingFormSubmit() {

		if (this.shippingForm.valid) {

			if (this.shippingForm.get('province_shipping').value < 0 || this.shippingForm.get('province_shipping').value < 0.00) {
				this.commonService.toastError(this.translate.instant('PROFILE.shipping_price_issue'));
				return false;
			}
			this.commonService.showLoader();
			const formData = new FormData();
			formData.append('province_id', this.shippingForm.get('province_id').value);
			formData.append('shipping_charges', this.shippingForm.get('province_shipping').value);
			formData.append('municipalities', this.shippingForm.get('add_municipalities').value);


			this.sellerService.shippingChargesAdd(formData).subscribe(
				(data) => {
					this.commonService.hideLoader();

					if (data.status == 'success') {
						var values = this.shippingForm.get('province_id').value
						this.commonService.toastSuccess(data.message);
						this.getProvinceList();
						this.getShippingList();
						this.municipalitiesAddDataList = [];
						this.shippingForm.reset();
					} else {
						this.commonService.toastError(data.message);
						this.getProvinceList();
						this.getShippingList();
						this.municipalitiesAddDataList = [];
						this.shippingForm.reset();
					}
				}, err => {
					this.commonService.hideLoader();
					this.commonService.errorhandler(err);
				});


		} else {
			this.validateAllFormFields(this.shippingForm); //{7}
		}

	}
	// End

	restrictValue(type ='') {
		var input = (type == '1') ? $('#shippingPrice') : $('#someid');
		console.log('input',input.val());
		var num = parseFloat(input.val());
		// Restrict to values greater than 0 and up to 10
		if (num <= 0) {
			input.val(''); // Clear input if it is 0 or negative
		} else if (num > 10) {
			// return false;
			input.val(10); // Cap the value at 10
		}
	}

	checkNumberLimit(e) {
		let val = e.target.value;
		
		// Get the key that is pressed
		let char = String.fromCharCode(e.which || e.keyCode);
	
		// Allow control keys like backspace, delete, arrows
		if (e.ctrlKey || e.metaKey || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
			return;
		}
	
		// Prevent entering multiple decimal points
		if (e.key === '.' && val.includes('.')) {
			e.preventDefault();
			return false;
		}
	
		// Only allow numbers and a single decimal point
		if (!/^\d*\.?\d*$/.test(val + e.key)) {
			e.preventDefault();
			return false;
		}
	
		// Allow input but validate the value on the fly
		setTimeout(() => {
			// Limit to 2 digits before the decimal point and 2 after the decimal point
			let regex = /^\d{0,2}(\.\d{0,2})?$/;
	
			if (!regex.test(e.target.value)) {
				e.target.value = val; // Restore previous valid value if the current one is invalid
			}
		}, 0);
	}
	




	// End

	// For shipping form validation
	get province_shipping(): any { return this.shippingForm.get('province_shipping'); }
	get province_id(): any { return this.shippingForm.get('province_id'); }
	get add_municipalities(): any { return this.shippingForm.get('add_municipalities'); }
	// End

	//  For otp varification form validatio
	get otp() { return this.otpVerificationForm.get('otp'); }
	// End

	// For edit profile form validation
	get full_name(): any { return this.editprofileForm.get('full_name'); }
	get business_name(): any { return this.editprofileForm.get('business_name'); }
	get profile_country(): any { return this.editprofileForm.get('profile_country'); }
	get province(): any { return this.editprofileForm.get('province'); }
	get mobile(): any { return this.editprofileForm.get('mobile'); }
	get email(): any { return this.editprofileForm.get('email'); }
	// End

	// For change password form validation
	get oldPass() { return this.changePasswordForm.get('oldPass'); }
	get newPass() { return this.changePasswordForm.get('newPass'); }
	get cnfPass() { return this.changePasswordForm.get('cnfPass'); }
	// End

	//For Write Feedback validation
	get feedback() { return this.feedbackForm.get('feedback'); }
	// End

	ngAfterViewInit() {
		// stripe payment
		this.stripe = Stripe(environment.striePkKey);
		const elements = this.stripe.elements();

		this.card = elements.create('card');
		this.card.mount(this.cardElement.nativeElement);

		this.card.addEventListener('change', ({ error }) => {
			this.cardErrors = error && error.message;
		});
	}

	// For seller licence image 
	onSelectedFile(event) {
		if (event.target.files.length > 0) {
			const file = event.target.files[0];

			this.editprofileForm.get('license').setValue(file);
			var reader = new FileReader();
			reader.addEventListener("load", function () {
				$('#license-preview').attr('src', reader.result);
			}, false);
			reader.readAsDataURL(event.target.files[0]);

		}
	}
	// End

	// For get province list
	getProvinceList() {
		this.authService.getProvinceList()
			.subscribe(provinceData => {
				this.commonService.hideLoader();

				if (provinceData.status == 'success') {
					this.provinceList = provinceData.data.province;
				} else {
					this.provinceList = [];
				}
				// console.log('provinceList -------',this.provinceList);
				
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End

	// For selected province 
	selectedProvinceShow() {

		let selectedArray = [];
		let provinceIdArray = this.userProfile.user_detail.province ? this.userProfile.user_detail.province.split(',') : [];
		let provinceNameArray = this.userProfile.user_detail.province_name ? this.userProfile.user_detail.province_name.split(',') : [];

		if (provinceNameArray.length != 0) {
			provinceIdArray.forEach((element, key) => {
				selectedArray.push({ name: provinceNameArray[key].trim(), provinceID: element.trim() });
			});

			this.selectedItems = selectedArray;
		} else {
			this.selectedItems = [];
		}


		// this.editprofileForm.patchValue({
		//   province: this.selectedItems,
		// });
	}
	// End
	//set auto detect country
	telInputObject(obj) {

		var initialCountrySet = this.authService.getUserDetails().profile_country_code;

		if (initialCountrySet == "" || initialCountrySet == null) {
			initialCountrySet = localStorage.getItem('countryCode');

		} else {
			initialCountrySet = this.authService.getUserDetails().profile_country_code;

		}


		$.getJSON('https://ipapi.co/json/', function (data) {

			this.initialCountrySet = initialCountrySet;

			// this.initialCountrySet = data.country_code.toLowerCase();
			// this.country_code = data.country_code;
			// this.dial_code = data.country_calling_code;
			obj.setCountry(this.initialCountrySet);
		});
	}

		//set auto detect country
		telInputWarehouseObject(obj) {

			var initialCountrySet = this.authService.getUserDetails().warehouse_country_code;
	
			if (initialCountrySet == "" || initialCountrySet == null) {
				initialCountrySet = localStorage.getItem('countryCode');
	
			} else {
				initialCountrySet = this.authService.getUserDetails().warehouse_country_code;
	
			}
	
	
			$.getJSON('https://ipapi.co/json/', function (data) {
	
				this.initialCountrySet = initialCountrySet;
	
				// this.initialCountrySet = data.country_code.toLowerCase();
				// this.country_code = data.country_code;
				// this.dial_code = data.country_calling_code;
				obj.setCountry(this.initialCountrySet);
			});
		}

	// For rating 
	createRatingRange(number) {
		return this.commonService.createRatingRange(number);
	}
	// End

	// For rating blank
	createRatingBlank(number) {
		return this.commonService.createRatingBlank(number);
	}
	// End

	isFloat(number) {
		return this.commonService.isFloat(number);
	}

	// For seller profile data get
	getSellerProfile() {
		this.commonService.showLoader();

		this.sellerService.getSellerProfile().subscribe(data => {
			this.commonService.hideLoader();
			this.userProfile = data.data;
			this.userIdGet = this.userProfile.user_detail.userID;
			this.avatar = this.userProfile.user_detail.avatar;
			this.sellerName = this.userProfile.user_detail.full_name;
			this.sellerRating = this.userProfile.rating_info;
			this.country_code = this.userProfile.user_detail.profile_country_code;
			this.profile_country_code = this.userProfile.user_detail.profile_country_code;
			this.sellerEmail = this.userProfile.user_detail.email;
			this.isCheckedEmail = this.userProfile.user_detail.is_email_show;
			this.isPhoneShow = this.userProfile.user_detail.is_phone_show;
			// this.dial_code = this.userProfile.user_detail.phone_dial_code.trim();
			this.is_seller_verified = this.userProfile.user_detail.is_seller_verified;
			localStorage.setItem(environment.dataStore.userData, JSON.stringify(this.userProfile));
			this.initialCountrySet = this.authService.getUserDetails().profile_country_code;


			this.editprofileForm.patchValue(
				{
					'full_name': this.getUserProfileData.full_name,
					'email': this.getUserProfileData.email,
					'profile_country': this.getUserProfileData.country,
					'business_name': this.getUserProfileData.business_name,
					'address': this.getUserProfileData.business_address,
					'latitude': this.getUserProfileData.latitude,
					'longitude': this.getUserProfileData.longitude,
					'mobile': this.getUserProfileData.mobile_number,
					'province': this.getUserProfileData.province,
				}
			);
			this.selectedProvinceShow();

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For seller profile details
	getUserData(user_id) {
		this.sellerService.getUserprofileDetails(user_id).subscribe(data => {
			this.getUserProfileData = data.data.seller_info;
			this.getAddMunicipalities(this.getUserProfileData.province);
			this.categoryName = data.data.seller_info.category_name;
			this.sellerCategoryid = data.data.seller_info.seller_category_id;
			this.nameSeller = data.data.seller_info.full_name;
			this.emailSeller = data.data.seller_info.email;
			this.phoneSeller = data.data.seller_info.mobile_number;
			this.reviewSeller = data.data.rating.rating_count.average_rating;
			this.totalRating = data.data.rating.rating_count.total_rating;
			this.sellerImage = data.data.seller_info.avatar;
			this.sellerVerified = data.data.seller_info.is_seller_verified;
			this.dial_code = this.getUserProfileData.phone_dial_code;
			this.is_email_verified = this.getUserProfileData.is_email_verified;
			this.is_phone_verified = this.getUserProfileData.is_phone_verified;
			this.social_id = this.getUserProfileData.social_id;
			this.isPickup = data.data.seller_info.is_pickup;
			this.payMethod = data.data.seller_info.is_cod;
			this.directPay = data.data.seller_info.is_direct_pay;
			this.sellerCategory = data.data.seller_info.seller_next_level_text;

			this.editprofileForm.patchValue(
				{
					'full_name': this.getUserProfileData.full_name,
					'email': this.getUserProfileData.email,
					'profile_country': this.getUserProfileData.country,
					'business_name': this.getUserProfileData.business_name,
					'address': this.getUserProfileData.business_address,
					'latitude': this.getUserProfileData.latitude,
					'longitude': this.getUserProfileData.longitude,
					'mobile': this.getUserProfileData.mobile_number,
					'province': this.getUserProfileData.province,
				}
			);

			if (this.getUserProfileData.license == '') {
				$('#license-preview').attr('src', 'assets/img/common/image_placeholder.png');

				// $('#license-preview').attr('src', this.getUserProfileData.license);
			} else {
				// $('#license-preview').attr('src', 'assets/img/common/image_placeholder.png');
				$('#license-preview').attr('src', this.getUserProfileData.license);

			}
			this.getSellerProfile(); //For seller profile data	


		}, err => {
			this.commonService.errorhandler(err);
		});

	}
	// End



	//For Get seller subscription
	sellerSubscription() {
		this.sellerService.getSellerSubscription().subscribe(data => {

			this.sellerPlan = data.data.plan_detail;
			this.isPlan = data.data.is_seller_subscribed;

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For change country -- on change
	onCountryChange($event) {
		this.editprofileForm.patchValue({ profile_country: $event.name.split('(')[0] });
		this.profile_country_code = $event.iso2.toUpperCase(); //For country code
		this.dial_code = `+${$event.dialCode}`; // For dial code
		this.country = $event.name.split('(')[0]; //For country name
	}
	// End

	// For change country -- on change
	onWarehouseCountryChange($event) {
		this.editprofileForm.patchValue({ warehouse_mobile_number: $event.name.split('(')[0] });
		this.warehouse_country_code = $event.iso2.toUpperCase(); //For country code
		this.warehouse_dial_code = `+${$event.dialCode}`; // For dial code
		this.warehouse_country_name = $event.name.split('(')[0]; //For country name
	}
	// End

	getNumber(event: string) {
		console.log('Mobile Number:', event); // This will log the full mobile number
		// this.mobileNumber = event; // Store it in a variable if needed
	}
	  

	onKeyUp(event) {
		return false;
	}

	// For mobile number -- enter only number
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	// End

	handleAddressChange(address): void {
		this.editprofileForm.get('address').setValue(address.formatted_address);
		this.editprofileForm.get('latitude').setValue(address.geometry.location.lat());
		this.editprofileForm.get('longitude').setValue(address.geometry.location.lng());
	}

	// For check form validation 
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
	// End

	// For Edit profile form submit
	editFormSubmit() {
		if (this.editprofileForm.valid) {
			this.commonService.showLoader();

			const formData = new FormData();
			formData.append('full_name', this.editprofileForm.get('full_name').value);
			formData.append('business_name', this.editprofileForm.get('business_name').value);
			formData.append('address', this.editprofileForm.get('address').value);
			formData.append('country', this.editprofileForm.get('profile_country').value);
			formData.append('province', this.editprofileForm.get('province').value);
			formData.append('country_code', this.profile_country_code);
			formData.append('email', this.editprofileForm.get('email').value);
			formData.append('license', this.editprofileForm.get('license').value);
			formData.append('phone_number', this.editprofileForm.get('mobile').value);
			formData.append('dial_code', this.dial_code);
			formData.append('is_email_show', this.isCheckedEmail);
			formData.append('is_phone_show', this.isPhoneShow);
			formData.append('is_cod', this.payMethod);
			formData.append('is_pickup', this.isPickup);
			formData.append('is_direct_pay', this.directPay);


			this.sellerService.updateProfile(formData).subscribe((data) => {
				this.commonService.hideLoader();
				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					this.verifyOt = data.data;
					this.profileResponse = data.data.user_detail;
					this.profileEmail = this.profileResponse.email;
					this.profilePhone = this.profileResponse.phone_number;
					this.dialCode = this.profileResponse.dial_code;

					this.getUserProfileInfoLocal.full_name = this.profileResponse.full_name;
					this.getUserProfileInfoLocal.email = this.profileResponse.email;
					this.getUserProfileInfoLocal.country = this.profileResponse.country;
					this.getUserProfileInfoLocal.business_name = this.profileResponse.business_name;
					this.getUserProfileInfoLocal.business_address = this.profileResponse.profile_address;
					this.getUserProfileInfoLocal.mobile_number = this.profileResponse.mobile_number;
					this.getUserProfileInfoLocal.province = this.profileResponse.province;

					if (data.data.is_change_email == 1) {
						$("#emailVerify").modal("show");
					}


					if (data.data.is_change_phone == 1 && data.data.email_not_change == 0) {
						$("#otpVerify").modal("show");
					}
					this.userProfile = data.data;
					// update on firebase
					this.addOnFirebase(data.data.user_detail);

					localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

					this.ngOnInit();
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			// validate all form fields
			this.validateAllFormFields(this.editprofileForm); //{7}
		}

	}
	// End

	//For Email verification -----
	verifyEmail() {
		this.commonService.showLoader();
		this.authService.verifyEmail().subscribe((data) => {
			this.commonService.hideLoader();
			if (data.data.is_email_verified == 1) {

				// logout firebase user and create again 

				this.authenticationService.SignOut();
				this.authenticationService.SignIn(data.data.user_detail.email, '123456');

				$("#emailVerify").modal("hide");
				if (this.verifyOt.is_change_phone == 1) {
					this.editFormSubmit();
					$("#otpVerify").modal("show");
				}
			}

			this.commonService.toastSuccess(data.message);

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	// Resend Email verification -----
	resendEmail() {
		this.commonService.showLoader();
		this.authService.resendEmail().subscribe((data) => {
			this.commonService.hideLoader();
		}, err => {
			this.commonService.errorhandler(err);
			this.commonService.hideLoader();
		});
	}
	// End

	// For Otp Varification 
	otpVerify() {
		if (this.otpVerificationForm.valid) {
			const formData = new FormData();
			formData.append('phone_number', this.profileResponse.phone_number);
			formData.append('dial_code', this.profileResponse.dial_code);
			formData.append('country', this.profileResponse.country);
			formData.append('country_code', this.profileResponse.country_code);
			formData.append('otp', this.otpVerificationForm.get('otp').value);
			formData.append('is_phone_show', this.profileResponse.is_phone_show);

			this.commonService.showLoader();

			this.authService.verifySendOtp(formData).subscribe(res => {

				this.getEmailData = JSON.parse(localStorage.getItem(environment.dataStore.userData));

				this.commonService.hideLoader();

				// this.commonService.toastSuccess(res.message);
				$("#otpVerify").modal("hide");
				this.otpVerificationForm.reset();
				this.editFormSubmit();
				let userdatasetLocal = localStorage.setItem(environment.dataStore.userData, JSON.stringify(this.getEmailData));
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
				// this.otpVerificationForm.reset();
			});
		}
	}




	sendMobileOtp() {
		const formData = new FormData();
		formData.append('phone_number', this.profileResponse.phone_number);
		formData.append('dial_code', this.profileResponse.dial_code);
		formData.append('is_phone_show', this.profileResponse.is_phone_show);
		this.commonService.showLoader();

		this.authService.otpSendMobile(formData).subscribe(data => {

			this.commonService.hideLoader();
			this.otpVerificationForm.reset();

			this.commonService.toastSuccess(data.message);
		}, err => {
			this.commonService.hideLoader();

			this.commonService.errorhandler(err);
		});

	}
	// End

	// Resend Otp
	reSendOtp() {
		// alert('hello');
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('email', this.emailPro);

		this.authService.reSendOtp(formData).subscribe(data => {
			this.commonService.toastSuccess(data.message);
			this.commonService.hideLoader();
		}, err => {
			this.commonService.errorhandler(err);
			this.commonService.hideLoader();
		});
	}
	// End


	trimValue(formControl) {
		((typeof formControl.value) && formControl.value.trim() == "") ?
			formControl.setValue("") : formControl.setValue(formControl.value);
	}


	//For update user profile image
	onSelectImage(event) {
		if (event.target.files.length > 0) {
			this.commonService.showLoader();

			const file = event.target.files[0];

			const formData = new FormData();
			formData.append('profile_picture', file);

			this.sellerService.updateAvatar(formData).subscribe((data) => {
				this.commonService.hideLoader();

				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					this.userProfile = data.data;
					localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
					this.addOnFirebase(data.data.user_detail);

					this.ngOnInit();
				}

			}, err => {
				$('#pImg').removeAttr('src')
				$("#pImg").attr("src", this.avatar);
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}
	}
	// End

	//For give feedback 
	writeFeedback() {

		if (this.feedbackForm.valid) {
			this.commonService.showLoader();

			const formData = new FormData();
			formData.append('feedback', this.feedbackForm.get('feedback').value);
			this.sellerService.writefeedback(formData).subscribe(
				(feedbackData) => {
					this.commonService.hideLoader();

					if (feedbackData.status == 'success') {
						this.commonService.toastSuccess(feedbackData.message);
						this.feedbackForm.reset();
						$("#leaveFeedback").modal("hide");
					} else {
						this.commonService.toastError(feedbackData.message);
						this.feedbackForm.reset();
					}
				}, err => {
					this.commonService.hideLoader();
					this.commonService.errorhandler(err);
				});

		} else {
			// validate all form fields
			this.validateAllFormFields(this.feedbackForm); //{7}
		}
	}
	// End

	//For feedback form reset
	cancelfeedback() {
		this.feedbackForm.reset();
	}
	// End


	// For change password form
	changePassFormSubmit() {

		if (this.changePasswordForm.valid) {
			this.commonService.showLoader();
			const formData = new FormData();
			if (this.isPassword != "" && this.isPassword != null) {
				formData.append('old_password', this.changePasswordForm.get('oldPass').value);
			}
			formData.append('new_password', this.changePasswordForm.get('newPass').value);
			formData.append('confirm_password', this.changePasswordForm.get('cnfPass').value);

			this.sellerService.changePassword(formData).subscribe((data) => {
				this.commonService.hideLoader();

				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					this.authService.logout();
					this.router.navigate(['/login']);
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
		} else {
			// validate all form fields
			this.validateAllFormFields(this.changePasswordForm); //{7}
		}

	}
	// End

	// For reset password form
	cancelChangePassword() {
		this.changePasswordForm.reset();
	}
	// End

	// For firebase 
	addOnFirebase(userData) {
		let userDataStore = {
			'user_id': userData.userID,
			'full_name': userData.full_name,
			'email': userData.email,
			'user_type': userData.user_type,
			'avatar': userData.avatar,
			'notification_status': userData.push_alert_status,
			'firebase_token': "",
			'seller_name': userData.full_name,
			'business_name': "",
			'buyer_name': "",
			'online': "Online",
		}

		this.firebaseService.userUpdateFB(userData.userID, userDataStore)
	}
	// End

	// For switch profile seller to buyer
	switchProfile(userType) {
		this.commonService.showLoader();

		this.authService.switchProfile(userType).subscribe(profileData => {
			this.commonService.hideLoader();


			if (profileData.data.user_detail.switch_profile == 1) {

				// redirect to shop page
				localStorage.setItem(environment.dataStore.userData, JSON.stringify(profileData.data));

				this.updateOnFirebase(profileData.data);
				this.router.navigate(['/']);
			} else {
				// redirect to complete profile page
				// this.router.navigate(['/seller/complete-switch-profile']);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	// For update details on firebase
	updateOnFirebase(userData) {
		let userDataStore = {
			'user_id': userData.user_detail.userID,
			'full_name': userData.user_detail.full_name,
			'email': userData.user_detail.email,
			'user_type': userData.user_detail.user_type,
			'seller_name': "",
			'buyer_name': userData.user_detail.full_name
		}

		this.firebaseService.userUpdateFB(userData.user_detail.userID, userDataStore)
	}
	// End

	//For get card list
	getCardList() {
		this.buyerService.getCardList().subscribe(cardData => {
			this.commonService.hideLoader();
			if (cardData.status == 'success') {
				this.cardList = cardData.data.card_list;
				var keys = Object.keys(cardData.data); //Check data is empty object
				this.cardListCount = keys.length;  //get length of empty object
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For add card
	async handleForm(e) {
		this.commonService.showLoader();
		e.preventDefault();

		const { token, error } = await this.stripe.createToken(this.card);

		if (error) {
			this.commonService.hideLoader();
			// Inform the customer that there was an error.
			const cardErrors = error.message;
		} else {

			// Send the token to your server.
			const formData = new FormData();
			formData.append('sourceId', token.id);

			this.buyerService.addCard(formData).subscribe(cardData => {
				this.commonService.hideLoader();

				if (cardData.status == 'fail') {
					this.commonService.toastError(cardData.message);
				} else {
					this.saveCard(cardData.data.card_detail);
					this.resetCard();
					const str = this.router.url;
					const regex = RegExp('/seller/seller-account/subscription');
					if (regex.test(str) == true) {
						this.router.navigate(['/seller/subscription']);
					}
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}
	}
	// End

	// For save card details 
	saveCard(cardData) {
		this.commonService.showLoader();
		// Send the token to your server.
		const formData = new FormData();
		formData.append('stripe_card_id', cardData.id);
		formData.append('card_holder_name', this.authService.getUserDetails().full_name);
		formData.append('card_last_4_digits', cardData.last4);
		formData.append('card_expiry_month', cardData.exp_month);
		formData.append('card_expiry_year', cardData.exp_year);
		formData.append('card_brand_type', cardData.brand);

		this.buyerService.saveCard(formData).subscribe((data) => {
			this.commonService.hideLoader();

			if (data.status == 'fail') {
				this.commonService.toastError(data.message);
			} else {
				this.commonService.toastSuccess(data.message);
				this.getCardList();
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	//For Delete card form card list
	deleteCard(cardId) {
		this.commonService.showLoader();

		this.buyerService.deleteCard(cardId).subscribe((data) => {
			this.commonService.hideLoader();

			if (data.status == 'fail') {
				this.commonService.toastError(data.message);
			} else {
				this.commonService.toastSuccess(data.message);
				//after deletion of card remove from local storage
				if (this.authService.getUserCard()) {
					if (this.authService.getUserCard() == cardId) {
						localStorage.removeItem(environment.dataStore.userCard);
					}
				}
				// get all card list
				this.getCardList();
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For reset add card form and hide modal
	resetCard() {
		this.card.clear();
		$('#addCard').modal('hide');
	}
	// End

	// For card verification 
	verifyModal(cardID) {
		this.cardID = cardID;
		$('#cardVerifyModal').modal('show');
	}
	// End

	// For reset verification form
	cardVerifyReset() {
		this.verifyPrice = '';
	}
	// End

	// For card verification api 
	cardVerifySubmit() {

		if (this.verifyPrice.trim() != "") {
			this.commonService.showLoader();

			const formData = new FormData();
			formData.append('card_id', this.cardID);
			formData.append('verify_amount', this.verifyPrice.trim());

			this.buyerService.cardVerify(formData).subscribe((data) => {
				this.commonService.hideLoader();

				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					$('#cardVerifyModal').modal('hide');
					// get all card list
					this.getCardList();

					const str = this.router.url;
					const regex = RegExp('/seller/seller-account/subscription');
					if (regex.test(str) == true) {
						this.router.navigate(['/seller/subscription']);
					}
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			this.commonService.toastError(this.translate.instant('CUSTOMER.please_enter_deducted_amount'));
		}
	}
	// End

	//For update payment and delivery options
	getOptionsValue(event) {
		this.commonService.showLoader();
		this.getValue = event.target.value;
		if (event.target.checked == true) {
			this.isChecked = 1
		} else {
			this.isChecked = 0
		}

		const formData = new FormData();
		formData.append('type', this.getValue);
		formData.append('value', this.isChecked);

		this.sellerService.updateOptions(formData).subscribe((res) => {
			// this.commonService.toastSuccess(res.message);
			this.commonService.hideLoader();
			this.updatePaymentOption();

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});


	}
	// End

	// For seller profile details
	updatePaymentOption() {
		this.sellerService.getProfileOptions(this.userIdGet).subscribe(data => {

			this.isPickup = data.data.seller_info.is_pickup;
			this.payMethod = data.data.seller_info.is_cod;
			this.directPay = data.data.seller_info.is_direct_pay;

			let userData = this.authService.getFullUserDetails();
			userData.business_info = data.data.business_info;
			localStorage.setItem(environment.dataStore.userData, JSON.stringify(userData));


		}, err => {
			this.commonService.errorhandler(err);
		});

	}

	//For Direct pay
	directPayValue(event) {
		this.commonService.showLoader();
		this.getValue = event.target.value;
		if (event.target.checked == true) {
			this.isDirectPay = 1
		} else {
			this.isDirectPay = 0
		}

		const formData = new FormData();
		formData.append('type', this.getValue);
		formData.append('value', this.isDirectPay);

		this.sellerService.updateOptions(formData).subscribe((res) => {
			// this.commonService.toastSuccess(res.message);
			this.commonService.hideLoader();
			this.updatePaymentOption();

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End


	// getSellerReview(seller_id) {
	// 	this.router.navigate(['seller-review',seller_id]);
	// }

	// Delete profile 
	deleteProfile() {
		this.commonService.showLoader();

		this.sellerService.deleteUser().subscribe((data) => {
			this.commonService.toastSuccess(data.message);
			$("#profileDelete").modal("hide");
			this.authService.logout();
			this.router.navigate(['/login']);
			this.commonService.hideLoader();
		}, err => {
			this.commonService.errorhandler(err);
			this.commonService.hideLoader();
			// this.commonService.errorhandler(err);
		});
	}
	// End
}
