import { Component, OnInit } from '@angular/core';
import { faTrashAlt, faAngleDoubleRight, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { BuyerService } from '../services/buyer.service';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';


@Component({
	selector: 'app-my-orders',
	templateUrl: './my-orders.component.html',
	styleUrls: ['./my-orders.component.css']
})
export class MyOrdersComponent implements OnInit {
	faTrashAlt = faTrashAlt
	faAngleDoubleRight = faAngleDoubleRight;
	faCommentDots = faCommentDots;
	faAngleRight = faAngleRight;
	orderList = [];
	limitOfOrderList: number = 10;
	offsetOfOrderList: number = 0;
	OrderListCount: number;
	productloader: boolean = true;
	searchData: any = "";

	constructor(
		private commonService: CommonService,
		private buyerService: BuyerService,
		private router: Router,
		private translate: TranslateService
	) { translate.use(this.commonService.getLanguage()); } // get user language

	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Orders'); // set title
		this.getOrderList(this.limitOfOrderList, this.offsetOfOrderList); // method get order list 
	}


	ngOnDestroy(): void {
		Swal.close();
	}

	searchTimer: any;
	doneSearchInterval: any = 800; // set search interval time

	// function for search order in order list 
	search() {
		clearTimeout(this.searchTimer);
		this.searchTimer = setTimeout(() => {
			this.resetNewOrder(); // reset order 
			this.getOrderList(this.limitOfOrderList, this.offsetOfOrderList); // get order list method call 
		}, this.doneSearchInterval);
	} // end function 
	

	// reset order 
	resetNewOrder() {
		this.orderList = [];
		this.limitOfOrderList = 10;
		this.offsetOfOrderList = 0;
	} // end function 

	// function for load more 
	orderListLoadMore() { //load more 
		this.offsetOfOrderList = this.offsetOfOrderList + Number(this.limitOfOrderList);
		this.getOrderList(this.limitOfOrderList, this.offsetOfOrderList);
	} // end function 

	// function for get order list 
	//@param limit , offset , search data
	getOrderList(limit, offset) {

		if (this.offsetOfOrderList == 0) {
			this.commonService.showLoader(); // show loader 
		} else {
			this.productloader = false;
		}
		// api calling 
		this.buyerService.getMyOrderList(limit, offset, this.searchData).subscribe(orderData => { 

			this.productloader = true;
			this.commonService.hideLoader(); // hide loader

			if (orderData.status == 'success') {

				if (orderData.data.data_found) {

					if (this.offsetOfOrderList == 0) { // check refresh then show starting
						this.orderList = orderData.data.order_list; // assign data in order list 
						this.OrderListCount = orderData.data.total_records; //total product          
					} else {
						this.OrderListCount = orderData.data.total_records; //total product           
						orderData.data.order_list.forEach(element => {
							this.orderList.push(element); // push data on orderlist
						});
					}

				} else {
					this.orderList = []; // empty order list 
					this.OrderListCount = 0; // order list count reset it's managed for load more function 
				}

			}
		}, err => {
			this.commonService.hideLoader(); // hide loader
			this.commonService.errorhandler(err); //   error hamdle
		});
	} // end function 

	// function for get order time 
	getOrderTimeShow(dateTime, string) {
		return this.commonService.getOrderTime(dateTime, string);
	} // end function 


		// function for get order time 
		getDisputeTimeShow(dateTime) {
			return this.commonService.getDisputeTimeShow(dateTime);
		} // end function 
	

	// // cancel order 
	// cancelOrder(id){
	// 	alert(id);
	// 	// return false;
	// 	// this.router.navigate(['/my-orders']);
	// 	location.reload();
	// 	return false;
	// }
  // Confirm order (Accept & Reject order)
  confirmationOrder(orderId, orderNumber) {
      Swal.fire({
        title: this.translate.instant('MYORDER.are_you_sure'),
        text: this.translate.instant('MYORDER.You_want_to_cancel_this_order') + orderNumber,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('FOOTER.ok_button'),
        cancelButtonText: this.translate.instant('COMMON.cancel'),
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
			var id = parseFloat(orderId)
			this.cancelOrder(orderId)
        //   this.acceptReject(status, orderId, licenseStatus);
        } 
      });
   

  }

	cancelOrder(id){
		// alert('hello')

		const formData = new FormData();
		formData.append('order_id', id);
		formData.append('cancel_by', "buyer");
		formData.append('note', "");
		this.commonService.showLoader();  // show loader
		this.buyerService.cancelOrder(formData).subscribe(data => {


			if (data.status == 'success') {
				this.commonService.hideLoader();
				this.commonService.toastSuccess(data.message);
				this.getOrderList(this.limitOfOrderList, this.offsetOfOrderList);

				// this.getMyOrderDetail(this.orderId); // get order details 
			}else{
				this.commonService.hideLoader();
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}

}
