import { Component, OnInit, ViewChild } from '@angular/core';
import { faStar, faAngleDoubleRight, faEdit, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { SellerService } from '../services/seller.service';
import { HeaderSellerComponent } from '../../common/header-seller/header-seller.component';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-products',
  templateUrl: './my-products.component.html',
  styleUrls: ['./my-products.component.css']
})
export class MyProductsComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  faStar = faStar;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  productloader: boolean = true;
  limitOfProduct: number = 12;
  offsetOfProduct: number = 0;
  myProductList: any = [];
  myProductCount: any;
  search_term: string = "";
  titleConfirm: string = "";
  textConfirm: string = "";
  confirmButtonTextConfirm: string = "";
  cancelButtonTextConfirm: string = "";
  cancelled: string = "";
  yourProductSafe: string = "";

  titleRecent: string = "";
  textRecent: string = "";
  confirmButtonTextRecent: string = "";
  cancelButtonTextRecent: string = "";

  @ViewChild('header', { static: false }) header: HeaderSellerComponent;

  constructor(
    private commonService: CommonService,
    private sellerService: SellerService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private router: Router,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | My Product');

    //localization
    this.translate.get('SELLER_SIDE.delete_product').subscribe((text: string) => { this.titleConfirm = text; });
    this.translate.get('SELLER_SIDE.delete_product_popup').subscribe((text: string) => { this.textConfirm = text; });
    this.translate.get('SELLER_SIDE.yes_delete_it').subscribe((text: string) => { this.confirmButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.cancelled').subscribe((text: string) => { this.cancelled = text; });
    this.translate.get('SELLER_SIDE.your_product_is_safe').subscribe((text: string) => { this.yourProductSafe = text; });
    this.translate.get('SELLER_SIDE.save_to_recently_deleted').subscribe((text: string) => { this.titleRecent = text; });
    this.translate.get('SELLER_SIDE.recently_deleted_list').subscribe((text: string) => { this.textRecent = text; });
    this.translate.get('SELLER_SIDE.yes_save_it').subscribe((text: string) => { this.confirmButtonTextRecent = text; });
    this.translate.get('SELLER_SIDE.no_delete_it').subscribe((text: string) => { this.cancelButtonTextRecent = text; });

    // set search box on header
    const str = this.router.url;
    const regex = RegExp('/seller/my-products');
    const regex2 = RegExp('/seller/my-products/');

    if (regex2.test(str) == true) {
      this.search_term = "";
      this.authService.searchTextStore = "";
    } else if (regex.test(str) == true) {
      this.search_term = this.authService.searchTextStore;
    }
    this.getProductList(this.limitOfProduct, this.offsetOfProduct);
  }

  searchTimer: any;
  doneSearchInterval: any = 500;

  setSearchText(text) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.search_term = text;
      this.resetLoadMore();
      this.getProductList(this.limitOfProduct, this.offsetOfProduct);
    }, this.doneSearchInterval);

  }

  resetLoadMore() {
    this.myProductList = [];
    this.limitOfProduct = 8;
    this.offsetOfProduct = 0;
  }

  productLoadMore() { //load more 
    this.offsetOfProduct = this.offsetOfProduct + Number(this.limitOfProduct);
    this.getProductList(this.limitOfProduct, this.offsetOfProduct);
  }

  getProductList(limit, offset) {

    if (this.offsetOfProduct == 0) {
      this.commonService.showLoader();
    } else {
      this.productloader = false;
    }

    this.sellerService.getMyProductList(limit, offset, this.search_term)
      .subscribe(productData => {

        this.productloader = true;
        this.commonService.hideLoader();

        if (productData.status == 'success') {

          if (productData.data.data_found) {

            if (this.offsetOfProduct == 0) { // check refresh then show starting
              productData.data.product_list.forEach(element => {
                element.sale_discount = Math.ceil(element.sale_discount);
                element.discount_percent = Math.round(element.discount_percent);

                
                this.myProductList.push(element);
              });

              // this.myProductList = productData.data.product_list;
              this.myProductCount = productData.data.total_records; //total product
            } else {
              productData.data.product_list.forEach(element => {
                element.sale_discount = Math.ceil(element.sale_discount);
                this.myProductList.push(element);
              });
              this.myProductCount = productData.data.total_records; //total product           
            }

          } else {
            this.myProductCount = 0;
            this.resetLoadMore();
          }

        } else {
          this.myProductCount = 0;
          this.myProductList = [];
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

  }

  confrimation(productID, isCopy) {
    // alert(isCopy);
    // return false;
    Swal.fire({
      title: this.titleConfirm,
      text: this.textConfirm,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextConfirm,
      cancelButtonText: this.cancelButtonTextConfirm,
      reverseButtons: true
    }).then((result) => {


     
      
        if (result.value) {

          if(isCopy !=0){
            this.deleteProduct(productID);
    
          }else{
            this.recentDelete(productID);

          }
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            this.cancelled,
            this.yourProductSafe,
            'error'
          )
        }
        
      
    });
  }

  recentDelete(productID) {
    Swal.fire({
      title: this.titleRecent,
      text: this.textRecent,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextRecent,
      cancelButtonText: this.cancelButtonTextRecent,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.recentDeleteAdd(productID);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.deleteProduct(productID);
      }
    });
  }

  deleteProduct(productID) {

    this.commonService.showLoader();

    this.sellerService.deleteProduct(productID).subscribe(data => {
      this.commonService.hideLoader();

      if (data.status == 'success') {
        this.commonService.toastSuccess(data.message);
        this.resetLoadMore();
        this.ngOnInit();
        this.firebaseService.productDelete(productID);
      } else {
        this.commonService.toastError(data.message);
      }

    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });

  }

  recentDeleteAdd(productID) {
    this.commonService.showLoader();

    let statusData = `status=1`;
    this.sellerService.recentDeleted(productID, statusData).subscribe(orderData => {
      this.commonService.hideLoader();

      if (orderData.status == 'success') {
        this.commonService.toastSuccess(orderData.message);
        this.firebaseService.recentlyProductDelete(productID);
        this.resetLoadMore();
        this.ngOnInit();
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

}
