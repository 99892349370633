import { AfterViewInit, ViewChild, Component, OnInit } from '@angular/core';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { CommonService } from '../services/common.service';
import { AuthService } from '../auth/auth.service';
import { ShopService } from '../services/shop.service';
import { TranslateService } from '@ngx-translate/core';
import { ExcerptCategoryPipe } from '../pipes/excerpt-category.pipe';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { HeaderBuyerComponent } from '../common/header-buyer/header-buyer.component';
import { BuyerService } from '../buyer/services/buyer.service';




declare const $: any;
@Component({
	selector: 'app-home',
	templateUrl: './home.component.html',
	styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit, AfterViewInit {
	[x: string]: any;
	faStar = faStar;
	currentUserId: number;
	productList: any = [];
	categoryProduct: any = [];
	slidesStore: any = [];
	featuredProductList: any = [];
	promotedProductList: any = [];
	recentProductList: any = [];
	dealList: any = [];
	bannerOptionsData123: any;
	recentProductListCount: any;
	promotedProductListCount: any;
	featuredProductListCount: any;
	offerProductListCount: any;
	offerProductList: any = [];
	ids = '1';
	productSeeMore: any;
	productSeeMoreForCombo: any;
	promotedAllButton: any;
	featureAllButton: any;
	LeftBannerData: any = {}
	rightBannerData: any = {}
	dataFound: any
	recentAllButton: any;
	OfferAllButton: any = 0;
	catList: any = [];
	catDataCount : any = 0;
	// For Caresol slider 
	bannerOptions: OwlOptions = {
		loop: true,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: true,
		navSpeed: 700,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		nav: true,
		autoplay: true,
		autoplayTimeout: 5000,
		autoplayHoverPause: false,
		responsive: {
			0: {
				items: 1
			},
			767: {
				items: 1
			},
			992: {
				items: 1
			}
		},
	}

	// For Caresol slider 
	catOption: OwlOptions = {
		loop: false,
		items: 8,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		nav: true,
		responsive: {
			0: {
				items: 3
			},
			767: {
				items: 6
			},
			992: {
				items: 8
			}
		},

	}

	// End

	// For Offer caresol 
	offerBannerOptions: OwlOptions = {
		loop: false,
		margin: 30,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		dots: false,
		nav: true,
		navSpeed: 700,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			767: {
				items: 2
			},
			992: {
				items: 4
			}
		},
	}
	// End

	// For category slider 
	CategorySliderOptions: OwlOptions = {
		loop: true,
		margin: 30,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		nav: true,
		dots: false,
		navSpeed: 700,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			767: {
				items: 3
			},
			992: {
				items: 3
			}
		}
	}
	// End

	// Caresol for Based on last visit==========
	basedonLastvisit: OwlOptions = {
		loop: false,
		margin: 30,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		nav: true,
		dots: false,
		navSpeed: 500,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			767: {
				items: 3
			},
			992: {
				items: 4
			}

		}
	}
	// End---------



	// //For Promoted product Caresol=======
	// promotedProduct: OwlOptions = {
	//   loop: false,
	//   margin: 30,
	//   items: 1,
	//   mouseDrag: true,
	//   touchDrag: true,
	//   pullDrag: false,
	//   nav: true,
	//   dots: false,
	//   navSpeed: 500,
	//   // navSpeed: 700,

	//   navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
	//   responsive: {
	//     0: {
	//       items: 1
	//     },
	//     480: {
	//       items: 2
	//     },
	//     767: {
	//       items: 3
	//     },
	//     992: {
	//       items: 4
	//     }
	//   }
	// }
	// // End-----


	// Featured product
	promotedProduct: OwlOptions = {
		loop: false,
		margin: 30,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		nav: true,
		dots: false,
		navSpeed: 500,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			767: {
				items: 3
			},
			992: {
				items: 4
			}
		}
	}
	// End-------

	// Featured product
	featuredProduct: OwlOptions = {
		loop: false,
		margin: 30,
		items: 1,
		mouseDrag: true,
		touchDrag: true,
		pullDrag: false,
		nav: true,
		dots: false,
		navSpeed: 500,
		navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
		responsive: {
			0: {
				items: 1
			},
			480: {
				items: 2
			},
			767: {
				items: 3
			},
			992: {
				items: 4
			}
		}
	}
	// End-------


	limit: any;
	offeset: number;
	offset: number;
	filter: object;
	categoryList: any = [];
	variantList: any = [];
	selectedColor: any = [];
	selectedSize: any = [];
	category: any = "";
	color: any = "";
	size: any = "";
	productCount: number;
	limitOfProduct: number = 12;
	offsetOfProduct: number = 0;
	productloader: boolean = true;
	selectedCategory: any = [];
	minPrice: number = 0;
	maxPrice: number = 0;
	price_from: any = "";
	price_to: any = "";
	sort: any = "";
	search_term: string = "";
	deal: any = "";
	isCatRoute: boolean = false;
	isBreadcrumb: number = 1;
	isBreadcrumbCat: any = 'undefined';
	isBreadcrumbSubCat: any = 'undefined';
	condition: any = '';
	sellerWarranty: any = '';
	sellerRating: any = '';
	newArrival: any = '';
	discount: any = '';
	deliveryIn: any = [];
	deliveryInData: any = '';
	provinceList: any = [];
	provinceCheckArray: any = [];
	provinceData: any = '';
	brandCheckArray: any = [];
	brandData: any = '';
	isBrandListShow: boolean = false;
	brandList: any = [];
	remainingCategoryList: any = [];
	delivery_in: any;
	direct_pay: any = '';
	superMarket: any = {}
	sellerList: any = {}
	superMarketFlag: any = 0;
	sellerFlag: any = 0;
	dataFoundHome: boolean = false;
	combo: any = [];
	comboImage: any
	comoData: any = {}
	comboImageUrl: any;
	comboFlag: any = 0;
	// End-------
	@ViewChild('header', { static: true }) header: HeaderBuyerComponent;

	constructor(
		private commonService: CommonService,
		private shopService: ShopService,
		private authService: AuthService,
		private translate: TranslateService,
		private router: Router,
		private buyerService: BuyerService,


	) { translate.use(this.commonService.getLanguage()); }


	ngOnInit() {
		console.log('header', this.header);
		this.getBanner();
		this.commonService.scrollToTop();
		this.commonService.setTitle('Bienvenido a mercadoba: Tu Tienda en Línea en Cuba | Envío Rápido y Productos de Calidad');
		this.currentUserId = this.authService.getCurrentUserId(); //For Current user id


		this.commonService.getMetaTages('Bienvenido a mercadoba: Tu Tienda en Línea en Cuba | Envío Rápido y Productos de Calidad', 'Explora la mejor selección de productos en línea en Cuba con Qvazon. Entrega rápida garantizada. Únete a nosotros para una experiencia de compra excepcional. Descubre ofertas increíbles y novedades en nuestra tienda en línea.')


		this.getProductList(); //For product list
		this.getSuperMarket();
		this.getCategorySlider(); //For category slider
		this.getCategory();
		this.getCombo();
		this.getSeller();
		// this.getDeals();

		// For wishlist icon
		$(".likeIcon").click(function () {
			$(this).toggleClass("liked");
			$(this).find('i').toggleClass("lar las");
			$(this).find('i').toggleClass("animate__animated animate__heartBeat");
		});
		// End

	}

	ngAfterViewInit(): void {
		this.getBanner();
	}

	// For category slider
	getCategorySlider() {
		this.shopService.getCategorySubacategoryList().subscribe(
			(categoryData) => {

				if (categoryData.status == 'success') {
					this.categoryProduct = categoryData.data.category;
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End


	// get data for filter
	getFilterData(init) {
		var filter = {
			'category': "",
			'color': "",
			'size': "",
			'sort': "",
			'price': "",
			'search_term': "",
			'condition': "",
			'sellerWarranty': "",
			'sellerRating': "",
			'newArrival': "",
			'discount': "",
			'deliveryInData': "",
			'provinceData': "",
			'brandData': "",
			'deal': "",
			'delivery_in': "",
			'direct_pay': "",
			'is_featured': "",
			'is_promoted': "",
			'is_offer': "",

		}
		if (init != 1) { // for starting
			filter.category = this.category;
			filter.color = this.color;
			filter.size = this.size;
			filter.price = `${this.price_from}|${this.price_to}`;
			filter.sort = this.sort;
			filter.search_term = this.search_term.trim();;
			filter.condition = this.condition;
			filter.sellerWarranty = (this.sellerWarranty != '' || this.sellerWarranty != false) ? this.sellerWarranty : '';
			filter.sellerRating = this.sellerRating;
			filter.newArrival = this.newArrival;
			filter.discount = this.discount;
			filter.deliveryInData = this.deliveryInData;
			filter.provinceData = this.provinceData;
			filter.brandData = this.brandData;
			filter.deal = this.deal;
			filter.delivery_in = this.delivery_in;
			filter.delivery_in = this.deliveryInData;
			filter.direct_pay = this.direct_pay;
			filter.is_featured = '1';
			filter.is_promoted = '1';
			filter.is_offer = '1';
		}

		return filter;
	}
	// End



	// See more button function
	seeMore() {
		this.router.navigate(["/view/featured"])

		// this.router.navigate(["/shop"], {
		// 	state: { is_featured: 1 }
		// });
	}
	// See more button function
	seeMorePromoted() {
		this.router.navigate(["/view/promoted"])

		// this.router.navigate(["/shop"], {
		// 	state: { is_promoted: 1 }
		// });
	}
	// See more button function
	seeMoreOffer() {
		this.router.navigate(["/view/offer"])

		// this.router.navigate(["/shop"], {
		// 	state: { is_offer: 1 }
		// });
	}



	// See more button function
	seeMoreSuper() {
		this.router.navigate(["/supermarket"], {
		});
	}


	// See more button function
	seeMoreCombo() {
		this.router.navigate(["/combo"], {
		});
	}
	// End


	getSuperMarket() {
		this.shopService.getSuperMarket(1).subscribe((supermarket) => {

			console.log('supermarket', supermarket);


			if (supermarket.data.supermarket != null) {
				var supermarket = supermarket.data.supermarket.categoryID;
				this.getProductListSuperMarket(supermarket)
			}


		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}

	getSeller() {
		this.shopService.getSeller().subscribe((seller) => {

			if(seller.data.sellerdata.length > 0){

				this.sellerFlag = 1;	
			}

			this.sellerList = seller.data.sellerdata;
			// if (seller.data.seller != null) {
			// 	var seller = seller.data.seller.categoryID;
			// 	this.getProductListSuperMarket(seller)
			// }


		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}

	// For product list
	getProductListSuperMarket(supermarket) {
		this.category = "";

		this.limit = 12;
		this.offset = 0;
		this.category = supermarket;
		this.shopService.getProductListSuper(this.limit, this.offset, this.currentUserId, this.getFilterData(2), 0, 1).subscribe(
			(productData) => {


				if (productData.status == 'success' && productData.data.data_found == true) {
					var productList = productData.data.product_list;
					this.superMarketFlag = 1;


					if (productList.length > 8) {
						this.productSeeMore = 1;
					} else {
						this.productSeeMore = 0;

					}

					this.superMarket = productData.data.category_image_super_market;


					if (productData.data.product_list.length != 0) {
						var filtered = productData.data.product_list.filter(function (value, index, arr) {
							return index <= 7;

						});

						this.productList = filtered;
					}

				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// super market list 


	getCombo() {
		this.shopService.getSuperMarket(2).subscribe((combo) => {
			if (combo.data.supermarket != null) {
				this.comoData = combo.data.supermarket;
				this.comboImageUrl = this.comoData.category_image;
				this.comboImage = this.comoData.image;
				this.getProductListCombo(this.comoData.categoryID)
			}


		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}

	// For product list combo
	getProductListCombo(combo) {

		this.category = "";
		this.limit = 12
		this.offset = 0;
		this.category = combo;
		this.shopService.getProductListSuper(this.limit, this.offset, this.currentUserId, this.getFilterData(2), 0, 1).subscribe(
			(productData) => {

				if (productData.status == 'success' && productData.data.data_found == true) {
					var productList = productData.data.product_list;
					this.comboFlag = 1;


					if (productList.length > 8) {
						this.productSeeMoreForCombo = 1;
					} else {
						this.productSeeMoreForCombo = 0;

					}



					if (productData.data.product_list.length != 0) {
						var filtered = productData.data.product_list.filter(function (value, index, arr) {
							return index <= 7;

						});

						this.combo = filtered;
					}

					console.log('this.combo ', this.combo);

				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	//


	// For product list
	getProductList() {
		this.limit = 12;
		this.offset = 0;
		// this.category = 86;
		this.shopService.getProductList(this.limit, this.offset, this.currentUserId, this.getFilterData(2), 0).subscribe(
			(productData) => {


				if (this.productList.length == 0 && this.combo.length == 0 && productData.data.recentProductList.length == 0 && productData.data.promote_product_list.length == 0 && productData.data.feature_product_list.length == 0 && productData.data.offer_product_list.length == 0) {
					this.dataFoundHome = false;
				} else {
					this.dataFoundHome = true;

				}



				this.dataFound = productData.data.data_found;
				if (productData.status == 'success') {
					if (productData.data.recentProductList.length >= 1) {
						this.recentProductListCount = '1';
						productData.data.recentProductList.forEach(element => {
							element.discount_percent = Math.round(element.discount_percent);
							this.recentProductList.push(element);
						});
					} else {
						this.recentProductListCount = 0;
					}

					// promoted product list  
					if (productData.data.promote_product_list.length >= 1) {
						this.promotedProductListCount = 1;

						productData.data.promote_product_list.forEach(element => {
							element.discount_percent = Math.round(element.discount_percent);

							this.promotedProductList.push(element);

							// this.productCount = productData.data.total_records; //total product
						});
						console.log('this.promotedProductListOne',this.promotedProductList);
					} else {
						this.promotedProductListCount = 0;
					}

					//  feature product 
					if (productData.data.feature_product_list.length > 1) {
						this.featuredProductListCount = 1;

						productData.data.feature_product_list.forEach(element => {
							element.discount_percent = Math.round(element.discount_percent);

							this.featuredProductList.push(element);
						});
					} else {
						this.featuredProductListCount = 0;
					}

					// offer module :---

					if (productData.data.offer_product_list.length >= 1) {
						this.offerProductListCount = 1;
						// productData.data.offer_product_list.forEach(element => {

						// 	element.discount_percent = Math.round(element.discount_percent);

						// 	this.offerProductList.push(element);
						// });

						for (let i = 0; i < productData.data.offer_product_list.length; i++) {
							let element = productData.data.offer_product_list[i];
						
							// Rounding the discount_percent
							element.discount_percent = Math.round(element.discount_percent);
						
							// Push the modified element to the offerProductList
							this.offerProductList.push(element);
						}
						
					} else {
						this.offerProductListCount = 0;
					}

					// if (productList.length > 8) {
					//   this.productSeeMore = 1;
					// } else {
					//   this.productSeeMore = 0;

					// }

					if (productData.data.promote_product_list.length > 4) {
						this.promotedAllButton = 1;
					} else {
						this.promotedAllButton = 0;

					}

					if (productData.data.feature_product_list.length > 4) {
						this.featureAllButton = 1;
					} else {
						this.featureAllButton = 0;

					}
					if (productData.data.recentProductList.length > 4) {
						this.recentAllButton = 1;
					} else {
						this.recentAllButton = 0;

					}
					if (productData.data.offer_product_list.length > 4) {
						this.OfferAllButton = 1;
					} else {
						this.OfferAllButton = 0;

					}
					// var filtered = productData.data.product_list.filter(function (value, index, arr) {
					//   // return index !=0 && index <=8;
					//   return index <= 7;

					// });

					// this.productList = filtered;
					// this.productList.splice(0, 1);


				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End


	getBanner() {

		this.commonService.showLoader();

		this.shopService.getBanner().subscribe(
			(bannerData) => {

				if (bannerData.status == 'success') {
					this.bannerOptionsData123 = bannerData.data.banner_list;
					this.LeftBannerData = this.bannerOptionsData123[0];
					this.rightBannerData = this.bannerOptionsData123[1];

				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}

	getDeals() {
		this.shopService.getDealList().subscribe(
			(dealsData) => {
				if (dealsData.status == 'success') {
					this.dealList = dealsData.data.deal_list;
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}

	getCategory() {
		this.shopService.getCategory().subscribe(
			(catData) => {
				console.log('catData',catData);
				if (catData.status == 'success') {
					if(catData.data.category == undefined){
						this.catDataCount = 0;
					}else{
						this.catDataCount = 1;

					}
					this.catList = catData.data.category;
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}

	// function for navigate to category detail page 
	categoryDetail(id, supermarket) {

		localStorage.removeItem('categoryName');
		localStorage.setItem('categoryName', supermarket);

		var name1 = (supermarket.split(' ').join(''));

		var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
			return '-' + c.charCodeAt(0).toString(16);
		});

		var removeSpace = (url.split('%').join('-'));
		var validUrl = '/supermarket' + '/' + removeSpace + '/' + id;
		this.router.navigate([validUrl]);
		// this.router.navigate(['/supermarket', supermarket,id]);

	}


	// End
	redirectFunction(detail, name, id1) {
		var name1 = (name.split(' ').join(''));

		var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
			return '-' + c.charCodeAt(0).toString(16);
		});

		var removeSpace = (url.split('%').join('-'));
		var validUrl = detail + '/' + removeSpace + '/' + id1;
		this.router.navigate([validUrl]);

	}


	// For add item to cart
	addToCart(product, index, type = "") {
		console.log('product', product);
		var user_province = localStorage.getItem("user_province");
		// For check user is loggedin or not
		if (this.authService.isLoggedIn()) {
			const formData = new FormData();
			formData.append('product_varint_id', product.productVariantID);
			formData.append('product_quantity', '1');
			formData.append('user_province', user_province);
			// this.commonService.showLoader();

			this.shopService.addToCart(formData).subscribe((data) => {
				this.commonService.hideLoader();
				// feature product list
				const foundProduct = this.featuredProductList.find(productF => productF.productID === product.productID);
				if (foundProduct) {				// Update the specific key with the new value
					foundProduct['is_cart'] = '1';
					foundProduct['cartItemID'] = data.data.cartID;
					this.featuredProductList = [...this.featuredProductList];
				}

				// recentProductList visit list
				const foundProductLast = this.recentProductList.find(productF => productF.productID === product.productID);

				if (foundProductLast) {				// Update the specific key with the new value
					foundProductLast['is_cart'] = '1';
					foundProductLast['cartItemID'] = data.data.cartID;
					this.recentProductList = [...this.recentProductList];
				}


				//promoted visit list
				const foundPromoted = this.promotedProductList.find(productF => productF.productID === product.productID);

				if (foundPromoted) {				// Update the specific key with the new value
					foundPromoted['is_cart'] = '1';
					foundPromoted['cartItemID'] = data.data.cartID;
					this.promotedProductList = [...this.promotedProductList];
				}

				//offred visit list
				const foundOffer = this.offerProductList.find(productF => productF.productID === product.productID);

				if (foundOffer) {				// Update the specific key with the new value
					foundOffer['is_cart'] = '1';
					foundOffer['cartItemID'] = data.data.cartID;
					this.offerProductList = [...this.offerProductList];
				}
				//product list
				const founddProductList = this.productList.find(productF => productF.productID === product.productID);

				if (founddProductList) {				// Update the specific key with the new value
					founddProductList['is_cart'] = '1';
					founddProductList['cartItemID'] = data.data.cartID;
					this.productList = [...this.productList];
				}
				// combo product list
				const combo = this.combo.find(productF => productF.productID === product.productID);

				if (combo) {				// Update the specific key with the new value
					combo['is_cart'] = '1';
					combo['cartItemID'] = data.data.cartID;
					this.combo = [...this.combo];
				}



				this.header.getCartCount();// for decrease card count


			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}

		// } 
		else {
			this.router.navigate(['/login']);
		}

		// }

	}

	//Remove cart item
	removeCartItem(product, index, type = "") {
		console.log('product', product);
		var cartItemId = product.cartItemID;

		this.buyerService.removeCartItem(cartItemId).subscribe(
			(cartlistDataRemove) => {
				this.commonService.hideLoader();


				const foundProduct = this.featuredProductList.find(productF => productF.productID === product.productID);
				if (foundProduct) {				// Update the specific key with the new value
					foundProduct['is_cart'] = '0';
					foundProduct['cartItemID'] = null;
					this.featuredProductList = [...this.featuredProductList];
				}

				// recentProductList visit list
				const foundProductLast = this.recentProductList.find(productF => productF.productID === product.productID);

				if (foundProductLast) {				// Update the specific key with the new value
					foundProductLast['is_cart'] = '0';
					foundProductLast['cartItemID'] = null;
					this.recentProductList = [...this.recentProductList];
				}

				//promoted visit list
				const foundPromoted = this.promotedProductList.find(productF => productF.productID === product.productID);

				if (foundPromoted) {				// Update the specific key with the new value
					foundPromoted['is_cart'] = '0';
					foundPromoted['cartItemID'] = null;
					this.promotedProductList = [...this.promotedProductList];
				}

				//offred visit list
				const foundOffer = this.offerProductList.find(productF => productF.productID === product.productID);

				if (foundOffer) {				// Update the specific key with the new value
					foundOffer['is_cart'] = '0';
					foundOffer['cartItemID'] = null;
					this.offerProductList = [...this.offerProductList];
				}


				//product list
				const founddProductList = this.productList.find(productF => productF.productID === product.productID);

				if (founddProductList) {				// Update the specific key with the new value
					founddProductList['is_cart'] = '0';
					founddProductList['cartItemID'] = null;
					this.productList = [...this.productList];
				}

				// combo product list
				const combo = this.combo.find(productF => productF.productID === product.productID);

				if (combo) {				// Update the specific key with the new value
					combo['is_cart'] = '0';
					combo['cartItemID'] = null;
					this.combo = [...this.combo];
				}

				this.header.getCartCount();// for decrease card count

			}, err => {
				// this.commonService.hideLoader();
				// this.commonService.errorhandler(err);
			});
	}


}
