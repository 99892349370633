import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faAngleDoubleRight, faStar, faEye, faHeart, faShoppingCart, faCheck, faTimes, faEdit, faTrashAlt, faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { ShopService } from '../../services/shop.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { WalletService } from '../services/wallet.service';
import Swal from 'sweetalert2';
import { SellerService } from '../services/seller.service';


declare const $: any;
declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-my-product-detail',
  templateUrl: './my-product-detail.component.html',
  styleUrls: ['./my-product-detail.component.css']
})
export class MyProductDetailComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  faStar = faStar;
  faCommentDots = faCommentDots;
  faEye = faEye;
  faHeart = faHeart;
  faShoppingCart = faShoppingCart;
  faCheck = faCheck;
  faTimes = faTimes;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faCircle = faCircle;
  faPlus = faPlus;
  productId: string = '';
  productDetail: any = {};
  currentUserId: any;
  similarProduct: any = [];
  galleryOptions: NgxGalleryOptions[];
  galleryImages: any = [];
  galleryImage: any = [];
  variants: any = [];
  variantListData: any = [];
  variant: string = '';
  keyFeatureList: any = [];
  //promotedType: any = '1';  1 for week, 2 for month
  //paymentType: any = '1';  0 for case, 1 for card
  cardList: any = [];
  stripeCardId: any = "";
  cardID: any;
  verifyPrice: any = "";
  answerForm: FormGroup;
  packagesGet: any;
  questionAnswer: any;
  questionId: any;
  offerIdGet: any;
  makeOfferCounter: FormGroup;
  statusGet: any;
  productIdGet: any;
  promoteListData: any;
  isPackage: boolean = false;
  questionAnswerLength: boolean = false;
  jsonPackage: any;
  showQuestionAnsButton: any;
  remaingQuestion: any;
  promoteDataList: any;
  payFlagget: any = 1;
  walletAmountData: any;
  walletAmount: any;
  proAmountGet: any;
  proTypeGet: any;
  promoteListVal: any;
  durationGet: any;
  titleConfirm: string = "";
  textConfirm: string = "";
  confirmButtonTextConfirm: string = "";
  cancelButtonTextConfirm: string = "";
  cancelled: string = "";
  yourProductSafe: string = "";

  titleRecent: string = "";
  textRecent: string = "";
  confirmButtonTextRecent: string = "";
  cancelButtonTextRecent: string = "";


  stripe;
  card;
  cardErrors;
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;
  discountPercent: any;
  is_no_varinat: any;
  noVariantQuantity: any;
  noVariantWeight: any;
  varification_status: any = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private authService: AuthService,
    private shopService: ShopService,
    private walletService: WalletService,
    private formBuilder: FormBuilder,
    private router: Router,
    private translate: TranslateService,
    private sellerService: SellerService,

  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {

    this.answerForm = this.formBuilder.group({
      // description: ['', [Validators.required, Validators.maxLength(200)]],
    });

    $('.modal').on('hidden.bs.modal', function () {
      if ($(".modal:visible").length > 0) {
        $('body').addClass('modal-open');
      } else {
        $('body').removeClass('modal-open');
      }
    });
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | My Product Detail');
    this.productId = this.activatedRoute.snapshot.paramMap.get("id");
    this.setConfigImageGallery();
    this.getProductDetail(this.productId, this.authService.getCurrentUserId());
    this.getCardList();

    //localization
    this.translate.get('SELLER_SIDE.delete_product').subscribe((text: string) => { this.titleConfirm = text; });
    this.translate.get('SELLER_SIDE.delete_product_popup').subscribe((text: string) => { this.textConfirm = text; });
    this.translate.get('SELLER_SIDE.yes_delete_it').subscribe((text: string) => { this.confirmButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.cancelled').subscribe((text: string) => { this.cancelled = text; });
    this.translate.get('SELLER_SIDE.your_product_is_safe').subscribe((text: string) => { this.yourProductSafe = text; });
    this.translate.get('SELLER_SIDE.save_to_recently_deleted').subscribe((text: string) => { this.titleRecent = text; });
    this.translate.get('SELLER_SIDE.recently_deleted_list').subscribe((text: string) => { this.textRecent = text; });
    this.translate.get('SELLER_SIDE.yes_save_it').subscribe((text: string) => { this.confirmButtonTextRecent = text; });
    this.translate.get('SELLER_SIDE.no_delete_it').subscribe((text: string) => { this.cancelButtonTextRecent = text; });

    // offer counter for
    this.makeOfferCounter = this.formBuilder.group({
      counter_price: ['', [Validators.required]],
    })

    // Wallet Amount Get
    this.getWalletAmount();

  }

  get description() { return this.answerForm.get('description'); }
  get counter_price() { return this.makeOfferCounter.get('counter_price'); }

  ngAfterViewInit() {

    //localization
    this.translate.get('SELLER_SIDE.delete_product').subscribe((text: string) => { this.titleConfirm = text; });
    this.translate.get('SELLER_SIDE.delete_product_popup').subscribe((text: string) => { this.textConfirm = text; });
    this.translate.get('SELLER_SIDE.yes_delete_it').subscribe((text: string) => { this.confirmButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.cancelled').subscribe((text: string) => { this.cancelled = text; });
    this.translate.get('SELLER_SIDE.your_product_is_safe').subscribe((text: string) => { this.yourProductSafe = text; });
    this.translate.get('SELLER_SIDE.save_to_recently_deleted').subscribe((text: string) => { this.titleRecent = text; });
    this.translate.get('SELLER_SIDE.recently_deleted_list').subscribe((text: string) => { this.textRecent = text; });
    this.translate.get('SELLER_SIDE.yes_save_it').subscribe((text: string) => { this.confirmButtonTextRecent = text; });
    this.translate.get('SELLER_SIDE.no_delete_it').subscribe((text: string) => { this.cancelButtonTextRecent = text; });

    // stripe payment
    this.stripe = Stripe(environment.striePkKey);
    const elements = this.stripe.elements();

    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  setConfigImageGallery() {
    this.galleryOptions = [
      {
        width: '350px',
        height: '550px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewZoom: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }


  getDisputeTimeShow(dateTime) {

    return this.commonService.getDateTime2(dateTime);
  }

  getTillShow(dateTime) {

    return this.commonService.getDisputeTimeShow(dateTime);
  }


  //Get product Detail
  getProductDetail(productId, currentUserId) {
    this.galleryImages = [];
    this.commonService.showLoader();
    this.shopService.getProductDetail(productId, currentUserId).subscribe((productDetailData) => {
      console.log('productDetailData', productDetailData);



      this.commonService.hideLoader();
      if (productDetailData.status == 'success') {
        this.commonService.scrollToTop();
        this.galleryImage = productDetailData.data.product_detail.gallery_images;
        this.productDetail = productDetailData.data.product_detail;
        this.is_no_varinat = productDetailData.data.product_detail.is_no_variants;
        this.productIdGet = this.productDetail.productID;
        this.questionAnswer = this.productDetail.question;

        console.log('galleryImage', this.galleryImage);

        this.galleryImage.forEach(element => {
          let galleryImagesData = {
            small: `${element.gallery_image_url}${element.gallery_image}`,
            medium: `${element.gallery_image_url}${element.gallery_image}`,
            big: `${element.gallery_image_url}${element.gallery_image}`
          };
          this.galleryImages.push(galleryImagesData);

        });

        // questions and answer button coun/
        if (this.questionAnswer.length <= 4) {
          this.showQuestionAnsButton = 0

        } else {
          this.showQuestionAnsButton = 1
          this.remaingQuestion = this.questionAnswer.length - 4;
        }

        var filtered = this.questionAnswer.filter(function (value, index, arr) {
          // return index !=0 && index <=8;
          return index <= 3;

        });

        this.questionAnswer = filtered;
        // END


        if (this.questionAnswer.length != 0) {
          this.questionAnswerLength = true
        }
        this.variants = this.productDetail.variant_list;
        this.keyFeatureList = JSON.parse(this.productDetail.key_features);

        this.jsonPackage = (this.productDetail.packages) ? JSON.parse(this.productDetail.packages) : [];

        this.discountPercent = Math.round(this.productDetail.discount_percent);


        if (this.jsonPackage.length == 0) {

          this.isPackage = false;

        } else {
          this.isPackage = true;

        }

        this.productDetail.offer_counter_list.forEach(element => {
          this.offerIdGet = element.offerItemID;
        });

        this.noVariantQuantity = productDetailData.data.product_detail.variant_list[0].quantity;
        // this.noVariantWeight = productDetailData.data.product_detail.variant_list[0].weight;

        if (productDetailData.data.product_detail.variant_list[0].weight > 0) {

          this.noVariantWeight = Number(productDetailData.data.product_detail.variant_list[0].weight)
        } else {
          this.noVariantWeight = ""
        }



        console.log('  this.galleryImages', this.galleryImages);

      } else {

        this.productDetail = [];
      }
    }, err => {
      //""
      this.commonService.hideLoader();

      if ((err.error.message == 'Este producto ya no está disponible. Producto eliminado por el vendedor') || (err.error.message == 'This product is not longer available. Product deleted by seller')) {
        this.router.navigate(['/seller/my-products']);
      } else {
        this.commonService.errorhandler(err);

      }
    });


  }

  // Product Promote api
  promoteList() {
    const formData = new FormData();
    formData.append('product_id', this.productId);
    this.shopService.productPromote(formData).subscribe((res) => {
      this.promoteListData = res;
      this.commonService.hideLoader();
      this.commonService.toastSuccess(res.message);

    }, err => {
      this.commonService.errorhandler(err);
      this.commonService.hideLoader();
    });
  }


  trimValue(formControl) {
    ((typeof formControl.value) && formControl.value.trim() == "") ?
      formControl.setValue("") : formControl.setValue(formControl.value);
  }

  confrimation(productID, isCopy) {
    // return false;
    Swal.fire({
      title: this.titleConfirm,
      text: this.textConfirm,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextConfirm,
      cancelButtonText: this.cancelButtonTextConfirm,
      reverseButtons: true
    }).then((result) => {




      if (result.value) {

        if (isCopy != 0) {
          this.deleteProduct(productID);

        } else {
          this.recentDelete(productID);

        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.yourProductSafe,
          'error'
        )
      }


    });
  }



  deleteProduct(productID) {
    this.commonService.showLoader();

    this.sellerService.deleteProduct(productID).subscribe(data => {
      this.commonService.hideLoader();

      if (data.status == 'success') {
        this.commonService.toastSuccess(data.message);
        // this.ngOnInit();
        this.router.navigate(['/seller/my-products']);
      } else {
        this.commonService.toastError(data.message);
      }

    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });

  }

  recentDelete(productID) {
    Swal.fire({
      title: this.titleRecent,
      text: this.textRecent,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextRecent,
      cancelButtonText: this.cancelButtonTextRecent,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.recentDeleteAdd(productID);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        this.deleteProduct(productID);
      }
    });
  }

  recentDeleteAdd(productID) {
    this.commonService.showLoader();

    let statusData = `status=1`;
    this.sellerService.recentDeleted(productID, statusData).subscribe(orderData => {
      this.commonService.hideLoader();

      if (orderData.status == 'success') {
        this.commonService.toastSuccess(orderData.message);
        // this.ngOnInit();
        this.router.navigate(['/seller/my-products']);
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }


  getDeliveryDuration(key) {
    var lang = this.authService.getLanguage();

    if (lang == 'en') {
      switch (key) {
        case '1':
          return "1 Day";
          break;
        case '2':
          return "2 Days";
          break;
        case '3':
          return "3 Days";
          break;
        case '4':
          return "7 Days";
          break;
        case '5':
          return "More than 7 days";
          break;
      }
    } else {
      switch (key) {
        case '1':
          return "1 Día";
          break;
        case '2':
          return "2 Días";
          break;
        case '3':
          return "3 Días";
          break;
        case '4':
          return "7 Días";
          break;
        case '5':
          return "More than 7 días";
          break;
      }
    }


  }

  addNewCard() {
    $('#addCard').modal('show');
  }


  promotedModelhide() {
    this.promoteListVal = undefined;
    $('#promoteProduct').modal('hide');

  }

  promoted() {
    if (this.payFlagget == 1) {
      if (this.promoteListVal == undefined) {
        this.commonService.toastError('Please select duration type');
      } else {
        if (this.stripeCardId) {
          this.addPromotedProduct();
        } else {
          this.commonService.toastError('Please select card');
        }
      }
    } else {
      if (this.promoteListVal == undefined) {
        // alert(this.promoteListVal);
        this.commonService.toastError('Please select duration type');
      } else {
        // alert(this.promoteListVal);

        this.addPromotedProduct();
      }
    }
  }


  getCardList() {
    this.cardList = [];
    this.commonService.showLoader();

    this.shopService.getCardList().subscribe(cardData => {
      this.commonService.hideLoader();
      if (cardData.status == 'success') {
        console.log('cardData', cardData);
        this.varification_status = cardData.data.varification_status

        console.log('varification_status', this.varification_status);

        var keys = Object.keys(cardData.data); //Check data is empty object
        //this.cardList = cardData.data.card_list;
        var len = keys.length;  //get length of empty object
        if (len === 0) {
          this.cardList = "";

        } else {
          cardData.data.card_list.forEach(card => {
            if (card.is_expired == '0') {
              this.cardList.push(card);
            }

          });
        }


      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

  async handleForm(e) {
    this.commonService.showLoader();
    e.preventDefault();

    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
      this.commonService.hideLoader();
      // Inform the customer that there was an error.
      const cardErrors = error.message;
    } else {

      // Send the token to your server.
      const formData = new FormData();
      formData.append('sourceId', token.id);

      this.shopService.addCard(formData).subscribe(cardData => {
        this.commonService.hideLoader();

        if (cardData.status == 'fail') {
          this.commonService.toastError(cardData.message);
        } else {
          this.addCard(cardData.data.card_detail);
        }
      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
    }
  }

  addCard(card_detail) {

    this.commonService.showLoader();
    const formData = new FormData();
    formData.append('stripe_card_id', card_detail.id);
    formData.append('card_holder_name', this.authService.getUserDetails().full_name);
    formData.append('card_last_4_digits', card_detail.last4);
    formData.append('card_expiry_month', card_detail.exp_month);
    formData.append('card_expiry_year', card_detail.exp_year);
    formData.append('card_brand_type', card_detail.brand);

    this.shopService.saveCard(formData).subscribe(cardData => {
      this.commonService.hideLoader();

      if (cardData.status == 'fail') {
        this.commonService.toastError(cardData.message);
      } else {
        this.commonService.toastSuccess(cardData.message);
        this.resetCard();
        $('#addCard').modal('hide');
        // $('#promoteProduct').modal('hide');
        this.getCardList();
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

  resetCard() {
    this.card.clear();
  }

  verifyModal(cardID) {
    this.cardID = cardID;
    this.cardVerifyReset()
    $('#cardVerifyModal').modal('show');
  }

  cardVerifyReset() {
    this.verifyPrice = '';
  }


  // For shipping charges limit set
  checkNumberLimit(e) {
    // if (e.target.value.includes('.') && e.keyCode == 46) {
    // 	e.preventDefault();
    // 	return false
    // }
    var valid = /^\d{0,4}(\.\d{0,1})?$/.test(e.target.value),
      val = e.target.value;

    if (!valid) {

      // return false;
      e.target.value = val.substring(0, val.length - 1);
    }

    // if (e.target.value.length > 10 || e.keyCode == 45) {
    // 	e.preventDefault();
    // 	return false;
    // }

  }

  cardVerifySubmit() {
    if (this.proAmountGet != "") {
      this.commonService.showLoader();

      const formData = new FormData();
      formData.append('card_id', this.cardID);
      formData.append('verify_amount', this.verifyPrice);

      this.shopService.cardVerify(formData).subscribe((data) => {
        this.commonService.hideLoader();

        if (data.status == 'fail') {
          this.cardVerifyReset()

          this.commonService.toastError(data.message);
        } else {
          this.commonService.toastSuccess(data.message);
          $('#cardVerifyModal').modal('hide');
          // get all card list
          this.getCardList();
        }

      }, err => {
        this.cardVerifyReset()
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

    } else {
      this.commonService.toastError('Please enter deducted amount.');
    }

  }

  promotedTypeGet(typeGet, id) {
    this.promoteListVal = typeGet;
    this.promoteDataList.forEach(element => {
      if (element.promoteID == id) {
        this.proAmountGet = element.amount;
        this.proTypeGet = element.duration_type;
        this.durationGet = element.duration;
      }
    });
  }

  addPromotedProduct() {
    this.commonService.showLoader();
    let price = this.proAmountGet;
    let duration = this.durationGet;
    let duration_type = this.proTypeGet;
    const formData = new FormData();
    formData.append('product_id', this.productId);
    formData.append('payment_type', this.payFlagget);
    formData.append('price', price);
    formData.append('duration', duration);
    formData.append('duration_type', duration_type);
    formData.append('stripe_card_id', this.stripeCardId);

    this.shopService.promote(formData).subscribe(cardData => {
      this.commonService.hideLoader();

      if (cardData.status == 'fail') {
        this.commonService.toastError(cardData.message);
      } else {
        this.commonService.toastSuccess(cardData.message);
        $('#promoteProduct').modal('hide');
        this.ngOnInit();
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });


  }


  addAnswerKeyup(id) {
    $('#question_error' + id).css('display', "none")
    $('#question_error' + id).css('color', "unset")
  }

  // Add questions form
  addAnsWer(id) {

    if ($(`#ans_${id}`).val().trim() == "") {
      $('#question_error' + id).css('display', "block")
      $('#question_error' + id).css('color', "red")

      return false;
    }


    $('#question_error' + id).css('display', "none")
    $('#question_error' + id).css('color', "unset")



    // if (this.answerForm.valid) {
    this.commonService.showLoader();
    const formData = new FormData();
    formData.append('product_id', this.productDetail.productID);
    formData.append('description', $(`#ans_${id}`).val());
    formData.append('question_id', id);
    this.shopService.askQuestions(formData).subscribe((res) => {
      this.commonService.hideLoader();
      this.commonService.toastSuccess(res.message);
      this.getProductDetail(this.productId, this.authService.getCurrentUserId());
      // window.location.reload();
      this.answerForm.reset();

    }, err => {
      this.commonService.errorhandler(err);
      this.commonService.hideLoader();
      this.answerForm.reset();
    });
    // } 
    // else {
    //   this.validateAllFormFields(this.answerForm); //{7}

    // }

  }
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }



  // Button offer status change
  offerCheckFun(statusNum) {
    this.commonService.showLoader();
    const formData = new FormData();
    formData.append('status', statusNum);
    formData.append('offer_id', this.offerIdGet);

    this.shopService.offerAction(formData).subscribe((res) => {
      this.commonService.hideLoader();
      this.commonService.toastSuccess(res.message);
      window.location.reload();

    }, err => {
      this.commonService.errorhandler(err);
      this.commonService.hideLoader();
    });
  }

  // Counter price form
  offerCounter(statusNum) {
    $('#counterModal').modal('show');
    this.statusGet = statusNum;
  }

  makeOfferCounterSubmit() {
    this.commonService.showLoader();
    const formData = new FormData();
    formData.append('status', this.statusGet);
    formData.append('offer_id', this.offerIdGet);
    formData.append('counter_price', this.makeOfferCounter.get('counter_price').value);

    this.shopService.offerAction(formData).subscribe((res) => {
      this.commonService.hideLoader();
      this.commonService.toastSuccess(res.message);
      this.makeOfferCounter.reset();
      $('#counterModal').modal('hide');
      window.location.reload();

    }, err => {
      this.commonService.errorhandler(err);
      this.commonService.hideLoader();
      this.makeOfferCounter.reset();
    });
  }

  // Promote product list get api
  promoteListGet() {
    this.commonService.showLoader();
    this.shopService.promoteProductList().subscribe((res) => {
      this.promoteDataList = res.data;
      this.commonService.hideLoader();

    }, err => {
      this.commonService.errorhandler(err);
      this.commonService.hideLoader();
    });
  }
  // end promote lis/

  //promote Payment type flg get
  payTypeGet(flag) {
    this.payFlagget = flag;
  }

  // Wallet Amount Get
  getWalletAmount() {
    this.commonService.showLoader();
    this.walletService.getWalletAmount().subscribe(data => {
      this.commonService.hideLoader();

      if (data.status == "fail") {
        this.walletAmountData = "";
        this.walletAmount = 0;

      } else {
        this.walletAmountData = data.data;
        this.walletAmount = this.walletAmountData.amount;
      }



    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    })
  }

}
