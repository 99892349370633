import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: "excerptCategory"
})

export class ExcerptCategoryPipe implements PipeTransform {

  transform(text: string) {


    if (text != "" && text == undefined) {

      let categoryArray = text.split('>');


      return categoryArray[categoryArray.length - 1];
    } else {
      return text;
    }

  }

}

// exapmle: {{ category | excerptCategory }}