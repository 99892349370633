import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { NewOrdersComponent } from './new-orders/new-orders.component';
import { MyProductsComponent } from './my-products/my-products.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AddBusinessComponent } from './add-business/add-business.component';
import { SellerAccountComponent } from './seller-account/seller-account.component';
import { NewOrderDetailComponent } from './new-order-detail/new-order-detail.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyProductDetailComponent } from './my-product-detail/my-product-detail.component';
import { ChatComponent } from './chat/chat.component';
import { MyOrderDetailComponent } from './my-order-detail/my-order-detail.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { SellerReviewComponent } from './seller-review/seller-review.component';
import { CompleteSwitchProfileComponent } from './complete-switch-profile/complete-switch-profile.component';
import { RecentlyDeleteProductComponent } from './recently-delete-product/recently-delete-product.component';
import { DisputeComponent } from './dispute/dispute.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TrashChatComponent } from './trash-chat/trash-chat.component';
import { RecentlyDeleteProductDetailComponent } from './recently-delete-product-detail/recently-delete-product-detail.component';
import { RejectedOrderComponent } from './rejected-order/rejected-order.component';
import { RejectedOrderDetailsComponent } from './rejected-order-details/rejected-order-details.component';
import { WalletComponent } from './wallet/wallet.component';
import { WalletDetailComponent } from './wallet-detail/wallet-detail.component';
import { ProductOffersComponent } from './product-offers/product-offers.component';
import { ProductOffersDetailsComponent } from './product-offers-details/product-offers-details.component';
import { EmailVarificationComponent } from './email-varification/email-varification.component';
import { MobileVarificationComponent } from './mobile-varification/mobile-varification.component';
import { NumberVerifyComponent } from './number-verify/number-verify.component';
import { QuestionAnswersComponent } from './question-answers/question-answers.component';
import { EditWarehouseAddressComponent } from './edit-warehouse-address/edit-warehouse-address.component';

const routes: Routes = [
  { path: 'seller/add-business', component: AddBusinessComponent},
  { path: 'seller/new-orders', component: NewOrdersComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/my-products', component: MyProductsComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/my-product-detail/:id', component: MyProductDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/add-product', component: AddProductComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-account', component: SellerAccountComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-account/warehouse', component: SellerAccountComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-account/subscription', component: SellerAccountComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-account/commission', component: SellerAccountComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-account/shipping', component: SellerAccountComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/new-order-detail/:id', component: NewOrderDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/my-orders', component: MyOrdersComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/my-order-detail/:id', component: MyOrderDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/my-order-details/:id', component: MyOrderDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/my-order-details/:id/:type', component: MyOrderDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/edit-product/:id', component: EditProductComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/chat', component: ChatComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/chat/order/:orderId/:buyerId', component: ChatComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/chat/product/:productId/:sellerId', component: ChatComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-review/:sellerId', component: SellerReviewComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/complete-switch-profile', component: CompleteSwitchProfileComponent },
  { path: 'seller/recently-delete-product', component: RecentlyDeleteProductComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-account/recently-delete-product', component: RecentlyDeleteProductComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/recently-delete-product-detail/:id', component: RecentlyDeleteProductDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/seller-account/recently-delete-product-detail/:id', component: RecentlyDeleteProductDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/dispute/:id', component: DisputeComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/subscription', component: SubscriptionComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/subscription/signup', component: SubscriptionComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/trash-chat', component: TrashChatComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/rejected-order', component: RejectedOrderComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/rejected-order-details/:orderID', component: RejectedOrderDetailsComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/wallet', component: WalletComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/wallet-detail/:id', component: WalletDetailComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/product-offers', component: ProductOffersComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/product-offers-details/:productID/:variantID/:offerItem_id', component: ProductOffersDetailsComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
  { path: 'seller/email-varification', component: EmailVarificationComponent },
  { path: 'seller/mobile-varification', component: MobileVarificationComponent },
  { path: 'seller/number-verify', component: NumberVerifyComponent },
  { path: 'seller/question-answers/:productId', component: QuestionAnswersComponent },
  { path:'edit-warehouse-address', component:EditWarehouseAddressComponent,canActivate:[AuthGuard], data: {role: 'seller'}},

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class SellerRoutingModule { }
