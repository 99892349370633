import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { CommonService } from '../../services/common.service';

@Component({
  selector: 'app-footer-buyer',
  templateUrl: './footer-buyer.component.html',
  styleUrls: ['./footer-buyer.component.css']
})
export class FooterBuyerComponent implements OnInit {

  year:any = formatDate(new Date(), 'yyyy', 'en');
  lang: string;

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
     //For language
     if(this.authService.isLoggedIn()==true){
      this.translate.use(this.authService.getLanguage());
      if(this.authService.getLanguage() == 'es'){
        this.lang = 'es'
      }else{
        this.lang = 'en'
  
      }
     

    }else{
      this.translate.use(this.commonService.getLanguage());
      if(this.commonService.getLanguage() == 'es'){
        this.lang = 'es'
      }else{
        this.lang = 'en'
  
      }
    }
  }

}
