import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderBuyerComponent } from 'src/app/common/header-buyer/header-buyer.component';
import { faAngleDoubleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { ShopService } from '../../services/shop.service';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { ETIME } from 'constants';
import { TranslateService } from '@ngx-translate/core';

declare var $: any

@Component({
  selector: 'app-product-offers',
  templateUrl: './product-offers.component.html',
  styleUrls: ['./product-offers.component.css']
})
export class ProductOffersComponent implements OnInit {


  @ViewChild('header', { static: true }) header: HeaderBuyerComponent;
  offerList: any = [];
  limit = 12;
  offset = 0;
  offerPriceGet: any;
  productPric: any;
  offerIdGet: any;
  CounterForm: FormGroup;
  totalOffer: any;
  offerCount: any;
  offerData: any;
  offerDataRecord: any;
  falg: number;

  constructor(
    private commonService: CommonService,
    private shopService: ShopService,
    private authService: AuthService,
    private router: Router,
    private formBuilder: FormBuilder,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Product-offer'); //For title
    this.getOfferList(); //For offer list

    // For counter form validation
    this.CounterForm = this.formBuilder.group({
      counter_price: ['', [Validators.required, Validators.maxLength(6)]]
    });
    // End validation

  }

  // Counter Form validation 
  get counter_price(): any { return this.CounterForm.get('counter_price'); }
  // End

  // For offer list
  getOfferList() {
    this.commonService.showLoader(); //For loader
    // Offer api calling 
    this.shopService.productOffer(this.limit, this.offset).subscribe(offerData => {
      this.commonService.hideLoader(); //Hide loader

      if (offerData.status == 'success') {
        offerData.data.offerList.forEach(element => {
          this.offerList.push(element);
        });


        if (offerData.data.offerList.length == 0) {
          this.offerCount = 0;
        } else {
          this.offerCount = 1;

        }
        this.totalOffer = offerData.data.total_records;
      }
    }, err => {
      this.commonService.hideLoader(); //For hide loader
      this.commonService.errorhandler(err);
    });
  }
  // End

  // Offer actions
  offerActions(offerId, flag, sellerPrice, offerPrice, offerData) {
    this.commonService.showLoader(); //For loader hide

    this.falg = 1;

    this.productPric = sellerPrice;
    this.offerPriceGet = offerPrice;
    this.offerIdGet = offerId;
    this.offerDataRecord = offerData;

    const formData = new FormData();
    formData.append('offer_id', offerId);
    formData.append('status', flag);

    // Accept
    if (flag == 1) {
      this.shopService.offerAction(formData).subscribe(res => {
        this.commonService.toastSuccess(res.message);
        this.offerList = [];
        this.getOfferList();
        this.commonService.hideLoader();
      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
    }

    // Reject
    if (flag == 2) {
      this.shopService.offerAction(formData).subscribe(res => {
        this.commonService.toastSuccess(res.message);
        this.offerList = [];
        this.getOfferList();
        this.commonService.hideLoader();
      }, err => {
        this.commonService.hideLoader();
        this.offerList = [];
        this.getOfferList();
        this.commonService.errorhandler(err);
      });
    }

    // counter 
    if (flag == 4) {
      this.CounterForm.reset();

      this.commonService.hideLoader();
      $('#counterModal').modal('show');
    }


  }
  //  End


  checkNumberLimit(e) {
		if (e.target.value.includes('.') && e.keyCode == 46) {
			e.preventDefault();
			return false
		}
		var valid = /^\d{0,7}(\.\d{0,2})?$/.test(e.target.value),
        val = e.target.value;
    
    if(!valid){
		// return false;
         e.target.value = val.substring(0, val.length - 1);
    }

		// if (e.target.value.length > 10 || e.keyCode == 45) {
		// 	e.preventDefault();
		// 	return false;
		// }

	}


  // Function For counter price 
  counterPrice(flag) {

    if(this.CounterForm.valid){

    this.commonService.showLoader();//For loader show

    if (this.CounterForm.get('counter_price').value <= 0) {
      this.commonService.hideLoader(); //For loader hide
      this.commonService.toastError(this.translate.instant('PROFILE.counter_price')); return false;
    }

    const formData = new FormData();
    formData.append('offer_id', this.offerIdGet);
    formData.append('status', flag);
    formData.append('counter_price', this.CounterForm.get('counter_price').value);

    this.shopService.offerAction(formData).subscribe(res => {
      this.commonService.toastSuccess(res.message);
      this.offerList = [];
      this.getOfferList();
      $('#counterModal').modal('hide');
      this.CounterForm.reset();
      this.commonService.hideLoader();
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }else{
    this.validateAllFormFields(this.CounterForm); //{7}

  }
  }
  // End

    // For check form valid
    validateAllFormFields(formGroup: FormGroup) {         //{1}
      Object.keys(formGroup.controls).forEach(field => {  //{2}
        const control = formGroup.get(field);             //{3}
        if (control instanceof FormControl) {             //{4}
          control.markAsTouched({ onlySelf: true });
        } else if (control instanceof FormGroup) {        //{5}
          this.validateAllFormFields(control);            //{6}
        }
      });
    }

  // Function for load more button offer list
  loadMore() {
    this.offset = this.offset + Number(this.limit);
    this.getOfferList(); //Offer list function
  }
  // End


}