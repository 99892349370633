import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { faAngleDoubleRight, faCheck, faStar, faCircle } from '@fortawesome/free-solid-svg-icons';
import { Options } from 'ng5-slider';
import { CommonService } from '../../services/common.service';
import { ShopService } from '../../services/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { HeaderBuyerComponent } from '../../common/header-buyer/header-buyer.component';
import { TranslateService } from '@ngx-translate/core';
import { WalletService } from '../services/wallet.service';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { environment } from '../../../environments/environment';
import { CountrylistService } from './countrylist.service';


declare const $: any;
@Component({
	selector: 'app-wallet',
	templateUrl: './wallet.component.html',
	styleUrls: ['./wallet.component.css']
})
export class WalletComponent implements OnInit {
	@ViewChild('header', { static: true }) header: HeaderBuyerComponent;
	currentUserId: any;
	walletAmountData: any;
	walletAmount: any;
	walletRequsetList: any = [];
	walletRequsetListData: any;
	bankTransferForm: FormGroup;
	zelleTransferForm: FormGroup;
	wireTransferForm: FormGroup;
	cubanBankForm: FormGroup;
	cashForm: FormGroup;
	reqData: any = 1;
	getCountry: any;
	provinceList: any;
	getAmountValue: any;
	walletBankDetails: any;
	userInfoLocal: any;
	countryGetLoacal: any;
	subtotalAmount: any;
	adminFee: any;
	amountLimitGet: any;
	walletData: any;
	countryOfBank: string = 'US';
	countryGetSeller: string;
	countryDeaflt: string = 'United States';
	phoneDialCode: any;
	usaArray: any = [];
	euArray: any = [];
	usaCountry: any;
	eurCountry: any;
	limit: number = 100;
	offset: number = 0;
	statusWallet: any = 0;
	payMetodGet: any = 0;
	itemPerPage: any;
	page: number = 1;
	noRecordFound: any;
	type: any;
	walletRequsetId: any;
	Zelle: any = 0;
	walletRequsetList1: any;

	constructor(
		@Inject(DOCUMENT) private document: Document,
		private commonService: CommonService,
		private shopService: ShopService,
		private activatedRoute: ActivatedRoute,
		private authService: AuthService,
		private router: Router,
		private translate: TranslateService,
		private walletService: WalletService,
		private countrylistService: CountrylistService,
		private fb: FormBuilder,
	) { translate.use(this.commonService.getLanguage()); }



	ngOnInit() {
		// Country array for usa and europe
		this.usaArray = this.countrylistService.countryListArray()[0];
		this.euArray = this.countrylistService.countryListArray()[1];
		// End=====

		// Country data get?
		this.userInfoLocal = JSON.parse(localStorage.getItem(environment.dataStore.userData));
		this.countryGetLoacal = this.userInfoLocal.user_detail.country_code;
		this.phoneDialCode = this.userInfoLocal.user_detail.phone_dial_code;
		this.countryGetSeller = this.userInfoLocal.user_detail.country.trim();

		// Usa Country
		const usaCountryFilter = this.usaArray.filter((val) => val.country.trim().toLowerCase() == this.countryGetSeller.trim().toLowerCase());

		usaCountryFilter.forEach(element => {
			this.usaCountry = element.country;
		});
		// End

		// Euro Country
		const euCountryFilter = this.euArray.filter((val) => val.country.trim().toLowerCase() == this.countryGetSeller.trim().toLowerCase());
		euCountryFilter.forEach(element => {
			this.eurCountry = element.country;
		});
		// End


		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Wallet Management');
		this.currentUserId = this.authService.getCurrentUserId();
		this.getWalletAmount();
		this.getWalletList();
		//  Wallet type
		this.walletTypeGet();

		this.getProvinceListData();


		// bankTransferForm
		if (this.countryGetSeller == this.usaCountry) {
			this.bankTransferForm = this.fb.group({
				amount: ['', [Validators.required, Validators.pattern(/^[1-9]+(\.?[0-9]+)?$/)]],
				bank_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
				name_of_recipient: ['', [Validators.required,Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
				address: ['', [Validators.required]],
				routing_number: ['', [Validators.required, Validators.pattern(/^[0-9]+(\?[0-9]+)?$/), Validators.maxLength(10),]],
				account_type: ['', [Validators.required]],
				account_numberd: ['', [Validators.required, Validators.maxLength(16), Validators.pattern(/^[0-9]+(\?[0-9]+)?$/)]],
				// name_of_recipient_accountd: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[a-zA-Z ]+')]],
			});
		}
		if (this.countryGetSeller == this.eurCountry) {
			this.bankTransferForm = this.fb.group({
				amount: ['', [Validators.required, Validators.pattern(/^[1-9]+(\.?[0-9]+)?$/)]],
				bank_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
				name_of_recipient: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
				address: ['', [Validators.required]],
				IBAN: ['', [Validators.required, Validators.maxLength(24)]],
				account_type: ['', [Validators.required]],
				// name_of_recipient_accountd: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(20), Validators.pattern('^[a-zA-Z ]+')]],
			});
		}

		// zelleTransferForm
		this.zelleTransferForm = this.fb.group({
			name_of_recipient1: ['', [Validators.required, Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
			mobile_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern(/^[0-9]+(\?[0-9]+)?$/)]],
			email: ['', [Validators.required, Validators.email]],
			amount1: ['', [Validators.required, Validators.pattern(/^[1-9]+(\.?[0-9]+)?$/)]],
		});

		// wireTransferForm
		this.wireTransferForm = this.fb.group({
			bank_name1: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
			name_of_recipient_accountss: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[a-zA-Z ]+')]],
			address1: ['', [Validators.required]],
			account_type1: ['', [Validators.required]],
			amountt: ['', [Validators.required, Validators.pattern(/^[1-9]+(\.?[0-9]+)?$/)]],
			account_number: ['', [Validators.required, Validators.maxLength(16), Validators.pattern(/^[0-9]+(\?[0-9]+)?$/)]],
			routing_numberr: ['', [Validators.required, Validators.pattern(/^[0-9]+(\?[0-9]+)?$/), Validators.maxLength(10),]],
		});

		// cubanBankForm
		this.cubanBankForm = this.fb.group({
			card_number: ['', [Validators.required, Validators.minLength(16),Validators.maxLength(16), Validators.pattern(/^[0-9]+(\?[0-9]+)?$/)]],
			amounts: ['', [Validators.required, Validators.pattern(/^[0-9]+(\.?[0-9]+)?$/)]],
		});

		// cashForm
		this.cashForm = this.fb.group({
			province: ['', [Validators.required]],
			amountts: ['', [Validators.required, Validators.pattern(/^[1-9]+(\.?[0-9]+)?$/)]],
			first_name: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[a-zA-Z ]+')]],
			last_name: ['', [Validators.required, Validators.maxLength(20), Validators.pattern('^[a-zA-Z ]+')]],
			addresss: ['', [Validators.required]],
		});

		// For Filter dropdown 
		$("#dropStatus").click(function (e) {
			e.stopPropagation();
		});

		$("#dropPaymethod").click(function (e) {
			e.stopPropagation();
		});
		// End

	}

	// For order time 
	getDateTime(dateTime) {
		return this.commonService.getDateTime1(dateTime);
	}
	ngOnDestroy(): void {
		$("#addAmount").modal("hide");
		$("#walletRequsetModal").modal('hide')
		$("#filterStatus").modal("hide");

	}


	// Bank Transfer
	get amount() { return this.bankTransferForm.get('amount'); }
	get bank_name() { return this.bankTransferForm.get('bank_name'); }
	// get name_of_recipient_accountd() { return this.bankTransferForm.get('name_of_recipient_accountd'); }
	get account_numberd() { return this.bankTransferForm.get('account_numberd'); }
	get name_of_recipient() { return this.bankTransferForm.get('name_of_recipient'); }
	get address() { return this.bankTransferForm.get('address'); }
	get routing_number() { return this.bankTransferForm.get('routing_number'); }
	get IBAN() { return this.bankTransferForm.get('IBAN'); }
	get account_type() { return this.bankTransferForm.get('account_type'); }

	// zelle form
	get name_of_recipient1() { return this.zelleTransferForm.get('name_of_recipient1'); }
	get mobile_number() { return this.zelleTransferForm.get('mobile_number'); }
	get email() { return this.zelleTransferForm.get('email'); }
	get amount1() { return this.zelleTransferForm.get('amount1'); }

	// wire transfer form
	get bank_name1() { return this.wireTransferForm.get('bank_name1'); }
	get name_of_recipient_accountss() { return this.wireTransferForm.get('name_of_recipient_accountss'); }
	get address1() { return this.wireTransferForm.get('address1'); }
	get account_type1() { return this.wireTransferForm.get('account_type1'); }
	get amountt() { return this.wireTransferForm.get('amountt'); }
	get account_number() { return this.wireTransferForm.get('account_number'); }
	get routing_numberr() { return this.wireTransferForm.get('routing_numberr'); }

	// cuban bank form
	get card_number() { return this.cubanBankForm.get('card_number'); }
	get amounts() { return this.cubanBankForm.get('amounts'); }

	// Cash form
	get province() { return this.cashForm.get('province'); }
	get amountts() { return this.cashForm.get('amountts'); }
	get first_name() { return this.cashForm.get('first_name'); }
	get last_name() { return this.cashForm.get('last_name'); }
	get addresss() { return this.cashForm.get('addresss'); }

	// Withdraw reqest type and country get
	getWitdrawFlag(req, country) {
		this.reqData = req;
		this.countryOfBank = country;
		this.resetWalletForm();
	}
	// End

	// For check form valid
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}

		});
	}
	// End

	// Wallet type api
	walletTypeGet() {
		this.commonService.showLoader();
		this.walletService.getWalletType().subscribe(res => {

			this.commonService.hideLoader();
			if(res.status == 'fail'){

				this.walletBankDetails = "";
				this.type = 0;
				
			}else{
				this.walletBankDetails = res.data;
				this.walletBankDetails.forEach((element, index) => {
					if (element.payment_name == 'ACH' && element.country == 'EUR' && this.countryGetSeller == this.usaCountry) {
						this.walletBankDetails.splice(index, 1)
					}
					if (element.payment_name == 'Zelle') {
						this.Zelle = 1
					}
					if (element.payment_name == 'ACH' && element.country == 'US' && this.countryGetSeller == this.eurCountry) {
						this.walletBankDetails.splice(index, 1)
					}
					if (element.payment_name == 'ACH' && element.country == 'EUR' && this.countryGetSeller != this.eurCountry && this.countryGetSeller != this.usaCountry) {
						this.walletBankDetails.splice(index, 1)
					}
				});
			}
			


		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})
	}
	// End

		// For product description 
		trimValue(formControl) {
			((typeof formControl.value) && formControl.value.trim() == "") ?
				formControl.setValue("") : formControl.setValue(formControl.value);
		}

	// Reset wallet Form modal close
	resetWalletForm() {


		if (this.cashForm != undefined) {
			this.cashForm.reset();

		}
		if (this.bankTransferForm != undefined) {
			this.bankTransferForm.reset();

		}

		if (this.zelleTransferForm != undefined) {
			this.zelleTransferForm.reset();

		}

		if (this.cubanBankForm != undefined) {
			this.cubanBankForm.reset();

		}

		if (this.wireTransferForm != undefined) {
			this.wireTransferForm.reset();

		}
		this.getAmountValue = '0.00';
		this.adminFee = '0.00';
		this.subtotalAmount = '0.00';
	}
	// Reset wallet Form fuction End

	// Withdraw request submit
	withdrawFormSubmit(frmFlag) {
		// zelle form validation 
		if (frmFlag == 1) {

			if ((this.zelleTransferForm.valid)) {

			} else {
				this.validateAllFormFields(this.zelleTransferForm);
				return false;


			}
		}


		// zelle form validation 
		if (frmFlag == 2) {

			if ((this.bankTransferForm.valid)) {

			} else {
				this.validateAllFormFields(this.bankTransferForm);
				return false;


			}
		}


		// zelle form validation 
		if (frmFlag == 3) {

			if ((this.wireTransferForm.valid)) {

			} else {
				this.validateAllFormFields(this.wireTransferForm);
				return false;


			}
		}


		// cash form validation 
		if (frmFlag == 4) {

			if ((this.cashForm.valid)) {

			} else {
				this.validateAllFormFields(this.cashForm);
				return false;


			}
		}

		// cash form validation 
		if (frmFlag == 5) {

			if ((this.cubanBankForm.valid)) {

			} else {
				this.validateAllFormFields(this.cubanBankForm);
				return false;


			}
		}


		const formData = new FormData();
		// Zelle form
		if (this.reqData == 1) {
			if (this.countryGetSeller == this.usaCountry) {
				formData.append('country', '1');
			}
			else if (this.countryGetSeller == this.eurCountry) {
				formData.append('country', '2');
			}
			else if (this.countryGetSeller == 'Cuba') {
				formData.append('country', '3');
			} else {
				formData.append('country', this.countryGetSeller);
			}
			formData.append('withdraw_type', this.reqData);
			formData.append('name_of_recipient', this.zelleTransferForm.get('name_of_recipient1').value);
			formData.append('contact_country_code', this.countryGetLoacal);
			formData.append('contact_dial_code', this.phoneDialCode);
			formData.append('mobile_number', this.zelleTransferForm.get('mobile_number').value);
			formData.append('email', this.zelleTransferForm.get('email').value);
			formData.append('amount', this.zelleTransferForm.get('amount1').value);
		}
		// End

		// Bank Transfer
		if (this.reqData == 2) {
			if (this.countryGetSeller == this.usaCountry) {
				formData.append('country', '1');
			}
			else if (this.countryGetSeller == this.eurCountry) {
				formData.append('country', '2');
			}
			else if (this.countryGetSeller == 'Cuba') {
				formData.append('country', '3');
			} else {
				formData.append('country', '4');
			}
			formData.append('withdraw_type', this.reqData);
			formData.append('amount', this.bankTransferForm.get('amount').value);
			formData.append('bank_name', this.bankTransferForm.get('bank_name').value);
			formData.append('name_of_recipient', this.bankTransferForm.get('name_of_recipient').value);
			// formData.append('name_of_recipient_account', this.bankTransferForm.get('name_of_recipient_accountd').value);
			if (this.countryGetSeller == this.usaCountry) {
				formData.append('account_number', this.bankTransferForm.get('account_numberd').value);
			}
			formData.append('address', this.bankTransferForm.get('address').value);
			if (this.countryGetSeller == this.usaCountry) {
				formData.append('routing_number', this.bankTransferForm.get('routing_number').value);
			}
			if (this.countryGetSeller == this.eurCountry) {
				formData.append('IBAN', this.bankTransferForm.get('IBAN').value);
			}
			formData.append('account_type', this.bankTransferForm.get('account_type').value);
		}
		// Bank Transfer End

		// Wire transfer 
		if (this.reqData == 3) {
			if (this.countryGetSeller == this.usaCountry) {
				formData.append('country', '1');
			}
			else if (this.countryGetSeller == this.eurCountry) {
				formData.append('country', '2');
			}
			else if (this.countryGetSeller == 'Cuba') {
				formData.append('country', '3');
			} else {
				formData.append('country', '4');
			}
			formData.append('withdraw_type', this.reqData);
			formData.append('bank_name', this.wireTransferForm.get('bank_name1').value);
			formData.append('name_of_recipient', this.wireTransferForm.get('name_of_recipient_accountss').value);
			formData.append('address', this.wireTransferForm.get('address1').value);
			formData.append('account_type', this.wireTransferForm.get('account_type1').value);
			formData.append('amount', this.wireTransferForm.get('amountt').value);
			formData.append('account_number', this.wireTransferForm.get('account_number').value);
			formData.append('routing_number', this.wireTransferForm.get('routing_numberr').value);
		}
		// End

		// cash form
		if (this.reqData == 4) {
			if (this.countryGetSeller == this.usaCountry) {
				formData.append('country', '1');
			}
			else if (this.countryGetSeller == this.eurCountry) {
				formData.append('country', '2');
			}
			else if (this.countryGetSeller == 'Cuba') {
				formData.append('country', '3');
			} else {
				formData.append('country', '4');
			}
			formData.append('withdraw_type', this.reqData);
			formData.append('province', this.cashForm.get('province').value);
			formData.append('amount', this.cashForm.get('amountts').value);
			formData.append('first_name', this.cashForm.get('first_name').value);
			formData.append('last_name', this.cashForm.get('last_name').value);
			formData.append('address', this.cashForm.get('addresss').value);
		}
		// End

		// Cuban bank form
		if (this.reqData == 5) {

			if (this.countryGetSeller == 'Cuba') {
				formData.append('country', '3');
			} else {
				formData.append('country', '4');
			}
			formData.append('withdraw_type', this.reqData);
			formData.append('card_number', this.cubanBankForm.get('card_number').value);
			formData.append('currency', 'CUP');
			formData.append('amount', this.cubanBankForm.get('amounts').value);
		}
		// End

		this.commonService.showLoader();


		this.walletService.withdrawRequstPost(formData).subscribe(res => {
			this.commonService.showLoader();
			this.commonService.toastSuccess(res.message);
			this.commonService.hideLoader();

			$("#addAmount").modal("hide");


			this.getWalletList();
			this.getWalletAmount();
			this.resetWalletForm();


		}, err => {
			this.commonService.errorhandler(err);
			this.commonService.hideLoader();
		});



	}
	// Withdraw request End

	// get provience list
	getProvinceListData() {
		this.commonService.showLoader();
		this.walletService.getProvienceList().subscribe(res => {
			if (Object.keys(res.data).length === 0) {
				this.provinceList = [];
			} else {
				this.provinceList = res.data.province_name;
			}
			this.commonService.hideLoader();

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})
	}
	// End

	// For wallet ammount 
	getWalletAmount() {
		this.commonService.showLoader();
		this.walletService.getWalletAmount().subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == "fail") {
				this.walletAmountData = "";
				this.walletAmount = 0;

			} else {
				this.walletAmountData = data.data;
				this.walletAmount = this.walletAmountData.amount;
			}



		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})
	}
	// End

	// For wallet list 
	getWalletList() {
		this.commonService.showLoader();

		this.walletService.getWalletList(this.limit, this.offset, this.statusWallet, this.payMetodGet).subscribe(data => {

			this.commonService.hideLoader();
			this.noRecordFound = data.message;


		

			if (data.status == "success") {
				if (data.message != 'No record found' && data.message != 'No se encontró ningún registro') {
					this.itemPerPage = data.data.length;
				}else{
					// this.itemPerPage = data.data.length;
				}
				
				// this.walletRequsetList = data.data;
				this.walletRequsetList=[];

				data.data.forEach(element => {
					// element.created_date =new Date(element.created_date)+"UTC" ;
					// element.created_date = element.created_date.toString();
					this.walletRequsetList.push(element);
				});
				this.walletRequsetListData = 1;
				// 



			} else {
				this.walletRequsetListData = 0;

			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}
	// End

	// For router navigate wallet detail
	walletDetails(id) {
		this.router.navigate(['seller/wallet-detail/' + id]);
	}
	// End

	// Function for form amount calculation
	getValueAmount(FormControl) {
		this.walletBankDetails.forEach(element => {
			if (this.reqData == element.payment_type) {
				this.amountLimitGet = element.amount_limit;
				var percent = Number(element.fee_percentage) / 100;
				var netAmount = Number(percent) * Number(FormControl.value)
				this.getAmountValue = (Number(FormControl.value)).toFixed(2);
				this.adminFee = (Number(netAmount) + Number(element.fee_flat)).toFixed(2);
				this.subtotalAmount = (Number(this.adminFee) + Number(this.getAmountValue)).toFixed(2);

			}
		});


	}
	// End

	// Wallet list Filter
	walletListFilter() {
		var arr = [];


		$.each($("input[class='statuss']:checked"), function () {
			arr.push($(this).val());
		});

		this.statusWallet = arr.join(",");

		var arrpay = [];

		$.each($("input[class='paymethod']:checked"), function () {
			arrpay.push($(this).val());
		});

		this.payMetodGet = arrpay.join(",");
		this.getWalletList();
		$("#filterStatus").modal("hide");

	}
	// End

	// For filter reset
	resetFilterFrm() {
		this.statusWallet = 0;
		this.payMetodGet = 0;
		this.getWalletList();
		$('.paymethod').prop('checked', false);
		$('.statuss').prop('checked', false);
		$("#filterStatus").modal("hide");
	}
	// End
	deleteWalletRequestpopup(id){
		this.walletRequsetId = id;
		$('#walletRequsetModal').modal({
			show: true,
			keyboard: false,
			backdrop: 'static'
		  });
	}

	// delete wallet requset 
	deleteWalletRequest(){
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('request_id',this.walletRequsetId);
			
		this.walletService.deleteWalletRequest(formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == "success") {
				$("#walletRequsetModal").modal('hide')
				this.getWalletAmount();

				this.walletRequsetList=[];

				this.getWalletList();

			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}

}
