import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from './auth.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private authService: AuthService, private router: Router) { }
	canActivate(

		next: ActivatedRouteSnapshot,
		state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
		var currentUser = this.authService.getUserDetails();
		if (this.authService.isLoggedIn() && (this.authService.getUserRole() == next.data.role)) {

			if (!this.authService.isBizInfoComplete() && this.authService.getUserRole() == 'seller') {
			
				if (this.authService.getUserEmailVerify() == 0 && this.authService.getUserSwitchProfile() == 1 && this.authService.getUserSocialId() == null && this.authService.getEmailVerificationToken() == null) {
					this.router.navigate(['/seller/complete-switch-profile']); return false;
				}

				

				else if (this.authService.getUserEmailVerify() == 0 && this.authService.getUserSwitchProfile() == 1 && this.authService.getUserSocialId() != '') {

					this.router.navigate(['seller/email-varification']); return false;
				} else {


					if(this.authService.getUserEmailVerify() == 0){

						this.router.navigate(['seller/email-varification']); return false;
					}
					if (this.authService.getUserPhoneVerify() == 0) {

						this.router.navigate(['/seller/number-verify']); return false;

					} else {
						this.router.navigate(['/seller/add-business']); return false;

					}
				}

			}
			return true;

		} else if (next.data.role == 'NotSeller') {

			if (this.authService.getUserRole() == 'seller' && next.data.role == 'NotSeller') {
				this.router.navigate(['/seller/new-orders']);
			}
			return true;

		}
		this.router.navigate(['/']);
	}

}
