import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { FirebaseService } from '../services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare const $: any;

@Component({
    selector: 'app-complete-switch-profile',
    templateUrl: './complete-switch-profile.component.html',
    styleUrls: ['./complete-switch-profile.component.css']
})
export class CompleteSwitchProfileComponent implements OnInit {
    dial_code: string = '+1';
    country_code: string = 'US';
    initialCountrySet: string = 'US';
    ShowFilter = false;
    disabled = false;
    dropdownProvinceSettings: any = {};
    provinceList: any = [];
    userDetail: any;
    switchProfileFroms: FormGroup;
    isChecked: any = 1;
    getEmailValue;
    userdata: any;
    fullname: any;
    emailAddress: any;
    countryID: any;
    constructor(
        private router: Router,
        private authService: AuthService,
        private commonService: CommonService,
        private formBuilder: FormBuilder,
        private firebaseService: FirebaseService,
        private translate: TranslateService,
        private authenticationService: AuthenticationService,

    ) { translate.use(this.commonService.getLanguage()); }



    ngOnInit() {
        this.commonService.scrollToTop();
        this.commonService.setTitle('Mercadoba | Complete Profile');

        this.userDetail = this.authService.getFullUserDetails(); //For userdetail local


        // if(this.authService.getEmailVerificationToken() != null){
        //   this.router.navigate(['seller/email-varification']); return false;

        // }

        this.fullname = this.userDetail.user_detail.full_name;
        this.emailAddress = this.userDetail.user_detail.email;
        this.countryID = this.userDetail.user_detail.country;

        // For switch profile form validation
        this.switchProfileFroms = this.formBuilder.group({
            full_name: [this.fullname, [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]+')]],
            email: [this.emailAddress, [Validators.email, Validators.required]],
            country: [this.countryID, [Validators.required]],

        });
        // End
    }

    // For switch profile validation 
    get full_name() { return this.switchProfileFroms.get('full_name'); }
    get email() { return this.switchProfileFroms.get('email'); }
    get country() { return this.switchProfileFroms.get('country'); }
    // End

    // For check form validtaion
    validateAllFormFields(formGroup: FormGroup) {         //{1}
        Object.keys(formGroup.controls).forEach(field => {  //{2}
            const control = formGroup.get(field);             //{3}
            if (control instanceof FormControl) {             //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {         //{5}
                this.validateAllFormFields(control);            //{6}
            }
        });
    }
    // End

    // For country change 
    onCountryChange($event) {
        this.dial_code = `+${$event.dialCode}`; //For dial code
        this.switchProfileFroms.patchValue({ country: $event.name.split('(')[0] }); //For country name switch profile
        this.country_code = $event.iso2.toUpperCase(); //For country code 
        this.switchProfileFroms.patchValue({ 'mobile': "" }); //For number field value get switchprofile form
    }
    // End


    // //For Set auto detect country
    // telInputObject(obj) {
    //   $.getJSON('https://ipapi.co/json/', function (data) {
    //     this.initialCountrySet = data.country_code.toLowerCase();
    //     this.country_code = data.country_code;
    //     this.dial_code = data.country_calling_code;
    //     obj.setCountry(this.initialCountrySet);
    //   });

    // }
    // End





    //set auto detect country
    telInputObject(obj) {

        var initialCountrySet = this.authService.getUserDetails().profile_country_code;

        if (initialCountrySet == "" || initialCountrySet == null) {
            initialCountrySet = localStorage.getItem('countryCode');

        } else {
            initialCountrySet = this.authService.getUserDetails().profile_country_code;

        }


        $.getJSON('https://ipapi.co/json/', function (data) {

            this.initialCountrySet = initialCountrySet;

            // this.initialCountrySet = data.country_code.toLowerCase();
            // this.country_code = data.country_code;
            // this.dial_code = data.country_calling_code;
            obj.setCountry(this.initialCountrySet);
        });
    }


    // empty value validation 
    trimValue(formControl) {
        ((typeof formControl.value) && formControl.value.trim() == "") ?
            formControl.setValue("") : formControl.setValue(formControl.value);
    }
    onKeyUp(event) {
        return false;
    }
    // For switch profile seller to buyer
    switchProfileBuyer(userType) {
        this.commonService.showLoader();

        this.authService.switchProfile(userType).subscribe(profileData => {
            this.commonService.hideLoader();


            if (profileData.data.user_detail.switch_profile == 1) {

                // redirect to shop page
                localStorage.setItem(environment.dataStore.userData, JSON.stringify(profileData.data));

                this.updateOnFirebase(profileData.data);
                this.router.navigate(['/']);
            } else {
                // redirect to complete profile page
                // this.router.navigate(['/seller/complete-switch-profile']);
            }

        }, err => {
            this.commonService.hideLoader();
            this.commonService.errorhandler(err);
        });

    }


    // For update details on firebase
    updateOnFirebase(userData) {
        let userDataStore = {
            'user_id': userData.user_detail.userID,
            'full_name': userData.user_detail.full_name,
            'email': userData.user_detail.email,
            'user_type': userData.user_detail.user_type,
            'seller_name': "",
            'buyer_name': userData.user_detail.full_name
        }

        this.firebaseService.userUpdateFB(userData.user_detail.userID, userDataStore)
    }
    // End
    // End

    // For switch profile buyer to seller
    switchProfile() {
        if (this.switchProfileFroms.valid) {
            this.commonService.showLoader();
            let province = "";

            this.userDetail = this.authService.getFullUserDetails();

            const formData = new FormData();
            formData.append('full_name', this.switchProfileFroms.get('full_name').value);
            formData.append('email', this.switchProfileFroms.get('email').value);
            formData.append('country', this.switchProfileFroms.get('country').value);
            formData.append('switch_profile', this.userDetail.user_detail.switch_profile);
            formData.append('user_type', this.userDetail.user_detail.user_type);
            formData.append('show_mail', this.isChecked);
            formData.append('device_token', new Date().toISOString());

            this.authService.switchForm(formData).subscribe(data => {
                this.commonService.hideLoader();

                // check email changed or not 

                if(this.switchProfileFroms.get('email').value != this.emailAddress ){

                    // this.authenticationService.SignOut();
                    this.authenticationService.SignIn(data.data.user_detail.email, '123456');

                   
                }




                this.getEmailValue = data.data.user_detail.is_email_verified;
                if (this.getEmailValue == 0) {
                    localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
                    this.router.navigate(['seller/email-varification']);

                }
                // const userDetail = JSON.parse(localStorage.getItem(environment.dataStore.userData));
                if (data.data.user_detail.is_phone_verified == 0 && data.data.user_detail.is_email_verified == 1) {

                    this.router.navigate(['seller/number-verify']);

                }


            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        } else {
            this.validateAllFormFields(this.switchProfileFroms); //{7}
        }
    }
    // End

    //For show mail checkbox flag get
    getValueShowemail(event) {
        if (this.isChecked == 0) {
            this.isChecked = 1;
        }
        else {
            this.isChecked = 0;
        }
        this.isChecked;
    }
    // End


}
