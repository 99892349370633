import { Component, OnInit } from '@angular/core';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SellerService } from '../services/seller.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
declare const $: any;

@Component({
  selector: 'app-rejected-order',
  templateUrl: './rejected-order.component.html',
  styleUrls: ['./rejected-order.component.css']
})
export class RejectedOrderComponent implements OnInit {
  search_term: string = "";
  limit: number = 20;
  offset: number = 0;
  orderListget: any = [];
  myOrderList: any = [];
  orderCountGet: any;
  offerCount: any;
  rejectOrderData: any;
  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private sellerService: SellerService,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.getRejectedOrder(); //rejected order
  }

  searchTimer: any;
  doneSearchInterval: any = 800;

  // For search filter
  search() {
    this.commonService.showLoader();// For loader
    clearTimeout(this.searchTimer); //For search time
    this.searchTimer = setTimeout(() => {
      this.resetMyOrderList(); //Reset order
      this.getRejectedOrder(); //For order list get
      this.commonService.hideLoader();
    }, this.doneSearchInterval);
  }
  // End

  // Function For reset order 
  resetMyOrderList() {
    this.myOrderList = [];
    this.limit = 20;
    this.offset = 0;
  }
  // End

  // rejected order list
  getRejectedOrder() {
    this.commonService.showLoader();
    this.sellerService.rejectedOrderList(this.limit, this.offset, this.search_term)
      .subscribe(res => {
        this.commonService.hideLoader();


        if (this.offset == 0) { // check refresh then show starting
          this.orderListget = res.data.reject_order_list; // assign data in order list 
        this.orderCountGet = res.data.total_records;
        } else {
          this.orderCountGet =res.data.total_records; //total product           
          res.data.reject_order_list.forEach(element => {
            this.orderListget.push(element);          });
        }
        

        // this.rejectOrderData.forEach(element => {
        //   this.orderListget.push(element);
        // });

        this.orderCountGet = res.data.total_records;
        if (res.data.reject_order_list.length == 0) {
          this.offerCount = 0;
        } else {
          this.offerCount = 1;

        }
      }, err => {
        this.commonService.errorhandler(err);
        this.commonService.hideLoader();
      });
  }
  // End

  // Load more order button
  orderLoadMore() {
    this.offset = this.offset + Number(this.limit);
    this.getRejectedOrder();
  }


  // For order time
	getOrderTimeShow(dateTime, string) {

		return this.commonService.getOrderTime(dateTime, string);
	}

	// For order time 
	getOrdeDate(dateTime, string) {

		return this.commonService.getOrderDate(dateTime, string);
	}
	// For order time 
	getDisputeTimeShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}
  // End

}
