import { Component, OnInit, ViewChild } from '@angular/core';
import { faStar, faAngleDoubleRight, faEdit, faTrashAlt, faReply } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { SellerService } from '../services/seller.service';
import { HeaderSellerComponent } from '../../common/header-seller/header-seller.component';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recently-delete-product',
  templateUrl: './recently-delete-product.component.html',
  styleUrls: ['./recently-delete-product.component.css']
})
export class RecentlyDeleteProductComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  faStar = faStar;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faReply = faReply;
  productloader: boolean = true;
  limitOfProduct: number = 12;
  offsetOfProduct: number = 0;
  myProductList: any = [];
  myProductCount: any;
  search_term: string = "";

  titleConfirm: string = "";
  textConfirm: string = "";
  confirmButtonTextConfirm: string = "";
  cancelButtonTextConfirm: string = "";
  cancelled: string = "";
  yourProductSafe: string = "";

  titleRestore: string = "";
  textRestore: string = "";
  confirmButtonTextRestore: string = "";
  cancelButtonTextRestore: string = "";

  @ViewChild('header', { static: false }) header: HeaderSellerComponent;

  constructor(
    private commonService: CommonService,
    private sellerService: SellerService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private router: Router,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Recently Delete Product');

    //localization
    this.translate.get('SELLER_SIDE.delete_product').subscribe((text: string) => { this.titleConfirm = text; });
    this.translate.get('SELLER_SIDE.delete_product_popup').subscribe((text: string) => { this.textConfirm = text; });
    this.translate.get('SELLER_SIDE.yes_delete_it').subscribe((text: string) => { this.confirmButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.cancelled').subscribe((text: string) => { this.cancelled = text; });
    this.translate.get('SELLER_SIDE.your_product_is_safe').subscribe((text: string) => { this.yourProductSafe = text; });

    this.translate.get('SELLER_SIDE.restore_product').subscribe((text: string) => { this.titleRestore = text; });
    this.translate.get('SELLER_SIDE.restore_product_popup').subscribe((text: string) => { this.textRestore = text; });
    this.translate.get('SELLER_SIDE.yes_restore_it').subscribe((text: string) => { this.confirmButtonTextRestore = text; });
    this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextRestore = text; });

    // set search box on header
    const str = this.router.url;
    const regex = RegExp('/seller/my-products');
    const regex2 = RegExp('/seller/my-products/');

    if (regex2.test(str) == true) {
      this.search_term = "";
      this.authService.searchTextStore = "";
    } else if (regex.test(str) == true) {
      this.search_term = this.authService.searchTextStore;
    }
    this.getProductList(this.limitOfProduct, this.offsetOfProduct); //For product list
  }

  searchTimer: any;
  doneSearchInterval: any = 500;

  // For search filter
  setSearchText(text) {
    clearTimeout(this.searchTimer);
    this.searchTimer = setTimeout(() => {
      this.search_term = text;
      this.resetLoadMore(); //For load more button reset
      this.getProductList(this.limitOfProduct, this.offsetOfProduct); //Get product list
    }, this.doneSearchInterval);

  }
  // End

  // For reset load more button
  resetLoadMore() {
    this.myProductList = [];
    this.limitOfProduct = 8;
    this.offsetOfProduct = 0;
  }
  // End

  // For load more button
  productLoadMore() { //load more 
    this.offsetOfProduct = this.offsetOfProduct + Number(this.limitOfProduct);
    this.getProductList(this.limitOfProduct, this.offsetOfProduct);
  }
  // End

  // Function for get product list
  getProductList(limit, offset) {

    if (this.offsetOfProduct == 0) {
      this.commonService.showLoader();
    } else {
      this.productloader = false;
    }

    this.sellerService.getRecentDeleteProductList(limit, offset, this.search_term)
      .subscribe(productData => {
        this.productloader = true;
        this.commonService.hideLoader();

        if (productData.status == 'success') {

          if (productData.data.data_found) {

            if (this.offsetOfProduct == 0) { // check refresh then show starting
              productData.data.product_list.forEach(element => {
                element.sale_discount = Math.ceil(element.sale_discount);
                this.myProductList.push(element);
              });

              this.myProductCount = productData.data.total_records; //total product
            } else {
              productData.data.product_list.forEach(element => {
                element.sale_discount = Math.ceil(element.sale_discount);
                this.myProductList.push(element);
              });
              this.myProductCount = productData.data.total_records; //total product           
            }

          } else {
            this.myProductCount = 0;
            this.resetLoadMore();
          }

        } else {
          this.myProductCount = 0;
          this.myProductList = [];
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

  }
  // End

  // Function for product confirmation
  confirmProduct(productID) {
    Swal.fire({
      title: this.titleConfirm,
      text: this.textConfirm,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextConfirm,
      cancelButtonText: this.cancelButtonTextConfirm,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteProduct(productID);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.yourProductSafe,
          'error'
        )
      }
    });
  }
  // End

  // Delete product from list
  deleteProduct(productID) {

    this.commonService.showLoader();

    this.sellerService.deleteProduct(productID).subscribe(data => {
      this.commonService.hideLoader();

      if (data.status == 'success') {
        this.commonService.toastSuccess(data.message);
        this.resetLoadMore();
        this.ngOnInit();
        this.firebaseService.productDelete(productID);
      } else {
        this.commonService.toastError(data.message);
      }

    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });

  }
  // End

  // Function for restore product
  restoreProduct(productID) {
    Swal.fire({
      title: this.titleRestore,
      text: this.textRestore,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextRestore,
      cancelButtonText: this.cancelButtonTextRestore,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.recentDeleteAdd(productID);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.yourProductSafe,
          'error'
        )
      }
    });
  }
  // End

  // For recentdelete
  recentDeleteAdd(productID) {
    this.commonService.showLoader();

    let statusData = `status=2`;
    this.sellerService.recentDeleted(productID, statusData).subscribe(orderData => {
      this.commonService.hideLoader();

      if (orderData.status == 'success') {
        this.commonService.toastSuccess(orderData.message);
        this.firebaseService.restoreProductDelete(productID);
        this.resetLoadMore();
        this.ngOnInit();
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

}
