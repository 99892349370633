import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../services/common.service';
import { environment } from '../../environments/environment';
import { FirebaseService } from '../services/firebase.service';
import { AuthenticationService } from '../services/authentication.service';
declare const $: any;
@Component({
    selector: 'app-social-email',
    templateUrl: './social-email.component.html',
    styleUrls: ['./social-email.component.css']
})
export class SocialEmailComponent implements OnInit {
    signupForm: FormGroup;
    socialData: any = {};
    dial_code: string = '+1';
    country_code: string = 'US';
    initialCountrySet: string = 'US';
    ShowFilter = false;
    disabled = false;
    dropdownProvinceSettings: any = {};
    provinceList: any = [];
    userType: string = "";
    termsConditions: any;
    isChecked: any = 1;
    countryCode: void;

    constructor(
        private router: Router,
        private authService: AuthService,
        private commonService: CommonService,
        private fb: FormBuilder,
        private firebaseService: FirebaseService,
        private authenticationService: AuthenticationService,

    ) { }

    ngOnInit() {
        this.authService.isLoggedIn();
        this.commonService.scrollToTop();
        this.commonService.setTitle('Mercadoba | Signup');
        this.socialData = this.authService.socialDataCollection;
        this.userType = this.socialData.userType;

        this.dropdownProvinceSettings = {
            singleSelection: false,
            idField: 'provinceID',
            textField: 'name',
            itemsShowLimit: 1,
            allowSearchFilter: this.ShowFilter
        };

        this.signupForm = this.fb.group({
            full_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
            email: ['', [Validators.email, Validators.required]],
            country: ['United States', [Validators.required]],
            // province: ['', [Validators.required]],
            // mobile: ['', [Validators.required,Validators.minLength(5),Validators.maxLength(15),Validators.pattern('[0-9]+')]]
            mobile: ['', this.userType == 'buyer' ? [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')] : []]

        });

        this.checkValidPath(this.authService.socialDataCollection);
        this.getProvinceList();
    }

    //set auto detect country
    telInputObject(obj) {
        $.getJSON('https://ipapi.co/json/', function (data) {
            this.initialCountrySet = "US";

            // this.initialCountrySet = data.country_code.toLowerCase();
            // this.country_code = data.country_code;
            // this.dial_code = data.country_calling_code;
            obj.setCountry(this.initialCountrySet);
        });

    }

    onCountryChange($event) {
        this.dial_code = `+${$event.dialCode}`;
        this.signupForm.patchValue({ country: $event.name.split('(')[0] });
        this.country_code = $event.iso2.toUpperCase();
        this.signupForm.patchValue({ 'mobile': "" });
        localStorage.removeItem("countryCode");
		this.countryCode = localStorage.setItem('countryCode', this.country_code);
    }

    onKeyUp(event) {
        return false;
    }
    checkChar(e) {
        e.preventDefault();
    }
    getProvinceList() {
        this.authService.getProvinceList()
            .subscribe(provinceData => {
                this.commonService.hideLoader();

                if (provinceData.status == 'success') {
                    this.provinceList = provinceData.data.province;
                } else {
                    this.provinceList = [];
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
    }

    checkValidPath(data) {
        if (Object.keys(data).length === 0) {
            this.router.navigate(['/login']);
        } else {
            this.socialData = this.authService.socialDataCollection;
            this.userType = this.socialData.userType;

            this.signupForm.patchValue({
                'full_name': data.full_name,
                'email': data.email,
            });
        }
    }

    get full_name() { return this.signupForm.get('full_name'); }
    get email() { return this.signupForm.get('email'); }
    get country() { return this.signupForm.get('country'); }
    // get province() { return this.signupForm.get('province'); }
    get mobile() { return this.signupForm.get('mobile'); }


    validateAllFormFields(formGroup: FormGroup) {         //{1}
        Object.keys(formGroup.controls).forEach(field => {  //{2}
            const control = formGroup.get(field);             //{3}
            if (control instanceof FormControl) {             //{4}
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {        //{5}
                this.validateAllFormFields(control);            //{6}
            }
        });
    }


    doAction(event: any) {
        if (event.target.checked == false) {
            this.termsConditions = 0;

        } else {
            this.termsConditions = 1;

        }
    }



    getValueShowemail(event: any) {

        if (event.target.checked == false) {
            this.isChecked = 0;

        } else {
            this.isChecked = 1;

        }
    }

    //   getValueTermsCondition(event){

    //   }

    signup() {
        if (this.signupForm.valid) {

            this.commonService.showLoader();
            let province = "";
            const formData = new FormData();
            formData.append('full_name', this.signupForm.get('full_name').value);
            formData.append('email', this.signupForm.get('email').value);
            formData.append('user_type', this.socialData.userType);
            formData.append('signup_from', '3');
            formData.append('country', this.signupForm.get('country').value);
            formData.append('country_code', this.country_code);
            formData.append('device_token', new Date().toISOString());
            formData.append('profile_timezone', this.commonService.getTimeZone());
            formData.append('social_id', this.socialData.id);
            formData.append('social_type', this.socialData.socialType);
            formData.append('profile_image', this.socialData.photoUrl);
            formData.append('show_mail', this.isChecked);
            formData.append('email_verified_flag', '1');




            if (this.termsConditions != 1) {
                this.commonService.hideLoader();
                $('#termConditionCheck').modal('show');

                return false;

            }

            if (this.socialData.userType == "seller" && this.isChecked == undefined) {
                this.isChecked = 0;
                this.commonService.hideLoader();
                return false;


            }


            if (this.socialData.user_type != 'seller') {
                formData.append('dial_code', this.dial_code);
                formData.append('phone_number', this.signupForm.get('mobile').value);
            }




            this.authService.socialSignup(formData).subscribe(data => {
                this.commonService.hideLoader();
                if (data.status == "fail") {
                    this.commonService.toastError(data.message);
                } else {
                    this.authenticationService.SignUp(this.signupForm.get('email').value, '123456');

                    this.commonService.toastSuccess(data.message);
                    localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
                    this.addOnFirebase(data.data.user_detail);



                    if (data.data.user_detail.user_type == 'seller') { // user type check

                        if (data.data.user_detail.is_phone_verified == 0) {
                            this.router.navigate(['seller/number-verify']);
                            return false;

                        } else {
                            this.router.navigate(['/seller/add-business']);
                        }
                    } else {
                        this.router.navigate(['/']);
                    }

                    // this.addOnFirebase(data.data.user_detail);
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        } else {
            // validate all form fields
            this.validateAllFormFields(this.signupForm); //{7}
        }

    }

    addOnFirebase(userData) {
        let userDataStore = {
            'user_id': userData.userID,
            'full_name': userData.full_name,
            'email': userData.email,
            'user_type': userData.user_type,
            'avatar': userData.avatar,
            'notification_status': userData.push_alert_status,
            'firebase_token': "",
            'business_name': "",
            'online': "Online",
            'seller_name': "",
            'buyer_name': ""
        }

        if (userData.user_type == 'seller') {
            userDataStore.seller_name = userData.full_name;
        } else {
            userDataStore.buyer_name = userData.full_name;
        }
        setTimeout(() => {
            this.firebaseService.userAddFB(userData.userID, userDataStore)

		}, 2000);
    }

}
