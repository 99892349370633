import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClient } from '@angular/common/http';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxGalleryModule } from 'ngx-gallery';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SignaturePadModule } from 'angular2-signaturepad';

import { SellerRoutingModule } from './seller-routing.module';
import { NewOrdersComponent } from './new-orders/new-orders.component';
import { HeaderSellerComponent } from '../common/header-seller/header-seller.component';
import { FooterSellerComponent } from '../common/footer-seller/footer-seller.component';
import { MyProductsComponent } from './my-products/my-products.component';
import { AddProductComponent } from './add-product/add-product.component';
import { AddBusinessComponent } from './add-business/add-business.component';
import { SellerAccountComponent } from './seller-account/seller-account.component';
import { NewOrderDetailComponent } from './new-order-detail/new-order-detail.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyProductDetailComponent } from './my-product-detail/my-product-detail.component';
import { ChatComponent } from './chat/chat.component';
import { MyOrderDetailComponent } from './my-order-detail/my-order-detail.component';
import { EditProductComponent } from './edit-product/edit-product.component';
import { SellerReviewComponent } from './seller-review/seller-review.component';
import { CompleteSwitchProfileComponent } from './complete-switch-profile/complete-switch-profile.component';
import { RecentlyDeleteProductComponent } from './recently-delete-product/recently-delete-product.component';
import { DisputeComponent } from './dispute/dispute.component';
import { SubscriptionComponent } from './subscription/subscription.component';
import { TrashChatComponent } from './trash-chat/trash-chat.component';
import { RecentlyDeleteProductDetailComponent } from './recently-delete-product-detail/recently-delete-product-detail.component';

//For Localization
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { RejectedOrderComponent } from './rejected-order/rejected-order.component';
import { RejectedOrderDetailsComponent } from './rejected-order-details/rejected-order-details.component';
import { WalletComponent } from './wallet/wallet.component';
import { WalletDetailComponent } from './wallet-detail/wallet-detail.component';
import { ProductOffersComponent } from './product-offers/product-offers.component';
import { ProductOffersDetailsComponent } from './product-offers-details/product-offers-details.component';
import { EmailVarificationComponent } from './email-varification/email-varification.component';
import { MobileVarificationComponent } from './mobile-varification/mobile-varification.component';
import { NumberVerifyComponent } from './number-verify/number-verify.component';
import { QuestionAnswersComponent } from './question-answers/question-answers.component';
import { TwoDigitDecimaNumberDirective } from './wallet/two-digit-decima-number.directive';
import { NgxPaginationModule } from 'ngx-pagination';
import { NoCommaPipe } from './no-comma.pipe';
import { EditWarehouseAddressComponent } from './edit-warehouse-address/edit-warehouse-address.component';
// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/language/', '.json');
}

@NgModule({
  declarations: [NewOrdersComponent, HeaderSellerComponent, FooterSellerComponent, MyProductsComponent, AddProductComponent, AddBusinessComponent, SellerAccountComponent, NewOrderDetailComponent, MyOrdersComponent, MyProductDetailComponent, ChatComponent, MyOrderDetailComponent, EditProductComponent, SellerReviewComponent, CompleteSwitchProfileComponent, RecentlyDeleteProductComponent, DisputeComponent, SubscriptionComponent, TrashChatComponent, RecentlyDeleteProductDetailComponent, RejectedOrderComponent, RejectedOrderDetailsComponent, WalletComponent, WalletDetailComponent, ProductOffersComponent, ProductOffersDetailsComponent, EmailVarificationComponent,
    MobileVarificationComponent,
    NumberVerifyComponent,
    QuestionAnswersComponent,
    TwoDigitDecimaNumberDirective,
    NoCommaPipe,
    EditWarehouseAddressComponent,],
  imports: [
    CommonModule,
    SellerRoutingModule,
    FontAwesomeModule,
    NgMultiSelectDropDownModule,
    FormsModule,
    ReactiveFormsModule,
    GooglePlaceModule,
    ImageCropperModule,
    NgxGalleryModule,
    Ng2TelInputModule,
    NgbModule,
    SignaturePadModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }) //For Localization
  ]
})
export class SellerModule { }
