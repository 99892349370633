import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faAngleDoubleRight, faStar, faEye, faHeart, faShoppingCart, faCheck, faTimes, faEdit, faTrashAlt, faCircle, faPlus } from '@fortawesome/free-solid-svg-icons';
import { faCommentDots } from '@fortawesome/free-regular-svg-icons';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ShopService } from '../../services/shop.service';
import { SellerService } from '../services/seller.service';
import { FirebaseService } from '../services/firebase.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { AuthService } from '../../auth/auth.service';

declare const $: any;


@Component({
  selector: 'app-recently-delete-product-detail',
  templateUrl: './recently-delete-product-detail.component.html',
  styleUrls: ['./recently-delete-product-detail.component.css']
})
export class RecentlyDeleteProductDetailComponent implements OnInit {

  faAngleDoubleRight = faAngleDoubleRight;
  faStar = faStar;
  faCommentDots = faCommentDots;
  faEye = faEye;
  faHeart = faHeart;
  faShoppingCart = faShoppingCart;
  faCheck = faCheck;
  faTimes = faTimes;
  faEdit = faEdit;
  faTrashAlt = faTrashAlt;
  faCircle = faCircle;
  faPlus = faPlus;
  productId: string = '';
  productDetail: any = {};
  currentUserId: any;
  similarProduct: any = [];
  galleryOptions: NgxGalleryOptions[];
  galleryImages: any = [];
  galleryImage: any = [];
  variants: any = [];
  variantListData: any = [];
  variant: string = '';
  keyFeatureList: any = [];

  titleConfirm: string = "";
  textConfirm: string = "";
  confirmButtonTextConfirm: string = "";
  cancelButtonTextConfirm: string = "";
  cancelled: string = "";
  yourProductSafe: string = "";

  titleRestore: string = "";
  textRestore: string = "";
  confirmButtonTextRestore: string = "";
  cancelButtonTextRestore: string = "";
  isPackage: boolean = false;
  jsonPackage: any;
  questionAnswer: any;
  answerForm: FormGroup;
  showQuestionAnsButton: any;
  remaingQuestion: any;
  questionAnswerLength: boolean = false;
  discountPercent: any;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private shopService: ShopService,
    private sellerService: SellerService,
    private firebaseService: FirebaseService,
    private router: Router,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | My Product Detail');

    //localization
    this.translate.get('SELLER_SIDE.delete_product').subscribe((text: string) => { this.titleConfirm = text; });
    this.translate.get('SELLER_SIDE.delete_product_popup').subscribe((text: string) => { this.textConfirm = text; });
    this.translate.get('SELLER_SIDE.yes_delete_it').subscribe((text: string) => { this.confirmButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextConfirm = text; });
    this.translate.get('SELLER_SIDE.cancelled').subscribe((text: string) => { this.cancelled = text; });
    this.translate.get('SELLER_SIDE.your_product_is_safe').subscribe((text: string) => { this.yourProductSafe = text; });

    this.translate.get('SELLER_SIDE.restore_product').subscribe((text: string) => { this.titleRestore = text; });
    this.translate.get('SELLER_SIDE.restore_product_popup').subscribe((text: string) => { this.textRestore = text; });
    this.translate.get('SELLER_SIDE.yes_restore_it').subscribe((text: string) => { this.confirmButtonTextRestore = text; });
    this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextRestore = text; });

    this.productId = this.activatedRoute.snapshot.paramMap.get("id");
    this.setConfigImageGallery();
    this.getProductDetail(this.productId, this.authService.getCurrentUserId());

    // Questions and answer validatio/
    this.answerForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
    });
  }

  // Questions and answer////
  get description() { return this.answerForm.get('description'); }

  // For image gallery
  setConfigImageGallery() {
    this.galleryOptions = [
      {
        width: '350px',
        height: '550px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewZoom: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
  }
  // End
  ngAfterViewInit() {
      //localization
      this.translate.get('SELLER_SIDE.delete_product').subscribe((text: string) => { this.titleConfirm = text; });
      this.translate.get('SELLER_SIDE.delete_product_popup').subscribe((text: string) => { this.textConfirm = text; });
      this.translate.get('SELLER_SIDE.yes_delete_it').subscribe((text: string) => { this.confirmButtonTextConfirm = text; });
      this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextConfirm = text; });
      this.translate.get('SELLER_SIDE.cancelled').subscribe((text: string) => { this.cancelled = text; });
      this.translate.get('SELLER_SIDE.your_product_is_safe').subscribe((text: string) => { this.yourProductSafe = text; });
  
      this.translate.get('SELLER_SIDE.restore_product').subscribe((text: string) => { this.titleRestore = text; });
      this.translate.get('SELLER_SIDE.restore_product_popup').subscribe((text: string) => { this.textRestore = text; });
      this.translate.get('SELLER_SIDE.yes_restore_it').subscribe((text: string) => { this.confirmButtonTextRestore = text; });
      this.translate.get('SELLER_SIDE.no_keep_it').subscribe((text: string) => { this.cancelButtonTextRestore = text; });
  
  }

  //Get product Detail
  getProductDetail(productId, currentUserId) {

    this.commonService.showLoader();
    this.shopService.getProductDetail(productId, currentUserId).subscribe((productDetailData) => {

      this.commonService.hideLoader();
      if (productDetailData.status == 'success') {
        this.commonService.scrollToTop();

        this.productDetail = productDetailData.data.product_detail;
        this.questionAnswer = this.productDetail.question;

        // questions and answer button coun/
        if (this.questionAnswer.length <= 4) {
          this.showQuestionAnsButton = 0

        } else {
          this.showQuestionAnsButton = 1
          this.remaingQuestion = this.questionAnswer.length - 4;
        }

        var filtered = this.questionAnswer.filter(function (value, index, arr) {
          // return index !=0 && index <=8;
          return index <= 3;

        });

        this.questionAnswer = filtered;
        // END


        if (this.questionAnswer.length != 0) {
          this.questionAnswerLength = true
        }
        this.variants = this.productDetail.variant;
        this.keyFeatureList = JSON.parse(this.productDetail.key_features);


        this.galleryImage = this.productDetail.gallery_images;
        // this.galleryImage.unshift({ 'gallery_image': this.productDetail.feature_image, 'gallery_image_url': this.productDetail.feature_image_url });

        this.galleryImage.forEach(element => {
          let galleryImagesData = {
            small: `${element.gallery_image_url}${element.gallery_image}`,
            medium: `${element.gallery_image_url}${element.gallery_image}`,
            big: `${element.gallery_image_url}${element.gallery_image}`
          };
          this.galleryImages.push(galleryImagesData);

        });

        this.jsonPackage = (this.productDetail.packages) ? JSON.parse(this.productDetail.packages) : [];
        this.discountPercent = Math.round(this.productDetail.discount_percent);

        if (this.jsonPackage.length == 0) {

          this.isPackage = false;

        } else {
          this.isPackage = true;

        }

      } else {

        this.productDetail = [];
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

  // For delivery duration
  getDeliveryDuration(key) {
    switch (key) {
      case '1':
        return "1 Day";
        break;
      case '2':
        return "2 Days";
        break;
      case '3':
        return "3 Days";
        break;
      case '4':
        return "7 Days";
        break;
      case '5':
        return "More than 7 days";
        break;
    }
  }
  // End

  // For restore product
  restoreProduct(productID) {
    Swal.fire({
      title: this.titleRestore,
      text: this.textRestore,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextRestore,
      cancelButtonText: this.cancelButtonTextRestore,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.recentDeleteAdd(productID);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.yourProductSafe,
          'error'
        )
      }
    });
  }
  // End

  // For recent delete product
  recentDeleteAdd(productID) {
    this.commonService.showLoader();
    let statusData = `status=2`;
    this.sellerService.recentDeleted(productID, statusData).subscribe(orderData => {
      this.commonService.hideLoader();

      if (orderData.status == 'success') {
        this.commonService.toastSuccess(orderData.message);
        this.firebaseService.restoreProductDelete(productID);
        this.router.navigate(['../seller/recently-delete-product']);
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

  // For confirmation 
  confirmationAlert(productID) {
    Swal.fire({
      title: this.titleConfirm,
      text: this.textConfirm,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.confirmButtonTextConfirm,
      cancelButtonText: this.cancelButtonTextConfirm,
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteProduct(productID);
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.cancelled,
          this.yourProductSafe,
          'error'
        )
      }
    });
  }
  // End


  // For product delete 
  deleteProduct(productID) {

    this.commonService.showLoader();

    this.sellerService.deleteProduct(productID).subscribe(data => {
      this.commonService.hideLoader();

      if (data.status == 'success') {
        this.commonService.toastSuccess(data.message);
        this.firebaseService.productDelete(productID);
        this.router.navigate(['../seller/recently-delete-product']);
      } else {
        this.commonService.toastError(data.message);
      }

    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });

  }
  // End

  // Add questions form
  addAnsWer(id) {

    if (this.answerForm.valid) {
      this.commonService.showLoader();
      const formData = new FormData();
      formData.append('product_id', this.productDetail.productID);
      formData.append('description', $(`#ans_${id}`).val());
      formData.append('question_id', id);
      this.shopService.askQuestions(formData).subscribe((res) => {
        this.commonService.hideLoader();
        this.commonService.toastSuccess(res.message);
        this.getProductDetail(this.productId, this.authService.getCurrentUserId());
        // window.location.reload();
        this.answerForm.reset();

      }, err => {
        this.commonService.errorhandler(err);
        this.commonService.hideLoader();
        this.answerForm.reset();
      });
    } else {
      this.validateAllFormFields(this.answerForm); //{7}

    }

  }
  // End

  // For check form valid
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  // End

}
