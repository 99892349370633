import { Component } from '@angular/core';
import { AuthService } from './auth/auth.service';
import { environment } from '../environments/environment';
import { CommonService } from './services/common.service';
import { ShopService } from './services/shop.service';
import { TranslateService } from '@ngx-translate/core';
import { AngularFireDatabase } from '@angular/fire/database';
import { Router } from '@angular/router';
import { MessagingService } from '../../service/messaging.service';

// import { getMessaging, getToken, onMessage } from "firebase/messaging";

declare const notifyMe: any;
declare const setValue: any;
declare const getWebNotification: any;
declare const setBadgeCount: any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent  {
  title = 'mercadoba';
  paramUserId: any;
  totalUnreadNotification: any = 0;
  message;


 
  

  constructor(
    private authService: AuthService,
    private commonService: CommonService,
    private shopService: ShopService,
    private translate: TranslateService,
    private router: Router,
    private db: AngularFireDatabase,
    private messagingService: MessagingService
  ) {
    authService.setDeviceId();
    this.getWebPushNotification();
    this.chatNotification();
    translate.use(this.commonService.getLanguage());
  }

  ngOnInit() {
    this.messagingService.requestPermission()
    this.messagingService.receiveMessage()
    this.message = this.messagingService.currentMessage
   }
  
  // web push notification 
  getWebPushNotification() {
    setInterval(() => {
      if (this.authService.isLoggedIn()) {
        this.paramUserId = this.authService.getCurrentUserId();

        if (this.paramUserId != 0) {
          let statusData = `status='1'`;

          this.shopService.getWebPushNotifiction(statusData).subscribe(
            (notifiyData) => {

              if (notifiyData.status == 'success' && notifiyData.data.data_found) {
                this.totalUnreadNotification = notifiyData.data.total;
                // for set push notification

                if (notifiyData.data.data_found) {

                  var dataNotiType = notifiyData.data.notification.notification_type;
                  var dataNotiRefid = notifiyData.data.notification.reference_id;

                  let userType = this.authService.getUserDetails().user_type;
                  var siteUrl = environment.siteUrl;
                  var setNotifiyData = {
                    'title': JSON.parse(notifiyData.data.notification.notification_payload).title,
                    'body': JSON.parse(notifiyData.data.notification.notification_payload).body,
                    'icon': siteUrl + '/assets/img/common/Logo_img.png',
                    'type': JSON.parse(notifiyData.data.notification.notification_payload).type,
                    // 'reference_id': JSON.parse(notifiyData.data.notification.notification_payload).order_id,
                    'reference_id': dataNotiRefid,
                    'siteUrl': siteUrl,
                    'userType': userType,
                    'currentUserId': this.paramUserId,
                    'notificationFor': notifiyData.data.notification.notification_for,
                    'notificationID': notifiyData.data.notification.notificationID,
                    'token': this.authService.getAuthorizationToken(),
                    'lang': this.authService.getLanguage(),
                    'device_id': this.authService.getDeviceId(),
                  }

                  notifyMe(setNotifiyData);

                }

              } else {
                this.totalUnreadNotification = 0;
              }
            }, err => {
              this.commonService.hideLoader();
              this.commonService.errorhandler(err);
            });

        }

      }
    }, 7000);
  }

  chatNotification() {

    if (this.authService.isLoggedIn()) { // check user authentication

      setValue('senderUserId', this.authService.getCurrentUserId());
      var setNotifiyData = {
        'siteUrl': environment.siteUrl,
        'userType': this.authService.getUserDetails().user_type,
        'icon': environment.siteUrl + '/assets/img/common/Logo_img.png',
      }
      getWebNotification(this.db.database, setNotifiyData);

      setBadgeCount(this.db.database, this.authService.getCurrentUserId());

    }

  }



}
