import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { ShopService } from '../../services/shop.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare var Stripe; // : stripe.StripeStatic;

@Component({
  selector: 'app-subscription',
  templateUrl: './subscription.component.html',
  styleUrls: ['./subscription.component.css']
})
export class SubscriptionComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  skipButtonShow: boolean = false;
  sellerId: any;
  subscriptionList: any = [];
  isSellerSubscribed: any;
  currentPlan: any = {};
  purchasePlan: any = {};
  cardCount: any;

  stripe;
  card;
  cardErrors;
  @ViewChild('cardElement', { static: false }) cardElement: ElementRef;

  constructor(
    private commonService: CommonService,
    private shopService: ShopService,
    private authService: AuthService,
    private translate: TranslateService,
    private router: Router,
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Subscription');
    this.getSellerProfile(true);
    this.sellerId = this.authService.getCurrentUserId();

    // set search box on header
    const str = this.router.url;
    const regex = RegExp('/seller/subscription/signup');

    if (regex.test(str) == true) {
      this.skipButtonShow = true;
    }

    this.isSellerSubscribed = this.authService.getSellerSubscribed();
    this.currentPlan = this.authService.getUserDetails().plan_detail;

    let stripeCustomerID = this.authService.getStripeCustomerID();
    if (stripeCustomerID == false) {
      this.addStripeCustomer();
    }
    this.getSubscriptionList();
    this.getCardList();
  }

  ngAfterViewInit() {
    // stripe payment
    this.stripe = Stripe(environment.striePkKey);
    const elements = this.stripe.elements();

    this.card = elements.create('card');
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', ({ error }) => {
      this.cardErrors = error && error.message;
    });
  }

  addStripeCustomer() {
    this.shopService.createStripeCustomer().subscribe(customerID => {
      if (customerID.status == 'success') {
        let userData = this.authService.getFullUserDetails();
        userData.user_detail.stripe_customer_id = customerID.data.stripe_customer_id;
        localStorage.setItem(environment.dataStore.userData, JSON.stringify(userData));
      }
    }, err => {
      this.commonService.errorhandler(err);
    });
  }

  getSubscriptionList() {
    this.commonService.showLoader();

    this.shopService.getSubscriptionList().subscribe(data => {
      this.commonService.hideLoader();

      if (data.status == 'success') {
        // this.subscriptionList = data.data.plan_list;

        if (data.data.plan_list.length > 0) {
          data.data.plan_list.forEach(element => {

            if (element.planID == this.currentPlan.plan_id) {
              this.subscriptionList.push(element);

            } else if (element.status == '1') {
              this.subscriptionList.push(element);
            }

          });
        }

      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

  getCardList() {
    this.commonService.showLoader();

    this.shopService.getCardList().subscribe(cardData => {
      this.commonService.hideLoader();
      if (cardData.status == 'success') {

        var keys = Object.keys(cardData.data); //Check data is empty object

        this.cardCount = keys.length;
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

  async handleForm(e) {
    this.commonService.showLoader();
    e.preventDefault();

    const { token, error } = await this.stripe.createToken(this.card);

    if (error) {
      this.commonService.hideLoader();
      // Inform the customer that there was an error.
      const cardErrors = error.message;
    } else {

      // Send the token to your server.
      const formData = new FormData();
      formData.append('sourceId', token.id);

      this.shopService.addCard(formData).subscribe(cardData => {
        this.commonService.hideLoader();

        if (cardData.status == 'fail') {
          this.commonService.toastError(cardData.message);
        } else {
          this.addCard(cardData.data.card_detail);
        }
      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
    }
  }

  addCard(card_detail) {

    this.commonService.showLoader();
    const formData = new FormData();
    formData.append('stripe_card_id', card_detail.id);
    formData.append('card_holder_name', this.authService.getUserDetails().full_name);
    formData.append('card_last_4_digits', card_detail.last4);
    formData.append('card_expiry_month', card_detail.exp_month);
    formData.append('card_expiry_year', card_detail.exp_year);
    formData.append('card_brand_type', card_detail.brand);

    this.shopService.saveCard(formData).subscribe(cardData => {
      this.commonService.hideLoader();

      if (cardData.status == 'fail') {
        this.commonService.toastError(cardData.message);
      } else {
        this.commonService.toastSuccess(cardData.message);
        this.resetCard();
        this.getCardList();
        $('#addCard').modal('hide');
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

  resetCard() {
    this.card.clear();
  }

  subscribePlan(plan) {

    if (this.cardCount == 0) {

      this.commonService.toastWarning('Please add card first to do subscribe');
      this.router.navigate(['/seller/seller-account/subscription']);
      // $('#addCard').modal('show');

    } else {

      this.purchasePlan = plan;

      this.commonService.showLoader();

      const formData = new FormData();
      formData.append('customer_id', this.authService.getStripeCustomerID());
      formData.append('stripe_plan_id', this.purchasePlan.stripe_plan_id);

      this.shopService.purchaseStripePlan(formData).subscribe((data) => {
        this.commonService.hideLoader();

        if (data.status == 'fail') {
          this.commonService.toastError(data.message);

          if (data.error_type == 'NOT_VERIFIED') {
            this.router.navigate(['/seller/seller-account/subscription']);
          }
        } else {

          if (data.data.error_type == 'NOT_VERIFIED') {
            this.commonService.toastWarning(data.message);
            this.router.navigate(['/seller/seller-account/subscription']);
          } else {
            this.purchaseSubscription(data.data.user_subscription_data);
          }
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

    }

  }

  purchaseSubscription(data) {

    this.commonService.showLoader();

    const formData = new FormData();
    formData.append('plan_id', this.purchasePlan.planID);
    formData.append('platform_type', "2");
    formData.append('payment_platform_type', "2");
    formData.append('platform_identifier', this.purchasePlan.stripe_product_id);
    formData.append('platform_product_id', this.purchasePlan.stripe_plan_id);
    formData.append('transaction_detail', data.purchase_details);
    formData.append('purchase_token', data.purchase_token);
    formData.append('plan_title', this.purchasePlan.title);
    formData.append('plan_amount', this.purchasePlan.amount);

    this.shopService.purchaseSubscription(formData).subscribe((data) => {
      this.commonService.hideLoader();

      if (data.status == 'fail') {
        this.commonService.toastError(data.message);
      } else {
        this.commonService.toastSuccess(data.message);
        this.getSellerProfile(false);
      }

    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });

  }

  getSellerProfile(isInit) {
    this.shopService.getSellerProfile().subscribe(data => {
      localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

      if (isInit == false) {
        //this.ngOnInit();
        this.isSellerSubscribed = this.authService.getSellerSubscribed();
        this.currentPlan = this.authService.getUserDetails().plan_detail;
      }

    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

}
