import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../services/common.service';
import { environment } from '../../environments/environment';
import { AuthService as SocialService } from 'angularx-social-login';
// import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { FirebaseService } from '../services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication.service';
import { GoogleAuthService } from '../google-auth.service';

declare const $: any;

@Component({
	selector: 'app-signup',
	templateUrl: './signup.component.html',
	styleUrls: ['./signup.component.css']
})
export class SignupComponent implements OnInit {
	faFacebookSquare = faFacebookSquare;
	userType: string;
	signupForm: FormGroup;
	dial_code: string = '+1';
	country_code: string = 'US';
	initialCountrySet: string = 'US';
	ShowFilter = false;
	disabled = false;
	dropdownProvinceSettings: any = {};
	provinceList: any = [];
	verifyOTP: any = "";
	emailSet: any = "";
	showEmailValue: number;
	getEmailValue;
	isChecked: any = 1;
	getTermCheckValue: any = 0;
	getLoginData1: any;
	phoneNumber: string;
	userTypeGet: any;
	countName: void;
	countryCode: void;
	googleResponse: any;

	constructor(
		private router: Router,
		private authService: AuthService,
		private commonService: CommonService,
		private fb: FormBuilder,
		// private socialService: SocialService,
		private firebaseService: FirebaseService,
		private translate: TranslateService,
		private authenticationService: AuthenticationService,
		private googleAuthService: GoogleAuthService


	) { translate.use(this.commonService.getLanguage()); }


	ngAfterViewInit(): void {
		this.googleAuthService.renderButton('buttonDiv', {
		  theme: 'outline',
		  size: 'large',
		  type: 'standard', 
          logo_alignment: 'left'
		});
	  }

	ngOnInit() {


		this.googleAuthService.initialize(environment.googleClientId, (response: any) => {
			this.googleResponse = response;
			console.log(response);
			const token = response.credential;
			if (token) {
				const decodedToken = this.decodeJwt(token);
				this.checkSocialSignup(decodedToken, '1');
			}

		});



		localStorage.removeItem("countryCode");

		this.commonService.scrollToTop();




		// Check user type and navigate router
		const currentUrl = this.router.url;
		this.userType = (currentUrl == "/signup/seller") ? 'seller' : 'buyer';

		if (this.userType == "seller") {
			this.commonService.setTitle('Únete como Vendedor en Línea con Mercadoba | Lleva tus Productos a Cuba');
			this.commonService.getMetaTages('Únete como Vendedor en Línea con Mercadoba | Lleva tus Productos a Cuba', 'Regístrate como vendedor en Qvazon y expande tu negocio en línea en Cuba. Conéctate con compradores y aumenta tus ventas. Únete a nosotros hoy y alcanza nuevos horizontes.');
		} else {
			this.commonService.setTitle('Regístrate como Comprador en Línea con Mercadoba | Explora y Compra en Cuba');
			this.commonService.getMetaTages('Únete como Vendedor en Línea con Mercadoba | Lleva tus Productos a Cuba', 'Regístrate como comprador en Qvazon y descubre la conveniencia de comprar en línea en Cuba. Explora productos, realiza compras y disfruta de entregas rápidas. Únete a la comunidad de compradores en línea.');
		}



		if (this.authService.isLoggedIn() == true) {
			this.router.navigate(['/']);
		}
		// End

		// Province dropdown 
		this.dropdownProvinceSettings = {
			singleSelection: false,
			idField: 'provinceID',
			textField: 'name',
			itemsShowLimit: 1,
			allowSearchFilter: this.ShowFilter
		};
		// End

		// Sign up form validation according user type
		if (this.userType == 'seller') {
			this.signupForm = this.fb.group({
				full_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
				email: ['', [Validators.email, Validators.required]],
				password: ['', [Validators.required,Validators.minLength(6), Validators.maxLength(50)]],
				// password: ['', [Validators.required, Validators.maxLength(50), Validators.pattern('(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$')]],
				country: ['United States', [Validators.required]],
				signup_from: ['']
			});
		} else {

			this.signupForm = this.fb.group({
				full_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(30), Validators.pattern('^[a-zA-Z ]+')]],
				email: ['', [Validators.email, Validators.required]],
				password: ['', [Validators.required,Validators.minLength(6), Validators.maxLength(50)]],
				country: ['United States', [Validators.required]],
				phone_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')]],
				signup_from: ['']
			});
		}
		// End validation 

		//this.signup();

		// Province list get
		this.getProvinceList();
	}

	private decodeJwt(token: string): any {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
		  `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join(''));
	
		return JSON.parse(jsonPayload);
	  }

	//Set auto detect country
	telInputObject(obj) {
		$.getJSON('https://ipapi.co/json/', function (data) {
			this.initialCountrySet = "US";
			// this.initialCountrySet = data.country_code.toLowerCase();
			// this.country_code = data.country_code;
			// this.dial_code = data.country_calling_code;
			obj.setCountry(this.initialCountrySet);
		});

	}
	// End


	trimValue(formControl) {
		((typeof formControl.value) && formControl.value.trim() == "") ?
			formControl.setValue("") : formControl.setValue(formControl.value);
	}

	// Validation signUp form
	get full_name() { return this.signupForm.get('full_name'); }
	get email() { return this.signupForm.get('email'); }
	get password() { return this.signupForm.get('password'); }
	get country() { return this.signupForm.get('country'); }
	get phone_number() { return this.signupForm.get('phone_number'); }
	// End

	// When change country get dial code and country code 
	onCountryChange($event) {
		this.dial_code = `+${$event.dialCode}`;
		this.signupForm.patchValue({ country: $event.name.split('(')[0] });
		this.country_code = $event.iso2.toUpperCase();
		this.signupForm.patchValue({ 'mobile': "" });
		localStorage.removeItem("countryCode");
		this.countryCode = localStorage.setItem('countryCode', this.country_code);
	}

	// End


	// resend otp code 


	// // Resend OTP phone
	// resendOtp() {
	// 	const formData = new FormData();
	// 	formData.append('email', this.emailSet);
	// 	this.authService.OTPResend(formData).subscribe(data => {
	// 	});

	// }



	// Resend OTP phone
	resendOtp() {
		const formData = new FormData();
		formData.append('email', this.emailSet);

		this.commonService.showLoader();

		this.authService.OTPResend(formData).subscribe(data => {

			if (data.status == 'success') {
				this.commonService.hideLoader();
				this.commonService.toastSuccess(data.message);
			}
		}, err => {
			this.commonService.hideLoader();
			// this.commonService.errorhandler(err);
			this.commonService.errorhandlerForLogin(err);

		});

	}

	onKeyUp(event) {
		return false;
	}

	// Set only numbers allowed for phone number
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;
	}
	// End

	// Province list get function
	getProvinceList() {
		this.authService.getProvinceList()
			.subscribe(provinceData => {
				this.commonService.hideLoader();

				if (provinceData.status == 'success') {
					this.provinceList = provinceData.data.province;
				} else {
					this.provinceList = [];
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End

	// Function for check form validate 
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {         //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
	// End

	// show Email checkbox value get on click (usertype == seller)
	getValueShowemail(event) {
		if (this.isChecked == 0) {
			this.isChecked = 1;
		}
		else {
			this.isChecked = 0;
		}
		this.isChecked;
	}
	// End code 

	// Terms and condition checkbox value get(when term value 0 form not submit)
	termCondition(event) {
		if (this.getTermCheckValue == 0) {
			this.getTermCheckValue = 1;
		} else {
			this.getTermCheckValue = 0;
		}
		// this.getTermCheckValue;
	}
	// End

	// SignUp function
	signup() {
		if (this.signupForm.valid) {
			this.commonService.showLoader();
			let province = "";

			const formData = new FormData();
			if (this.userType == 'seller') {
				formData.append('full_name', this.signupForm.get('full_name').value);
				formData.append('email', this.signupForm.get('email').value);
				formData.append('password', this.signupForm.get('password').value);
				formData.append('province', province);
				formData.append('country', this.signupForm.get('country').value);
				formData.append('country_code', this.country_code);
				formData.append('show_mail', this.isChecked);
				formData.append('user_type', this.userType);
				formData.append('signup_from', '3');
				formData.append('device_token', new Date().toISOString());
				formData.append('profile_timezone', this.commonService.getTimeZone());
				formData.append('dial_code', this.dial_code);
			} else {
				formData.append('full_name', this.signupForm.get('full_name').value);
				formData.append('email', this.signupForm.get('email').value);
				formData.append('password', this.signupForm.get('password').value);
				formData.append('province', province);
				formData.append('country', this.signupForm.get('country').value);
				formData.append('country_code', this.country_code);
				formData.append('show_mail', this.isChecked);
				formData.append('user_type', this.userType);
				formData.append('signup_from', '3');
				formData.append('device_token', new Date().toISOString());
				formData.append('profile_timezone', this.commonService.getTimeZone());
				formData.append('dial_code', this.dial_code);
				formData.append('phone_number', this.signupForm.get('phone_number').value);
			}



			if (this.getTermCheckValue == 1) {

				this.authService.signup(formData).subscribe(data => {
					this.commonService.hideLoader();


					this.authenticationService.SignUp(this.signupForm.get('email').value, '123456');

					if (this.userType == 'buyer') {


						this.addOnFirebase(data.data, '1', true);
						this.router.navigate(['/']);
						localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
					} else {
						this.getEmailValue = data.data.user_detail.is_email_verified;
						if (this.getEmailValue == 0) {
							this.router.navigate(['seller/email-varification']);
							localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
						}
						// const userDetail = JSON.parse(localStorage.getItem(environment.dataStore.userData));
						if (data.data.user_detail.is_phone_verified == 0 && data.data.user_detail.is_email_verified == 1) {

							this.router.navigate(['seller/number-verify']);

						}

					}


				}, err => {
					this.commonService.hideLoader();
					this.commonService.errorhandler(err);
				});
			} else {
				this.commonService.hideLoader();
				$('#termConditionCheck').modal('show');
			}

		} else {
			// validate all form fields
			this.validateAllFormFields(this.signupForm); //{7}
		}

	}
	// End


	checkChar(e) {
		e.preventDefault();
	}

	IPVerifySubmit() {

		if (this.verifyOTP.trim() != "") {
			this.commonService.showLoader();

			// const formData = new FormData();
			// formData.append('email', this.emailSet);
			// formData.append('otp', this.verifyOTP.trim());
			// // formData.append('device_token', new Date().toISOString());
			this.getLoginData1 = JSON.parse(localStorage.getItem(environment.dataStore.userData));

			const formData = new FormData();
			formData.append('email', this.emailSet);
			formData.append('otp', this.verifyOTP.trim());
			// formData.append('country', this.getLoginData1.user_detail.country);
			// formData.append('phone_number', this.getLoginData1.user_detail.mobile_number);
			// formData.append('country_code', this.getLoginData1.user_detail.country_code);
			// formData.append('dial_code', this.getLoginData1.user_detail.phone_dial_code);
			// formData.append('is_phone_show', this.getLoginData1.user_detail.is_phone_show);
			formData.append('web_verify_otp', "1");





			this.authService.OTPVerifiyWithoutLogin(formData).subscribe((data) => {

				this.commonService.hideLoader();

				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					this.addOnFirebase(data.data, '2', false);

					$('#IPVerifyModal').modal('hide');
					localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
					//Login here
					this.commonService.toastSuccess(data.message);

					// localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

					// for check user come to add to card
					let redirectURI = localStorage.getItem(environment.dataStore.redirectUri);
					if (redirectURI != null) {

						this.router.navigate([redirectURI]);
						localStorage.removeItem(environment.dataStore.redirectUri);
						window.location.reload();

					} else {

						if (data.data.user_detail.user_type == 'seller') { // user type check

							// check user complete business info.
							if (Object.keys(data.data.business_info).length === 0) {
								// add on firebase
								this.addOnFirebase(data.data, '1', false);
								this.router.navigate(['seller/add-business']);
								window.location.reload();
							} else {
								// add on firebase
								this.addOnFirebase(data.data, '2', false);
								this.router.navigate(['seller/new-orders']);
								window.location.reload();
							}

						} else {
							// add on firebase
							this.addOnFirebase(data.data, '1', false);
							this.router.navigate(['/']);
							window.location.reload();
						}

					}
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			this.commonService.toastError(this.translate.instant('SIGNUP.please_enter_otp_code'));
		}

	}


	// Check social sign up function
	checkSocialSignup(socialData, socialType) {

		let device_token = new Date().toISOString();
		let formData;
		if (socialData.email != "") {
			formData = `email=${socialData.email}&social_id=${socialData.sub}&social_type=${socialType}&user_type=buyer&device_token=${device_token}&profile_image=${socialData.photoUrl}&full_name=${socialData.name}`;
		} else {
			formData = `social_id=${socialData.sub}&social_type=${socialType}&user_type=buyer&device_token=${device_token}&profile_image=${socialData.photoUrl}&full_name=${socialData.name}`;
		}

		this.authService.checkSocialSignup(formData).subscribe(data => {

			this.commonService.hideLoader();

			if (data.status == 'success') {

				// check user exist or not
				if (data.data.social_status == '0') { // not exist

					let socialDataStore = {
						'userType': this.userType,
						'id': socialData.sub,
						'full_name': socialData.name,
						'email': socialData.email,
						'socialType': socialType,
						'photoUrl': socialData.photoUrl
					}

					this.authService.socialDataCollection = socialDataStore;
					localStorage.removeItem("countryCode");
					this.router.navigate(['/social-email']);

				} else { // exist
					this.authenticationService.SignIn(data.data.user_detail.email, '123456');


					if (data.data.isExpired == '1') {
						this.emailSet = socialData.email;

						if (data.data.user_detail.is_phone_verified == 0) {
							localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

							this.router.navigate(['seller/number-verify']);

						}
						else {
							this.phoneNumber = data.data.user_detail.phone_dial_code + '-' + data.data.user_detail.mobile_number;
							$('#IPVerifyModal').modal('show');
							return false;
						}

						// $('#IPVerifyModal').modal('show');

						// 
					} else {
						localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));


						if (data.data.user_detail.is_email_verified == 0 && data.data.user_detail.switch_profile == 1) {

							return this.router.navigate(['/seller/complete-switch-profile']);

						}


						this.commonService.toastSuccess(data.message);
						localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

						// for check user come to add to card
						let redirectURI = localStorage.getItem(environment.dataStore.redirectUri);
						if (redirectURI != null) {
							this.router.navigate([redirectURI]);
							localStorage.removeItem(environment.dataStore.redirectUri);
							// window.location.reload();
						} else {

							if (data.data.user_detail.user_type == 'seller' && data.data.user_detail.is_email_verified == 1) { // user type check
								// check user complete business info.
								if (Object.keys(data.data.business_info).length === 0) {
									// add on firebase
									// this.addOnFirebase(data.data, '1');
									this.addOnFirebase(data.data, '1', false);
									// email and mobile verified router navigate page
									if (data.data.user_detail.is_phone_verified == 1 && data.data.user_detail.is_email_verified == 1) {
										this.router.navigate(['seller/add-business']);
										// window.location.reload();
									} else {
										this.router.navigate(['seller/number-verify']);
									}
								} else {
									// add on firebase
									// this.addOnFirebase(data.data, '2');
									this.addOnFirebase(data.data, '2', false);
									this.router.navigate(['seller/new-orders']);
									// window.location.reload();
								}

							} else {
								// add on firebase
								// this.addOnFirebase(data.data, '1');
								this.addOnFirebase(data.data, '1', false);
								this.router.navigate(['/']);
								// window.location.reload();
							}

						}

					}

				}

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			// this.commonService.errorhandler(err);
			this.commonService.errorhandlerForLogin(err);

		});

	}
	// End


	// // social connect with google
	// signInWithGoogle(): void {
	// 	this.commonService.showLoader();

	// 	this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {

	// 		this.checkSocialSignup(x, '1');

	// 	}).catch(error => {
	// 		this.commonService.hideLoader();
	// 	});
	// }
	// End

	// social connect with facebook
	// signInWithFB(): void {
	// 	this.commonService.showLoader();

	// 	this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {

	// 		this.checkSocialSignup(x, '2');

	// 	}).catch(error => {
	// 		this.commonService.hideLoader();
	// 	});
	// }
	// End

	// Firebase code 
	addOnFirebase(userData, type, isSignup) {


		// this.authenticationService.SignUp(this.signupForm.get('email').value, '123456');

		// alert('check');
		let userDataStore: any = {
			'user_id': userData.user_detail.userID,
			'full_name': userData.user_detail.full_name,
			'email': userData.user_detail.email,
			'user_type': userData.user_detail.user_type,
			'avatar': userData.user_detail.avatar,
			'notification_status': userData.user_detail.push_alert_status,
			'firebase_token': "",
			'business_name': "",
			'online': "Online"
		}

		if (isSignup && type == '2') {
			userDataStore.seller_name = userData.user_detail.full_name;
			userDataStore.buyer_name = "";
		} else if (isSignup && type == '1') {
			userDataStore.buyer_name = userData.user_detail.full_name;
			userDataStore.seller_name = "";
		}

		userDataStore.business_name = (type == '2') ? userData.business_info.business_name : "";

		setTimeout(() => {
			this.firebaseService.userAddFB(userData.user_detail.userID, userDataStore)

		}, 2000);



	}
	// End

}
