import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { faEllipsisV, faSearch, faArrowLeft, faMicrophone, faPaperPlane, faImages, faCheck, faTimes, faCheckDouble, faRedo } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { BuyerService } from '../services/buyer.service';
import { AuthService } from '../../auth/auth.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const scrollDown: any;
declare const checkOnline: any;
declare const setValue: any;

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {
	uploadPercent: Observable<number>;
	downloadURL: Observable<string>;
	faEllipsisV = faEllipsisV;
	faSearch = faSearch;
	faArrowLeft = faArrowLeft;
	faMicrophone = faMicrophone;
	faPaperPlane = faPaperPlane;
	faImages = faImages;
	faCheck = faCheck;
	faTimes = faTimes;
	faCheckDouble = faCheckDouble;
	faRedo = faRedo;
	makeOfferForm: FormGroup;
	productId: any;
	orderId: any;
	message: any;
	chatType: any;
	isOrderExist: boolean = false;
	isProductExist: boolean = false;
	chatData: any = {};
	chatList: any = [];
	chatHistoryList: any = [];
	buyerId: any;
	buyerName: any;
	offer: any = {};
	lastTimeStamp: any = {};
	blockMessage: any;
	chatBoxAllow: any;
	blockByMe: any;
	variants: any = [];
	variantListData: any = [];
	variant: string = '';
	firebaseToken: any = '';
	imagePreview: any;
	price: any;
	item: any = {
		quantity: 1,
		minQty: 1
	};
	productPrice: any = 0;
	productVariantID: any = 0;
	isCorrectVariantCombination: boolean = false;
	seller_province: any = [];
	orderListSubscription: Subscription;
	productListSubscription: Subscription;
	isViewBtnShow: boolean = true;

	@ViewChild('myForm', { static: true }) form: any;
	newBuyer: any;
	orderID: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private formBuilder: FormBuilder,
		private router: Router,
		private buyerService: BuyerService,
		private authService: AuthService,
		private firebaseService: FirebaseService,
		private storage: AngularFireStorage,
		private translate: TranslateService
	) { translate.use(this.commonService.getLanguage()); }

	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Chat'); //s set title 
		this.buyerId = this.authService.getCurrentUserId(); //For buyer id
		this.buyerName = this.authService.getUserDetails().full_name; //For buyer name

		checkOnline(this.firebaseService.getDataBase(), this.authService.getCurrentUserId());


		$(function () {
			if ($(window).width() <= 640) {
				$(".chatHistoryItem").click(function () {
					$(".sideHistory").css({ "flex": "0 0 0%", "-webkit-flex": "0 0 0%" });
					$(".conversation").css({ "flex": "0 0 100%", "-webkit-flex": "0 0 100%" });
				});
				$(".chatBack").click(function () {
					$(".sideHistory").css({ "flex": "0 0 100%", "-webkit-flex": "0 0 100%" });
					$(".conversation").css({ "flex": "0 0 0%", "-webkit-flex": "0 0 0%" });
				});
			}
		});

		const body = document.getElementsByTagName('html')[0];
		body.classList.add('chatComponent');

		const str = this.router.url;
		const regexOrder = RegExp('/chat/order/');  // check url for page 
		const regexProduct = RegExp('/chat/product/'); // check url for page 

		if (regexOrder.test(str) == true) {

			this.orderId = this.activatedRoute.snapshot.paramMap.get("orderId"); //For Order id
			this.chatType = '2' // 2 for order
			this.getOrderDetail(this.orderId); //For order detail

		} else if (regexProduct.test(str) == true) {

			this.productId = this.activatedRoute.snapshot.paramMap.get("productId"); //For product Id
			this.chatType = '3' // 3 for product
			this.getProductDetail(this.productId); //For product detail

		} else {

			this.chatType = '1' // no 
			setValue('chatNode', "");
		}

		this.getChatHistory(); //For chat history
	}


	// angular life cycle 
	ngOnDestroy(): void {

		Swal.close();

		$('#imgFullViewModal').modal('hide');
		$('#makeAnOfferModal').modal('hide');

		const body = document.getElementsByTagName('html')[0];
		body.classList.remove('chatComponent');
		setValue('chatNode', "");

		// unscunscribe apis 
		if (this.chatType == '2' && this.isOrderExist) {
			this.orderListSubscription.unsubscribe();
		} else if (this.chatType == '3' && this.isProductExist) {
			this.productListSubscription.unsubscribe();
		}
	}

	// For chat history
	changeChat(chatHistory) {
		this.newBuyer = chatHistory.seller_id;
		this.orderID = chatHistory.orderId;

		if (chatHistory.chat_type == 'order') {

			if (this.chatType == '2') {
				this.orderListSubscription.unsubscribe();
			} else if (this.chatType == '3') {
				this.productListSubscription.unsubscribe();
			}

			this.chatType = '2';
			this.getOrderDetail(chatHistory.history_id); // get order detail 
		} else if (chatHistory.chat_type == 'product') {

			if (this.chatType == '2') {
				this.orderListSubscription.unsubscribe();
			} else if (this.chatType == '3') {
				this.productListSubscription.unsubscribe();
			}

			this.chatType = '3';
			this.getProductDetail(chatHistory.history_id); // get product details 
		}
	}
	// End

	// For order detail
	getOrderDetail(orderId) {
		this.commonService.showLoader();

		this.buyerService.getMyOrderDetail(orderId).subscribe(myOrderData => {

			console.log('myOrderData', myOrderData);
			this.commonService.hideLoader();

			if (myOrderData.status == 'success') {
				this.isOrderExist = true;
				this.chatData = myOrderData.data.order_detail;

				this.firebaseService.getSellerDetails(this.chatData.seller_id).subscribe(data => {
					let usr;
					usr = data.payload.val();
					this.chatData.isOnline = usr.online;
				});

				setValue('chatNode', `${this.chatData.seller_id}-${this.buyerId}-${this.chatData.orderID}-O`);

				this.getFirebaseToken();
				this.getBlockStatus();
				this.getDeleteTime();
			}
		}, err => {
			this.isOrderExist = false;
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
			this.router.navigate(['/']);
		});
	}
	// End

	// For product detail
	getProductDetail(productId) {
		this.commonService.showLoader();

		this.buyerService.getProductDetail(productId, this.buyerId).subscribe(productData => {
			this.commonService.hideLoader();

			if (productData.status == 'success') {
				this.isProductExist = true;
				this.chatData = productData.data.product_detail;
				this.variants = productData.data.product_detail.variants_options;
				this.seller_province = this.chatData.seller_province.split(',');

				this.firebaseService.getSellerDetails(this.chatData.seller_id).subscribe(data => {
					let usr;
					usr = data.payload.val();
					this.chatData.isOnline = usr.online;
				});

				// agrang variant data
				this.chatData.variant_list.forEach(element1 => {
					let newKey = "";
					element1.variant_value.forEach(element2 => {
						newKey += element2.variantValueID;
					});

					element1.variantValuesString = newKey;
				});

				setValue('chatNode', `${this.chatData.seller_id}-${this.buyerId}-${this.chatData.productID}-P`);

				this.getFirebaseToken();
				this.getBlockStatus();
				this.getDeleteTime();
				this.checkProductExist();
			}
		}, err => {
			this.isProductExist = false;
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
			this.router.navigate(['/']);
		});
	}
	// End

	// For check exist product
	checkProductExist() {
		let offerData = {
			productId: this.chatData.productID,
		}

		this.firebaseService.checkProductExist(offerData).then(data => {
			if (data.val()) {

				if (data.val().recently_deleted == '1') {

					this.isViewBtnShow = false;

				} else {
					this.isViewBtnShow = true;
				}

			} else {
				this.isViewBtnShow = false;
			}
		});
	}
	// End

	// For firebase token  (device token )
	getFirebaseToken() {
		this.firebaseService.getFirebaseToken(this.chatData.seller_id).subscribe(userData => {
			let token;
			if (userData.payload.val()) {
				token = userData.payload.val();
				this.firebaseToken = token.firebase_token;
			}
		});
	}
	// End

	// For product detail routing
	productDetailLink(name, productID) {
		this.router.navigate(['/product-detail', name, productID]);
	}
	// End

	// For message time
	getTimeAgo(timestamp) {
		return this.commonService.chatHistoryTimeAgo(timestamp);
	}
	// End

	// For image preview
	imagePreviewModal(imageSrc) {
		$('#imgFullViewModal').modal('show');
		this.imagePreview = imageSrc;
	}
	// End

	// For get deleted time
	getDeleteTime() {

		if (this.chatType == '2') { // get order chat

			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID
			}

			this.firebaseService.checkDeleteMessage(chatData, '2').subscribe(deleteData => {
				console.log('deleteChat', deleteData)
				let dt;
				if (deleteData.payload.val()) {
					dt = deleteData.payload.val();
					this.lastTimeStamp = dt.timestamp;
					this.getChatList();
				} else {
					this.lastTimeStamp = "";
					this.getChatList();
				}
			});

		} else if (this.chatType == '3') {
			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				productId: this.chatData.productID
			}

			this.firebaseService.checkDeleteMessage(chatData, '3').subscribe(deleteData => {
				let dt;
				if (deleteData.payload.val()) {
					dt = deleteData.payload.val();
					this.lastTimeStamp = dt.timestamp;
					this.getChatList();
				} else {
					this.lastTimeStamp = "";
					this.getChatList();
				}
			});
		}

	}
	// End

	// For chat list
	getChatList() {
		if (this.chatType == '2') { // get order chat

			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
				lastTimeStamp: this.lastTimeStamp,
			}

			this.orderListSubscription = this.firebaseService.getOrderChatList(chatData).subscribe(chatData => {

				this.chatList = chatData;
				scrollDown();

				this.commonService.timeStore = "";
				// change buyer message from unread to read.
				this.chatList.forEach(element => {
					element.centerTime = this.commonService.chatGroupTime(element.timestamp);
					if (element.last_sender_id != this.buyerId) {

						let readData = {
							sellerId: this.chatData.seller_id,
							buyerId: this.buyerId,
							orderId: this.chatData.orderID,
							key: element.key,
						}

						this.firebaseService.msgReadTick(readData, '2');
					}
				});

			});

		} else if (this.chatType == '3') { // get product chat

			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				lastTimeStamp: this.lastTimeStamp,
			}

			this.productListSubscription = this.firebaseService.getProductChatList(chatData).subscribe(chatData => {
				this.chatList = chatData;
				scrollDown();

				this.commonService.timeStore = "";
				// change buyer message from unread to read.
				this.chatList.forEach(element => {
					element.centerTime = this.commonService.chatGroupTime(element.timestamp);
					if (element.last_sender_id != this.buyerId) {

						let readData = {
							sellerId: this.chatData.seller_id,
							buyerId: this.buyerId,
							productId: this.chatData.productID,
							key: element.key,
						}

						this.firebaseService.msgReadTick(readData, '3');
					}
				});

			});

			this.firebaseService.getProductOffer(chatData).subscribe(offerData => {
				this.offer = {};
				if (offerData.key == 'offer') {
					this.offer = offerData.payload.val();
				}
			});

		} else {
		}
	}
	// End

	// For chat history get
	getChatHistory() {

		if (this.chatType == '2') { // for order history

			let chatData = {
				buyerId: this.buyerId
			}

			this.firebaseService.getChatHistoryList(chatData).subscribe(chatData => {
				this.chatHistoryList = chatData;
				this.chatHistoryList.map((val) => {
					if (val.seller_id == this.newBuyer && val.orderId == this.orderID) {
						this.chatData.typing = val.typing;

					}


				})
				this.chatHistoryList.sort(this.sortFunction);
			});

		}


		else {

			let chatData = {
				buyerId: this.buyerId
			}

			this.firebaseService.getChatHistoryList(chatData).subscribe(chatData => {
				this.chatHistoryList = chatData;
				this.chatHistoryList.sort(this.sortFunction);
			});

		}

	}
	// End

	sortFunction(a, b) {
		if (a['timestamp'] === b['timestamp']) {
			return 0;
		}
		else {
			return (a['timestamp'] < b['timestamp']) ? 1 : -1;
		}
	}

	// For make an offer
	makeAnOffer() {

		if (!this.seller_province.includes(this.authService.getUserDetails().province)) {
			this.commonService.toastError(this.translate.instant('CHAT.you_cant_offer_on_this_product'));
		} else {
			$('#makeAnOfferModal').modal('show');
		}
	}
	// End

	// For check form validation
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
	// End

	// For variant select
	selectVariant(form: NgForm) {

		let temapPrice = form.value["price"];

		delete form.value["price"];

		let selectVariantValuesArray = Object.values(form.value);
		let selectVariantValues = selectVariantValuesArray.join('');

		form.value["price"] = temapPrice;

		let variantMatch = this.chatData.variant_list.some(element => {

			if (element.variantValuesString == selectVariantValues) {
				this.productPrice = element.price;
				this.productVariantID = element.productVariantID;
				this.isCorrectVariantCombination = true;
				return true;
			}
		});

		if (variantMatch === false) {
			this.isCorrectVariantCombination = false;
			this.productPrice = 0;
			this.productVariantID = 0;
		}

	}
	// End

	//Change Quantity of cart 
	changeqty(value: number): void {
		if (this.item.quantity > value) {
			this.item.quantity = value;
		} else {
			this.item.quantity = value;
		}
	}
	// End

	// For reset offer form
	resetOfferForm() {
		this.form.reset();
		this.item.quantity = 1;
		this.productPrice = 0;
	}
	// End

	// Function for offer
	offerSubmit(form: NgForm) {
		if (form.valid && this.isCorrectVariantCombination === true) {

			if (Number(this.productPrice) <= Number(form.value.price)) {
				this.commonService.toastError(this.translate.instant('CHAT.offer_price_should_be_less_than_product_price'));
				return false;
			}
			if (form.value.price.match('^[0-9]{1,7}((\.[0-9]{1,2}))?$') == null) {
				this.commonService.toastError(this.translate.instant('CHAT.offer_price_only_allowed_decimal_values')); return false;
			}

			let offerPriceString = parseFloat(form.value.price).toFixed(2);

			let offerData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				productVariantId: this.productVariantID,
				counterPrice: "0",
				productPrice: this.productPrice,
				currency: this.chatData.currency_symbol,
				offerPrice: offerPriceString.toString(),
				size: "",
				color: "",
				quantity: this.item.quantity.toString(),
			}

			this.firebaseService.checkProductExist(offerData).then(data => {
				if (data.val()) {

					if (data.val().recently_deleted == '0') {

						this.firebaseService.makeAnOfferStore(offerData);
						this.message = this.translate.instant('SHOP.you_have_new_offer_request');
						this.sendMsg(1);

					} else {
						this.commonService.toastError(this.translate.instant('CHAT.this_product_is_recently_deleted'));
						this.router.navigate(['/']);
					}

				} else {
					this.commonService.toastError(this.translate.instant('CHAT.product_deleted_by_seller'));
					this.router.navigate(['/']);
				}
			});
			this.commonService.toastSuccess(this.translate.instant('SHOP.offer_submitted_successfully'));
			$('#makeAnOfferModal').modal('hide');
			this.resetOfferForm();

		} else {

			if (typeof form.value.price == 'undefined' || form.value.price == null) {
				this.commonService.toastError(this.translate.instant('SHOP.offer_price_is_required'));
			} else if (form.value.price.match(/^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/igm) == null) {
				this.commonService.toastError(this.translate.instant('SHOP.offer_price_must_be_vaild'));
			} else if (!form.valid) {
				this.commonService.toastError(this.translate.instant('CHAT.please_select_variant'));
			} else if (this.isCorrectVariantCombination === false) {
				this.commonService.toastError(this.translate.instant('SHOP.product_variant_combination_not'));
			} else {
				this.commonService.toastError('Error retry');
			}

		}
	}
	// End

	// For order message send
	sendMsg(isOffer = 0) {

		if (this.chatType == '2' && this.message.trim()) { // order chat

			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				buyerName: this.buyerName,
				orderId: this.chatData.orderID,
				orderNumber: this.chatData.order_number,
				firebaseToken: this.firebaseToken,
				message: this.message.trim()
			}

			this.firebaseService.sendOrderMsg(chatData);

		} else if (this.chatType == '3' && this.message.trim()) { // product chat

			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				buyerName: this.buyerName,
				productId: this.chatData.productID,
				productName: this.chatData.name,
				firebaseToken: this.firebaseToken,
				isOffer: (isOffer || Object.keys(this.offer).length != 0) ? 1 : 0,
				message: this.message.trim()
			}

			this.firebaseService.sendProductMsg(chatData);


		} else {
		}

		this.message = "";
	}
	// End

	// For file upload
	onSelectedFile(event) {
		if (event.target.files.length > 0) {
			this.commonService.showLoader();

			const file = event.target.files[0];
			var ext = file.name.split('.').pop().toLowerCase();

			if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
				this.commonService.hideLoader();
				Swal.fire(this.translate.instant('MYORDER.ops_txt'), this.translate.instant('MYORDER.invalid_extension'), this.translate.instant('MYORDER.error_txt'));
				return false;
			}
			const filePath = environment.storageBucketFile + Date.now();
			const fileRef = this.storage.ref(filePath);

			const task = this.storage.upload(filePath, file).then(() => {
				const ref = this.storage.ref(filePath);
				const downloadURL = ref.getDownloadURL().subscribe(url => {

					this.commonService.hideLoader();

					if (this.chatType == '2') { // order chat

						let chatData = {
							sellerId: this.chatData.seller_id,
							buyerId: this.buyerId,
							buyerName: this.buyerName,
							orderId: this.chatData.orderID,
							orderNumber: this.chatData.order_number,
							firebaseToken: this.firebaseToken,
							message: this.message
						}

						this.firebaseService.sendOrderMsg(chatData, url);

					} else if (this.chatType == '3') {  // product chat

						let chatData = {
							sellerId: this.chatData.seller_id,
							buyerId: this.buyerId,
							buyerName: this.buyerName,
							productId: this.chatData.productID,
							productName: this.chatData.name,
							firebaseToken: this.firebaseToken,
							isOffer: (Object.keys(this.offer).length != 0) ? 1 : 0,
							message: this.message
						}

						this.firebaseService.sendProductMsg(chatData, url);

					}

				});
			});
		}
	}
	// End

	// For confirmation before delete
	deleteConfirm() {
		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('CHAT.you_want_to_delete_chat'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('SELLER_SIDE.yes_delete_it'),
			cancelButtonText: this.translate.instant('SELLER_SIDE.no_keep_it'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.deleteChatData();
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					this.translate.instant('SELLER_SIDE.cancelled'),
					this.translate.instant('CHAT.your_chat_is_safe'),
					this.translate.instant('MYORDER.error_txt')
				)
			}
		});
	}
	// End

	// For chat data delete
	deleteChatData() {

		if (this.chatType == '2' && this.chatList.length != 0) { // order chat

			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
				timestamp: Number(this.chatList[this.chatList.length - 1].timestamp) + Number(1),
			}

			console.log('chatData', chatData);

			this.firebaseService.deleteChat(chatData, '2');

		} else if (this.chatType == '3' && this.chatList.length != 0) { // product chat

			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				timestamp: Number(this.chatList[this.chatList.length - 1].timestamp) + Number(1),
			}

			this.firebaseService.deleteChat(chatData, '3');

		} else {
		}
	}
	// End

	// Confirmation function
	blockConfirm() {
		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('CHAT.block_confirm_text'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('CHAT.yes_i_am_sure'),
			cancelButtonText: this.translate.instant('CHAT.no_cancel_it'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.blockUser();
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					this.translate.instant('SELLER_SIDE.cancelled'),
					this.translate.instant('CHAT.your_blocked_is_safe'),
					this.translate.instant('MYORDER.error_txt')
				)
			}
		});
	}
	// End

	// For block user
	blockUser() {
		let chatData = {
			sellerId: this.chatData.seller_id,
			buyerId: this.buyerId,
		}

		this.firebaseService.blockUser(chatData);
	}
	// End

	// For unblock user
	unBlockUser() {
		let chatData = {
			sellerId: this.chatData.seller_id,
			buyerId: this.buyerId,
		}

		this.firebaseService.unBlockUser(chatData);
	}
	// End

	// User block unblock status
	getBlockStatus() {
		let chatData = {
			sellerId: this.chatData.seller_id,
			buyerId: this.buyerId,
		}
		this.firebaseService.getBlockStatus(chatData).subscribe(data => {

			let block;
			if (data.payload.val()) {
				block = data.payload.val();

				if (block.blocked_by == this.buyerId || block.blocked_by == "Both") {
					this.chatBoxAllow = false;
					this.blockMessage = this.translate.instant('CHAT.you_have_blocked_this_user');
					this.blockByMe = true;
				} else {
					this.chatBoxAllow = false;
					this.blockByMe = false;
					this.blockMessage = this.translate.instant('CHAT.you_are_blocked_by_this_user');
				}

			} else {
				this.chatBoxAllow = true;
				this.blockByMe = false;
			}

		});
	}
	// End

	typeTimer: any;
	doneTypeInterval: any = 1200;

	// For chat typing
	typing() {
		let chatData;

		if (this.chatType == '2') { // for order
			chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
			}

		} else if (this.chatType == '3') { // for product
			chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
			}
		}

		this.firebaseService.setTyping(chatData, this.chatType, 1);

		clearTimeout(this.typeTimer);
		this.typeTimer = setTimeout(() => {
			this.firebaseService.setTyping(chatData, this.chatType, 0);
		}, this.doneTypeInterval);
	}
	// End

	// Order Confirmation 
	confrimation(status, productId) {

		if (status == '0') {
			Swal.fire({
				title: this.translate.instant('MYORDER.are_you_sure'),
				text: this.translate.instant('CHAT.you_want_to_reject_this_offer'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: this.translate.instant('MYORDER.yes_reject_it'),
				cancelButtonText: this.translate.instant('MYORDER.no_keep_it'),
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.acceptReject(status, productId);
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire(
						this.translate.instant('SELLER_SIDE.cancelled'),
						this.translate.instant('CHAT.your_offer_is_safe'),
						this.translate.instant('MYORDER.error_txt')
					)
				}
			});
		} else {
			Swal.fire({
				title: this.translate.instant('MYORDER.are_you_sure'),
				text: this.translate.instant('CHAT.you_want_to_accept_this_offer'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: this.translate.instant('MYORDER.yes_accept_it'),
				cancelButtonText: this.translate.instant('CHAT.no_keep_it'),
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.acceptReject(status, productId);
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire(
						this.translate.instant('SELLER_SIDE.cancelled'),
						this.translate.instant('CHAT.your_offer_not_accept'),
						this.translate.instant('MYORDER.error_txt')
					)
				}
			});
		}

	}
	// End

	// Accept reject case
	acceptReject(status, productId) {

		let offerData = {
			sellerId: this.chatData.seller_id,
			buyerId: this.buyerId,
			productId: productId,
			status: status,
		}

		this.firebaseService.offerAcceptReject(offerData);

		// send message when accept offer by buyer.
		if (status == '1') {
			let chatData = {
				sellerId: this.chatData.seller_id,
				buyerId: this.buyerId,
				buyerName: this.buyerName,
				productId: this.chatData.productID,
				productName: this.chatData.name,
				firebaseToken: this.firebaseToken,
				isOffer: 1,
				message: this.translate.instant('CHAT.your_counter_price_is_accepted_by_buyer')
			}

			this.firebaseService.sendProductMsg(chatData);
		}

	}
	// End

	// place order 
	placeOrder() {
		this.commonService.showLoader();

		let feature_image = `${this.chatData.feature_image_url}thumb/${this.chatData.feature_image}`;

		var formData = new FormData();
		formData.append('product_id', this.chatData.productID);
		formData.append('product_name', this.chatData.name);
		formData.append('quantity', this.offer.quantity);
		formData.append('feature_image', feature_image);
		formData.append('seller_id', this.chatData.seller_id);
		formData.append('category', this.chatData.category_id);
		formData.append('product_variant_price', this.offer.product_price);
		formData.append('regular_price', this.chatData.regular_price);
		formData.append('offer_price', this.offer.offer_price);
		formData.append('category_name', this.chatData.category_name);
		formData.append('counter_price', this.offer.counter_price);
		formData.append('product_variant_id', this.offer.product_variant_id);

		this.buyerService.makeAnOffer(formData).subscribe((data) => {
			this.commonService.hideLoader();
			if (data.status == "fail") {
				this.commonService.toastError(data.message);
				this.resetOfferForm();
				$('#makeAnOfferModal').modal('hide');
			} else {

				this.commonService.toastSuccess(data.message);
				this.resetOfferForm();
				this.router.navigate(['offer-cart/', data.data.offer_id]);
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

}
