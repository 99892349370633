import { Component, OnInit, OnDestroy } from '@angular/core';
import { faEllipsisV, faSearch, faArrowLeft, faMicrophone, faPaperPlane, faImages, faCheck, faTimes, faCheckDouble, faRedo } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { AuthService } from '../../auth/auth.service';
import { SellerService } from '../services/seller.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const scrollDown: any;
declare const checkOnline: any;
declare const setValue: any;
declare const getTime: any;

@Component({
	selector: 'app-chat',
	templateUrl: './chat.component.html',
	styleUrls: ['./chat.component.css']
})
export class ChatComponent implements OnInit, OnDestroy {
	faEllipsisV = faEllipsisV;
	faSearch = faSearch;
	faArrowLeft = faArrowLeft;
	faMicrophone = faMicrophone;
	faPaperPlane = faPaperPlane;
	faImages = faImages;
	faCheck = faCheck;
	faTimes = faTimes;
	faRedo = faRedo;
	faCheckDouble = faCheckDouble;
	sellerId: any;
	sellerName: any;
	buyerId: any;
	chatType: any;
	productId: any;
	orderId: any;
	chatHistoryList: any = [];
	chatData: any = {};
	chatList: any = [];
	offer: any = {};
	message: any;
	lastTimeStamp: any = {};
	blockMessage: any;
	chatBoxAllow: any;
	blockByMe: any;
	firebaseToken: any = '';
	imagePreview: any;
	counterPrice: any;
	orderListSubscription: Subscription;
	productListSubscription: Subscription;
	isTyping: any;
	type: any;
	newBuyer: any;
	newsellerId;
	orderID: any;

	constructor(
		private activatedRoute: ActivatedRoute,
		private commonService: CommonService,
		private router: Router,
		private sellerService: SellerService,
		private authService: AuthService,
		private firebaseService: FirebaseService,
		private storage: AngularFireStorage,
		private translate: TranslateService
	) { translate.use(this.commonService.getLanguage()); }

	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Chat');
		this.sellerId = this.authService.getCurrentUserId(); //For seller Id
		this.sellerName = this.authService.getUserDetails().full_name; //For seller name

		checkOnline(this.firebaseService.getDataBase(), this.authService.getCurrentUserId());

		$(function () {
			if ($(window).width() <= 640) {
				$(".chatHistoryItem").click(function () {
					$(".sideHistory").css({ "flex": "0 0 0%", "-webkit-flex": "0 0 0%" });
					$(".conversation").css({ "flex": "0 0 100%", "-webkit-flex": "0 0 100%" });
				});
				$(".chatBack").click(function () {
					$(".sideHistory").css({ "flex": "0 0 100%", "-webkit-flex": "0 0 100%" });
					$(".conversation").css({ "flex": "0 0 0%", "-webkit-flex": "0 0 0%" });
				});
			}
		});

		const body = document.getElementsByTagName('html')[0];
		body.classList.add('chatComponent');

		const str = this.router.url;
		const regexOrder = RegExp('/chat/order/');
		const regexProduct = RegExp('/chat/product/');

		if (regexOrder.test(str) == true) {

			this.orderId = this.activatedRoute.snapshot.paramMap.get("orderId"); //For order Id
			this.buyerId = this.activatedRoute.snapshot.paramMap.get("buyerId"); //For buyer Id

			this.chatType = '2' // 2 for order
			this.getOrderDetail(this.orderId); //For order detail

		} else if (regexProduct.test(str) == true) {

			this.productId = this.activatedRoute.snapshot.paramMap.get("productId");
			this.buyerId = this.activatedRoute.snapshot.paramMap.get("buyerId");
			this.chatType = '3' // 3 for product
			this.getProductDetail(this.productId); //For product detail

		} else {
			this.chatType = '1' // no 
			setValue('chatNode', "");
		}

		this.getChatHistory(); //For chat history
	}

	ngOnDestroy(): void {
		Swal.close();

		$('#imgFullViewModal').modal('hide');
		$('#counterOfferModal').modal('hide');
		const body = document.getElementsByTagName('html')[0];
		body.classList.remove('chatComponent');
		setValue('chatNode', "");

		if (this.chatType == '2') {
			this.orderListSubscription.unsubscribe();
		} else if (this.chatType == '3') {
			this.productListSubscription.unsubscribe();
		}
	}

	getTimeAgo(timestamp) {
		return this.commonService.chatHistoryTimeAgo(timestamp);
	}

	// for chat image preview
	imagePreviewModal(imageSrc) {
		$('#imgFullViewModal').modal('show');
		this.imagePreview = imageSrc;
	}
	// End

	// For chat history
	getChatHistory() {

		if (this.chatType == '2') { // for order history

			let chatData = {
				sellerId: this.sellerId
			}
			this.firebaseService.getChatHistoryList(chatData).subscribe(chatData => {
				this.chatHistoryList = chatData;

				this.chatHistoryList.map((val) => {
					if (val.buyer_id == this.newBuyer && val.orderId == this.orderID) {
						this.chatData.typing = val.typing;
					}

				})
				this.chatHistoryList.sort(this.sortFunction);
			});

		}


		else {
			let chatData = {
				sellerId: this.sellerId
			}

			this.firebaseService.getChatHistoryList(chatData).subscribe(chatData => {
				this.chatHistoryList = chatData;
				this.chatHistoryList.sort(this.sortFunction);
			});
		}

	}
	// End

	sortFunction(a, b) {
		if (a['timestamp'] === b['timestamp']) {
			return 0;
		}
		else {
			return (a['timestamp'] < b['timestamp']) ? 1 : -1;
		}
	}

	// For change history
	changeChat(chatHistory) {
		this.newBuyer = chatHistory.buyer_id;
		this.orderID = chatHistory.orderId;

		if (chatHistory.chat_type == 'order') {
			if (this.chatType == '2') {
				this.orderListSubscription.unsubscribe();
			} else if (this.chatType == '3') {
				this.productListSubscription.unsubscribe();
			}

			this.chatType = '2';
			this.buyerId = chatHistory.key.split('-')[1];
			this.getOrderDetail(chatHistory.history_id);

		} else if (chatHistory.chat_type == 'product') {

			if (this.chatType == '2') {
				this.orderListSubscription.unsubscribe();
			} else if (this.chatType == '3') {
				this.productListSubscription.unsubscribe();
			}

			this.chatType = '3';
			this.buyerId = chatHistory.key.split('-')[1];
			this.getProductDetail(chatHistory.history_id);

		}
	}
	// End

	// For order detail
	getOrderDetail(orderId) {
		this.commonService.showLoader();

		this.sellerService.getMyOrderDetail(orderId).subscribe(myOrderData => {

			this.commonService.hideLoader();

			if (myOrderData.status == 'success') {
				this.chatData = myOrderData.data.order_detail;
				this.firebaseService.getBuyerDetails(this.buyerId).subscribe(data => {
					let usr;
					usr = data.payload.val();
					if (usr.hasOwnProperty('is_deleted')) {
						this.chatData.isdeleted = 1;
					}
					else {
						this.chatData.isdeleted = 0;
					}

					this.chatData.isOnline = usr.online;
					this.chatData.isOnline = this.type;
					this.getChatHistory();

				});

				setValue('chatNode', `${this.sellerId}-${this.buyerId}-${this.chatData.orderID}-O`);


				var chatNode = `${this.sellerId}-${this.buyerId}-${this.chatData.orderID}-O`

				// this.getChatHistory();
				this.getFirebaseToken();
				this.getBlockStatus();
				this.getDeleteTime();
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
			this.router.navigate(['/']);
		});
	}
	// End

	// For product details
	getProductDetail(productId) {
		this.commonService.showLoader();

		this.sellerService.getProductDetail(productId, '0').subscribe(productData => {
			this.commonService.hideLoader();

			if (productData.status == 'success') {
				this.chatData = productData.data.product_detail;

				this.firebaseService.getBuyerDetails(this.buyerId).subscribe(data => {
					let usr;
					usr = data.payload.val();
					this.chatData.buyer_image = usr.avatar;
					this.chatData.full_name = usr.buyer_name;
					this.chatData.isOnline = usr.online;
				});
				// this.getChatHistory();

				setValue('chatNode', `${this.sellerId}-${this.buyerId}-${this.chatData.productID}-P`);

				this.getFirebaseToken();
				this.getBlockStatus();
				this.getDeleteTime();
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
			this.router.navigate(['/']);
		});
	}
	// End

	// For firebase
	getFirebaseToken() {
		this.firebaseService.getFirebaseToken(this.buyerId).subscribe(userData => {
			let token;
			if (userData.payload.val()) {
				token = userData.payload.val();
				this.firebaseToken = token.firebase_token;
			}
		});
	}
	// End

	// For delete time
	getDeleteTime() {

		if (this.chatType == '2') { // get order chat

			let chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
			}

			this.firebaseService.checkDeleteMessage(chatData, '2').subscribe(deleteData => {
				let dt;
				if (deleteData.payload.val()) {
					dt = deleteData.payload.val();
					this.lastTimeStamp = dt.timestamp;
					this.getChatList();
				} else {
					this.lastTimeStamp = "";
					this.getChatList();
				}
			});

		} else if (this.chatType == '3') { // get product chat

			let chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
			}

			this.firebaseService.checkDeleteMessage(chatData, '3').subscribe(deleteData => {
				let dt;
				if (deleteData.payload.val()) {
					dt = deleteData.payload.val();
					this.lastTimeStamp = dt.timestamp;
					this.getChatList();
				} else {
					this.lastTimeStamp = "";
					this.getChatList();
				}
			});

		}

	}
	// End

	// For chat list
	getChatList() {
		if (this.chatType == '2') { // get order chat

			let chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
				lastTimeStamp: this.lastTimeStamp,
			}

			this.orderListSubscription = this.firebaseService.getOrderChatList(chatData).subscribe(chatData => {
				this.chatList = chatData;

				scrollDown();

				this.commonService.timeStore = "";
				// change buyer message from unread to read.
				this.chatList.forEach(element => {
					element.centerTime = this.commonService.chatGroupTime(element.timestamp);
					if (element.last_sender_id != this.sellerId) {

						let readData = {
							sellerId: this.sellerId,
							buyerId: this.buyerId,
							orderId: this.chatData.orderID,
							key: element.key,
						}

						this.firebaseService.msgReadTick(readData, '2');
					}
				});

			});

		} else if (this.chatType == '3') { // get product chat

			let chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				lastTimeStamp: this.lastTimeStamp,
			}

			this.productListSubscription = this.firebaseService.getProductChatList(chatData).subscribe(chatData => {
				this.chatList = chatData;
				scrollDown();

				this.commonService.timeStore = "";
				// change buyer message from unread to read.
				this.chatList.forEach(element => {
					element.centerTime = this.commonService.chatGroupTime(element.timestamp);
					if (element.last_sender_id != this.sellerId) {

						let readData = {
							sellerId: this.sellerId,
							buyerId: this.buyerId,
							productId: this.chatData.productID,
							key: element.key,
						}

						this.firebaseService.msgReadTick(readData, '3');
					}
				});

			});

			this.firebaseService.getProductOffer(chatData).subscribe(offerData => {
				this.offer = {};
				if (offerData.key == 'offer') {
					this.offer = offerData.payload.val();
				}
			});

		} else {
		}

	}
	// End

	// For send message
	sendMsg() {

		if (this.chatType == '2' && this.message.trim()) { // order chat

			let chatData = {
				sellerId: this.sellerId,
				sellerName: this.sellerName,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
				orderNumber: this.chatData.order_number,
				typing: this.chatData.typing,

				firebaseToken: this.firebaseToken,
				message: this.message.trim()
			}
			this.firebaseService.sendOrderMsg(chatData);

		} else if (this.chatType == '3' && this.message.trim()) { // product chat

			let chatData = {
				sellerId: this.sellerId,
				sellerName: this.sellerName,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				productName: this.chatData.name,
				firebaseToken: this.firebaseToken,
				isOffer: (Object.keys(this.offer).length != 0) ? 1 : 0,
				message: this.message.trim()
			}

			this.firebaseService.sendProductMsg(chatData);

		} else {
		}

		this.message = "";
	}
	// End

	// For confrimation
	confrimation(status, productId) {

		if (status == '0') {
			Swal.fire({
				title: this.translate.instant('MYORDER.are_you_sure'),
				text: this.translate.instant('CHAT.you_want_to_reject_this_offer'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: this.translate.instant('MYORDER.yes_reject_it'),
				cancelButtonText: this.translate.instant('MYORDER.no_keep_it'),
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.acceptReject(status, productId);
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire(
						this.translate.instant('SELLER_SIDE.cancelled'),
						this.translate.instant('CHAT.your_offer_is_safe'),
						this.translate.instant('MYORDER.error_txt')
					)
				}
			});
		} else {
			Swal.fire({
				title: this.translate.instant('MYORDER.are_you_sure'),
				text: this.translate.instant('CHAT.you_want_to_accept_this_order'),
				icon: 'warning',
				showCancelButton: true,
				confirmButtonText: this.translate.instant('MYORDER.yes_accept_it'),
				cancelButtonText: this.translate.instant('MYORDER.no_keep_it'),
				reverseButtons: true
			}).then((result) => {
				if (result.value) {
					this.acceptReject(status, productId);
				} else if (result.dismiss === Swal.DismissReason.cancel) {
					Swal.fire(
						this.translate.instant('SELLER_SIDE.cancelled'),
						this.translate.instant('CHAT.your_offer_not_accept'),
						this.translate.instant('MYORDER.error_txt')
					)
				}
			});
		}

	}
	// End

	// For accept reject order button
	acceptReject(status, productId) {

		let offerData = {
			sellerId: this.sellerId,
			buyerId: this.buyerId,
			productId: productId,
			status: status,
		}

		this.firebaseService.offerAcceptReject(offerData);

		// send message when accept offer by seller.
		if (status == '1') {
			let chatData = {
				sellerId: this.sellerId,
				sellerName: this.sellerName,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				productName: this.chatData.name,
				firebaseToken: this.firebaseToken,
				isOffer: 1,
				message: this.translate.instant('CHAT.your_offer_is_accepted_by_seller')
			}

			this.firebaseService.sendProductMsg(chatData);
		}

	}
	// End

	// For file upload
	onSelectedFile(event) {
		if (event.target.files.length > 0) {
			this.commonService.showLoader();

			const file = event.target.files[0];
			var ext = file.name.split('.').pop().toLowerCase();

			if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
				this.commonService.hideLoader();
				Swal.fire(this.translate.instant('MYORDER.ops_txt'), this.translate.instant('MYORDER.invalid_extension'), this.translate.instant('MYORDER.error_txt'));
				return false;
			}
			const filePath = environment.storageBucketFile + Date.now();
			const fileRef = this.storage.ref(filePath);

			const task = this.storage.upload(filePath, file).then(() => {
				const ref = this.storage.ref(filePath);
				const downloadURL = ref.getDownloadURL().subscribe(url => {
					this.commonService.hideLoader();

					if (this.chatType == '2') { // order chat

						let chatData = {
							sellerId: this.sellerId,
							sellerName: this.sellerName,
							buyerId: this.buyerId,
							orderId: this.chatData.orderID,
							orderNumber: this.chatData.order_number,
							typing: this.chatData.typing,
							firebaseToken: this.firebaseToken,
							message: this.message
						}

						this.firebaseService.sendOrderMsg(chatData, url);

					} else if (this.chatType == '3') {  // product chat

						let chatData = {
							sellerId: this.sellerId,
							sellerName: this.sellerName,
							buyerId: this.buyerId,
							productId: this.chatData.productID,
							productName: this.chatData.name,
							firebaseToken: this.firebaseToken,
							isOffer: (Object.keys(this.offer).length != 0) ? 1 : 0,
							message: this.message
						}

						this.firebaseService.sendProductMsg(chatData, url);

					}

				});
			});
		}
	}
	// End

	// Confirm before delete
	deleteConfirm() {
		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('CHAT.you_want_to_delete_chat'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('SELLER_SIDE.yes_delete_it'),
			cancelButtonText: this.translate.instant('SELLER_SIDE.no_keep_it'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.deleteChatData();
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					this.translate.instant('SELLER_SIDE.cancelled'),
					this.translate.instant('CHAT.your_chat_is_safe'),
					this.translate.instant('MYORDER.error_txt')
				)
			}
		});
	}
	// End

	// Delete chat data
	deleteChatData() {

		if (this.chatType == '2' && this.chatList.length != 0) { // order chat

			let chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
				timestamp: Number(this.chatList[this.chatList.length - 1].timestamp) + Number(1),
			}

			this.firebaseService.deleteChat(chatData, '2');

		} else if (this.chatType == '3' && this.chatList.length != 0) { // product chat

			let chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				timestamp: Number(this.chatList[this.chatList.length - 1].timestamp) + Number(1),
			}

			this.firebaseService.deleteChat(chatData, '3');

		} else {
		}

	}
	// End

	// Confirm before block
	blockConfirm() {
		Swal.fire({
			title: this.translate.instant('MYORDER.are_you_sure'),
			text: this.translate.instant('CHAT.block_confirm_text'),
			icon: 'warning',
			showCancelButton: true,
			confirmButtonText: this.translate.instant('CHAT.yes_i_am_sure'),
			cancelButtonText: this.translate.instant('CHAT.no_cancel_it'),
			reverseButtons: true
		}).then((result) => {
			if (result.value) {
				this.blockUser();
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				Swal.fire(
					this.translate.instant('SELLER_SIDE.cancelled'),
					this.translate.instant('CHAT.your_blocked_is_safe'),
					this.translate.instant('MYORDER.error_txt')
				)
			}
		});
	}
	// End

	// For block user
	blockUser() {
		let chatData = {
			sellerId: this.sellerId,
			buyerId: this.buyerId,
		}

		this.firebaseService.blockUser(chatData);
	}
	// End

	// For unblock user
	unBlockUser() {
		let chatData = {
			sellerId: this.sellerId,
			buyerId: this.buyerId,
		}

		this.firebaseService.unBlockUser(chatData);
	}
	// End

	// For block status
	getBlockStatus() {
		let chatData = {
			sellerId: this.sellerId,
			buyerId: this.buyerId,
		}
		this.firebaseService.getBlockStatus(chatData).subscribe(data => {

			let block;
			if (data.payload.val()) {
				block = data.payload.val();

				if (block.blocked_by == this.sellerId || block.blocked_by == "Both") {
					this.chatBoxAllow = false;
					this.blockMessage = this.translate.instant('CHAT.you_have_blocked_this_user');
					this.blockByMe = true;
				} else {
					this.chatBoxAllow = false;
					this.blockByMe = false;
					this.blockMessage = this.translate.instant('CHAT.you_are_blocked_by_this_user');
				}

			} else {
				this.chatBoxAllow = true;
				this.blockByMe = false;
			}

		});
	}
	// End

	typeTimer: any;
	doneTypeInterval: any = 1200;

	typing() {

		let chatData;
		if (this.chatType == '2') { // for order
			chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				orderId: this.chatData.orderID,
			}

		} else if (this.chatType == '3') { // for product
			chatData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
			}
		}

		this.firebaseService.setTyping(chatData, this.chatType, 1);

		clearTimeout(this.typeTimer);
		this.typeTimer = setTimeout(() => {
			this.firebaseService.setTyping(chatData, this.chatType, 0);
		}, this.doneTypeInterval);

	}

	//Reset Counter form 
	resetCounterForm(offerForm) {
		offerForm.reset();
	}
	/* 
	* Counter offer
	* @param offerForm
	*/
	counterOffer(offerForm: NgForm) {
		if (offerForm.valid) {

			if (Number(this.offer.product_price) <= Number(offerForm.value.counterPrice)) {
				this.commonService.toastError(this.translate.instant('CHAT.counter_price_should_be_less_than_product_price'));
				return false;
			}
			if (offerForm.value.counterPrice.match('^[0-9]{1,7}((\.[0-9]{1,2}))?$') == null) {
				this.commonService.toastError(this.translate.instant('CHAT.counter_price_only_allowed_decimal_values')); return false;
			}

			let counterPriceString = parseFloat(offerForm.value.counterPrice).toFixed(2);

			let offerData = {
				sellerId: this.sellerId,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				counterPrice: counterPriceString.toString(),
			}

			this.firebaseService.counterOfferStore(offerData);
			$('#counterOfferModal').modal('hide');
			this.commonService.toastSuccess(this.translate.instant('CHAT.counter_offer_added_successfully'));
			this.resetCounterForm(offerForm);

			// send message when counter price send.
			let chatData = {
				sellerId: this.sellerId,
				sellerName: this.sellerName,
				buyerId: this.buyerId,
				productId: this.chatData.productID,
				productName: this.chatData.name,
				firebaseToken: this.firebaseToken,
				isOffer: 1,
				message: this.translate.instant('CHAT.you_have_a_counter_request')
			}

			this.firebaseService.sendProductMsg(chatData);

		} else {
			if (typeof offerForm.value.counterPrice == 'undefined' || offerForm.value.counterPrice == null) {
				this.commonService.toastError(this.translate.instant('CHAT.counter_offer_price_is_required'));
			} else if (offerForm.value.counterPrice.match(/^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/igm) == null) {
				this.commonService.toastError(this.translate.instant('CHAT.counter_offer_price_must_be_vaild'));
			}
		}
	}

}
