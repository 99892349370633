import { Component, OnInit, OnDestroy } from '@angular/core';
import { faImages, faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { AuthService } from '../../auth/auth.service';
import { SellerService } from '../services/seller.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const scrollDown: any;

@Component({
  selector: 'app-dispute',
  templateUrl: './dispute.component.html',
  styleUrls: ['./dispute.component.css']
})
export class DisputeComponent implements OnInit, OnDestroy {
  faImages = faImages;
  faPaperPlane = faPaperPlane;
  disputeNode: any;
  disputeSubscription: Subscription;
  disputeChatSubscription: Subscription;
  chatList: any = [];
  currentUserId: any;
  message: any = "";
  imagePreview: any = "";
  DisputeFBData: any = {
    video_url: "",
    closed: 0
  };

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private sellerService: SellerService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private storage: AngularFireStorage,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }
  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Dispute Chat');
    this.disputeNode = this.activatedRoute.snapshot.paramMap.get("id");

    this.currentUserId = this.authService.getCurrentUserId();
    this.getDisputeDetail();
    this.getDisputeChat();
  }

  ngOnDestroy(): void {
    this.disputeSubscription.unsubscribe();
    this.disputeChatSubscription.unsubscribe();
  }

  getDisputeDetail() {
    if (this.disputeNode != null) {
      this.disputeSubscription = this.firebaseService.getDisputeDetail(this.disputeNode).subscribe(data => {
        if (data.key == "detail") {
          this.DisputeFBData = data.payload.val();
        } else {
          // this.router.navigate(['../seller/my-orders']);
        }
      });

    } else {
      // this.router.navigate(['../seller/my-orders']);
    }
  }

  getDisputeChat() {
    this.disputeChatSubscription = this.firebaseService.getDisputeChat(this.disputeNode).subscribe(data => {
      scrollDown();
      this.chatList = data;
      this.chatList.forEach(element => {
        element.centerTime = this.commonService.chatGroupTime(element.timestamp);
      });

    });
  }

  getTimeAgo(timestamp) {
    return this.commonService.chatHistoryTimeAgo(timestamp);
  }

  imagePreviewModal(imageSrc) {
    $('#imgFullViewModal').modal('show');
    this.imagePreview = imageSrc;
  }

  sendMsg() {
    if (this.message.trim()) {

      let disputeArray = this.disputeNode.split('-');

      let chatMessage = {
        buyer_id: disputeArray[2],
        image: 0,
        image_url: "",
        last_sender_id: disputeArray[1],
        message: this.message.trim(),
        msg_read_tick: 0,
        order_id: disputeArray[0],
        seller_id: disputeArray[1],
        timestamp: Date.now()
      }

      var orderDetails ="";
      this.firebaseService.sendDisputeMessage(this.disputeNode, chatMessage,orderDetails);



    }

    this.message = "";
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      this.commonService.showLoader();

      const file = event.target.files[0];
      var ext = file.name.split('.').pop().toLowerCase();

      if ($.inArray(ext, ['gif', 'png', 'jpg', 'jpeg']) == -1) {
        this.commonService.hideLoader();
        Swal.fire(this.translate.instant('MYORDER.ops_txt'), this.translate.instant('MYORDER.invalid_extension'), this.translate.instant('MYORDER.error_txt'));
        return false;
      }
      const filePath = environment.storageBucketFile + Date.now();
      const fileRef = this.storage.ref(filePath);

      const task = this.storage.upload(filePath, file).then(() => {
        const ref = this.storage.ref(filePath);
        const downloadURL = ref.getDownloadURL().subscribe(url => {
          this.commonService.hideLoader();

          let disputeArray = this.disputeNode.split('-');

          let chatMessage = {
            buyer_id: disputeArray[2],
            image: 1,
            image_url: url,
            last_sender_id: disputeArray[1],
            message: "",
            msg_read_tick: 0,
            order_id: disputeArray[0],
            seller_id: disputeArray[1],
            timestamp: Date.now()
          }

          // this.firebaseService.sendDisputeMessage(this.disputeNode, chatMessage);
          var orderDetails ="";
          this.firebaseService.sendDisputeMessage(this.disputeNode, chatMessage,orderDetails);


        });
      });
    }
  }

}
