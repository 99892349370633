import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { faAngleDoubleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { BuyerService } from '../services/buyer.service';
import { AuthService } from '../../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ShopService } from '../../services/shop.service';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { FormBuilder, Validators, FormGroup, FormControl, FormArray } from '@angular/forms';


declare let fbq: Function;
declare const $: any;
declare const hideLoader:any;
declare const showLoader:any;
declare var saveIntent: any;
declare var Stripe; // : stripe.StripeStatic;
declare var paypal: { Buttons: (arg0: { createOrder: (data: any, actions: { order: { create: (arg0: { purchase_units: { description: string; amount: { currency_code: string; value: number; }; }[]; }) => any; }; }) => any; onApprove: (data: any, actions: { order: { capture: () => any; }; }) => Promise<void>; onError: (err: any) => void; }) => { (): any; new(): any; render: { (arg0: any): void; new(): any; }; }; }

@Component({
	selector: 'app-payment-option',
	templateUrl: './payment-option.component.html',
	styleUrls: ['./payment-option.component.css']
})



export class PaymentOptionComponent implements OnInit, AfterViewInit {
	@ViewChild('paypal', { static: true })
	paypalElement!: ElementRef;
	faAngleDoubleRight = faAngleDoubleRight;
	faCircle = faCircle;
	checkCubaCountry: boolean = true;
	pricing_detail: any = {};
	tax_detail: any = {};
	offer_item_list: any = {};
	shippingAddress: any = {};
	userDetails: any = {};
	cardListCount: number = 0;
	payment: any = '1';
	isOffer: any;
	offerRemove: any;
	cardList: any = [];
	verifyPrice: any = "";
	cardID: any;
	stripeCardId: any = "";
	cardSection: boolean = false;
	stripe;
	card;
	cardErrors;
	referralDetail: any;
	@ViewChild('cardElement', { static: false }) cardElement: ElementRef;
	coupon: any;
	coupon1: any;
	coupon_code_id;
	coupon2: any;
	paymentTypeData: any;
	type: any;
	influencer_code_amount: any;
	influencer_code_percentage: any;
	is_used: any;
	apply_coupon_amount: any;
	apply_coupon_percentage: any;
	assign_coupon_amount: any;
	assign_coupon_percentage: any;
	paidFor = false;
	browserName: any;
	tax_detailPercent: any;
	intent: any;
	paymentMethod: any;
	intent_id: any;
	proccessingFee: any;
	pickup: any = 0;
	pickup_active: any = 0;
	is_direct_payment_option: any;

	cod: any = 0;
	cardVarification: any;
	offer_id: any;
	addressId: string;
	cartlistCount: any;
	newamount: any;
	grand_total_with_fee: any;
	paymentIntent: any;
	grandTotalPrice: any;
	shipingAddressId: any;
	getDeviceId: any;
	amountSubtotal: any;
	offerType: any;
	payementLinkData: any;
	bankOrderCode: string;
	reference: string;
	state: string;
	bmsForm: FormGroup;
	showInvalidLengthError = false;

	constructor(
		private commonService: CommonService,
		private buyerService: BuyerService,
		private router: Router,
		private authService: AuthService,
		private firebaseService: FirebaseService,
		private translate: TranslateService,
		private shopService: ShopService,
		private route: ActivatedRoute,
		private activatedRoute: ActivatedRoute,
        private formBuilder: FormBuilder

	) { translate.use(this.commonService.getLanguage()); }

	ngOnInit() {

		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Payment');
		this.shippingAddress = this.authService.getUserShippingAddress();
		this.userDetails = this.authService.getUserDetails();

		this.coupon_code_id = localStorage.getItem("coupon");
		this.pickup_active = localStorage.getItem("pickup");
		let userAgent = navigator.userAgent;
		let browserName;

		this.route.queryParams.subscribe(params => {
			this.bankOrderCode = params['bankOrderCode'];
			this.reference = params['reference'];
			this.state = params['state'];

		
		});

		if (userAgent.match(/chrome|chromium|crios/i)) {
			browserName = "chrome";
		} else if (userAgent.match(/firefox|fxios/i)) {
			browserName = "firefox";
		} else if (userAgent.match(/safari/i)) {
			browserName = "safari";
		} else if (userAgent.match(/opr\//i)) {
			browserName = "opera";
		} else if (userAgent.match(/edg/i)) {
			browserName = "edge";
		} else {
			browserName = "No browser detection";
		}

		this.browserName = browserName;
		this.getCardList();
		this.getPaymentType();

		// online payment option not available for cuba country 
		if (this.userDetails.profile_country_code == 'CU') {
			this.checkCubaCountry = false;
		}
		let stripeCustomerID = this.authService.getStripeCustomerID();
		if (stripeCustomerID == false) {
			// this.addStripeCustomer();
		}
		const str = this.router.url;
		const regex = RegExp('/payment-option/offer');


		if (regex.test(str) == true) {
			this.isOffer = "1";

			this.route.paramMap.subscribe(params => {
				var id = params.get('id'); //get id from url 
				this.offer_id = id;
				this.getCartList(); // get cart list with offer

			});

		} else {
			this.getCartList(); // get cart  list without offer 
			this.isOffer = "0";

		}

		this.activatedRoute.paramMap.subscribe(param => {
			this.cartlistCount = param.get('count');
			//get selected address of user
			const userAddress = JSON.parse(localStorage.getItem(environment.dataStore.userAddress));
			if(userAddress != null){
				this.addressId = userAddress.userAddressId;
				this.shipingAddressId = userAddress.addressID;
			}
			
		});

		this.getPaymentType();  // get payment type 

		// Add address form validations
		this.bmsForm = this.formBuilder.group({
			card_holder_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50), Validators.pattern('^[a-zA-Z ]+')]],
			business_zip_code: ['', [Validators.required, Validators.minLength(5)]],
			card_number: [
				'',
				[
				  Validators.required,
				//   Validators.pattern(/^\d{4}( \d{4}){2,3}$/),
				],
			  ],
			cvn: ['', [Validators.required, Validators.minLength(3), Validators.maxLength(4)]],
			month: ['', [Validators.required, Validators.minLength(2)]],
			year: ['', [Validators.required, Validators.minLength(2)]]
			
		});
		// End
		

	}

	onKeyUpCardNumber(event: Event): void {
		const input = event.target as HTMLInputElement;
	  
		// Format the value: Remove non-numeric characters
		let numericValue = input.value.replace(/[^0-9]/g, ''); // Only numeric characters
	  
		// Limit numeric value length to 16 (without spaces)
		numericValue = numericValue.substring(0, 16);
	  
		// Add spaces every 4 digits
		const formattedValue = numericValue.replace(/(.{4})(?=.)/g, '$1 ');
	  
		// Update the input field with the formatted value
		input.value = formattedValue;
	  
		// Update the form control value with the formatted value
		const cardNumberControl = this.bmsForm.get('card_number');
		if (cardNumberControl) {
		  cardNumberControl.setValue(formattedValue, { emitEvent: false });
		}
	  
		// Validate the card number length (between 14 and 16 digits, excluding spaces)
		this.showInvalidLengthError = numericValue.length < 14 || numericValue.length > 16;
	}
	  
	// For add new card form validation
	get card_holder_name() { return this.bmsForm.get('card_holder_name'); }
	get business_zip_code() { return this.bmsForm.get('business_zip_code'); }
	get card_number() { return this.bmsForm.get('card_number'); }
	get cvn() { return this.bmsForm.get('cvn'); }
	get month() { return this.bmsForm.get('month'); }
	get year() { return this.bmsForm.get('year'); }
	// End 

	// For check form is valid
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
	// End

	trimValue(formControl) {
        ((typeof formControl.value) && formControl.value.trim() == "") ?
            formControl.setValue("") : formControl.setValue(formControl.value);
    }
    // End

	addBms(){

		if (this.bmsForm.valid) {
            this.commonService.showLoader();

			var currentUserId = this.authService.getCurrentUserId(); //current user ID
			this.buyerService.checkProduct(currentUserId, this.cartlistCount, this.offerType).subscribe( //  call api for check product 
				(productCheck) => {
	
					if (productCheck.flag == 0) {
						this.confirmationPopup();
					} else {
						if (this.pickup_active == 1) {
							var seller_add = "";
				
						} else {
							seller_add = this.authService.getUserAddress()
						}
						this.commonService.showLoader();
						var formData = new FormData();
						
						formData.append('coupon_code', this.coupon_code_id);
						formData.append('shipping_address_id', seller_add);
						formData.append('payment_type', this.type);
						formData.append('is_pickup', this.pickup_active);
						formData.append('is_offer', this.isOffer);
						formData.append('for_web', '1');
						formData.append('card_holder_name', this.bmsForm.get('card_holder_name').value);
						formData.append('zip_code', this.bmsForm.get('business_zip_code').value);
						formData.append('card_number', this.bmsForm.get('card_number').value.replace(/\s+/g, '').trim());
						formData.append('cvn', this.bmsForm.get('cvn').value);
						formData.append('exp_date', this.bmsForm.get('month').value + this.bmsForm.get('year').value);

						// formData.append('exp_date', this.bmsForm.get('card_number').value);
	
						if (this.offer_id == undefined) {
							var offer_id = 0;
						} else {
							offer_id = this.offer_id
						}
						// formData.append('offer_item_id', this.offer_id);
						formData.append('offer_item_id', this.offer_id);

						// formData.forEach((value, key) => {
						// 	console.log(key + " " + value)
						// });
	
						this.buyerService.addBms(formData).subscribe((data) => {
							// console.log('data', data);
							if(data.data.ResponseCode == 200){
								$('#bmsType').modal('hide');
								this.pay("",data.data, data.data.ServiceReferenceNumber);
							}else{
								
								this.commonService.toastError(data.data.verbiage);
							}

							localStorage.setItem('pickup', '0'); // set pickup option in local 
							this.commonService.hideLoader();
							
						},err=>{
							console.log('errorone', err)
						})
					}
				})

			} else {
				// validate all form fields
				this.validateAllFormFields(this.bmsForm);
        	}
		
		
		// return false;
        // if (this.bmsForm.valid) {
        //     this.commonService.showLoader();

        //     var formData = new FormData();
        //     formData.append('card_holder_name', this.bmsForm.get('card_holder_name').value);
        //     formData.append('zip_code', this.bmsForm.get('business_zip_code').value);
        //     formData.append('card_number', this.bmsForm.get('card_number').value);
        //     formData.append('cvn', '123');
        //     formData.append('exp_date', '1225');
          

        //     this.buyerService.addBms(formData).subscribe((data) => {
        //         this.commonService.hideLoader();

        //         if (data.status == "fail") {
        //             this.commonService.toastError(data.message);
        //         } else {
        //             this.commonService.toastSuccess(data.message);
                  
        //         }

        //     }, err => {
        //         this.commonService.hideLoader();
        //         this.commonService.errorhandler(err);
        //     });

        // } else {
        //     // validate all form fields
        //     this.validateAllFormFields(this.bmsForm);
        // }
	}
	

	ngAfterViewInit() {

		// this.bankOrderCode= "hiiii";
		if(this.bankOrderCode != undefined){
			console.log('Hello');
			this.type = 6;
			// this.isOffer = '1';
			$('#Tropipay').prop('checked', true);
			// $('#Tropipay').click();

			// showLoader();
			console.log('Hello123' )
			setTimeout(() => {
				console.log('Hello1235' )

				this.pay();

			}, 1000);
			console.log('check condition');

			
		}
		this.getPaymentType();

		// Get device id
		this.getDeviceId = this.authService.getDeviceId();
		// End

		// stripe payment
		this.stripe = Stripe(environment.striePkKey);
		const elements = this.stripe.elements();

		this.card = elements.create('card');
		this.card.mount(this.cardElement.nativeElement);

		this.card.addEventListener('change', ({ error }) => {
			if(this.commonService.getLanguage() == 'en'){
				this.cardErrors = error && error.message;

			}else{
				this.cardErrors = error && 'Su número de tarjeta está incompleto.';

			}
		});
	}

	// Save payment intent function
	savePaymentIntent() {
		var formData = new FormData();
		formData.append('shipping_add_id', this.shipingAddressId);
		formData.append('coupon_code', this.coupon_code_id);
		formData.append('payment_intent', this.intent_id);
		this.authService.createIntent(formData).subscribe((data) => {
			document.getElementById('payment-request-button').style.display = 'none';
			this.router.navigate(['/confirmation']);


		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}
	// End intent function


	// Google pay payment code with stripe
	async googlePay() {
		const elements = this.stripe.elements();
		const authToken = this.authService.getAuthorizationToken();
		const lang = this.authService.getLanguage();
		const thiscoupon_code_id = this.coupon_code_id;
		const thispaymentIntentid = this.intent_id;
		const thisaddressId = this.shipingAddressId;
		const thiscartlistCount = this.cartlistCount;
		const thisgetDeviceId = this.getDeviceId;
		const thistype = this.type;
		const paymentMode = this.type;
		const shippingCharge = this.pricing_detail.shipping_charge;
		const thispickupActive = this.pickup_active;
		const thisisOffer = this.isOffer;
		const thisgrandTotalPrice = this.grandTotalPrice;
		const thisamountSubtotal = this.amountSubtotal;
		var payment_intent = $("#payment_intent").val();

		var stripe = Stripe(environment.striePkKey, {
			apiVersion: "2020-08-27",
		});
		var amountss = this.grandTotalPrice * 100;
		var paymentRequest = await stripe.paymentRequest({
			country: 'US',
			currency: 'usd',
			total: {
				label: 'Total',
				amount: amountss,
			},
			requestPayerName: true,
			requestPayerEmail: true,
		});
		var prButton = elements.create('paymentRequestButton', {
			paymentRequest: paymentRequest,
		});

		// Check the availability of the Payment Request API first.
		await paymentRequest.canMakePayment().then(function (result) {
			if (result) {
				prButton.mount('#payment-request-button');
			} else {
				document.getElementById('payment-request-button').style.display = 'none';
			}
		});

		// Payement request and confirm google payment
		paymentRequest.on('paymentmethod', async function (ev) {

			var payment_intent = $("#payment_intent").val();
			// Confirm the PaymentIntent without handling potential next actions (yet).
			stripe.confirmCardPayment(payment_intent,
				{ payment_method: ev.paymentMethod.id },
				{ handleActions: false }
			).then(async (confirmResult) => {
				if (confirmResult.error) {
					// Report to the browser that the payment failed, prompting it to
					// re-show the payment interface, or show an error message and close
					// the payment interface.
					ev.complete('fail');

				} else {
					// Report to the browser that the confirmation was successful, prompting
					// it to close the browser payment method collection interface.
					await ev.complete('success');

					await saveIntent(authToken, lang, thiscoupon_code_id, thispaymentIntentid, thisaddressId, thiscartlistCount, thisgetDeviceId, thistype, paymentMode, shippingCharge, thispickupActive, thisisOffer, thisgrandTotalPrice, thisamountSubtotal);



				}
				if (await ev.complete('success')) {
					alert('ev success ====')
				}
			})
		});

	}
	// End Google pay payment code

	// Referel code Function
	getReferralCode() {
		const userDetails = JSON.parse(localStorage.getItem(environment.dataStore.userData));
		var id = userDetails.user_detail.userID;

		this.authService.getRefferal(this.pricing_detail.total_amount_subtotal).subscribe((data) => {

			if (data.data.referral_data == 0) {
				this.is_used = '0';
				var influencer_code = '0';
			} else {

				const userDetails = JSON.parse(localStorage.getItem(environment.dataStore.userData));

				this.is_used = data.data.referral_data.is_used;
				influencer_code = data.data.referral_data.referral_code_id;


				if (influencer_code == null) {
					this.influencer_code_amount = 0;
				} else {
					this.influencer_code_amount = this.influencer_code_amount;


				}
				this.referralDetail = data.data.referral_data;
			}

		})

	}
	// Referel code function end


	// Paypal payment function
	paypal() {
		paypal
			.Buttons({
				createOrder: (data: any, actions: { order: { create: (arg0: { purchase_units: { description: string; amount: { currency_code: string; value: number; }; }[]; }) => any; }; }) => {
					return actions.order.create({
						purchase_units: [
							{
								description: 'hello',
								amount: {
									currency_code: 'USD',
									value: this.pricing_detail.grand_total
								}
							}
						]
					});
				},
				onApprove: async (data: any, actions: { order: { capture: () => any; }; }) => {
					const order = await actions.order.capture();
					this.paidFor = true;
					var paypal_transaction_id = order.purchase_units[0].payments.captures[0].id;


					var formData = new FormData();
					formData.append('coupon_code', this.coupon_code_id);

					// remove for v3 version 
					//=======================================================================================
					// formData.append('payment_mode', this.type);
					// formData.append('shipping_charges', this.pricing_detail.shipping_charge);
					// formData.append('total_amount', this.pricing_detail.grand_total);
					// formData.append('subtotal_amount', this.pricing_detail.total_amount_subtotal);

					//=========================================================================================



					formData.append('shipping_address_id', this.authService.getUserAddress());
					formData.append('payment_type', this.type);
					formData.append('transaction_response', JSON.stringify(order));
					formData.append('status', order.status);
					formData.append('paypal_transaction_id', paypal_transaction_id);
					formData.append('is_offer', this.isOffer);
					formData.append('is_pickup', this.pickup_active);


					if (this.offer_id == undefined) {
						var offer_id = 0;
					} else {
						offer_id = this.offer_id
					}
					formData.append('offer_item_id', this.offer_id);


					// if (this.type == 3) {
					// 	formData.append('stripe_card_id', this.stripeCardId);

					// }


					formData.append('is_return', "0");
					if (order.status == "COMPLETED") {
						this.commonService.showLoader();

						this.authService.Payment_new(formData).subscribe((data) => {
							this.commonService.hideLoader();
							this.commonService.toastSuccess(data.message);
							localStorage.setItem('pickup', '0'); // set pickup option in local storage
							this.buyerService.confirmationProductData = data.data.order_detail;
							this.router.navigate(['/confirmation']);
						})

					}


				},
				onError: (err: any) => {
					console.log('err', err);
				}
			}).render(
				'#paypal-button-container'
			);


	}

	// End paypal payment 

	// Confirm card for payment
	async confmIntentCard() {
		// this.commonService.showLoader();
		showLoader();
		if (this.cardList.length == 0 && this.type == '3') {
			hideLoader();

			this.commonService.toastError(this.translate.instant('MYORDER.please_add_atleast_one_card'));
			// $('#addCard').modal('show');
			return false;

		}

		if (this.stripeCardId == "" && this.type == '3') {
			hideLoader();

			this.commonService.toastError(this.translate.instant('MYORDER.please_select_card'));
			return false;
		}

		try {
			// hideLoader();
			 await this.stripe.confirmCardPayment(this.intent, {
				payment_method: this.paymentMethod,
				
			}).then(function(result) {

				console.log('result.paymentIntent.status', result.paymentIntent.status)
				if(result.paymentIntent.status == 'succeeded'){

					(<HTMLInputElement>document.getElementById('status')).value = "success";

				}else{
				}

			}).then((res)=>{

				if((<HTMLInputElement>document.getElementById('status')).value=="success"){
					this.pay();
				}else{
				hideLoader();

					// this.ngOnInit();
				}
			  });

		} catch (error) {
			hideLoader();
			console.log('error', error)
		}

	}

	
	// End card confirmation 

	// Direct payment function
	directPayPayment() {					
		$('#paypal-button-container').html('');


		const str = this.router.url;
		const regex = RegExp('/payment-option/offer');

		if (regex.test(str) == true) {
			this.isOffer = "1";

			this.type = 9;
			this.route.paramMap.subscribe(params => {

				var id = params.get('id');
				this.offer_id = id;

				this.getCartList();


			});

		} else {
			this.getCartList();
			this.isOffer = "0";

		}
	}
	// End Direct payment function



	//open popup for delete 
	// check product is available or not if product is not available than redirect to card list or offer list 
	confirmation() {
		$('#deleteItem').modal('hide');
		// this.ngOnInit();

		if (this.isOffer == 1) {
			this.router.navigate(['/product-offers']);

		} else {
			this.router.navigate(['/cart']);

		}

	}

	// function for open confirmation popup 
	confirmationPopup() {
		$('#deleteItem').modal('show');

	}


	// function for check product is available or not 
	ValidateApi(type = "") {

		if(this.isOffer == '1'){
			this.offerType = 1;
		}else{
			this.offerType = 0;

		}
		var currentUserId = this.authService.getCurrentUserId(); //current user ID

		this.buyerService.checkProduct(currentUserId, this.cartlistCount, this.offerType).subscribe( //  call api for check product 
			(productCheck) => {
				if (productCheck.flag == 0) {
					this.confirmationPopup();
				} else {
					this.getCartList()
					$('#paypal-button-container').html('');
					if (this.type == 1) {
						this.getPaymentIntent();
						this.googlePay();

					}

					if (this.type == 2) {
						this.paypal();

					}
					if (this.type == 6) {
						this.tropipay();

					}
					this.showCardList();

				}

			})
	}

	tropipay(){
		// console.log('this.offer_i',this.offer_id);
		// return false;
		var currentUserId = this.authService.getCurrentUserId(); //current user ID
		this.buyerService.checkProduct(currentUserId, this.cartlistCount, this.offerType).subscribe( //  call api for check product 
			(productCheck) => {

				if (productCheck.flag == 0) {
					this.confirmationPopup();
				} else {
					if (this.pickup_active == 1) {
						var seller_add = "";
			
					} else {
						seller_add = this.authService.getUserAddress()
					}
					this.commonService.showLoader();
					var formData = new FormData();
					formData.append('coupon_code', this.coupon_code_id);
					formData.append('shipping_address_id', seller_add);
					formData.append('payment_type', this.type);
					formData.append('is_pickup', this.pickup_active);
					formData.append('is_offer', this.isOffer);
					formData.append('for_web', '1');

					// alert('this.isOffer'+ this.isOffer);

					if (this.offer_id == undefined) {
						var offer_id = 0;
					} else {
						offer_id = this.offer_id
					}
					formData.append('offer_item_id', this.offer_id);

				
					this.authService.tropiPayLink(formData).subscribe((data) => {
						console.log('payementLinkData',data.data.error);
						if(data.data.error != undefined){
							// this.commonService.toastError(this.translate.instant('MYORDER.please_add_atleast_one_card'));
							this.commonService.toastError('Payment amount exceeds the maximum allowed limit ,Please try a smaller amount.');

						}
						console.log('payementLinkData',data);
						localStorage.setItem('pickup', '0'); // set pickup option in local storage
						this.commonService.hideLoader();
						this.payementLinkData = data.data.shortUrl;
						
					})
				}
			})
	}
	tropiPayUrl(){
		if(this.payementLinkData != undefined){
			window.location.href = this.payementLinkData ;

		}

		// this.router.navigate([this.payementLinkData]);
		// alert('url');
	}

	// Payment api function
	pay(type = "",bms_success_data = "",bms_service_reference_number = "") {
	
		this.commonService.showLoader();
		let  totalCouponAmonut = 0;
		totalCouponAmonut =  Number(this.pricing_detail.coupon1_amount) + Number(this.pricing_detail.coupon2_amount) + Number(this.pricing_detail.referral_code_amount);
			let subtotal = Number(this.pricing_detail.total_amount_subtotal) - (this.pricing_detail.admin_discount_amount)
			const couponAmount = Number(totalCouponAmonut);
			if (subtotal <= couponAmount) {
				this.pricing_detail.tax_amount = 0;
				this.pricing_detail.shipping_charge = 0;
				this.pricing_detail.grand_total = 0;




			}

			if (this.pricing_detail.grand_total < 0.5) {
				this.commonService.toastError(this.translate.instant('CHECKOUT.price_issue'));
				return false;
	
			}
		if (type != "") {
			this.type = '9';
		}
		if (this.cardList.length == 0 && this.type == '3') {
			this.commonService.toastError(this.translate.instant('MYORDER.please_add_atleast_one_card'));
			// $('#addCard').modal('show');
			return false;

		}

		if (this.stripeCardId == "" && this.type == '3') {
			this.commonService.toastError(this.translate.instant('MYORDER.please_select_card'));
			return false;
		}

		if (this.pickup_active == 1) {
			var seller_add = "";

		} else {
			seller_add = this.authService.getUserAddress()
		}

		var formData = new FormData();
		formData.append('coupon_code', this.coupon_code_id);


		//===========Remove for v3 version==========================================
		// formData.append('payment_mode', this.type);
		// formData.append('shipping_charges', this.pricing_detail.shipping_charge);
		// formData.append('total_amount', this.pricing_detail.grand_total);
		// formData.append('subtotal_amount', this.pricing_detail.total_amount_subtotal);

		//===========================================================================

		formData.append('shipping_address_id', seller_add);
		formData.append('payment_type', this.type);
		formData.append('is_pickup', this.pickup_active);
		formData.append('is_offer', this.isOffer);
		
		if(this.type == "7"){
			
			formData.append('transaction_response', JSON.stringify(bms_success_data));
			formData.append('service_reference_number', bms_service_reference_number);
		}

		if (this.offer_id == undefined) {
			var offer_id = 0;
			this.offerType  = 0;
		} else {
			offer_id = this.offer_id
			this.offerType  = 1;

		}
		formData.append('offer_item_id', this.offer_id);

		// if (this.type == 3) {
		// 	formData.append('stripe_card_id', this.stripeCardId);
		// 	formData.append('payment_intent', this.intent_id)

		// }

		
		formData.append('is_return', "0");

		var currentUserId = this.authService.getCurrentUserId(); //current user ID

		this.buyerService.checkProduct(currentUserId, this.cartlistCount, this.offerType).subscribe( //  call api for check product 
			(productCheck) => {

				if (productCheck.flag == 0) {
					this.confirmationPopup();
				} else {
					this.commonService.showLoader();

					this.authService.Payment_new(formData).subscribe((data) => {
						$('#directPay').modal('hide');
						localStorage.setItem('pickup', '0'); // set pickup option in local storage

						this.commonService.hideLoader();

						this.paymentTypeData = data.data;
						this.commonService.toastSuccess(data.message);
						this.buyerService.confirmationProductData = data.data.order_detail;
						this.router.navigate(['/confirmation']);
					})
				}
			})


	}
	// End 

	// Inactive direct pay checkbox value
	resetDirectPay() {

		$("input[type=radio][name=card]").prop('checked', false);
		$('#directPay').modal('hide');
		this.type = "";
		this.getCartList()

	}
	// End

	// Payment type api list
	getPaymentType() {

		this.authService.getPaymentType().subscribe((data) => {
			this.paymentTypeData = data.data;

			this.paymentTypeData.map((val) => {


				if (val.payment_name == "stripe" || val.payment_name == "Stripe") {
					if (this.browserName == "safari") {
						val.payment_name = "Apple Pay";
						val.image = "assets/img/common/apple-pay.webp";

					}


					
					else {

						
						val.payment_name = "Google Pay";
						val.image = "assets/img/common/google-pay.webp";


						if (val.payment_name ==  "Google Pay") {
							// remove google from array
							this.paymentTypeData = this.paymentTypeData.filter(object => {
								return object.payment_name !== 'Google Pay';
							});
			
						}


						
					}
				}
			})


			if (this.cod == 0) {
				// remove cod from array
				this.paymentTypeData = this.paymentTypeData.filter(object => {
					return object.payment_type !== '5';
				});

			}

			
		})

	}
	// End Function

	getPaymentTypess(type) {
		this.type = type;
		if (this.isOffer == 1) {
			this.ValidateApi('1')

		} else {
			this.ValidateApi()

		}

	}


	// Get payment intent function
	getPaymentIntent() {

		var formData = new FormData();
		formData.append('coupon_code', this.coupon_code_id);

		//========================Remove for v3 version====================================
		// formData.append('payment_mode', this.type);
		// formData.append('shipping_charges', this.pricing_detail.shipping_charge);
		// formData.append('total_amount', this.pricing_detail.grand_total);
		// formData.append('subtotal_amount', this.pricing_detail.total_amount_subtotal);
		//===================================================================================


		formData.append('shipping_address_id', this.authService.getUserAddress());
		formData.append('payment_type', this.type);
		formData.append('is_offer', this.isOffer);
		formData.append('is_pickup', this.pickup_active);


		if (this.offer_id == undefined) {
			var offer_id = 0;
		} else {
			offer_id = this.offer_id
		}
		formData.append('offer_item_id', this.offer_id);

		if (this.type == 3) {
			formData.append('stripe_card_id', this.stripeCardId);

		}
		formData.append('is_return', "0");

		// return false;

		this.commonService.showLoader();
		this.authService.createIntent(formData).subscribe((data) => {
			this.intent = data.data.data.client_secret;
			this.intent_id = data.data.data.id;
			this.paymentIntent = data.data.data.client_secret;
			this.paymentMethod = data.data.data.payment_method;
			this.commonService.hideLoader();
			this.googlePay();

		})


	}
	// End 

	// Cart payment details 
	getCartList(): void {

		this.commonService.showLoader();
		var formData = new FormData();
		formData.append('coupon_code', this.coupon_code_id);
		formData.append('is_offer', this.isOffer);
		formData.append('is_pickup', this.pickup_active);


		if (this.offer_id == undefined) {
			var offer_id = 0;
		} else {
			offer_id = this.offer_id
		}
		formData.append('offer_item_id', this.offer_id);
		if (this.type == 2 || this.type == 5 || this.type == 1 || this.type == 3) {
			formData.append('payment_type', this.type);

		}

		if (this.type == 9) {
			formData.append('payment_type', '9');

		}

		if (this.type == 6) {
			formData.append('payment_type', '6');

		}

		if (this.type == 7) {
			formData.append('payment_type', '7');

		}



		this.buyerService.getUserCartList1(formData).subscribe(
			(cartlistData) => {

				console.log('cartlistData', cartlistData);

			
			if(cartlistData.data.total_records == '0'){
				// alert('Hello');
				this.router.navigate(['/shop']); // navigate to home page 

			}
			// if()
				this.cartlistCount = cartlistData.data.total_records; //total cart 

				var asd = cartlistData.data.cart_list.filter((val) => val.seller_id == cartlistData.data.cart_list[0].seller_id)

				if (cartlistData.data.cart_list.length == asd.length && cartlistData.data.cart_list[0].is_pick_up_option == '1') {
					this.pickup = 1;

				} else {
					this.pickup = 0;

				}


				if (cartlistData.data.amount_details.cod_option == 1 &&cartlistData.data.cart_list.length == asd.length ) {
					this.cod = 1;


				} else {
					this.cod = 0;


				}

				// cod one case remaing array remove 
				if (cartlistData.data.amount_details.direct_option == 1 && cartlistData.data.cart_list.length == asd.length) {
					this.is_direct_payment_option = 1;


				} else {
					this.is_direct_payment_option = 0;

				}







				// =======================================================================================================================================
				/// ==========================================================================================================================================

				this.commonService.hideLoader();

				if (cartlistData.status == 'success') {
					this.pricing_detail = cartlistData.data.amount_details;
					var coupon = cartlistData.data.amount_details.coupon1_percentage;
					this.coupon = coupon.replace(/\.00$/, '');
					this.coupon2 = cartlistData.data.amount_details.coupon2_percentage.replace(/\.00$/, '');
					this.apply_coupon_amount = this.pricing_detail.coupon2_amount;
					this.apply_coupon_percentage = this.pricing_detail.coupon2_percentage;
					this.assign_coupon_amount = this.pricing_detail.coupon1_amount;
					this.assign_coupon_percentage = this.pricing_detail.coupon1_percentage;
					this.influencer_code_amount = this.pricing_detail.referral_code_amount;
					this.influencer_code_percentage = this.pricing_detail.referral_code_percentage;
					this.influencer_code_percentage = this.influencer_code_percentage.replace(/\.00$/, '');
					this.tax_detailPercent = this.pricing_detail.tax_percent.replace(/\.00$/, '');
					this.proccessingFee = this.pricing_detail.processing_fee;
					console.log('this.proccessingFee ', this.proccessingFee);
					this.grandTotalPrice = this.pricing_detail.grand_total;
					this.amountSubtotal = this.pricing_detail.total_amount_subtotal;
					this.getReferralCode(); // get refferal code 
					console.log('this.pricing_detail', this.pricing_detail);

					var totalCouponAmonut = 0;
					totalCouponAmonut =  Number(this.pricing_detail.coupon1_amount) + Number(this.pricing_detail.coupon2_amount) + Number(this.pricing_detail.referral_code_amount);
					let subtotal = Number(this.pricing_detail.total_amount_subtotal) - (this.pricing_detail.admin_discount_amount)
					const couponAmount = Number(totalCouponAmonut);
					if (subtotal <= couponAmount) {
						this.pricing_detail.tax_amount = 0;
						this.pricing_detail.shipping_charge = 0;
						this.pricing_detail.grand_total = 0;




					}


				}

			}, err => {
				this.commonService.hideLoader(); // hide loader 
				this.commonService.errorhandler(err);
				this.router.navigate(['/']);
			});

	}
	// End



	// Offer Cart payment details 
	getOfferCart(offerId): void {
		this.commonService.showLoader();

		this.buyerService.getOfferCart(offerId).subscribe(
			(cartlistData) => { // call api  for get offer detail 
				this.commonService.hideLoader();

				if (cartlistData.status == 'success') {
					this.pricing_detail = cartlistData.data.pricing_detail;
					this.tax_detail = cartlistData.data.tax_detail;
					this.offer_item_list = cartlistData.data.offer_item_list;

					this.offerRemove = {
						sellerId: cartlistData.data.offer_item_list.seller_id,
						buyerId: cartlistData.data.offer_item_list.buyer_id,
						productId: cartlistData.data.offer_item_list.product_id,
					}
				}

			}, err => {
				this.commonService.hideLoader(); // hide loader 
				this.commonService.errorhandler(err); //  err haldle 
				this.router.navigate(['/']); // navigate to home page 
			});

	}
	// End

	// Get stripe card id
	stripeCardId1(id) {
		this.stripeCardId = id;
		this.getPaymentIntent();

	}
	// End

	// Function for add stripe customer
	addStripeCustomer() {
		this.buyerService.createStripeCustomer().subscribe(customerID => {
			if (customerID.status == 'success') {
				let userData = this.authService.getFullUserDetails();
				userData.user_detail.stripe_customer_id = customerID.data.stripe_customer_id;
				localStorage.setItem(environment.dataStore.userData, JSON.stringify(userData));
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}
	// End


	addNewCard() {
		$('#cardAddModal').modal('show');
	}

	//Hide card section
	hideCardOption() {
		this.cardSection = false;
		$('.creditCartOpt').removeClass('selectedCreditOpt');
	}

	// show model direct payment
	directPay() {
		this.commonService.hideLoader();

		let  totalCouponAmonut = 0;
		totalCouponAmonut =  Number(this.pricing_detail.coupon1_amount) + Number(this.pricing_detail.coupon2_amount) + Number(this.pricing_detail.referral_code_amount);
		let subtotal = Number(this.pricing_detail.total_amount_subtotal) - (this.pricing_detail.admin_discount_amount)
			const couponAmount = Number(totalCouponAmonut);
			if (subtotal <= couponAmount) {
				this.pricing_detail.tax_amount = 0;
				this.pricing_detail.shipping_charge = 0;
				this.pricing_detail.grand_total = 0;




			}

			if (this.pricing_detail.grand_total < 0.5) {
				this.commonService.toastError(this.translate.instant('CHECKOUT.price_issue'));
				return false;
	
			}else{
				this.commonService.hideLoader();
				$('#directPay').modal({
					backdrop: 'static',
					keyboard: false
				})
				this.type = 9;
				this.getPaymentTypess(this.type)
				this.hideCardOption();
				this.proccessingFee = 0;
			}

	
		


	}

	// Show card list and add class
	showCardList() {

		if (this.type == 3) {
			this.cardSection = true;
		} else {
			this.cardSection = false;

		}
		$('.creditCartOpt').addClass('selectedCreditOpt');


	}
	// End


	getCardList() {
		// $('.creditCartOpt').addClass('selectedCreditOpt');
		this.cardList = [];
		// this.cardSection = true;
		this.commonService.showLoader();

		this.buyerService.getCardList().subscribe(cardData => {
			


			this.cardVarification = cardData.data.varification_status;
			this.commonService.hideLoader();
			if (cardData.message != "No record found" && cardData.message != 'No se encontró ningún registro') {

				cardData.data.card_list.forEach(card => {
					if (card.is_expired == '0' && card.is_verified == 1) {
						this.cardList.push(card);
					}
				});


			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}

	resetCard() {
		this.card.clear();
	}

	async handleForm(e) {
		this.commonService.showLoader();
		e.preventDefault();

		this.getCardList();

		const { token, error } = await this.stripe.createToken(this.card);

		if (error) {
			this.commonService.hideLoader();
			// Inform the customer that there was an error.
			const cardErrors = error.message;
		} else {

			// Send the token to your server.
			const formData = new FormData();
			formData.append('sourceId', token.id);

			this.buyerService.addCard(formData).subscribe(cardData => {
				this.commonService.hideLoader();

				if (cardData.status == 'fail') {
					this.commonService.toastError(cardData.message);
				} else {
					this.addCard(cardData.data.card_detail);
					$('#addCard').modal('hide');


					if(this.cardVarification == 1){
						setTimeout(() => {
							$('#cardVerifyModal').modal('show');
					
							}, 1000);
					}
					
										

				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
		}
	}

	// Add stripe card 
	addCard(card_detail) {

		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('stripe_card_id', card_detail.id);
		formData.append('card_holder_name', this.authService.getUserDetails().full_name);
		formData.append('card_last_4_digits', card_detail.last4);
		formData.append('card_expiry_month', card_detail.exp_month);
		formData.append('card_expiry_year', card_detail.exp_year);
		formData.append('card_brand_type', card_detail.brand);

		this.buyerService.saveCard(formData).subscribe(cardData => {
			this.commonService.hideLoader();

			if (cardData.status == 'fail') {
				this.commonService.toastError(cardData.message);
			} else {
				$('#cardAddModal').modal('hide');
				this.commonService.toastSuccess(cardData.message);
				this.resetCard();
				this.getCardList();
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// End

	verifyModal(cardID) {
		this.cardID = cardID;
		$('#VaricationAmount').val('');
		$('#cardVerifyModal').modal('show');
	}

	cardVerifyReset() {
		this.verifyPrice = '';
	}



	checkNumberLimit(e) {

		if (e.target.value.length > 4 || e.keyCode == 45) {
			e.preventDefault();
			return false;
		}

	}

	cardVerifySubmit() {

		if (this.verifyPrice != "") {
			this.commonService.showLoader();

			const formData = new FormData();
			formData.append('card_id', this.cardID);
			formData.append('verify_amount', this.verifyPrice);

			this.buyerService.cardVerify(formData).subscribe((data) => {
				this.commonService.hideLoader();

				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					$('#cardVerifyModal').modal('hide');
					// get all card list
					this.getCardList();
				}

			}, err => {
				$('#VaricationAmount').val('');

				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			this.commonService.toastError(this.translate.instant('CUSTOMER.please_enter_deducted_amount'));
		}

	}

	payNow_old() {

		if (this.cardListCount == 0 && this.payment == '2') {
			this.commonService.toastError(this.translate.instant('MYORDER.please_add_atleast_one_card'));
			$('#cardAddModal').modal('show');
		} else {

			this.commonService.showLoader();
			const formData = new FormData();
			formData.append('payment_mode', this.payment);
			formData.append('shipping_address_id', this.authService.getUserAddress());
			formData.append('total_amount', this.pricing_detail.total_amount);
			formData.append('subtotal_amount', this.pricing_detail.subtotal);
			formData.append('shipping_charges', this.pricing_detail.shipping_charge);
			formData.append('tax_percent', this.tax_detail.tax_percent);
			formData.append('tax_amount', this.tax_detail.tax_amount);
			formData.append('is_offer', this.isOffer);
			formData.append('offer_item_id', this.offer_item_list.offerItemID);
			formData.append('is_pickup', this.pickup_active);


			this.buyerService.payment(formData).subscribe(cardData => {
				this.commonService.hideLoader();

				if (cardData.status == 'fail') {
					this.commonService.toastError(cardData.message);
				} else {
					this.commonService.toastSuccess(cardData.message);
					this.buyerService.confirmationProductData = cardData.data.order_detail;

					// for offer
					if (this.isOffer == '1') {
						localStorage.removeItem(environment.dataStore.offerId);
						this.firebaseService.offerRemove(this.offerRemove)
					}

					this.router.navigate(['/confirmation']);
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}
	}

	payNow() {

		let paymentType = (this.payment == '1') ? '1' : '2';
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('payment_mode', paymentType);
		formData.append('shipping_address_id', this.authService.getUserAddress());
		formData.append('total_amount', this.pricing_detail.total_amount);
		formData.append('subtotal_amount', this.pricing_detail.subtotal);
		formData.append('shipping_charges', this.pricing_detail.shipping_charge);
		formData.append('tax_percent', this.tax_detail.tax_percent);
		formData.append('tax_amount', this.tax_detail.tax_amount);
		formData.append('is_offer', this.isOffer);
		formData.append('offer_item_id', this.offer_item_list.offerItemID);
		formData.append('is_pickup', this.pickup_active);


		if (this.payment != '1') {
			formData.append('stripe_card_id', this.stripeCardId);
		}

		this.buyerService.payment(formData).subscribe(cardData => {
			this.commonService.hideLoader();

			if (cardData.status == 'fail') {
				this.commonService.toastError(cardData.message);
			} else {
				this.commonService.toastSuccess(cardData.message);
				this.buyerService.confirmationProductData = cardData.data.order_detail;

				// for offer
				if (this.isOffer == '1') {
					localStorage.removeItem(environment.dataStore.offerId);
					this.firebaseService.offerRemove(this.offerRemove)
				}

				this.router.navigate(['/confirmation']);
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
}
