// src/app/google-auth.service.ts

import { Injectable, NgZone } from '@angular/core';

declare const google: any;

@Injectable({
  providedIn: 'root',
})
export class GoogleAuthService {
  constructor(private ngZone: NgZone) {}

  initialize(clientId: string, callback: (response: any) => void) {
    google.accounts.id.initialize({
      client_id: clientId,
      callback: (response: any) => {
        this.ngZone.run(() => {
          callback(response);
        });
      },
    });

    google.accounts.id.prompt(); // Show the One Tap prompt
  }

  renderButton(elementId: string, options: any) {
    google.accounts.id.renderButton(
      document.getElementById(elementId),
      options
    );
  }
}
