import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../services/common.service';
import { environment } from '../../environments/environment';
// import { AuthService as SocialService } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { FirebaseService } from '../services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from '../services/authentication.service';
import { GoogleAuthService } from '../google-auth.service';

declare const $: any;

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.css']
})

export class LoginComponent implements OnInit {
	faFacebookSquare = faFacebookSquare;
	loginForm: FormGroup;
	forgotForm: FormGroup;
	verifyOTP: any = "";
	emailSet: any = "";
	getLoginData: any;
	getLoginData1: any;
	getEmailData;
	phoneNumber: any;
	googleResponse :any;

	constructor(
		private router: Router,
		private authService: AuthService,
		private commonService: CommonService,
		private formBuilder: FormBuilder,
		private firebaseService: FirebaseService,
		// private socialService: SocialService,
		private translate: TranslateService,
		private authenticationService: AuthenticationService,
		private googleAuthService: GoogleAuthService
		
		
	) { translate.use(this.commonService.getLanguage()); }


	ngOnInit() {



		this.commonService.scrollToTop();
		this.commonService.setTitle('Inicia Sesión en tu Cuenta Mercadoba | Compra y Vende en Línea en Cuba');
		this.commonService.getMetaTages('Inicia Sesión en tu Cuenta Mercadoba | Compra y Vende en Línea en Cuba','Accede a tu cuenta Qvazon para disfrutar de una experiencia completa de compra y venta en línea en Cuba. Inicia sesión ahora y explora nuestras funciones. Gestiona tus pedidos y productos con facilidad.')


		// when user loged in navigate to home page
		if (this.authService.isLoggedIn() == true) {
			this.router.navigate(['/']);
		}

		// Login form validation
		this.loginForm = this.formBuilder.group({
			email: ['', [Validators.email, Validators.required]],
			password: ['', Validators.required]
		});
		// End

		// Forgot form validation
		this.forgotForm = this.formBuilder.group({
			forgorEmail: ['', [Validators.required, Validators.email]]
		});
		this.getLoginData = JSON.parse(localStorage.getItem(environment.dataStore.userData));

		this.googleAuthService.initialize(environment.googleClientId, (response: any) => {
			this.googleResponse = response;
			console.log(response);
			const token = response.credential;
			if (token) {
				const decodedToken = this.decodeJwt(token);
				this.checkSocialSignup(decodedToken, '1');
			}

		});

		  
	}
	
	ngAfterViewInit(): void {
		this.googleAuthService.renderButton('buttonDiv', {
		  theme: 'outline',
		  size: 'large',
		  type: 'standard', 
          logo_alignment: 'left'
		});
	  }

	// Form validation error
	get email(): any { return this.loginForm.get('email'); }
	get password(): any { return this.loginForm.get('password'); }

	// reset password
	get forgorEmail() { return this.forgotForm.get('forgorEmail'); } // For forgot password
	// End

	// Function for check form validate 
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}
	// End

	

	//  function for login  api call 
	login() {
		if (this.loginForm.valid) {
			this.commonService.showLoader();
			const formData = new FormData();
			formData.append('email', this.loginForm.get('email').value);
			formData.append('password', this.loginForm.get('password').value);
			formData.append('device_token', new Date().toISOString());


			this.authService.login(formData).subscribe(data => {

				this.authenticationService.SignIn(data.data.user_detail.email, '123456');

				this.commonService.hideLoader();

				if (data.status == 'success') {
					
					if (data.data.isExpired == '1') { // check is Expired case for seller

						this.emailSet = this.loginForm.get('email').value;

						if (data.data.user_detail.is_phone_verified == 0 && data.data.user_detail.user_type == 'seller') {
							localStorage.setItem('loginData', JSON.stringify(data.data));
							localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

							this.router.navigate(['seller/number-verify']);
						}


						else {
							this.phoneNumber = data.data.user_detail.phone_dial_code + '-'+ data.data.user_detail.mobile_number;
							$('#IPVerifyModal').modal('show');
						}



					} else {

						localStorage.setItem('loginData', JSON.stringify(data.data));
						localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
	
						//  check switch profile case
						if (data.data.user_detail.is_email_verified == 0 && data.data.user_detail.switch_profile == 1 && data.data.user_detail.user_type == 'seller') {

							return this.router.navigate(['/seller/complete-switch-profile']);

						}


						this.commonService.toastSuccess(data.message);

						localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

						// for check user come to add to card
						let redirectURI = localStorage.getItem(environment.dataStore.redirectUri);


						if (redirectURI != null) {
							this.router.navigate([redirectURI]);
							localStorage.removeItem(environment.dataStore.redirectUri);
						}


						else {

							if (data.data.user_detail.user_type == 'seller' && data.data.user_detail.is_email_verified == 1) { // user type check

								// check user complete business info.
								if (Object.keys(data.data.business_info).length === 0) {

									// add on firebase
									this.addOnFirebase(data.data, '1');

									// email and mobile verified router navigate page
									if (data.data.user_detail.is_phone_verified == 1 && data.data.user_detail.is_email_verified == 1) {
										this.router.navigate(['seller/add-business']);
										// window.location.reload();
									} else {
										this.router.navigate(['seller/number-verify']);
									}
								} else {

									// add on firebase
									this.addOnFirebase(data.data, '2');
									this.router.navigate(['seller/new-orders']);
									// window.location.reload();
								}

							} else {


								// add on firebase
								this.addOnFirebase(data.data, '1');
								this.router.navigate(['/']);
								// window.location.reload();
							}

						}

					}

				} else {
					this.commonService.toastError(data.message);
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandlerForLogin(err);
			});

		} else {
			// validate all form fields
			this.validateAllFormFields(this.loginForm); //{7}
		}

	}
	// End

	IPVerifySubmit() {

		if (this.verifyOTP.trim() != "") {
			this.commonService.showLoader();

			this.getLoginData1 = JSON.parse(localStorage.getItem(environment.dataStore.userData));

			const formData = new FormData();
			formData.append('email', this.emailSet);
			formData.append('otp', this.verifyOTP.trim());
			// formData.append('country', this.getLoginData1.user_detail.country);
			// formData.append('phone_number', this.getLoginData1.user_detail.mobile_number);
			// formData.append('country_code', this.getLoginData1.user_detail.country_code);
			// formData.append('dial_code', this.getLoginData1.user_detail.phone_dial_code);
			// formData.append('is_phone_show', this.getLoginData1.user_detail.is_phone_show);
			formData.append('web_verify_otp','1');


			this.authService.OTPVerifiyWithoutLogin(formData).subscribe((data) => {

				this.commonService.hideLoader();

				if (data.status == 'fail') {
					this.commonService.toastError(data.message);
				} else {
					localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

					this.commonService.toastSuccess(data.message);
					$('#IPVerifyModal').modal('hide');

					//Login here
					this.commonService.toastSuccess(data.message);
					// localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
					// for check user come to add to card
					let redirectURI = localStorage.getItem(environment.dataStore.redirectUri);
					if (redirectURI != null) {

						this.router.navigate([redirectURI]);
						localStorage.removeItem(environment.dataStore.redirectUri);
						window.location.reload();

					} else {

						if (data.data.user_detail.user_type == 'seller') { // user type check
							// check user complete business info.
							if (Object.keys(data.data.business_info).length === 0) {
								// add on firebase
								this.addOnFirebase(data.data, '1');
								this.router.navigate(['seller/add-business']);
								// window.location.reload();
							} else {
								// add on firebase
								this.addOnFirebase(data.data, '2');
								this.router.navigate(['seller/new-orders']);
								// window.location.reload();
							}

						} else {
							// add on firebase
							this.addOnFirebase(data.data, '1');
							this.router.navigate(['/']);
							window.location.reload();
						}

					}
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			this.commonService.toastError(this.translate.instant('SIGNUP.please_enter_otp_code'));
		}

	}


	// Resend OTP phone
	resendOtp() {
		const formData = new FormData();
		formData.append('email', this.emailSet);

		this.commonService.showLoader();

		this.authService.OTPResend(formData).subscribe(data => {

			if (data.status == 'success') {
				this.commonService.hideLoader();
				this.commonService.toastSuccess(data.message);
			}
		}, err => {
			this.commonService.hideLoader();
			// this.commonService.errorhandler(err);
			this.commonService.errorhandlerForLogin(err);

		});

	}
	//  Otp End

	checkSocialSignup(socialData, socialType) {

		let device_token = new Date().toISOString();
		let formData;
		if (socialData.email != "") {
			formData = `email=${socialData.email}&social_id=${socialData.sub}&social_type=${socialType}&user_type=buyer&device_token=${device_token}&profile_image=${socialData.photoUrl}&full_name=${socialData.name}`;
		} else {
			formData = `social_id=${socialData.sub}&social_type=${socialType}&user_type=buyer&device_token=${device_token}&profile_image=${socialData.photoUrl}&full_name=${socialData.name}`;
		}

		this.authService.checkSocialSignup(formData).subscribe(data => {

			this.commonService.hideLoader();

			if (data.status == 'success') {

				// check user exist or not
				if (data.data.social_status == '0') { // not exist

					let socialDataStore = {
						'userType': 'buyer',
						'id': socialData.sub,
						'full_name': socialData.name,
						'email': socialData.email,
						'socialType': socialType,
						'photoUrl': socialData.photoUrl
					}

					this.authService.socialDataCollection = socialDataStore;
					localStorage.removeItem("countryCode");
					this.router.navigate(['/social-email']);

				} else { // exist
					// localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

					this.authenticationService.SignIn(data.data.user_detail.email, '123456');


					if (data.data.isExpired == '1') {
						this.emailSet = socialData.email;

						if (data.data.user_detail.is_phone_verified == 0) {
							localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

							this.router.navigate(['seller/number-verify']);

						}
						else {
							this.phoneNumber = data.data.user_detail.phone_dial_code + '-'+ data.data.user_detail.mobile_number;
							$('#IPVerifyModal').modal('show');
						}

						// $('#IPVerifyModal').modal('show');

						// 
					} else {

						localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

						if (data.data.user_detail.is_email_verified == 0 && data.data.user_detail.switch_profile == 1 && data.data.user_detail.user_type == 'seller') {


							return this.router.navigate(['/seller/complete-switch-profile']);

						}



						this.commonService.toastSuccess(data.message);
						localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

						// for check user come to add to card
						let redirectURI = localStorage.getItem(environment.dataStore.redirectUri);
						if (redirectURI != null) {
							this.router.navigate([redirectURI]);
							localStorage.removeItem(environment.dataStore.redirectUri);
							// window.location.reload();
						} else {

							if (data.data.user_detail.user_type == 'seller' && data.data.user_detail.is_email_verified == 1) { // user type check
								// check user complete business info.
								if (Object.keys(data.data.business_info).length === 0) {
									// add on firebase
									this.addOnFirebase(data.data, '1');
									// email and mobile verified router navigate page
									if (data.data.user_detail.is_phone_verified == 1 && data.data.user_detail.is_email_verified == 1) {
										this.router.navigate(['seller/add-business']);
										// window.location.reload();
									} else {
										this.router.navigate(['seller/number-verify']);
									}
								} else {
									// add on firebase
									this.addOnFirebase(data.data, '2');
									this.router.navigate(['seller/new-orders']);
									// window.location.reload();
								}

							} else {
								// add on firebase
								this.addOnFirebase(data.data, '1');
								this.router.navigate(['/']);
								// window.location.reload();
							}

						}

					}

				}

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			// this.commonService.errorhandler(err);
			this.commonService.errorhandlerForLogin(err);

		});

	}

	// // Social connect with google
	// signInWithGoogle(): void {
	// 	alert('Hello');
	// 	// this.commonService.showLoader();

	// 	// this.socialService.signIn(GoogleLoginProvider.PROVIDER_ID).then(x => {
	// 	// this.socialService.signIn('882600544135-t1e17i6a6pgmq81sop4prnjvchf0q396.apps.googleusercontent.com').then(x => {
	// 		this.googleAuthService.initialize('882600544135-t1e17i6a6pgmq81sop4prnjvchf0q396.apps.googleusercontent.com', (response: any) => {
	// 			this.googleResponse = response;
	// 			console.log('HelloGoogle',response.credential);
	// 			this.checkSocialSignup(this.googleResponse, '1');

	// 			// Handle the response from Google
	// 		  });

	// 	// }).catch(error => {
	// 	// 	this.commonService.hideLoader();
	// 	// });
	// }
	// End

	// // Social connect with facebook
	// signInWithFB(): void {
	// 	this.commonService.showLoader();

	// 	this.socialService.signIn(FacebookLoginProvider.PROVIDER_ID).then(x => {

	// 		this.checkSocialSignup(x, '2');

	// 	}).catch(error => {
	// 		this.commonService.hideLoader();
	// 	});
	// }

	// Forgot password form 
	forgotPass() {
		this.commonService.showLoader();

		const formData = `email=${this.forgotForm.get('forgorEmail').value}`;

		this.authService.forgotPassword(formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {

				this.commonService.toastSuccess(data.message);
				$('#forgotPassword').modal('hide');
				this.forgotForm.reset();

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	//Forgot password form reset
	resetForm() {
		this.forgotForm.reset();
	}
	// End

	// Firebase function
	addOnFirebase(userData, type) {
		let userDataStore = {
			'user_id': userData.user_detail.userID,
			'full_name': userData.user_detail.full_name,
			'email': userData.user_detail.email,
			'user_type': userData.user_detail.user_type,
			'avatar': userData.user_detail.avatar,
			'notification_status': userData.user_detail.push_alert_status,
			'firebase_token': "",
			'business_name': "",
			'buyer_name': "",
			'seller_name': "",
			'online': "Online",

		}

		userDataStore.business_name = (type == '2') ? userData.business_info.business_name : "";

		if (userData.user_detail.user_type == 'buyer') {
			userDataStore.buyer_name = userData.user_detail.full_name;
		} else {
			userDataStore.seller_name = userData.user_detail.full_name;

		}

		setTimeout(() => {
			this.firebaseService.userUpdateFB(userData.user_detail.userID, userDataStore)

		}, 2000);


	}

	private decodeJwt(token: string): any {
		const base64Url = token.split('.')[1];
		const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
		const jsonPayload = decodeURIComponent(atob(base64).split('').map(c =>
		  `%${('00' + c.charCodeAt(0).toString(16)).slice(-2)}`).join(''));
	
		return JSON.parse(jsonPayload);
	  }
	// End

}
