import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CountrylistService {

  constructor() { }

  countryListArray() {
    const USA = [
      {
        "rank": 1,
        "country": "United States",
        "pop2022": 338289857,
        "growthRate": "0.0038",
        "area": 9372610
      },
      {
        "rank": 2,
        "country": "Brazil",
        "pop2022": 215313498,
        "growthRate": "0.0046",
        "area": 8515767
      },
      {
        "rank": 3,
        "country": "Mexico",
        "pop2022": 127504125,
        "growthRate": "0.0063",
        "area": 1964375
      },
      {
        "rank": 4,
        "country": "Colombia",
        "pop2022": 51874024,
        "growthRate": "0.0069",
        "area": 1141748
      },
      {
        "rank": 5,
        "country": "Argentina",
        "pop2022": 45510318,
        "growthRate": "0.0052",
        "area": 2780400
      },
      {
        "rank": 6,
        "country": "Canada",
        "pop2022": 38454327,
        "growthRate": "0.0078",
        "area": 9984670
      },
      {
        "rank": 7,
        "country": "Peru",
        "pop2022": 34049588,
        "growthRate": "0.0099",
        "area": 1285216
      },
      {
        "rank": 8,
        "country": "Venezuela",
        "pop2022": 28301696,
        "growthRate": "0.0036",
        "area": 916445
      },
      {
        "rank": 9,
        "country": "Chile",
        "pop2022": 19603733,
        "growthRate": "0.0057",
        "area": 756102
      },
      {
        "rank": 10,
        "country": "Ecuador",
        "pop2022": 18001000,
        "growthRate": "0.0114",
        "area": 276841
      },
      {
        "rank": 11,
        "country": "Guatemala",
        "pop2022": 17843908,
        "growthRate": "0.0134",
        "area": 108889
      },
      {
        "rank": 12,
        "country": "Bolivia",
        "pop2022": 12224110,
        "growthRate": "0.0120",
        "area": 1098581
      },
      {
        "rank": 13,
        "country": "Haiti",
        "pop2022": 11584996,
        "growthRate": "0.0120",
        "area": 27750
      },
      {
        "rank": 14,
        "country": "Dominican Republic",
        "pop2022": 11228821,
        "growthRate": "0.0100",
        "area": 48671
      },
      {
        "rank": 15,
        "country": "Cuba",
        "pop2022": 11212191,
        "growthRate": "-0.0039",
        "area": 109884
      },
      {
        "rank": 16,
        "country": "Honduras",
        "pop2022": 10432860,
        "growthRate": "0.0150",
        "area": 112492
      },
      {
        "rank": 17,
        "country": "Nicaragua",
        "pop2022": 6948392,
        "growthRate": "0.0143",
        "area": 130373
      },
      {
        "rank": 18,
        "country": "Paraguay",
        "pop2022": 6780744,
        "growthRate": "0.0115",
        "area": 406752
      },
      {
        "rank": 19,
        "country": "El Salvador",
        "pop2022": 6336392,
        "growthRate": "0.0035",
        "area": 21041
      },
      {
        "rank": 20,
        "country": "Costa Rica",
        "pop2022": 5180829,
        "growthRate": "0.0052",
        "area": 51100
      },
      {
        "rank": 21,
        "country": "Panama",
        "pop2022": 4408581,
        "growthRate": "0.0132",
        "area": 75417
      },
      {
        "rank": 22,
        "country": "Uruguay",
        "pop2022": 3422794,
        "growthRate": "-0.0010",
        "area": 181034
      },
      {
        "rank": 23,
        "country": "Jamaica",
        "pop2022": 2827377,
        "growthRate": "-0.0001",
        "area": 10991
      },
      {
        "rank": 24,
        "country": "Trinidad and Tobago",
        "pop2022": 1531044,
        "growthRate": "0.0035",
        "area": 5130
      },
      {
        "rank": 25,
        "country": "Guyana",
        "pop2022": 808726,
        "growthRate": "0.0052",
        "area": 214969
      },
      {
        "rank": 26,
        "country": "Suriname",
        "pop2022": 618040,
        "growthRate": "0.0083",
        "area": 163820
      },
      {
        "rank": 27,
        "country": "Bahamas",
        "pop2022": 409984,
        "growthRate": "0.0051",
        "area": 13943
      },
      {
        "rank": 28,
        "country": "Belize",
        "pop2022": 405272,
        "growthRate": "0.0131",
        "area": 22966
      },
      {
        "rank": 29,
        "country": "Barbados",
        "pop2022": 281635,
        "growthRate": "0.0015",
        "area": 430
      },
      {
        "rank": 30,
        "country": "Saint Lucia",
        "pop2022": 179857,
        "growthRate": "0.0011",
        "area": 616
      },
      {
        "rank": 31,
        "country": "Grenada",
        "pop2022": 125438,
        "growthRate": "0.0066",
        "area": 344
      },
      {
        "rank": 32,
        "country": "Saint Vincent and the Grenadines",
        "pop2022": 103948,
        "growthRate": "-0.0037",
        "area": 389
      },
      {
        "rank": 33,
        "country": "Antigua and Barbuda",
        "pop2022": 93763,
        "growthRate": "0.0058",
        "area": 442
      },
      {
        "rank": 34,
        "country": "Dominica",
        "pop2022": 72737,
        "growthRate": "0.0045",
        "area": 751
      },
      {
        "rank": 35,
        "country": "Saint Kitts and Nevis",
        "pop2022": 47657,
        "growthRate": "0.0011",
        "area": 261
      }
    ]

    const European = [
      {
        "country": "Andorra",
        "code": "AD"
      },
      {
        "country": "Albania",
        "code": "AL"
      },
      {
        "country": "Austria",
        "code": "AT"
      },
      {
        "country": "Åland Islands",
        "code": "AX"
      },
      {
        "country": "Bosnia and Herzegovina",
        "code": "BA"
      },
      {
        "country": "Belgium",
        "code": "BE"
      },
      {
        "country": "Bulgaria",
        "code": "BG"
      },
      {
        "country": "Belarus",
        "code": "BY"
      },
      {
        "country": "Switzerland",
        "code": "CH"
      },
      {
        "country": "Cyprus",
        "code": "CY"
      },
      {
        "country": "Czech Republic",
        "code": "CZ"
      },
      {
        "country": "Germany",
        "code": "DE"
      },
      {
        "country": "Denmark",
        "code": "DK"
      },
      {
        "country": "Estonia",
        "code": "EE"
      },
      {
        "country": "Spain",
        "code": "ES"
      },
      {
        "country": "Finland",
        "code": "FI"
      },
      {
        "country": "Faroe Islands",
        "code": "FO"
      },
      {
        "country": "France",
        "code": "FR"
      },
      {
        "country": "United Kingdom",
        "code": "GB"
      },
      {
        "country": "Guernsey",
        "code": "GG"
      },
      {
        "country": "Greece",
        "code": "GR"
      },
      {
        "country": "Croatia",
        "code": "HR"
      },
      {
        "country": "Hungary",
        "code": "HU"
      },
      {
        "country": "Ireland",
        "code": "IE"
      },
      {
        "country": "Isle of Man",
        "code": "IM"
      },
      {
        "country": "Iceland",
        "code": "IC"
      },
      {
        "country": "Italy",
        "code": "IT"
      },
      {
        "country": "Jersey",
        "code": "JE"
      },
      {
        "country": "Liechtenstein",
        "code": "LI"
      },
      {
        "country": "Lithuania",
        "code": "LT"
      },
      {
        "country": "Luxembourg",
        "code": "LU"
      },
      {
        "country": "Latvia",
        "code": "LV"
      },
      {
        "country": "Monaco",
        "code": "MC"
      },
      {
        "country": "Moldova, Republic of",
        "code": "MD"
      },
      {
        "country": "Macedonia, The Former Yugoslav Republic of",
        "code": "MK"
      },
      {
        "country": "Malta",
        "code": "MT"
      },
      {
        "country": "Netherlands",
        "code": "NL"
      },
      {
        "country": "Norway",
        "code": "NO"
      },
      {
        "country": "Poland",
        "code": "PL"
      },
      {
        "country": "Portugal",
        "code": "PT"
      },
      {
        "country": "Romania",
        "code": "RO"
      },
      {
        "country": "Russian Federation",
        "code": "RU"
      },
      {
        "country": "Sweden",
        "code": "SE"
      },
      {
        "country": "Slovenia",
        "code": "SI"
      },
      {
        "country": "Svalbard and Jan Mayen",
        "code": "SJ"
      },
      {
        "country": "Slovakia",
        "code": "SK"
      },
      {
        "country": "San Marino",
        "code": "SM"
      },
      {
        "country": "Ukraine",
        "code": "UA"
      },
      {
        "country": "Holy See (Vatican City State)",
        "code": "VA"
      }
    ]

    let res = [USA, European];
    return res;
  }
}
