import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { faAngleDoubleRight, faCheck, faStar, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FormGroup, FormControl } from '@angular/forms';
import { Options } from 'ng5-slider';
import { CommonService } from '../services/common.service';
import { ShopService } from '../services/shop.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { HeaderBuyerComponent } from '../common/header-buyer/header-buyer.component';
import { TranslateService } from '@ngx-translate/core';
import * as CryptoJS from 'crypto-js';
import { BuyerService } from '../buyer/services/buyer.service';

declare const $: any;
@Component({
    selector: 'app-shop-list',
    templateUrl: './shop-list.component.html',
    styleUrls: ['./shop-list.component.css']
})
export class ShopListComponent implements OnInit {

    faAngleDoubleRight = faAngleDoubleRight;
    faCheck = faCheck;
    faStar = faStar;
    faCircle = faCircle;
    currentUserId: number;
    filter: object;
    productList: any = [];
    categoryList: any = [];
    variantList: any = [];
    selectedColor: any = [];
    selectedSize: any = [];
    category: any = "";
    color: any = "";
    size: any = "";
    productCount: number;
    limitOfProduct: number = 12;
    offsetOfProduct: number = 0;
    productloader: boolean = true;
    selectedCategory: any = [];
    minPrice: number = 0;
    maxPrice: number = 0;
    price_from: any = "";
    price_to: any = "";
    sort: any = "";
    search_term: string = "";
    deal: any = "";
    isCatRoute: boolean = false;
    isBreadcrumb: number = 1;
    isBreadcrumbCat: any = 'undefined';
    isBreadcrumbSubCat: any = 'undefined';
    condition: any = '';
    sellerWarranty: any = '';
    sellerRating: any = '';
    newArrival: any = '';
    discount: any = '';
    deliveryIn: any = [];
    deliveryInData: any = '';
    provinceList: any = [];
    provinceCheckArray: any = [];
    provinceData: any = '';
    brandCheckArray: any = [];
    brandData: any = '';
    isBrandListShow: boolean = false;
    brandList: any = [];
    remainingCategoryList: any = [];
    delivery_in: any;
    direct_pay: any = '';
    preventA: any = 1
    is_featured = ""
    is_promoted = ""
    is_offer =""
    seller_id =""
    is_shop_from=""

    // minValue: number = 50;
    // maxValue: number = 200;
    sliderForm: FormGroup = new FormGroup({
        sliderControl: new FormControl([0, 5000])
    });

    optionsPrice: Options = { floor: 0, ceil: 0, step: 1 };

    isShown: boolean = false; // hidden by default
    offerForm: any;
    variant: string;
    variantListData: any[];
    qvazon_warranty_use: number;
    is_wishlist: number;
    is_cart: number;
    is_qvazon_warranty_applied: number;
    form: any;
    variantId: any;
    discount1: any;
    ids: any;
    encryptSecretKey: 1;
    fastDelivery: any = 0;
    productss = [];
    toggleShow() {
        this.isShown = !this.isShown;
        if (this.isShown) {
            this.document.body.classList.add('filter-open');
        } else {
            this.document.body.classList.remove('filter-open');
        }
    }

    @ViewChild('header', { static: true }) header: HeaderBuyerComponent;

    constructor(
        @Inject(DOCUMENT) private document: Document,
        private commonService: CommonService,
        private shopService: ShopService,
        private activatedRoute: ActivatedRoute,
        private authService: AuthService,
        private router: Router,
        private translate: TranslateService,
        private buyerService: BuyerService,


    ) {
        translate.use(this.commonService.getLanguage());
        // this.ids = this.router.getCurrentNavigation();
        // this.ids = this.router.getCurrentNavigation().extras;


        if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state != undefined) {
            this.ids = this.router.getCurrentNavigation().extras.state.cat_id;
            this.is_featured = this.router.getCurrentNavigation().extras.state.is_featured;
            this.is_promoted = this.router.getCurrentNavigation().extras.state.is_promoted;
            this.is_offer = this.router.getCurrentNavigation().extras.state.is_offer;



        }
        // if (this.router.getCurrentNavigation() != null && this.router.getCurrentNavigation().extras.state != undefined) {
        //     this.ids = this.router.getCurrentNavigation().extras.state.cat_id;
        //     this.category = this.router.getCurrentNavigation().extras.state.category;

        // }

        if (this.activatedRoute.snapshot.params.catId != undefined)
            this.deal = this.activatedRoute.snapshot.params.catId;
        }

    ngOnInit() {
        
        if (this.ids != null) {
            this.ids = this.ids.replace(/\s*,\s*/g, ",");
            const split_string = this.ids.split(",");
            const previousSecondElementOfTheArray = split_string.splice(0, 1);
            let text = split_string.toString();
            this.category = text
            this.is_featured = "";
            this.is_offer = "";
        }

        if (this.is_featured != "" && this.is_featured != "null" && this.is_featured != undefined) {
            this.category = "";
            this.is_promoted = "";
            this.is_offer = "";
        }
        if (this.is_promoted != "" && this.is_promoted != "null" && this.is_promoted != undefined) {
            this.category = "";
            this.is_offer = "";

        }
        if (this.is_offer != "" && this.is_offer != "null" && this.is_offer != undefined) {
            this.category = "";
            this.is_featured = "";
            this.is_promoted = "";

        }


        localStorage.setItem("user_province", '0');

        this.commonService.scrollToTop();




        const str = this.router.url;
        const regex = RegExp('/shop/category');
        if (regex.test(str) == true) {
            this.commonService.setTitle('Explora Nuestras Categorías de Productos con Mercadoba | Compra en Línea en Cuba'); //For title

            this.commonService.getMetaTages('Explora Nuestras Categorías de Productos con Mercadoba | Compra en Línea en Cuba', 'Encuentra exactamente lo que necesitas en Cuba a través de nuestras diversas categorías de productos en línea con Qvazon. Compra ahora y recibe rápidamente. Explora nuestras categorías y elige entre una amplia gama de opciones.')

        } else {
            this.commonService.setTitle('Compra en Línea en Cuba con Mercadoba | Entrega Rápida y Variedad de Productos'); //For title

            this.commonService.getMetaTages('Compra en Línea en Cuba con Mercadoba | Entrega Rápida y Variedad de Productos', 'Compra en línea en Cuba de manera conveniente y segura con Qvazon. Amplia variedad de productos y envío rápido. Haz tu pedido ahora y recíbelo pronto. Explora nuestras categorías y encuentra justo lo que necesitas.')

        }

        this.currentUserId = this.authService.getCurrentUserId(); //For current userID
        this.setCategory(); //For set category
        this.getCategoryList(); //For category list
        this.getProvinceList(); //For province


        // if(this.router.url.split('/').pop() != "shop"){
        //     this.is_shop_from = this.router.url.split('/').pop();
        // }

        // console.log('iniiiiiitttt',this.is_shop_from);
        
    }

    // For get province list
    getProvinceList() {
        this.authService.getProvinceList()
            .subscribe(provinceData => {
                this.commonService.hideLoader();

                if (provinceData.status == 'success') {
                    this.provinceList = provinceData.data.province;
                } else {
                    this.provinceList = [];
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
    }
    // End

    // section for category 
    getCategoryList() {
        //Category Subactegory list for filter
        this.shopService.getCategorySubacategoryList().subscribe(
            (catSubactData) => {

                if (catSubactData.status == 'success') {
                    this.categoryList = catSubactData.data.category;
                }
            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
    }
    // End

    searchTimer: any;
    doneSearchInterval: any = 500;

    // For search filter
    setSearchText(text) {
        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
            this.search_term = text.trim();
            this.resetLoadMore();
            this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
        }, this.doneSearchInterval);

    }
    // End
    redirectFunction(detail, name, id1) {
        var name1 = (name.split(' ').join(''));

        var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
            // return '-' + c.charCodeAt(0).toString(16);
            return '-';
        });

        // console.log(url);

        var removeSpace = (url.split('%').join('-'));
        var validUrl = detail + '/' + removeSpace + '/' + id1;
        this.router.navigate([validUrl]);

    }


    // For manage category list
    setCategoryList(categoryData) {
        this.brandData = '';
        this.brandCheckArray = [];
        this.remainingCategoryList = [];

        if (categoryData.collectLastCategory.length > 0) {
            //this.remainingCategoryList = categoryData.collectLastCategory;

            this.remainingCategoryList = categoryData.collectLastCategory.map(function (el) {
                var o = Object.assign({}, el);
                o.is_selected = true;
                return o;
            })

        }

        const remainingCategory = this.remainingCategoryList.map(object => object.categoryID);
        let finialCategory = categoryData.filterCategory.concat(remainingCategory);

        this.category = finialCategory.join();
        this.resetLoadMore();
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
        this.isBrandListShow = (categoryData.filterParentCategory.length > 0) ? true : false;
        let categoryParent = categoryData.filterParentCategory.join();

        if (categoryData.filterParentCategory.length > 0) {
            this.getBrandList(categoryParent);
        }

    }
    // End

    // Function for set category
    setCategory() {
        // if user come to select category or subcategory
        this.activatedRoute.paramMap.subscribe(params => {

            // set search box on header
            const str = this.router.url;
            const regex = RegExp('/shop/category');
            const regex2 = RegExp('/shop');

            if (regex.test(str) == true) {
                this.header.SelectPrtyListOpn();
            } else if (regex2.test(str) == true) {
                this.search_term = this.commonService.searchTextStore;
            }

            this.resetLoadMore();
            this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));

        });

    }
    // End

    // Function for brand list
    getBrandList(category) {
        this.shopService.getBrandList(category).subscribe(
            (brandData) => {

                if (brandData.status == 'success') {
                    this.brandList = brandData.data.brand_List;
                }
            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
    }
    // End

    // Function for reset filter
    resetFilter() {
        $('input[class=proviceCls]').prop('checked', false);
        $('input[class=checkDeliveryDay]').prop('checked', false);
        $('input[class=checkDirectPay]').prop('checked', false);

        this.category = "";
        this.color = "";
        this.size = "";
        this.price_from = "";
        this.price_to = "";
        this.sort = "";
        this.search_term = "";
        this.selectedColor = [];
        this.selectedSize = [];
        this.condition = "";
        this.sellerWarranty = '';
        this.sellerRating = '';
        this.newArrival = '';
        this.direct_pay = '';
        this.discount = '';
        this.deliveryIn = [];
        this.deliveryInData = '';
        this.provinceData = '';
        this.brandData = '';
        this.deal = '';
        this.delivery_in = '';
        this.productList = [];
        this.is_featured = ""
        this.is_promoted = ""
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
        localStorage.removeItem('user_province');
    }
    // End

    // Function for reset load more button
    resetLoadMore() {
        this.productList = [];
        this.limitOfProduct = 12;
        this.offsetOfProduct = 0;
    }
    // End

    onCheckboxChangeCategory(option, event) {
        this.resetLoadMore(); //Load more button reset

        if (event.target.checked) {
            this.selectedCategory.push({ id: option.categoryID });
            let categoryArray = this.selectedCategory.map(item => item.id);
            this.category = categoryArray.join();
        } else {
            this.selectedCategory.forEach((subCategory, key) => {
                if (subCategory.id == option.categoryID) {
                    this.selectedCategory.splice(key, 1);
                    let categoryArray = this.selectedCategory.map(item => item.id);
                    this.category = categoryArray.join();
                }
            });
        }

        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }

    onVariantCheckboxChangeType(parentVariant, variants, event) {
        this.resetLoadMore();

        if (event.target.checked) {

            if (parentVariant.name == "color") {
                this.selectedColor.push({ id: variants.variantValueID });
                let colorArray = this.selectedColor.map(item => item.id);
                this.color = colorArray.join();
            } else if (parentVariant.name == "size") {
                this.selectedSize.push({ id: variants.variantValueID });
                let sizeArray = this.selectedSize.map(item => item.id);
                this.size = sizeArray.join();
            }

        } else {

            if (parentVariant.name == "color") {
                this.selectedColor.forEach((color, key) => {
                    if (color.id == variants.variantValueID) {
                        this.selectedColor.splice(key, 1);
                        let colorArray = this.selectedColor.map(item => item.id);
                        this.color = colorArray.join();
                    }
                });
            }
            else if (parentVariant.name == "size") {
                this.selectedSize.forEach((color, key) => {
                    if (color.id == variants.variantValueID) {
                        this.selectedSize.splice(key, 1);
                        let sizeArray = this.selectedSize.map(item => item.id);
                        this.size = sizeArray.join();
                    }
                });
            }

        }

        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }

    onCheckboxChange($event) {
        if ($event.target.checked) {
            this.direct_pay = '1';
        } else {
            this.direct_pay = '';
        }

        this.resetLoadMore();
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }


    // get data for filter
    getFilterData(init) {
        var filter = {
            'category': "",
            'color': "",
            'size': "",
            'sort': "",
            'price': "",
            'search_term': "",
            'condition': "",
            'sellerWarranty': "",
            'sellerRating': "",
            'newArrival': "",
            'discount': "",
            'deliveryInData': "",
            'provinceData': "",
            'brandData': "",
            'deal': "",
            'delivery_in': "",
            'direct_pay': "",
            'is_featured': "",
            'is_promoted': "",
            'seller_id': "",
            'is_offer': ""
            
        }
        if (init != 1) { // for starting
            filter.category = this.category;
            filter.color = this.color;
            filter.size = this.size;
            // filter.price_from = this.price_from;
            // filter.price_to = this.price_to;
            filter.price = `${this.price_from}|${this.price_to}`;
            filter.sort = this.sort;
            filter.search_term = this.search_term.trim();;
            filter.condition = this.condition;
            filter.sellerWarranty = (this.sellerWarranty != '' || this.sellerWarranty != false) ? this.sellerWarranty : '';
            filter.sellerRating = this.sellerRating;
            filter.newArrival = this.newArrival;
            filter.discount = this.discount;
            filter.deliveryInData = this.deliveryInData;
            filter.provinceData = this.provinceData;
            filter.brandData = this.brandData;
            filter.deal = this.deal;
            filter.delivery_in = this.delivery_in;
            filter.delivery_in = this.deliveryInData;
            filter.direct_pay = this.direct_pay;
            filter.is_featured = this.is_featured;
            filter.is_promoted = this.is_promoted;
            filter.is_offer = this.is_offer;

            if(this.router.url.split('/').pop() != "shop"){
                this.is_shop_from = this.router.url.split('/').pop();
            }
            
            filter.seller_id = this.is_shop_from ? this.is_shop_from : "";
        }

        return filter;
    }


    // Function for reset form
    resetForm() {
        // this.form.reset();
        this.qvazon_warranty_use = 0;
        this.is_qvazon_warranty_applied = 0;
        this.is_wishlist = 0;
    }
    // End

    // For reset offer form 
    resetOfferForm() {
        this.offerForm.reset();
        this.variantListData = [];
        this.variant = '';
    }
    // End

    //add wishlist
    addWishlist(productId, index) {
        // For check user is login or not
        if (this.authService.isLoggedIn()) {
            const formData = new FormData();
            formData.append('product_id', productId);

            this.shopService.addUserWishlist(formData).subscribe((data) => {
                this.header.getWishCount();// for decrease card count
                if (data.status == "fail") {
                } else {
                    this.resetForm();
                    this.productList[index].is_wishlist = data.data.is_wishlist;
                }

                //Redirect to solve selected varinat got empty after action
                if (this.activatedRoute.snapshot.url.length == 3) {  //get url array and length of array
                    let navigate = this.router.url;
                    this.router.navigate([navigate]);
                } else if (this.activatedRoute.snapshot.url.length == 4) {
                    let lastIndexOfUrl = this.activatedRoute.snapshot.url[3].path;
                    let popIndex = '/' + lastIndexOfUrl.toString();
                    let toStringArr = this.activatedRoute.snapshot.url.toString()
                    let urlSuffix = '/' + lastIndexOfUrl;
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });

        } else {
            // this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_product_add_in_wishlist'));
            //localStorage.setItem(environment.dataStore.redirectUri, this.router.url);
            this.router.navigate(['/login']);
        }
        // }
    }
    // End

    //sorting function
    sorting(event) {
        this.resetLoadMore();
        this.sort = event.target.value;
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }
    // End

    rangTimer: any;
    doneRangInterval: any = 500;

    // Price filter
    onchangePrice(event) {
        this.resetLoadMore();
        this.price_from = event.value;
        this.price_to = event.highValue;
        this.productList = [];
        clearTimeout(this.rangTimer);
        this.rangTimer = setTimeout(() => {
            this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
        }, this.doneRangInterval);
    }
    // End

    //  For Warranty filter 
    sellerWarrantyApply($event) {
        if ($event.target.checked) {
            this.sellerWarranty = '1';
        } else {
            this.sellerWarranty = '';
        }

        this.resetLoadMore();
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }

    test() {
        this.productList = [];
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));

    }


    // For common filter for rating
    commonFilterApply() {

        this.resetLoadMore();
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }

    // End

    // For delivery day filter
    deliveryInCheck(day) {






        if (this.deliveryIn.indexOf(day) > -1) {
            let index = this.deliveryIn.indexOf(day);

            this.deliveryIn.splice(index, 1);
        } else {
            this.deliveryIn.push(day);
        }

        this.deliveryInData = (this.deliveryIn.length > 0) ? this.deliveryIn.join() : "";
        this.commonFilterApply();
    }
    // End

    // Function for province
    provinceCheck(province) {

        this.provinceCheckArray = []; // for single province
        this.provinceData = ''; //// for single province
        if (this.provinceCheckArray.indexOf(province) > -1) {
            let index = this.provinceCheckArray.indexOf(province);

            this.provinceCheckArray.splice(index, 1);
        } else {
            this.provinceCheckArray.push(province);
        }

        this.provinceData = (this.provinceCheckArray.length > 0) ? this.provinceCheckArray.join() : "";
        localStorage.setItem("user_province", this.provinceData);

        this.commonFilterApply();
    }
    // End

    // Function for brand 
    brandCheck(brand) {
        if (this.brandCheckArray.indexOf(brand) > -1) {
            let index = this.brandCheckArray.indexOf(brand);
            this.brandCheckArray.splice(index, 1);
        } else {
            this.brandCheckArray.push(brand);
        }

        this.brandData = (this.brandCheckArray.length > 0) ? this.brandCheckArray.join() : "";
        this.commonFilterApply();
    }
    // End

    // For category check
    categoryCheck(categoryId, $event) {
        let category = this.category.split(',');

        if ($event.target.checked) {
            category.push(categoryId);
        } else {
            var index = category.indexOf(categoryId);
            category.splice(index, 1);
        }

        this.category = category.join();

        this.resetLoadMore();


        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }
    // End

    // get product list
    getProductListList(limit, offset, filter) {


        // console.log('filter', filter);return false;
        // this.commonService.showLoader();

        if (this.offsetOfProduct == 0) {
            this.commonService.showLoader();
        } else {
            this.productloader = false;
        }
        this.shopService.getProductList(limit, offset, this.currentUserId, filter, 1)
            .subscribe(productData => {

                this.productloader = true;
                this.commonService.hideLoader();

                if (productData.status == 'success') {

                    if (productData.data.data_found) {

                        //set maxprice
                        this.optionsPrice = { floor: 0, ceil: productData.data.min_max_price.maxPrice, step: 1 };
                        this.maxPrice = Number(productData.data.min_max_price.maxPrice);
                        this.minPrice = Number(productData.data.min_max_price.minPrice);


                        // const filteredArray = this.productList.filter(element => {
                        //   if (element.id === element.id) {

                        //     return false;
                        //   }

                        if (this.offsetOfProduct == 0) { // check refresh then show starting
                            this.productList = productData.data.product_list; // assign data in order list 
                            this.productCount = productData.data.total_records; //total product

                            // this.orderCountGet = productData.data.total_records;
                        } else {
                            // this.orderCountGet =res.data.total_records; //total product  
                            this.productCount = productData.data.total_records; //total product

                            productData.data.product_list.forEach(element => {
                                this.productList.push(element);
                            });
                        }

                        // this.productList = this.productList.concat(productData.data.product_list);

                        // });
                        // if (this.productList.includes(this.productList.productID)) {

                        //   const index = this.productList.indexOf(this.productList.productID);

                        //   this.productList = this.productList.splice(index, 1);
                        // }


                        // this.productList = arr3;
                        this.productCount = productData.data.total_records; //total product

                        // if (this.offsetOfProduct == 0) { // check refresh then show starting

                        //   productData.data.product_list.forEach(element => {
                        //     element.sale_discount = Math.round(element.sale_discount);
                        //     element.discount_percent = Math.round(element.discount_percent);

                        //     this.productList.push(element);
                        //     this.productCount = productData.data.total_records; //total product
                        //   });

                        // } else {

                        //   productData.data.product_list.forEach(element => {
                        //     element.discount_percent = Math.round(element.discount_percent);
                        //     this.productList.push(element);
                        //     this.productCount = productData.data.total_records; //total product    
                        //   });

                        // }



                        if (this.price_to != "") {
                            this.maxPrice = Number(this.price_to);
                        }
                        if (this.price_from != "") {
                            this.minPrice = Number(this.price_from);
                        }

                    } else {
                        this.productCount = 0;
                        this.productList = [];
                    }


                } else {
                    this.productCount = 0;
                    this.productList = [];
                }

            }, err => {
                this.commonService.hideLoader();
                this.commonService.errorhandler(err);
            });
    }// end getProductListList

    // For load more button product
    productLoadMore() { //load more 
        this.offsetOfProduct = this.offsetOfProduct + Number(this.limitOfProduct);
        this.getProductListList(this.limitOfProduct, this.offsetOfProduct, this.getFilterData(2));
    }
    // End

    filterOpen() {
        let body = document.getElementsByTagName('body')[0];
        let sidebarView = document.getElementById("sidebarView");
        let sideBarOverlay = document.getElementById("sideBarOverlay");

        body.classList.add("overflow");
        sideBarOverlay.classList.add("overlay-show");
        sidebarView.classList.add("sidebarViewSec");
    }

    // Function for close filter
    filterClose() {
        let body = document.getElementsByTagName('body')[0];
        let sidebarView = document.getElementById("sidebarView");
        let sideBarOverlay = document.getElementById("sideBarOverlay");

        body.classList.remove("overflow");
        sidebarView.classList.remove("sidebarViewSec");
        sideBarOverlay.classList.remove("overlay-show");
    }


    // For add item to cart
	addToCart(product, index) {
		var user_province = localStorage.getItem("user_province");
		// For check user is loggedin or not
		if (this.authService.isLoggedIn()) {
			const formData = new FormData();
			formData.append('product_varint_id',product.productVariantID );
			formData.append('product_quantity', '1');
			formData.append('user_province', user_province);
			// this.commonService.showLoader();

			this.shopService.addToCart(formData).subscribe((data) => {
                console.log('data', data.data);
				this.commonService.hideLoader();
                    this.productList[index].is_cart = 1;
                    this.productList[index].cartItemID = data.data.cartID;
                    this.header.getCartCount();// for decrease card count

					this.resetForm();
				
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		}

		// } 
		else {
			this.router.navigate(['/login']);
		}

		// }

	}

	//Remove cart item
	removeCartItem(cartItemId, index) {

		this.buyerService.removeCartItem(cartItemId).subscribe(
			(cartlistDataRemove) => {
				this.commonService.hideLoader();
                this.productList[index].is_cart = 0;
                this.header.getCartCount();// for decrease card count
				
			}, err => {
				// this.commonService.hideLoader();
				// this.commonService.errorhandler(err);
			});
	}


    // End

}
