import { Component, OnInit } from '@angular/core';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../services/common.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.css']
})
export class ContactComponent implements OnInit {

  faAngleDoubleRight = faAngleDoubleRight;
  constructor(
    private commonService: CommonService,
    private translate: TranslateService,
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Contact');
  }

}
