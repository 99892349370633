import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { HttpHeaders } from '@angular/common/http';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BuyerService {

  apiUrl = environment.apiUrl;
  apiUrlVersionTwo = environment.apiUrlVersionTwo;
  apiUrlVersionThree = environment.apiUrlVersionThree;

  constructor(private http: HttpClient) { }

  confirmationProductData: any = "";

  userAddress: any = "";

  getUserProfile() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}user/my-profile`);
  }

  // editProfile(userData) {
  //   return this.http.put<any>(`${this.apiUrl}user/profile`, userData);
  // }


  editProfileNew(userData) {
    return this.http.put<any>(`${this.apiUrlVersionTwo}user/profile`, userData);
  }

  updateAvatar(userData) {
    return this.http.post<any>(`${this.apiUrl}user/update-avatar`, userData);
  }

  // getUserWishlist(limit, offset) {
  //   return this.http.get<any>(`${this.apiUrl}user/wishlist-list?limit=${limit}&offset=${offset}`);
  // }


  getUserWishlist(limit, offset) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}user/wishlist-list?limit=${limit}&offset=${offset}`);
  }

  //remove wishlist
  removeUserWishlist(productVarintId) {
    return this.http.put<any>(`${this.apiUrl}user/wishlist`, productVarintId);
  }

  //cart list of user
  getUserCartListOld() {
    return this.http.get<any>(`${this.apiUrl}cart`);
  }

  //cart list of user
  getUserCartList() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}cart`);

  }


  //Coupon code list
  getCouponCodeList() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}coupon/coupon-list`);
  }
  //Check coupon code
  checkCouponCode(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}coupon/check-coupon`, formData);
  }


  getUserCartList1(userData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}checkout`, userData);
  }

  //offer cart of user
  getOfferCart(offerId) {
    return this.http.get<any>(`${this.apiUrl}offer/offer-list?offer_id=${offerId}`);
  }

  //Remove cart item
  removeCartItem(cartItemId) {
    return this.http.delete<any>(`${this.apiUrlVersionTwo}cart/${cartItemId}`);
  }

  //cart item increase
  updateCartIncrement(cartItemId, formData) {
    return this.http.put<any>(`${this.apiUrlVersionTwo}cart/alter/${cartItemId}`, formData);
  }




  checkProduct(userId,count,type=""){
    return this.http.get<any>(`${this.apiUrlVersionTwo}/cart-confirmation?user_id=${userId}&count=${count}&type=${type}`);
  }

  //cart item decrease
  updateCartDecrement(cartItemId, formData) {
    return this.http.put<any>(`${this.apiUrlVersionTwo}cart/alter/${cartItemId}`, formData);
  }

  //get user Address
  getUserAddress() {
    return this.http.get<any>(`${this.apiUrl}user/address`);
  }

    //get user Address
    getUserAddressCheck() {
      return this.http.get<any>(`${this.apiUrl}user/address/1`);
    }

  //add user Address
  addUserAddress(formdata) {
    return this.http.post<any>(`${this.apiUrl}user/address`, formdata);
  }

  //add user Address
  addBms(formdata) {
    return this.http.post<any>(`${this.apiUrlVersionThree}bms-pay`, formdata);
  }

  //update address
  updateAddress(addressId, formData) {
    return this.http.put<any>(`${this.apiUrl}user/address/${addressId}`, formData);
  }

  //get card list
  getCardList() {
    return this.http.get<any>(`${this.apiUrl}card-list`);
  }

  //Create customer on stripe 
  createStripeCustomer() {
    return this.http.put<any>(`${this.apiUrl}create-customer`, '');
  }

  //add card
  addCard(formData) {
    return this.http.post<any>(`${this.apiUrl}add-card`, formData);
  }

  //delete card
  deleteCard(cardId) {
    return this.http.delete<any>(`${this.apiUrl}card-delete/${cardId}`);
  }



  //delete card
  deleteWish(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}user/wishlist-delete`, formData);
  }




  //change password
  changePassword(formData) {
    return this.http.post<any>(`${this.apiUrl}user/change-password`, formData);
  }

  //feedback
  writefeedback(formData) {
    return this.http.post<any>(`${this.apiUrl}user/feedback`, formData);
  }

  //delete address
  deleteAddress(addressId) {
    return this.http.delete<any>(`${this.apiUrl}user/address/${addressId}`);
  }

  //save card
  saveCard(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}payment/card`, formData);
  }

    // Delete user profile
    cancelOrder(formData) {
      return this.http.post<any>(`${this.apiUrlVersionTwo}cancel-order`, formData);
    }

  //payment 
  paymentOld(formData) {
    return this.http.post<any>(`${this.apiUrl}payment`, formData);
  }

  payment(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}payment`, formData);
  }

  // //get my order list
  // getMyOrderList(limit, offset, searchTerm) {
  //   return this.http.get<any>(`${this.apiUrl}user/my-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  // }

  //get my order list
  getMyOrderList(limit, offset, searchTerm) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}user/my-order?limit=${limit}&offset=${offset}&searchTerm=${searchTerm}`);
  }
  
  //get my order list
  getVariant(product_id, variantValueid, varianID, product_variant_id, userId) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}product/componate?product_id=${product_id}&variantValueid=${variantValueid}&varianID=${varianID}&product_variant_id=${product_variant_id}&user_id=${userId}`);
  }

  // getMyOrderDetail(orderId) {
  //   return this.http.get<any>(`${this.apiUrl}order/buyer-order-detail/${orderId}`);
  // }


  getMyOrderDetail(orderId) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}order/buyer-order-detail/${orderId}`);
  }

  setRatingReview(formData) {
    return this.http.post<any>(`${this.apiUrl}user/user-rating`, formData);
  }

  changeOrderStatus(orderId, status) {
    return this.http.patch<any>(`${this.apiUrlVersionTwo}seller/change-status/${orderId}`, status);
  }
  addSignature(signatureData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}order/add-signature`, signatureData);
  }

  ReturnOrder(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}user/return-order`, formData);
  }

  getProductDetail(id, userId) {
    return this.http.get<any>(`${this.apiUrl}product/${id}?userID=${userId}`);
  }

  makeAnDefault(cardId, status) {
    return this.http.patch<any>(`${this.apiUrl}card/default/${cardId}`, status);
  }

  cardVerify(formData) {
    return this.http.post<any>(`${this.apiUrl}card/verify`, formData);
  }

  // makeAnOffer(formData){
  //   return this.http.post<any>(`${this.apiUrl}offer/make-offer`,formData);
  // }
  makeAnOffer(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}offer/make-offer`, formData);
  }

  addBizInfo(bizInfo) {
    return this.http.post<any>(`${this.apiUrl}seller/biz-info`, bizInfo);
  }

  getDisputeList() {
    return this.http.get<any>(`${this.apiUrl}dispute/title-list`);
  }

  disputeCreate(disputeData) {
    return this.http.post<any>(`${this.apiUrl}dispute/dispute-info`, disputeData);
  }

  //get product offer
  productOffer(limit, offset) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}offer/offer-list?limit=${limit}&offset=${offset}`);
  }
  // //get product offer
  // productOfferDetails(product_id, variant_id, ) {
  //   return this.http.get<any>(`${this.apiUrlVersionTwo}offer/offer-variant-detail?product_id=${product_id}&variant_id=${variant_id}`);
  // }


  //get product offer
  productOfferDetails(product_id, variant_id, offer_id) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}offer/offer-variant-detail?product_id=${product_id}&variant_id=${variant_id}&offerItem_id=${offer_id}`);
  }



  reSlovedDispute(disputeData) {
    return this.http.post<any>(`${this.apiUrl}dispute/update-dispute-info`, disputeData);
  }



  adminDisputeisRead(formData) {
    return this.http.post<any>(`${this.apiUrl}dispute/admin-notify`, formData);

  }

  // Delete user profile
  deleteUser() {
    return this.http.delete<any>(`${this.apiUrlVersionTwo}delete-user`);
  }
  // Delete User Api End


}
