import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpErrorResponse, HttpBackend } from '@angular/common/http';
import { throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
// import { CommonService } from '../services/common.service';




declare const setValue: any;

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.apiUrl;
  apiUrlVersionTwo = environment.apiUrlVersionTwo;   
  apiUrlVersionThree = environment.apiUrlVersionThree;

  constructor(private http: HttpClient,

  ) {
    
  }
  socialDataCollection: any = {};
  searchTextStore: any = "";

  // set Devive id from local storage when id is null or  balck than set in local storage
  setDeviceId() {
    const userDeviceId = JSON.parse(localStorage.getItem(environment.dataStore.userDeviceId)); // get data from local storage 
    if (userDeviceId == null || userDeviceId == '') {
      localStorage.setItem(environment.dataStore.userDeviceId, JSON.stringify(UUID.UUID())); // set data in local storage
      return true;
    } else {
      return false;
    }
  }

  // get device id frpm local storage
  getDeviceId() {
    const userDeviceId = JSON.parse(localStorage.getItem(environment.dataStore.userDeviceId)); // get dvice id from local storage
    if (userDeviceId != null && userDeviceId != '') {
      return userDeviceId;
    }
  }

  // get offer id  from local storage
  getOfferId() {
    const offerId = JSON.parse(localStorage.getItem(environment.dataStore.offerId));
    if (offerId != null && offerId != '') {
      return offerId;
    } else {
      return false;
    }
  }


  //Get language from local storage
  getLanguage() {
    const userLangauge = JSON.parse(localStorage.getItem(environment.dataStore.userData));


    if (userLangauge != null && userLangauge.user_detail.user_language != '') {
      return userLangauge.user_detail.user_language;
    }else{
      return 'es';

    }
  }

  // check user is logged in or not 
  isLoggedIn() {
    const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));
    if (currentUser != null && currentUser.user_detail.authtoken != '') {
      return true;
    } else {
      return false;
    }
  }

  // get Authorization token 
  getAuthorizationToken() {
    const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));
    return currentUser.user_detail.authtoken;
  }

  // get userid from local storage
  getCurrentUserId() {
    const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));
    return (currentUser != null) ? currentUser.user_detail.userID : 0;
  }

  // api  for add  influnser code 
  redeem_code(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/add-referral`, formData);

  }
  // api for getRefferal code 

  getRefferal(subtotal) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}auth/get-referral?user_id=${this.getCurrentUserId()}&subtotal=${subtotal}`);
  }

  // api for get payment type 
  getPaymentType() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}payment/payment_type`);
  }

  // api for get seller address 
  //@param 
  // seller_id
  
  sellerAddress(seller_id) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}seller/seller-address?seller_id=${seller_id}`);
  }



  // api/v2/payment_new
  //  api for  save data after payment sucessfull 
  //  @ param coupon_code, payment_mode, shipping_charges, subtotal_amount, shipping_address_id, payment_type, transaction_response, status, paypal_transaction_id, is_offer, is_pickup, offer_item_id, stripe_card_id


  // Payment_new(formData) {
  //   return this.http.post<any>(`${this.apiUrlVersionTwo}payment_new`, formData);
  // }




  // version 3 api url
  Payment_new(formData) {
    return this.http.post<any>(`${this.apiUrlVersionThree}payment_new`, formData);
    
  }

    // version 3 api url
    tropiPayLink(formData) {
      return this.http.post<any>(`${this.apiUrlVersionThree}tropipay-payment-link`, formData);
      
    }

  // version 3 api url
  notificationSend(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}/auth/notificationSend`, formData);
  }





  // // // api for create intent for strip payment (exmple googlepay, applepay, credit card)
  // createIntent(formData) {
  //   return this.http.post<any>(`${this.apiUrlVersionTwo}createIntent`, formData);
  // }




  // api for create intent for strip payment (exmple googlepay, applepay, credit card)
  createIntent(formData) {
    return this.http.post<any>(`${this.apiUrlVersionThree}createIntent`, formData);
  }

  // get user detail from local storage 
  getUserDetails() {
    const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));
    if (currentUser != null) {
      return currentUser.user_detail;
    }
  }

  // get full  user info like business info and all
  getFullUserDetails() {
    const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));
    if (currentUser != null) {
      return currentUser;
    }
  }

  // isBizInfoComplete() {
  //   const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));
  //   if(currentUser.isExpired == 0){
  //     if (currentUser != null && Object.keys(currentUser.business_info).length !== 0) {
  //       return true;
  //     } else {
  //       return false;
  //     }
  //   }

  // }

  // get business info from local storage 
  isBizInfoComplete() {
    const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));

    if (currentUser != null && Object.keys(currentUser.business_info).length !== 0) {


      return true;
    } else {
      return false;
    }


  }
  // get social id from local storage
  getUserSocialId() {
    const currentUser = this.getUserDetails();
    if (currentUser != null && currentUser.authToken != '') {
      return currentUser.social_id;
    }
  }
  //  get user role from local storage
  getUserRole() {
    const currentUser = this.getUserDetails();
    if (currentUser != null && currentUser.authToken != '') {
      return currentUser.user_type;
    }
  }
  //  chek email is varify or not 
  getUserEmailVerify() {
    const currentUser = this.getUserDetails();
    if (currentUser != null && currentUser.authToken != '') {
      return currentUser.is_email_verified;
    }
  }

  // check email varification token
  getEmailVerificationToken() {
    const currentUser = this.getUserDetails();
    if (currentUser != null && currentUser.email_verification_token != '') {
      return currentUser.email_verification_token;
    }
  }


  // check phone number is varify or not 
  getUserPhoneVerify() {
    const currentUser = this.getUserDetails();
    if (currentUser != null && currentUser.authToken != '') {
      return currentUser.is_phone_verified;
    }
  }
  // check  profile is switch or not 
  getUserSwitchProfile() {
    const currentUser = this.getUserDetails();
    if (currentUser != null && currentUser.authToken != '') {
      return currentUser.switch_profile;
    }
  }
  // logout function 
  logout() {
    localStorage.removeItem(environment.dataStore.userData); // remove data from local storage
    localStorage.removeItem(environment.dataStore.redirectUri); //remove data from local storage
    localStorage.removeItem(environment.dataStore.userAddress); // remove data from local storage
    localStorage.removeItem(environment.dataStore.userCard); // remove data from local storage
    localStorage.removeItem(environment.dataStore.offerId); //remove data from local storage


    

    setTimeout(() => {
      localStorage.removeItem(environment.dataStore.language); // remove language from local stroage 
      window.location.reload();
    }, 100);
  }
  // api for signup (v1 version)

  //@param 
  signupOld(formData) {
    return this.http.post<any>(`${this.apiUrl}auth/signup`, formData);
  }

  // api for signup 
  signup(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/signup`, formData);
  }

  // api for switch profile 
  switchForm(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/switch-profile-form`, formData);
  }

  // api for add province
  addProvince(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/add-province`, formData);
  }
  // api for login data
  login(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/login`, formData);
  }
  // api for varify phone number otp
  OTPVerifiy(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/verify-phone-otp`, formData);
  }
  // api for varify phone number otp
  OTPVerifiyWithoutLogin(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/verify-phone-otp-web`, formData);
  }

  
  OTPVerifiy_old(formData) {
    return this.http.post<any>(`${this.apiUrl}auth/verify-phone`, formData);
  }

  // api for resend otp
  OTPResend(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/otp-resend`, formData);
  }
  
  //api for varfy email 
  verifyEmail() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}auth/email-verification-status`);
  }

  // api for resend email varification link 
  resendEmail() {
    return this.http.get<any>(`${this.apiUrlVersionTwo}auth/resend-email`);
  }

  // api for send otp on mobile number
  otpSendMobile(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/send-phone-otp`, formData);
  }

  // api for varify otp 
  verifySendOtp(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/verify-phone-otp`, formData);
  }

  reSendOtp(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/otp-resend`, formData);
  }

  logoutWeb() {
    return this.http.delete<any>(`${this.apiUrl}auth/logout`);
  }

  // checkSocialSignup(formData) {
  //   return this.http.put<any>(`${this.apiUrl}auth/check-social-signup`, formData);
  // }


  checkSocialSignup(formData) {
    return this.http.put<any>(`${this.apiUrlVersionTwo}auth/check-social-signup`, formData);
  }

  socialSignupOld(formData) {
    return this.http.post<any>(`${this.apiUrl}auth/social-signup`, formData);
  }

  socialSignup(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}auth/social-signup`, formData);
  }

  changeLanguage(formData) {
    return this.http.post<any>(`${this.apiUrl}auth/change-language`, formData);
  }

  forgotPassword(formData) {
    return this.http.put<any>(`${this.apiUrl}auth/reset-password`, formData);
  }

  getProvinceList() {
    return this.http.get<any>(`${this.apiUrl}auth/province`);
  }

  getMunicipalities(province_ids) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}auth/municipalities?province_ids=${province_ids}`);
  }

  province_update(formData) {
    return this.http.post<any>(`${this.apiUrlVersionTwo}/update-province`, formData);
  }

  switchProfile(userType) {
    return this.http.get<any>(`${this.apiUrlVersionTwo}auth/switch_profile?user_type=${userType}`);
  }

  completeProfile(formData) {
    return this.http.put<any>(`${this.apiUrl}auth/complete-profile`, formData);
  }

  getNotifictionList() {
    return this.http.get<any>(`${this.apiUrl}notification/notification-list`);
  }

  readNotification(formData) {
    return this.http.post<any>(`${this.apiUrl}notification/read-notification`, formData);
  }

  getUserAddress() {
    //get selected address of user
    const userAddress = JSON.parse(localStorage.getItem(environment.dataStore.userAddress));
    if (userAddress != null) {
      return userAddress.addressID;
    } else {
      return false;
    }
  }

  getUserShippingAddress() {
    //get selected address of user
    const userAddress = JSON.parse(localStorage.getItem(environment.dataStore.userAddress));
    if (userAddress != null) {
      return userAddress;
    } else {
      return false;
    }
  }

  getUserCard() {
    //get selected card of user
    const userCard = JSON.parse(localStorage.getItem(environment.dataStore.userCard));
    if (userCard != null) {
      return userCard.cardID;
    } else {
      return false;
    }
  }

  getStripeCustomerID() {  //stripe customer ID
    //get stripe customer ID
    const userDetail = JSON.parse(localStorage.getItem(environment.dataStore.userData));

    if (userDetail.user_detail.stripe_customer_id != "") {
      return userDetail.user_detail.stripe_customer_id;
    } else {
      return false;
    }
  }

  getSellerSubscribed() {

    const userDetail = JSON.parse(localStorage.getItem(environment.dataStore.userData));

    return userDetail.user_detail.is_seller_subscribed;
  }



		// function for  submit code (referral code text field )
		notificationFire(deviceToken,title,body,payload) {
			var formData = new FormData();
			formData.append('deviceToken', deviceToken);
			formData.append('title',title);
			formData.append('body',body);
			formData.append('payload',payload);
			this.notificationSend(formData).subscribe((data) => {
	
			}, err => {
        console.log('error', err)
			});
		}

}
