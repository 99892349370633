import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList} from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../environments/environment';
import { CommonService } from '../services/common.service';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  userRef: AngularFireObject<any>;

  constructor(
    private fbDB:AngularFireDatabase,
    private fireStorage:AngularFireStorage,
    private commonService:CommonService,
    private router: Router,
  ) { }

  userAddFB(userId:any,userData:any){
    this.fbDB.object(`users/${userId}`).set(userData);
  }

  userUpdateFB(userId:any,userData:any){
    this.fbDB.object(`users/${userId}`).update(userData);
  }

  getDataBase(){
    return this.fbDB.database;
  }

  setOffline(userId){
    this.fbDB.object(`users/${userId}`).update({"online":""});
  }

  isOfferExist(chatData:any){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
    return this.fbDB.object(`chat/product_chat/${chat_node}/offer`).query.once("value");
  }

  deleteOfferExist(chatData:any){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
    this.fbDB.object(`chat/product_chat/${chat_node}/offer`).remove();
  }

  makeAnOfferStore(offerData){
    let chat_node = `${offerData.sellerId}-${offerData.buyerId}-${offerData.productId}`;

    let chat_offer = {
      product_id:offerData.productId,
      product_variant_id:offerData.productVariantId,
      offer_price:offerData.offerPrice,
      product_price:offerData.productPrice,
      offer_currency:offerData.currency,
      offer_status:"1",
      counter_price:"0",
      size:offerData.size,
      color:offerData.color,
      quantity:offerData.quantity,
      timestamp:Date.now()
    }

    this.fbDB.object(`products/${offerData.productId}`).query.once("value").then(data => {
      if(data.val()){
        this.fbDB.object(`chat/product_chat/${chat_node}/offer`).set(chat_offer);          
      }else{
        this.commonService.toastError('This product is not longer available. Product deleted by seller');
        this.router.navigate(['/']);
      }
    });

  }
}
