import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderBuyerComponent } from 'src/app/common/header-buyer/header-buyer.component';
import { faAngleDoubleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { BuyerService } from '../services/buyer.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-product-offers',
  templateUrl: './product-offers.component.html',
  styleUrls: ['./product-offers.component.css']
})
export class ProductOffersComponent implements OnInit {
  @ViewChild('header', { static: true }) header: HeaderBuyerComponent;
  offerList: any = [];

  offerCount: any;
  totalOffer: any
  limit = 12;
  offset = 0;

  constructor(
    private commonService: CommonService,
    private buyerService: BuyerService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Product-offer'); //For title
    this.getOfferList(); //For offerlist 

  }

  // Function use for offer list 
  getOfferList() {
    this.commonService.showLoader(); //For show loader 
    this.buyerService.productOffer(this.limit, this.offset).subscribe(offerData => {

      this.commonService.hideLoader(); //For loader hide 
      if (offerData.status == 'success') {
        this.totalOffer = offerData.data.total_records;
        offerData.data.offerList.forEach(element => {
          this.offerList.push(element);
        });

        if (offerData.data.offerList.length == 0) {
          this.offerCount = 0;
        } else {
          this.offerCount = 1;

        }

      }
    }, err => {
      this.commonService.hideLoader(); //For hide loader
      this.commonService.errorhandler(err); //For error
    });
  }
  // End Function 

  // For load more button offer list
  loadMore() {
    this.offset = this.offset + Number(this.limit);
    this.getOfferList();
  }
  // End function 


}
