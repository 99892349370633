import { Component, OnInit } from '@angular/core';
import { faAngleDoubleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { CommonService } from '../../services/common.service';
import { BuyerService } from '../services/buyer.service';
import { environment } from '../../../environments/environment';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;

@Component({
  selector: 'app-user-address',
  templateUrl: './user-address.component.html',
  styleUrls: ['./user-address.component.css']
})
export class UserAddressComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  faCircle = faCircle;
  addFormToggle: boolean = true;
  addressForm: FormGroup;
  addressList: any = [];
  addresslistCount: any;
  addressSelected: string = '';
  country: string = 'United States';
  dial_code: string = '+1';
  country_code: string = 'US';
  initialCountrySet: string = 'US';
  profile_country_code: string = 'US';
  phone_num: any;

  telInputObject(obj) {
    obj.setCountry('US');
  }

  constructor(
    private commonService: CommonService,
    private buyerService: BuyerService,
    private router: Router,
    private fb: FormBuilder,
    private authService: AuthService,
    private translate: TranslateService,
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {

    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Address');

    // For address form validation
    this.addressForm = this.fb.group({
      name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      phone_number: ['', [Validators.required]],
      house_number: ['', [Validators.maxLength(50), Validators.required]],
      street: ['', [Validators.required]],
      city: ['', [Validators.required]],
      pin_code: ['', [Validators.minLength(3), Validators.maxLength(20), Validators.required, Validators.pattern('[0-9]+')]],
    });
    // End

    this.getUserAddress(); //For user address
    //get selected address of user
    let userAddress = this.authService.getUserAddress();
    if (userAddress != false) {
      this.addressSelected = userAddress;
    }
  }

  get name() { return this.addressForm.get('name'); }
  get phone_number() { return this.addressForm.get('phone_number'); }
  get house_number() { return this.addressForm.get('house_number'); }
  get street() { return this.addressForm.get('street'); }
  get city() { return this.addressForm.get('city'); }
  get pin_code() { return this.addressForm.get('pin_code'); }

  //user address list
  getUserAddress() {
    this.commonService.showLoader();

    this.buyerService.getUserAddress().subscribe(
      (useraddressList) => {
        this.commonService.hideLoader();

        if (useraddressList.status == 'success') {
          var keys = Object.keys(useraddressList.data); //Check data is empty object
          var len = keys.length;  //get length of empty object
          if (len === 0) {
            this.addresslistCount = 0;
          }

          this.addressList = useraddressList.data.address_list;

          if (len != 0) {
            this.addressList.forEach(obj => {  //Used when user has deafult address and not selected any address in a session
              //get selected address of user
              let userAddress = this.authService.getUserAddress();
              if (userAddress == false) {
                if (obj.is_default == '1') {
                  this.addressSelected = obj.addressID;
                }
              }
            });
          }
        }
      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
  }
  // End

  hasError($event) {
    if ($event) {
      this.addressForm.patchValue({ phone_number: "1" });
    } else {
      this.addressForm.patchValue({ phone_number: "" });
    }
  }

  // Function for country 
  onCountryChange($event) {
    this.dial_code = `+${$event.dialCode}`;
    this.country = $event.name.split('(')[0];
    this.country_code = $event.iso2.toUpperCase();
  }
  // End

  // Function for phone number
  getNumber($event) {
    this.addressForm.patchValue({ phone_number: $event.split(this.dial_code)[1] });
  }
  // End

  //Validate add address form
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  // End

  //Add Address
  addAddress() {
    if (this.addressForm.valid) {

      this.commonService.showLoader();

      var formData = new FormData();
      formData.append('name', this.addressForm.get('name').value);
      formData.append('phone_number', this.addressForm.get('phone_number').value);
      formData.append('house_number', this.addressForm.get('house_number').value);
      formData.append('street', this.addressForm.get('street').value);
      formData.append('city', this.addressForm.get('city').value);
      formData.append('pincode', this.addressForm.get('pin_code').value);
      formData.append('dial_code', this.dial_code);
      formData.append('country', this.country);
      formData.append('country_code', this.country_code);

      this.buyerService.addUserAddress(formData).subscribe((data) => {
        this.commonService.hideLoader();

        if (data.status == "fail") {
          this.commonService.toastError(data.message);
        } else {
          this.commonService.toastSuccess(data.message);
          this.getUserAddress();
          this.addresslistCount = 1;
          this.getUserProfile();

          this.resetForm();
          this.addFormToggle = true;
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

    } else {
      // validate all form fields
      this.validateAllFormFields(this.addressForm);
    }
  }
  // End

  // For user profile 
  getUserProfile() {
    this.buyerService.getUserProfile().subscribe(data => {
      localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

  //on select address
  onAddressSelect(item, UserAddress) {
    localStorage.removeItem(environment.dataStore.userAddress);
    this.addressSelected = item;
    localStorage.setItem(environment.dataStore.userAddress, JSON.stringify(UserAddress));
  }
  // End

  // Address form Reset function 
  resetForm() {
    this.addressForm.reset();
    $('#phone_num').val('');
  }
  // End

  //continue to checkout page
  toCheckout() {
    if (this.addressSelected == '' || this.addressSelected == undefined) {
      this.commonService.toastError(this.translate.instant('USER_DETAILS.please_select_address'));
    } else {
      // data set 
      let address = this.addressList.find((address) => {
        return address.addressID === this.addressSelected;
      })
      this.onAddressSelect(this.addressSelected, address);

      const str = this.router.url;
      const regex = RegExp('/user-address/offer');

      if (this.authService.getOfferId() && regex.test(str) == true) {
        this.router.navigate(['/checkout/offer']);
      } else {
        this.router.navigate(['/checkout']);
      }

    }
  }

}
