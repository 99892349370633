import { Component, OnInit } from '@angular/core';
import { CommonService } from '../services/common.service';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.css']
})
export class PageNotFoundComponent implements OnInit {

  constructor(
    private commonService: CommonService,
    private translate: TranslateService,
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.setTitle('Mercadoba | Page Not Found');
  }

}
