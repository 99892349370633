import { Component, OnInit, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { faAngleDoubleRight, faCircle } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { BuyerService } from '../services/buyer.service';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { HeaderBuyerComponent } from '../../common/header-buyer/header-buyer.component';
import { TranslateService } from '@ngx-translate/core';

declare var Stripe; // : stripe.StripeStatic;

@Component({
     selector: 'app-card-list',
     templateUrl: './card-list.component.html',
     styleUrls: ['./card-list.component.css']
})
export class CardListComponent implements OnInit, AfterViewInit {
     faAngleDoubleRight = faAngleDoubleRight;
     faCircle = faCircle;
     showAddCard: boolean = true;
     cardList: any = [];
     cardSelected: string = '';
     cardId: any = "";
     addressId: any = "";
     cardListCount: number;

     stripe;
     card;
     cardErrors;
     @ViewChild('cardElement', { static: false }) cardElement: ElementRef; // get stripe data
     @ViewChild('header', { static: true }) header: HeaderBuyerComponent; // access data from header componet

     constructor(
          private commonService: CommonService,
          private buyerService: BuyerService,
          private authService: AuthService,
          private router: Router,
          private translate: TranslateService
     ) { translate.use(this.commonService.getLanguage()); }

     ngOnInit() {
          this.commonService.scrollToTop();
          this.commonService.setTitle('Lasross | card list'); // se titile 

          //get selected card of user
          let userCard = this.authService.getUserCard(); // get user card from local storage 
          if (userCard != false) {
               this.cardSelected = userCard;
          }

          this.getCardList(); // function for cart list 

          let stripeCustomerID = this.authService.getStripeCustomerID(); // get stripr customer id from local storage 
          if (stripeCustomerID == false) { // if id not found 
               // this.addStripeCustomer(); // function for add stripe customer 
          }
     }

     // hooks angular life cycle check condition after view load 
     ngAfterViewInit() {
          // stripe payment
          this.stripe = Stripe(environment.striePkKey); // get stripe key from env file 
          const elements = this.stripe.elements(); // load stripe element

          this.card = elements.create('card'); 
          this.card.mount(this.cardElement.nativeElement);

          this.card.addEventListener('change', ({ error }) => {
               this.cardErrors = error && error.message;
          });
     }

     // function for add stripe customer 
     addStripeCustomer() {
          this.buyerService.createStripeCustomer().subscribe(customerID => { // api calling for create customer on stripe 
               if (customerID.status == 'success') {
                    let userData = this.authService.getFullUserDetails(); // get full details of user from local storage
                    userData.user_detail.stripe_customer_id = customerID.data.stripe_customer_id; // get stripe customer id 
                    localStorage.setItem(environment.dataStore.userData, JSON.stringify(userData)); // set stripe customer id in local stroge 
               }
          }, err => {
               this.commonService.errorhandler(err); // handle  error case 
          });
     }

     // function for get card list 
     getCardList() {
          this.commonService.showLoader(); //  show loader before api calling 

          this.buyerService.getCardList().subscribe(cardData => { // api caling for get card list 
               this.commonService.hideLoader(); // hide loader 
               if (cardData.status == 'success') { // check  api status 
                    this.cardList = cardData.data.card_list; //  assign data into variable 

                    var keys = Object.keys(cardData.data); //Check data is empty object
                    this.cardListCount = keys.length;  //get length of empty object
               }
          }, err => { // error case 
               this.commonService.hideLoader(); // hide loader 
               this.commonService.errorhandler(err); // error handle 
          });
     }

     // add card on stripe form 
     async handleForm(e) {
          this.commonService.showLoader();
          e.preventDefault();

          const { token, error } = await this.stripe.createToken(this.card); // create token 

          if (error) {
               this.commonService.hideLoader();
               // Inform the customer that there was an error.
               const cardErrors = error.message;
          } else {

               // Send the token to your server.
               const formData = new FormData();
               formData.append('sourceId', token.id);

               this.buyerService.addCard(formData).subscribe(cardData => { // call api for add card 
                    this.commonService.hideLoader();

                    if (cardData.status == 'fail') {
                         this.commonService.toastError(cardData.message);
                    } else {
                         this.commonService.toastSuccess(cardData.message);
                         this.resetCard(); // reset form 
                         this.getCardList(); // get card list 
                    }
               }, err => {
                    this.commonService.hideLoader(); // hide loader 
                    this.commonService.errorhandler(err); // handle error 
               });
          }
     }

     // function for reset card form 
     resetCard() {
          this.card.clear();
          this.showAddCard = true;
     }

     //on select card
     onCardSelect(item, Usercard) {
          localStorage.removeItem(environment.dataStore.userCard); // remove  card data from  local stroage  
          this.cardSelected = item;
          localStorage.setItem(environment.dataStore.userCard, JSON.stringify(Usercard)); // set data in local storage 
     }

}
