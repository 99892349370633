import { Injectable } from '@angular/core';
import { AngularFireDatabase, AngularFireObject, AngularFireList} from '@angular/fire/database';
import { AngularFireStorage } from '@angular/fire/storage';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { Observable } from 'rxjs';
import { map, tap,filter } from 'rxjs/operators';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';

declare const sendNotification:any;
declare const showNotification:any;

@Injectable({
  providedIn: 'root'
})
export class FirebaseService {
  productRef: AngularFireObject<any>;
  //productRef: AngularFireList<any>;
  item: Observable<any>;

  sellerId:any;
  currentNode:"";
  allUsers;
  allProducts;
  arr = [];
  arrUser = [];
  usr: any;
  firebaseToken: any;
  token: any ={};
  
  constructor(
    private fbDB:AngularFireDatabase,
    private fireStorage:AngularFireStorage,
    private commonService:CommonService,
    private authService:AuthService,
    private router:Router,
  ) { 
    this.productRef = fbDB.object('Products');
    
    this.sellerId = this.authService.getCurrentUserId();
    this.getWebNotification();
    this.getAllUsers();
    this.getAllProducts();
  }

  getDataBase(){
    return this.fbDB.database;
  }
  
  timeAgo(chatTimeStamp){

    let startDateTime =  new Date(chatTimeStamp);
    let endDateTime = new Date(Date.now());
    
    var diffMs = startDateTime.getTime() - endDateTime.getTime(); // milliseconds between startDateTime & endDateTime

    var totalMins = Math.abs( Math.floor(diffMs / 60000) );

    let timeAgo = "";
    
    if (totalMins == 0) {
      timeAgo = 'just_now';
    }else if (totalMins == 1) {
      timeAgo = `${totalMins} minute`;
    }else if (totalMins >= 2 && totalMins <= 59) {
      timeAgo = `${totalMins} minutes`;
    }else if (totalMins >= 60 && totalMins <= 119) {
      timeAgo = `1 hour`;
    }else if (totalMins >= 120 && totalMins <= 1439) {
      timeAgo = `${(Math.round(totalMins / 60))} hours`;
    }else if (totalMins >= 1440 && totalMins <= 2519) {
      timeAgo = "Yesterday";
    }else if (totalMins >= 2520 && totalMins <= 43199) {
      timeAgo = `${(Math.round(totalMins / 1440))} days`;
    }else if (totalMins >= 43200 && totalMins <= 86399) {
      timeAgo = "1 month";
    }else if (totalMins >= 86400 && totalMins <= 525599) {
      timeAgo = `${(Math.round(totalMins / 43200))} month`;
    }else if (totalMins >= 525600 && totalMins <= 655199) {
      timeAgo = `1 year`;      
    }else if (totalMins >= 655200 && totalMins <= 914399) {
      timeAgo = `over a year`;
    }else if (totalMins >= 914400 && totalMins <= 1051199) {
      timeAgo = `almost 2 years`; 
    }else {
      timeAgo = `${(Math.round(totalMins / 525600))} years`;      
    }
    
    return timeAgo;
  }
  
  userAddFB(userId:any,userData:any){
    this.fbDB.object(`users/${userId}`).set(userData);
  }

  userUpdateFB(userId:any,userData:any){
    this.fbDB.object(`users/${userId}`).update(userData);
  }

  save(productId:number,newName: string) {
    this.fbDB.object(`products/${productId}`).set({ name: newName });
    //this.productRef.push({name:newName})
  }

  productAddFB(productId:number,productData:any){
    this.fbDB.object(`products/${productId}`).set(productData);
  }

  disputeAddOnFb(buyerId:any,chatData:any){

    let chat_node = `${chatData.dispute_order_id}-${chatData.dispute_by}-${buyerId}`;
    this.fbDB.object(`chat/dispute_chat/${chat_node}/detail`).set(chatData);

    let chatMessage = {
      buyer_id:buyerId,
      image:0,
      image_url:"",
      last_sender_id:chatData.dispute_by,
      message:"Dispute created by seller",
      msg_read_tick:0,
      order_id:chatData.dispute_order_id,
      seller_id:chatData.dispute_by,
      timestamp:Date.now()
    }
    this.fbDB.list(`chat/dispute_chat/${chat_node}/messages`).push(chatMessage);
  }

  getDisputeDetail(node){
    return this.fbDB.object(`chat/dispute_chat/${node}/detail`).snapshotChanges();
  }


    disputeUpdateFb(buyerId:any,chatData:any){
      let chat_node = `${chatData.dispute_order_id}-${chatData.dispute_by}-${buyerId}`;


        this.fbDB.object(`chat/dispute_chat/${chat_node}/detail`).query.once("value").then(data => {


          this.fbDB.object(`chat/dispute_chat/${chat_node}/detail`).update({'closed'
          :1,'favour_of_user_type' : "buyer", 'favour_of' : buyerId,'resloved_by':chatData.dispute_by,'resloved_of_user_type':"seller",'closed_timestamp':Date.now()});


        
        });
    }

  getDisputeChat(node){
    return this.fbDB.list<any>(`chat/dispute_chat/${node}/messages`).snapshotChanges().pipe(
      map(changes=>
          changes.map(c=>(
            {
              key: c.payload.key,
             ...c.payload.val() 
            }
          ))
        ),
        map(data=>{
          data.forEach(element => {
            let keyData;
  
            if(element.last_sender_id!="A"){
              this.chatHistoryData(element.last_sender_id,'2').subscribe(allData=>{
                keyData = allData.payload.val();
                element.historyName = keyData.full_name;  
              })
            }else{
              element.historyName = "Admin";
            }

          });
  
          return data;
      }),
      // map( (arr) => { return arr.reverse(); } )  
    );
  }
  // add  dispute message on firebase 
  sendDisputeMessage(chat_node,chatMessage, orderDetail, downloadURL = ""){
    this.fbDB.list(`chat/dispute_chat/${chat_node}/messages`).push(chatMessage);


    this.getFirebaseToken(chatMessage.buyer_id).subscribe(userData => {
      this.token = userData.payload.val();

    });


    this.getSellerDetails(chatMessage.seller_id).subscribe(data => {
			this.usr = data.payload.val();


			let chatData = {
				'message': chatMessage.message,
				'time': Date.now(),
				'buyerId': chatMessage.buyer_id,
				'sellerId': chatMessage.seller_id,
				'orderId': orderDetail.orderID,
				'sellerName': this.usr.seller_name,
				'orderNumber': orderDetail.number,
				'firebaseToken': this.token.firebase_token,



			}


			this.sendNotification(chatData, '2', downloadURL, 'dispute');

		});
  }


	getSellerDetails(id) {
		return this.fbDB.object(`users/${id}`).snapshotChanges();
	}

  productDelete(productId:number){
    this.fbDB.object(`products/${productId}`).remove();
  }

  recentlyProductDelete(productId:number){
    this.fbDB.object(`products/${productId}`).update({'recently_deleted':1});
  }

  restoreProductDelete(productId:number){
    this.fbDB.object(`products/${productId}`).update({'recently_deleted':0});
  }

  getAllUsers(){
   
    this.fbDB.object(`users`).query.once("value")
    .then(actions =>{
      this.allUsers = actions.val();
      this.arrUser = Object.values(this.allUsers);
    });
  }

  getAllProducts(){
    this.fbDB.object(`products`).query.once("value")
    .then(actions => {
    
      this.allProducts = actions.val();
      // this.arr = Object.values(this.allProducts);
    });
  }

  getChatHistoryList(chatData:any){


    return this.fbDB.list<any>(`chat_history/${chatData.sellerId}`, ref => ref.orderByChild("seller_id").equalTo(this.sellerId)).snapshotChanges().pipe(

      map(changes => 
        changes.map(c => (
          { 
            
            key: c.payload.key,
             ...c.payload.val() 
          }

        ))
        
      ),
      map(data=>{

        
        data.forEach(element => {


          
          let keyData;

          let chatType = (element.chat_type=='product')? '3' : '2';
          let id = (element.chat_type=='product')? element.key.split('-')[2] : element.key.split('-')[1];
          //if(chatType == '3'){
          
            this.arr.forEach((value:any) => {
              if(value.product_id == id){
                element.historyImage = value.feature_image_url+'thumb/'+value.feature_image; 
                element.historyName = value.name;
              }
            });
          //}else if(chatType == '2'){
            this.arrUser.forEach((val:any) => {
              

              if(val.user_id == element.key.split('-')[1]){

                element.historyUserName = val.full_name;
                element.isOnline = val.online;

                if((element.chat_type!='product')){
                  element.historyImage = val.avatar;
                  // element.historyName = val.buyer_name; //  comment for chat name not found 
                  element.historyName = val.full_name;
                  // element.isOnline = val.online;

                }

              }

            });
         

          this.productNodeDelete(element);
                 
        });
        return data;
      }),

      map( (arr) => { return arr.reverse(); } )
    );
  }

  productNodeDelete(data){
    if(data.chat_type=='product'){
      let chatHistoryNode = data.key
      let productId = data.key.split('-')[2];
      let sellerId = data.key.split('-')[0];
      let productChatNode = data.key.split('-P')[0];
      
      this.fbDB.object(`products/${productId}`).query.once("value").then(data => {
        if(!data.val()){
          this.fbDB.object(`chat/product_chat/${productChatNode}`).remove();          
          this.fbDB.object(`chat_history/${sellerId}/${chatHistoryNode}`).remove();          
        }
      });

    }
  }

  checkDeleteMessage(chatData:any,chatType){

    if(chatType=='2'){ // for order
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;
      return this.fbDB.object(`chat/order_chat/${chat_node}/delete/${chatData.sellerId}`).snapshotChanges();
    }else if(chatType=='3'){  // for product
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
      return this.fbDB.object(`chat/product_chat/${chat_node}/delete/${chatData.sellerId}`).snapshotChanges();
    }

  }

  getOrderChatList(chatData:any){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;

    if(chatData.lastTimeStamp){
      return this.fbDB.list<any>(`chat/order_chat/${chat_node}/messages`,ref => ref.orderByChild('timestamp').startAt(chatData.lastTimeStamp)).snapshotChanges().pipe(
        map(changes => 
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        ),
        map(chat=>{
          this.updateUnreadStatus(chatData,`${chat_node}-O`);
          return chat;
        })
      );
    }else{
      return this.fbDB.list<any>(`chat/order_chat/${chat_node}/messages`).snapshotChanges().pipe(
        map(changes => 
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        ),
        map(chat=>{
          this.updateUnreadStatus(chatData,`${chat_node}-O`);
          return chat;
        })
      );
    }

  }

  getProductChatList(chatData:any){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;

    if(chatData.lastTimeStamp){
      return this.fbDB.list<any>(`chat/product_chat/${chat_node}/messages`,ref => ref.orderByChild('timestamp').startAt(chatData.lastTimeStamp)).snapshotChanges().pipe(
        map(changes => 
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        ),
        map(chat=>{
          this.updateUnreadStatus(chatData,`${chat_node}-P`);
          return chat;
        })
      );
    }else{
      return this.fbDB.list<any>(`chat/product_chat/${chat_node}/messages`).snapshotChanges().pipe(
        map(changes => 
          changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
        ),
        map(chat=>{
          this.updateUnreadStatus(chatData,`${chat_node}-P`);
          return chat;
        })
      );
    }
  }

  sendOrderMsg(chatData:any,downloadURL=''){

    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;
    let imageUrl = downloadURL ? downloadURL : "";
    let image = downloadURL ? 1 : 0;
    let message = downloadURL ? "" : chatData.message;

    let chat_message = {
      order_id:chatData.orderId,
      seller_id: chatData.sellerId,
      buyer_id: chatData.buyerId,
      message: message,
      last_sender_id: chatData.sellerId,
      image: image,
      image_url: imageUrl,
      msg_read_tick: 0,
      timestamp:Date.now()
    }

    this.fbDB.list(`chat/order_chat/${chat_node}/messages`).push(chat_message);
    this.chatHistory(chatData,'2',downloadURL);
    this.sendNotification(chatData,'2',downloadURL);

    this.setDeleteForeverNode(chatData,'O');
  }

  sendProductMsg(chatData:any,downloadURL=''){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
    let imageUrl = downloadURL ? downloadURL : "";
    let image = downloadURL ? 1 : 0;
    let message = downloadURL ? "" : chatData.message;
  
    let chat_message = {
      product_id:chatData.productId,
      seller_id: chatData.sellerId,
      buyer_id: chatData.buyerId,
      message: message,
      last_sender_id: chatData.sellerId,
      image: image,
      image_url: imageUrl,
      msg_read_tick: 0,
      timestamp:Date.now()
    }

    this.fbDB.list(`chat/product_chat/${chat_node}/messages`).push(chat_message);
    this.chatHistory(chatData,'3',downloadURL);
    this.sendNotification(chatData,'3',downloadURL);
    this.setDeleteForeverNode(chatData,'P');
  }

  chatHistory(chatData,chatType,downloadURL=''){
    let chat_history_node;
    let chat_history;

    if(chatType=='2'){ // for order

      chat_history_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}-O`;
      let imageUrl = downloadURL ? downloadURL : "";
      let image = downloadURL ? 1 : 0;
      let message = downloadURL ? "" : chatData.message;
      
      chat_history = {
        chat_type:'order',
        last_message: message,
        user_id: chatData.buyerId,
        buyer_id: chatData.buyerId,
        seller_id: chatData.sellerId,
        order_number: chatData.orderNumber,
        history_id: chatData.orderId,
        image: image,
        image_url: imageUrl,
        unread_count: 0,
        typing:0,
        offer:0,
        timestamp:Date.now()
      }

    }
    this.fbDB.object(`chat_history/${chatData.sellerId}/${chat_history_node}`).set(chat_history);
    // this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).set(chat_history);
    this.setUnread(chatData,chat_history,chat_history_node);
  }

  setUnread(chatData,chat_history,chat_history_node) {
    this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).query.once("value").then(data => {
      chat_history.unread_count = 1;
      chat_history.user_id = chatData.sellerId;

      if(data.val()){
        let count = Number(data.val().unread_count) + Number(1);
        chat_history.unread_count = count;
        this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).set(chat_history);
      }else{
        this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).set(chat_history);
      }
      
    });
  }

  updateUnreadStatus(chatData,chat_history_node) {
    setTimeout(() => {
      this.fbDB.object(`chat_history/${chatData.sellerId}/${chat_history_node}`).query.once("value").then(data => {
        if(data.val()){
  
          if(data.val().unread_count > 0){
            this.fbDB.object(`chat_history/${chatData.sellerId}/${chat_history_node}`).update({'unread_count':0});
          }
  
        }
      });
    }, 800);

  }

  getProductOffer(chatData:any){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;

    return this.fbDB.object(`chat/product_chat/${chat_node}/offer`).snapshotChanges();
  }

  offerAcceptReject(offerData:any){
    let chat_node = `${offerData.sellerId}-${offerData.buyerId}-${offerData.productId}`;

    if(offerData.status=='1'){ // for accept
      let chat_offer = {
        offer_status:"3",
        timestamp:Date.now()
      }
  
      this.fbDB.object(`chat/product_chat/${chat_node}/offer`).update(chat_offer);
    }else{ // for reject
      this.fbDB.object(`chat/product_chat/${chat_node}/offer`).remove();
      this.offerRemoveHistory(offerData);
    }

  }

  offerRemoveHistory(offerData){
    let chat_node = `${offerData.sellerId}-${offerData.buyerId}-${offerData.productId}`;
    this.fbDB.object(`chat_history/${offerData.sellerId}/${chat_node}-P`).update({'offer':0});
    this.fbDB.object(`chat_history/${offerData.buyerId}/${chat_node}-P`).update({'offer':0});
  }

  msgReadTick(chatData:any,chatType){
    if(chatType=='2'){ // order
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;

      this.fbDB.object(`chat/order_chat/${chat_node}/messages/${chatData.key}`).query.once("value").then(data => {
        if(data.val()){
          this.fbDB.object(`chat/order_chat/${chat_node}/messages/${chatData.key}`).update({'msg_read_tick':1});
        }
      });

    }else if(chatType=='3'){ // product
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;

      this.fbDB.object(`chat/product_chat/${chat_node}/messages/${chatData.key}`).query.once("value").then(data => {
        if(data.val()){
          this.fbDB.object(`chat/product_chat/${chat_node}/messages/${chatData.key}`).update({'msg_read_tick':1});
        }
      });

    }
  }

  // chatHistoryData(userId){
  //   return this.fbDB.object(`users/${userId}`).snapshotChanges();
  // }

  chatHistoryData(id,type){
    if(type=='2'){ // order
      return this.fbDB.object(`users/${id}`).snapshotChanges();
    }else if(type=='3'){ // product
      return this.fbDB.object(`products/${id}`).snapshotChanges();
    }

    
  }

  chatNoteget(id,chat_node){
      return this.fbDB.object(`chat_history/${id}/${chat_node}`).snapshotChanges();
  }

  deleteChat(chatData:any,chatType){

    if(chatType=='2'){ // for order
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;
      this.fbDB.object(`chat/order_chat/${chat_node}/delete/${chatData.sellerId}`).update({'timestamp':chatData.timestamp});
      this.fbDB.object(`chat_history/${chatData.sellerId}/${chat_node}-O`).update({'last_message':""});
    }else if(chatType=='3'){  // for product
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
      this.fbDB.object(`chat/product_chat/${chat_node}/delete/${chatData.sellerId}`).update({'timestamp':chatData.timestamp});
      this.fbDB.object(`chat_history/${chatData.sellerId}/${chat_node}-P`).update({'last_message':""});
    }

  }

  blockUser(chatData:any){
    let chat_node = (Number(chatData.sellerId) > Number(chatData.buyerId))? `${chatData.buyerId}-${chatData.sellerId}` : `${chatData.sellerId}-${chatData.buyerId}`;

    this.fbDB.object(`block_users/${chat_node}`).query.once("value").then(data => {
    
      if(data.val()){
        this.fbDB.object(`block_users/${chat_node}`).update({'blocked_by':'Both'});
      }else{
        this.fbDB.object(`block_users/${chat_node}`).update({'blocked_by':chatData.sellerId});
      }
      
    });

  }

  unBlockUser(chatData:any){
    let chat_node = (Number(chatData.sellerId) > Number(chatData.buyerId))? `${chatData.buyerId}-${chatData.sellerId}` : `${chatData.sellerId}-${chatData.buyerId}`;

    this.fbDB.object(`block_users/${chat_node}`).query.once("value").then(data => {
      var block_id = data.val().blocked_by;

      if(block_id == 'Both'){
        this.fbDB.object(`block_users/${chat_node}`).update({'blocked_by':chatData.buyerId});
      }else{
        if (block_id == chatData.sellerId) {
          this.fbDB.object(`block_users/${chat_node}`).set(null);
        }
      }
      
    });

  }

  getBlockStatus(chatData:any){
    let chat_node = (Number(chatData.sellerId) > Number(chatData.buyerId))? `${chatData.buyerId}-${chatData.sellerId}` : `${chatData.sellerId}-${chatData.buyerId}`;
    return this.fbDB.object(`block_users/${chat_node}`).snapshotChanges();
  }

  setTyping(chatData,chatType,flag){

    if(chatType=='2'){ // for order

      let chat_history_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}-O`;
      this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).query.once("value").then(data => {
        if(data.val()){
          this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).update({'typing':flag});
        }
      });

    }else if(chatType=='3'){ // for product

      let chat_history_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}-P`;
      this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).query.once("value").then(data => {
        if(data.val()){
          this.fbDB.object(`chat_history/${chatData.buyerId}/${chat_history_node}`).update({'typing':flag});
        }
      });

    }

  }

  counterOfferStore(offerData:any){

    let chat_node = `${offerData.sellerId}-${offerData.buyerId}-${offerData.productId}`;

    let chat_offer = {
      offer_status:"2",
      counter_price:offerData.counterPrice
    }

    this.fbDB.object(`products/${offerData.productId}`).query.once("value").then(data => {
      if(data.val()){
        this.fbDB.object(`chat/product_chat/${chat_node}/offer`).update(chat_offer);          
      }else{
        this.commonService.toastError('This product is not longer available. Product deleted by seller');
        this.router.navigate(['/']);
      }
    });
  }

  getBuyerDetails(id){
    return this.fbDB.object(`users/${id}`).snapshotChanges();
  }




  getFirebaseToken(buyerId){
    return this.fbDB.object(`users/${buyerId}`).snapshotChanges();
  }


  








    sendNotification(chatData, chatType, downloadURL = "", dispute = "") {

    if(chatData.firebaseToken){
        // let chatTitle = (chatType=='3')? `${chatData.sellerName} (Dispute)-  @${chatData.productName}` : `${chatData.sellerName} @${chatData.orderNumber}`;



        let notification = {
            'title'         : '',
            'body'          : downloadURL ? 'Photo' : chatData.message,
            'type'          : '',
            'sender_name'   : '',
            'message'       : chatData.message,
            'time'          : Date.now(),
            'sellerId'      : chatData.sellerId,
            'buyerId'       : chatData.buyerId,
            'reference_id'  : (chatType=='3')? chatData.productId:chatData.orderId,
            'click_action'  : '',
            'sound'         : 'default'
      }

   
      if(dispute !=""){
				notification.click_action = "Dispute";
				notification.type = "order";

				notification.title = (chatType=='3')? `${chatData.sellerName} (Dispute)-  @${chatData.productName}` : `${chatData.sellerName} (Dispute)- @${chatData.orderNumber}`;


				notification.sender_name = (chatType=='3')? `${chatData.sellerName} (Dispute)-  @${chatData.productName}` : `${chatData.sellerName} (Dispute)- @${chatData.orderNumber}`;


			}else{
				notification.click_action = (chatType == '3') ? 'product' : 'order'
				notification.type = 'chat'

        notification.title = (chatType=='3')? `${chatData.sellerName} @${chatData.productName}` : `${chatData.sellerName} @${chatData.orderNumber}`;


        notification.sender_name = (chatType=='3')? `${chatData.sellerName} @${chatData.productName}` : `${chatData.sellerName} @${chatData.orderNumber}`;


				
			}

        // match user type 
        this.fbDB.object(`users/${chatData.buyerId}`).query.once("value").then(data => {

          if(data.val()){
            let userdata = data.val();

            if(userdata.user_type=="buyer" && userdata.notification_status=='1'){
              this.authService.notificationFire(chatData.firebaseToken,notification.title,notification.body,JSON.stringify(notification))

              // sendNotification(environment.notificationKey,chatData.firebaseToken,notification,notification);
            }
          }
          
        });

    }else{
        // let chatTitle = (chatType=='3')? `${chatData.sellerName} @${chatData.productName}` : `${chatData.sellerName} @${chatData.orderNumber}`;


        // let chatTitle = (chatType=='3')? `${chatData.sellerName} (Dispute)-  @${chatData.productName}` : `${chatData.sellerName} @${chatData.orderNumber}`;

        let webNotification = {
          'title'         : '',
          'body'          : downloadURL ? 'Photo' : chatData.message,
          'sellerId'      : chatData.sellerId,
          'buyerId'       : chatData.buyerId,
          'reference_id'  : (chatType=='3')? chatData.productId:chatData.orderId,
          'click_action'  : '',
        };

        if(dispute !=""){
          webNotification.click_action = "Dispute";
  
          webNotification.title = (chatType=='3')? `${chatData.sellerName} (Dispute)-  @${chatData.productName}` : `${chatData.sellerName} (Dispute)- @${chatData.orderNumber}`;
  
  
        
          
  
        }else{
          webNotification.click_action = (chatType == '3') ? 'product' : 'order'
  
              webNotification.title = (chatType=='3')? `${chatData.sellerName} @${chatData.productName}` : `${chatData.sellerName} @${chatData.orderNumber}`;
  
  
  
          
        }
  
  

        // match user type 
        this.fbDB.object(`users/${chatData.buyerId}`).query.once("value").then(data => {

          if(data.val()){
            let userdata = data.val();

            if(userdata.user_type=="buyer"){
              this.fbDB.list(`web_notification/${chatData.buyerId}`).push(webNotification);
            }
          }
          
        });
        
    }

    this.setBadgeCount(chatData);
  }

  setBadgeCount(chatData) {
    this.fbDB.object(`badge_count/${chatData.buyerId}`).query.once("value").then(data => {

      if(data.val()){
        let count = Number(data.val().count) + Number(1);
        this.fbDB.object(`badge_count/${chatData.buyerId}`).set({'count':count});
      }else{
        this.fbDB.object(`badge_count/${chatData.buyerId}`).set({'count':1});
      }
      
    });
  }

  orderRemoveFirebase(orderData){
    let order_node = `${orderData.sellerId}-${orderData.buyerId}-${orderData.orderId}`;
    this.fbDB.list(`chat/order_chat/${order_node}`).remove();
    this.fbDB.list(`chat_history/${orderData.sellerId}/${order_node}-O`).remove();
    this.fbDB.list(`chat_history/${orderData.buyerId}/${order_node}-O`).remove();

    this.fbDB.object(`order_delete/${orderData.orderId}`).set({'order_id':orderData.orderId});
  }

  getWebNotification(){
    let notifiyData = {
      'icon':environment.siteUrl+'/assets/img/common/Logo_img.png',
    }
    this.fbDB.list<any>(`web_notification/${this.sellerId}`).snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      )
    ).subscribe(notification=>{
      // change buyer message from unread to read.
      notification.forEach(element => {
        showNotification(element,notifiyData);
        this.fbDB.list(`web_notification/${this.sellerId}/${element.key}`).remove();
      });

    });
  }


  //// Code for recent delete //////
  setDeleteForeverNode(chatData,chatType){

    if(chatType=='P'){
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
  
      this.fbDB.object(`chat/product_chat/${chat_node}/delete_forever`).snapshotChanges().subscribe(deleteForever=>{
  
        if(deleteForever.key!='delete_forever'){
          let deleteForeverNode = {
            timestamp:0
          }
          this.fbDB.object(`chat/product_chat/${chat_node}/delete_forever/${chatData.buyerId}`).set(deleteForeverNode);
          this.fbDB.object(`chat/product_chat/${chat_node}/delete_forever/${chatData.sellerId}`).set(deleteForeverNode);
        }
  
      });
    }else{
      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;
  
      this.fbDB.object(`chat/order_chat/${chat_node}/delete_forever`).snapshotChanges().subscribe(deleteForever=>{
  
        if(deleteForever.key!='delete_forever'){
          let deleteForeverNode = {
            timestamp:0
          }
          this.fbDB.object(`chat/order_chat/${chat_node}/delete_forever/${chatData.buyerId}`).set(deleteForeverNode);
          this.fbDB.object(`chat/order_chat/${chat_node}/delete_forever/${chatData.sellerId}`).set(deleteForeverNode);
        }
  
      });
    }
    
  }

  // get product chat Data
  getProductChatData(chat_node){
    return this.fbDB.object(`chat/product_chat/${chat_node}`).snapshotChanges();
  }

  // get Order chat Data
  getOrderChatData(chat_node){
    return this.fbDB.object(`chat/order_chat/${chat_node}`).snapshotChanges();
  }

  getTrashProductChatList(chatData:any){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;

    return this.fbDB.list<any>(`chat/product_chat/${chat_node}/messages`,ref => ref.orderByChild('timestamp').startAt(chatData.deleteForeverTimestamp).endAt(chatData.deleteTimestamp)).snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      ),
      map(chat=>{
        this.updateUnreadStatus(chatData,`${chat_node}-P`);
        return chat;
      })
    );

  }

  getTrashOrderChatList(chatData:any){
    let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;

    return this.fbDB.list<any>(`chat/order_chat/${chat_node}/messages`, ref => ref.orderByChild('timestamp').startAt(chatData.deleteForeverTimestamp).endAt(chatData.deleteTimestamp)).snapshotChanges().pipe(
      map(changes => 
        changes.map(c => ({ key: c.payload.key, ...c.payload.val() }))
      ),
      map(chat=>{
        this.updateUnreadStatus(chatData,`${chat_node}-O`);
        return chat;
      })
    );

  }

  deleteForeverChat(chatData:any,chatType){

    if(chatType=='2'){ // for order

      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;
      return this.fbDB.object(`chat/order_chat/${chat_node}/delete_forever/${chatData.sellerId}`).update({'timestamp':chatData.timestamp});
      
    }else if(chatType=='3'){ // for product

      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;
      return this.fbDB.object(`chat/product_chat/${chat_node}/delete_forever/${chatData.sellerId}`).update({'timestamp':chatData.timestamp});
      
    }

  }

  deleteRestore(chatData:any,chatType){

    if(chatType=='2'){ // for order

      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.orderId}`;

      if(chatData.timestamp=='0'){
        return this.fbDB.object(`chat/order_chat/${chat_node}/delete/${chatData.sellerId}`).remove();
      }else{
        return this.fbDB.object(`chat/order_chat/${chat_node}/delete/${chatData.sellerId}`).update({'timestamp':chatData.timestamp});
      }
      
    }else if(chatType=='3'){ // for product

      let chat_node = `${chatData.sellerId}-${chatData.buyerId}-${chatData.productId}`;

      if(chatData.timestamp=='0'){
        return this.fbDB.object(`chat/product_chat/${chat_node}/delete/${chatData.sellerId}`).remove();
      }else{
        return this.fbDB.object(`chat/product_chat/${chat_node}/delete/${chatData.sellerId}`).update({'timestamp':chatData.timestamp});

      }
      
    }
  }
}
