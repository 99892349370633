import { Injectable } from '@angular/core';
import { AngularFireAuth } from "@angular/fire/auth";
import { AngularFireDatabase } from '@angular/fire/database';
import { Observable } from 'rxjs';

@Injectable({
	providedIn: 'root'
})

export class AuthenticationService {
	userData: Observable<firebase.User>;

	constructor(private angularFireAuth: AngularFireAuth,
		private fbDB: AngularFireDatabase,
	) {
		this.userData = angularFireAuth.authState;
	}



	/* Sign up */
	SignUp(email: string, password: any) {
		console.log('email', email);
		console.log('password', password);

		this.angularFireAuth.auth.createUserWithEmailAndPassword(email, password)

			.then(res => {

				console.log('You are Successfully signed up!', res);
			})
			.catch(error => {
				console.log('Something is wrongneha:', error.message);
			});
	}

	/* Sign in */
	SignIn(email: string, password: string) {
		this.angularFireAuth
			.auth
			.signInWithEmailAndPassword(email, password)
			.then(res => {

				console.log('res', res.user.uid)
				console.log('You are Successfully logged in!');
			})
			.catch(err => {
				this.SignUp(email, password);
				console.log('Something is wrong123:', err.message);
			});
	}

	/* Sign out */
	SignOut() {
		this.angularFireAuth
			.auth
			.signOut();
	}

}
