import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { SellerService } from '../services/seller.service';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare const $: any;
declare const checkLicenseImageSize: any;

@Component({
  selector: 'app-add-business',
  templateUrl: './add-business.component.html',
  styleUrls: ['./add-business.component.css']
})
export class AddBusinessComponent implements OnInit {
  faCheck = faCheck;
  faTimes = faTimes;
  addBusinessForm: FormGroup;
  userDetail: any;
  // country_code: string = 'IN';
  initialCountrySet: string = 'US';


  dial_code: string = '+1';
  dial_code_address: string = '+1';
  // country: string = 'Cuba';
  country_code: string = 'US';

  ShowFilter = false;
  disabled = false;
  dropdownProvinceSettings: any = {};
  provinceList: any = [];
  selectedItems = [];
  imageChangedEvent: any = '';
  croppedImage: any = 'assets/img/common/image_placeholder.png';
  cropImageShow: boolean = false;
  isCod: any = 0;
  isPickup: any = 0;
  directPay: any = 0;


  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private commonService: CommonService,
    private sellerService: SellerService,
    private router: Router,
    private firebaseService: FirebaseService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,

  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {

    if(this.authService.isLoggedIn() == false){
      this.router.navigate(['/']);

    }
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Add Bussiness');
    if (this.authService.isBizInfoComplete()) {
      this.router.navigate(['seller/new-orders']);
    }

    this.dropdownProvinceSettings = {
      singleSelection: false,
      idField: 'provinceID',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter
    };

    this.userDetail = this.authService.getUserDetails();
    // this.initialCountrySet = this.userDetail.profile_country_code.toLowerCase();
    this.initialCountrySet = 'US';

    this.country_code = this.userDetail.profile_country_code;


    //,Validators.pattern('^[a-zA-Z \-\']+')
    this.addBusinessForm = this.fb.group({
      business_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      //  business_license: ['',Validators.required],
      business_address: ['',],
      business_address_lat: ['',],
      business_address_long: ['',],
      country: ['United States', [Validators.required]],
      province: ['',],
      business_city: ['', [Validators.required, Validators.maxLength(50)]],
      business_zip_code: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(30)]],
      licenseImage: [''],
    });

    this.getProvinceList();
    this.selectedProvinceShow();
  }
  checkChar(e) {
    e.preventDefault();
  }

  getProvinceList() {
    this.authService.getProvinceList()
      .subscribe(provinceData => {
        this.commonService.hideLoader();

        if (provinceData.status == 'success') {
          this.provinceList = provinceData.data.province;
        } else {
          this.provinceList = [];
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
  }

  onCountryChange($event) {
    this.addBusinessForm.patchValue({ country: $event.name.split('(')[0] });
    this.country_code = $event.iso2.toUpperCase();
  }

  onKeyUp(event) {
    return false;
  }

  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];

      this.addBusinessForm.get('licenseImage').setValue(file);
      // var reader = new FileReader();
      // reader.addEventListener("load", function () {
      //   $('#license-preview').attr('src',reader.result);
      // }, false);
      // reader.readAsDataURL( event.target.files[0]);

    }
  }

  	// empty value validation 
	trimValue(formControl) {
		((typeof formControl.value) && formControl.value.trim() == "") ?
			formControl.setValue("") : formControl.setValue(formControl.value);
	}

  handleAddressChange(address): void {
    this.addBusinessForm.get('business_address').setValue(address.formatted_address);
    this.addBusinessForm.get('business_address_lat').setValue(address.geometry.location.lat());
    this.addBusinessForm.get('business_address_long').setValue(address.geometry.location.lng());
  }

  get business_name() { return this.addBusinessForm.get('business_name'); }
  get business_address() { return this.addBusinessForm.get('business_address'); }
  get business_city() { return this.addBusinessForm.get('business_city'); }
  get business_zip_code() { return this.addBusinessForm.get('business_zip_code'); }
  //get business_license() { return this.addBusinessForm.get('business_license'); }
  get country() { return this.addBusinessForm.get('country'); }
  get province() { return this.addBusinessForm.get('province'); }
  get licenseImage() { return this.addBusinessForm.get('licenseImage'); }

  selectedProvinceShow() {
    // let selectedArray = [];
    // let provinceIdArray = this.userDetail.province.split(',');
    // let provinceNameArray = this.userDetail.province_name.split(',');

    // provinceIdArray.forEach((element, key) => {
    //   selectedArray.push({ name: provinceNameArray[key].trim(), provinceID: element.trim() });
    // });

    // this.selectedItems = selectedArray;

    // this.addBusinessForm.patchValue({
    //   province: this.selectedItems,
    // });
  }

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }

  // Is cod payment
  payModeCod(event) {
    if (this.isCod == 0) {
      this.isCod = 1
    } else {
      this.isCod = 0
    }

  }

  // Is pickup Option
  pickupOption(event) {
    if (this.isPickup == 0) {
      this.isPickup = 1
    } else {
      this.isPickup = 0
    }
  }

  // Is Direct pay Option
  directPayOption(event) {
    if (this.directPay == 0) {
      this.directPay = 1
    } else {
      this.directPay = 0
    }
  }


  businessForm() {

    this.commonService.showLoader();

    if (this.addBusinessForm.valid) {
      // this.commonService.showLoader();
      const formData = new FormData();

      if (this.croppedImage != 'assets/img/common/image_placeholder.png') {


        var block = this.croppedImage.split(";");
        // Get the content type of the image
        var contentTypes = block[0].split(":")[1];// In this case "image/png"
        // get the real base64 content of the file
        var realData = block[1].split(",")[1];
        var blobImg = b64toBlob(realData, contentTypes, '');
        formData.append('license', blobImg, 'licenseImage.png');
      }
      // let formData = `business_name=${escape(this.addBusinessForm.get('business_name').value)}&license=${escape(this.addBusinessForm.get('business_license').value)}&address=${escape(this.addBusinessForm.get('business_address').value)}&latitude=${this.addBusinessForm.get('business_address_lat').value}&longitude=${this.addBusinessForm.get('business_address_long').value}`;

      // let provinceArray = this.commonService.ArrayFilter(this.addBusinessForm.get('province').value,3);//old province code
      let province = this.addBusinessForm.get('province').value;
      // let province = provinceArray.join();

      formData.append('business_name', this.addBusinessForm.get('business_name').value);
      formData.append('zip_code', this.addBusinessForm.get('business_zip_code').value);
      formData.append('city', this.addBusinessForm.get('business_city').value);
      formData.append('address', this.addBusinessForm.get('business_address').value);
      formData.append('country', this.addBusinessForm.get('country').value);
      formData.append('province', province);
      formData.append('redirect_from', '0');
      formData.append('country_code', this.country_code);
      // formData.append('pay_mode', '1');
      // formData.append('delivery_mode', '1');
      formData.append('is_cod', this.isCod);
      formData.append('is_pickup', this.isPickup);
      formData.append('is_direct_pay', this.directPay);
      formData.append('license', this.addBusinessForm.get('licenseImage').value);

      // return false
      this.sellerService.addBizInfo(formData).subscribe(data => {
        this.commonService.hideLoader();
        if (data.status == 'success') {
          let userData = this.authService.getFullUserDetails();
          userData.business_info = data.data.business_info;
          localStorage.setItem(environment.dataStore.userData, JSON.stringify(userData));
          this.addOnFirebase(userData, data.data.business_info);
          this.commonService.toastSuccess(data.message);

          this.router.navigate(['seller/new-orders']);
          // add business


        } else {
          this.commonService.hideLoader();

          this.commonService.toastError(data.message);
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

    } else {
      // validate all form fields
      this.commonService.hideLoader();

      this.validateAllFormFields(this.addBusinessForm); //{7}
    }

  }

  addOnFirebase(userData, business) {
    // this.authenticationService.SignUp(userData.user_detail.email, '123456');

    let userDataStore = {
      // 'business_name': business.business_name
      'user_id': userData.user_detail.userID,
      'full_name': userData.user_detail.full_name,
      'email': userData.user_detail.email,
      'user_type': userData.user_detail.user_type,
      'avatar': userData.user_detail.avatar,
      'notification_status': userData.user_detail.push_alert_status,
      'firebase_token': "",
      'business_name': business.business_name,
      'buyer_name': "",
      'seller_name': userData.user_detail.full_name,
      'online': "Online",


    }

    setTimeout(() => {
      this.firebaseService.userUpdateFB(this.authService.getCurrentUserId(), userDataStore)

		}, 1000);

  }

  // code for image cropper

  featuredImgSelect() {
    setTimeout(() => {
      this.addBusinessForm.get('licenseImage').setValue('');
      this.croppedImage = "assets/img/common/image_placeholder.png";
      this.cropImageShow = false;
    }, 1000);
  }
// For seller licence image 
onSelectedFile1(event) {
  if (event.target.files.length > 0) {
    const file = event.target.files[0];
    this.addBusinessForm.get('licenseImage').setValue(file);

    var reader = new FileReader();
    reader.addEventListener("load", function () {
      $('#license-preview').attr('src', reader.result);
    }, false);
    reader.readAsDataURL(event.target.files[0]);

  }
}
// End
  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {

    this.croppedImage = event.base64;
  }

  imageLoaded() {
    // show cropper
    checkLicenseImageSize(this.imageChangedEvent.target, (res) => {
      if (res) {
        $('#imageCropper').modal('show');
      } else {
        this.addBusinessForm.get('licenseImage').setValue('');
        this.croppedImage = "assets/img/common/image_placeholder.png";
        this.cropImageShow = false;
        this.commonService.toastError(this.translate.instant('PROFILE.file_size_should_be_at_least_dimensions'));
      }
    });
  }

  loadImageFailed() {
    // show message
  }

  cropImage() {
    $('#imageCropper').modal('hide');
    this.cropImageShow = true;
  }

  //set auto detect country
  telInputObject(obj) {

    var   initialCountrySet  = localStorage.getItem("countryCode");
    if(initialCountrySet == "" ||  initialCountrySet == null ){
      initialCountrySet = "US"
    }else{
      initialCountrySet = localStorage.getItem("countryCode");
    }
    // var initialCountrySet  = initialCountrySet = "US"

    $.getJSON('https://ipapi.co/json/', function (data) {

    this.initialCountrySet = initialCountrySet;

      // this.initialCountrySet = data.country_code.toLowerCase();
      // this.country_code = data.country_code;
      // this.dial_code = data.country_calling_code;
      obj.setCountry(this.initialCountrySet);
    });

  }



  resetCropper() {
    this.addBusinessForm.get('licenseImage').setValue('');
    this.cropImageShow = false;
    this.croppedImage = 'assets/img/common/image_placeholder.png';
  }

}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}
