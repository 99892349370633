import { AfterViewInit, Component, OnInit } from '@angular/core';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { BuyerService } from '../services/buyer.service';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute } from '@angular/router';



declare const $: any;
@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.css']
})
export class CheckoutComponent implements OnInit {
	faAngleDoubleRight = faAngleDoubleRight;
	pricing_detail: any = {};
	tax_detail: any = {};
	shippingAddress: any = {};
	isOffer: boolean;
	addressList: any = [];
	addresslistCount: any;
	addressSelected: string = '';
	addressForm: FormGroup;
	// dial_code: string = '+53';
	// dial_code_address: string = '+53';
	// country: string = 'Cuba';
	// country_code: string = 'CU';
	// initialCountrySet: string = 'CU';
	// profile_country_code: string = 'CU';



	dial_code: string = '+53';
	dial_code_address: string = '+53';
	country: string = 'Cuba';
	country_code: string = 'cu';
	initialCountrySet: string = 'cu';
	profile_country_code: string = 'CU';



	phone_num: any;
	referralDetail: any;
	rdbValue = "";
	coupon: any;
	couponList: any = [];
	shopService: any;
	coupon_code_id: string;
	codeForm1: FormGroup;
	coupon_disabled: boolean = true;
	apply_coupon_percentage: any;
	pickup = 0;
	Total: any = '';
	itemCount: string = '';
	subtotal: any = '';
	total_shipping_price: any = '';
	tax_percent: any = '';
	tax_amount: any = '';
	discounted_amount: any = '';
	discount_percent: any = '';
	subtotal_before_discount: any = '';
	free_shipping: string;
	currentUserId: any;
	cartlistCount: any;
	influencer_code_percentage: any;
	influencer_code_amount: any;
	apply_coupon_amount: any;
	assign_coupon_amount: any;
	assign_coupon_percentage: any;
	show_ref_pop: any;
	processing_fee: any;
	coupon2: any;
	is_used: any;
	influencer_code: any;
	tax_detailPercent: any;
	sellerId: any
	sellerAddress: any;
	influencer_code_amount1: any;
	pickup_active: any;
	is_Offer: any = 0;
	offer_id: any = 0;
	code: any;
	provinceList: any = [];
	municipalitiesAddDataList: any = [];

	constructor(
		private commonService: CommonService,
		private buyerService: BuyerService,
		private router: Router,
		private authService: AuthService,
		private fb: FormBuilder,
		private translate: TranslateService,
		private route: ActivatedRoute,
	) { translate.use(this.commonService.getLanguage()); }



	ngOnInit() {
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Checkout');
		this.getProvinceList();
		this.shippingAddress = this.authService.getUserShippingAddress(); // get user address 
		localStorage.removeItem("coupon");
		localStorage.removeItem("coupon_code_txt");

		if (localStorage.getItem('pickup') == '1') {
			this.rdbValue = 'pickup';

		} else {

			this.rdbValue = 'delivery';

		}

		const str = this.router.url;
		const regex = RegExp('/checkout/offer');
		if (regex.test(str) == true) {

			this.route.paramMap.subscribe(params => {  // get id from url 
				var id = params.get('id');
				this.offer_id = id;
			});

			this.isOffer = true;
			this.is_Offer = 1
			this.getCartList(); // get cart list  with offer param 
		} else {
			this.getCartList(); // get card list without offer param 
			this.offer_id = 0;
			this.is_Offer = 0
			this.isOffer = false;
		}
		// influencer code form validation 
		this.codeForm1 = this.fb.group({
			influencer_code: ['', [Validators.required, Validators.maxLength(20)]],
		});

		//address form validation 
		this.addressForm = this.fb.group({
			name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(50)]],

			phone_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')]],

			house_number: ['', [Validators.required]],
			add_province: ['', [Validators.required]],
			add_municipalities: ['', [Validators.required]],

			city: ['', [Validators.required, Validators.minLength(2)]],
			// pin_code: ['', [Validators.minLength(3), Validators.maxLength(20), Validators.required, Validators.pattern('[0-9]+')]],
		});

		this.getUserAddress(); // get user address
		let userAddress = this.authService.getUserAddress();
		if (userAddress != false) {
			this.addressSelected = userAddress;
		}
		this.getCouponCodeList(); //  get coupon code 

	}
	// get values from address form 
	get name() { return this.addressForm.get('name'); }
	get phone_number() { return this.addressForm.get('phone_number'); }
	get house_number() { return this.addressForm.get('house_number'); }
	// get street() { return this.addressForm.get('street'); }
	get city() { return this.addressForm.get('city'); }
	// get pin_code() { return this.addressForm.get('pin_code'); }
	get add_province() { return this.addressForm.get('add_province'); }
	get add_municipalities(): any { return this.addressForm.get('add_municipalities'); }

	// function for get referral code 
	getReferralCode() {
		const userDetails = JSON.parse(localStorage.getItem(environment.dataStore.userData));
		var id = userDetails.user_detail.userID;

		this.authService.getRefferal(this.pricing_detail.total_amount_subtotal).subscribe((data) => {

			Object.keys(data.data.referral_data).length === 0
			// if(data.data.referral_data == ''){
			if (Object.keys(data.data.referral_data).length === 0 && data.data.is_used == 0) {
				this.is_used = '0';
				var influencer_code = '0';
			}


			else if (Object.keys(data.data.referral_data).length === 0 && data.data.is_used == 1) {
				this.is_used = '1';
				var influencer_code = '0';
			}
			else {

				const userDetails = JSON.parse(localStorage.getItem(environment.dataStore.userData)); // get userdata from local storage 
				this.is_used = data.data.referral_data.is_used;
				influencer_code = data.data.referral_data.referral_code_id;
				if (influencer_code == null) {
					this.influencer_code_amount = 0;
				} else {
					this.influencer_code_amount = influencer_code;

				}
				this.referralDetail = data.data.referral_data;
			}

		})

	}



	openModal() {
		$("#input_coupon").val('');
		$("#coupon_code_modal").modal({ backdrop: 'static', keyboard: false });
	}

	sellerAddress1() {
		this.authService.sellerAddress(this.sellerId).subscribe((data) => {
			this.sellerAddress = data.data.seller_address_info;
		});

	}

	closeModal() {
		$("#input_coupon").val('');
		$("#coupon_code_modal").modal('hide');
	}

	//apply coupon code on select
	applyOnSelect(couponCode, code) {
		this.code = code
		this.coupon_code_id = couponCode;
		localStorage.setItem("coupon_code_txt", code);
		this.code = localStorage.getItem('coupon_code_txt')

		localStorage.setItem("coupon", this.coupon_code_id);
		this.getTotalAmount();
		$("#coupon_code_modal").modal('hide');
	}

	removeCoupon() {
		this.coupon_code_id = '';
		localStorage.removeItem("coupon");
		localStorage.removeItem("coupon_code_txt");

		this.getTotalAmount();
	}

	getTotalAmount() {
		this.getCartList();
	}

	getCouponCodeList() {
		this.buyerService.getCouponCodeList().subscribe((data) => {

			this.couponList = data.data.coupon_list;
		});
	}

	preventSpace(event) {
		if (event.keyCode == 32) {
			event.preventDefault();
			return false;
		}
	}

	enable() {
		if ($("#input_coupon").val() != "") {
			this.coupon_disabled = false
		} else {
			this.coupon_disabled = true
		}
	}




	// function for submit coupon code 
	submitCouponCode(value) {
		if (value == '') {
			return false
		}

		var formData = new FormData();
		formData.append('coupon_code', value);
		this.commonService.showLoader();
		this.buyerService.checkCouponCode(formData).subscribe((data) => {
			this.commonService.hideLoader();
			if (data.status == "fail") {
				this.commonService.toastError(data.message);
			} else {
				this.coupon_code_id = data.coupon_data.couponCodeId;
				localStorage.setItem("coupon", this.coupon_code_id);
				localStorage.setItem("coupon_code_txt", data.coupon_data.code);
				this.code = localStorage.getItem('coupon_code_txt');
				$("#input_coupon").val('');
				$("#coupon_code_modal").modal("hide");



				this.getTotalAmount();
				// setTimeout(() => {
				// 	this.checkCouponCode();

				// 	}, 500);





			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});


	}

	checkCouponCode() {
		console.log('this.pricing_detail.total_amount_subtotal', this.pricing_detail.total_amount_subtotal);
		console.log('this.pricing_detail.coupon2_amount', this.pricing_detail.coupon2_amount);
		const subtotal = Number(this.pricing_detail.total_amount_subtotal);
		const couponAmount = Number(this.pricing_detail.coupon2_amount);
		if (subtotal <= couponAmount) {
			console.log('checkout');
			this.pricing_detail.tax_amount = 0;
			this.pricing_detail.shipping_charge = 0;
			this.pricing_detail.grand_total = 0;
			console.log('this.pricing_detail.grand_total', this.pricing_detail.grand_total);




		}
	}

	// function for  submit code (referral code text field )
	submitCode() {
		var formData = new FormData();
		formData.append('referral', $("#influencer_code").val());
		formData.append('user_id', this.authService.getCurrentUserId());
		this.commonService.showLoader();
		this.authService.redeem_code(formData).subscribe((data) => {
			this.commonService.hideLoader();

			if (data.status == "fail") {
				this.commonService.toastError(data.message);
			} else {
				localStorage.removeItem("is_signup");
				this.commonService.toastSuccess(this.translate.instant('USER_DETAILS.congo') + " " + data.message);
				$("#influencerModalFb").modal("hide");
				$('#influencer_code').val('');
				this.getTotalAmount();
			}
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}


	// function for cancel referrel code 
	cancelCode() {
		$('#influencer_code').val('');
		$("#influencerModalFb").modal("hide");
	}

	// empty value validation 
	trimValue(formControl) {
		((typeof formControl.value) && formControl.value.trim() == "") ?
			formControl.setValue("") : formControl.setValue(formControl.value);
	}


	// function for get cart list 
	getCartList(): void {
		this.commonService.showLoader();
		var formData = new FormData();


		// if (this.coupon_code_id == undefined) {
		// 	var coupon = '0';
		// } else {
		// 	var coupon = this.coupon_code_id
		// }
		var coupon = localStorage.getItem("coupon");
		this.code = localStorage.getItem('coupon_code_txt')


		this.pickup_active = localStorage.getItem("pickup"); // get pickup option 
		formData.append('coupon_code', coupon); // coupon code 
		formData.append('is_pickup', this.pickup_active); // pickup option 
		formData.append('is_offer', this.is_Offer); // offer avaliable or not is offer == 1 means offer is avaiable 
		formData.append('offer_item_id', this.offer_id); // offer item id 
		this.buyerService.getUserCartList1(formData).subscribe( // api calling for get user cart list 
			(cartlistData) => {
				this.cartlistCount = cartlistData.data.total_records; //total cart 

				var checkPickup = cartlistData.data.cart_list.filter((val) => val.seller_id == cartlistData.data.cart_list[0].seller_id)

				if (cartlistData.data.cart_list.length == checkPickup.length && cartlistData.data.cart_list[0].is_pick_up_option == '1' && cartlistData.data.cart_list[0].is_pickup == 1) {
					this.pickup = 1;

				} else {
					if (cartlistData.data.cart_list.length != checkPickup.length && checkPickup.is_pick_up_option == '1' && checkPickup.is_pickup == 1) {
						this.pickup = 1;

					} else {
						this.pickup = 0;

					}
					// this.pickup = 0;

				}




				this.sellerId = cartlistData.data.cart_list[0].seller_id
				this.commonService.hideLoader();
				if (cartlistData.status == 'success') {
					this.pricing_detail = cartlistData.data.amount_details;
					var coupon = cartlistData.data.amount_details.coupon1_percentage;

					this.coupon = coupon.replace(/\.00$/, '');
					this.coupon2 = cartlistData.data.amount_details.coupon2_percentage.replace(/\.00$/, '');

					this.apply_coupon_amount = this.pricing_detail.coupon2_amount;
					this.apply_coupon_percentage = Math.round(this.pricing_detail.coupon2_percentage);

					this.assign_coupon_amount = this.pricing_detail.coupon1_amount;
					this.assign_coupon_percentage = this.pricing_detail.coupon1_percentage;

					this.influencer_code_amount1 = this.pricing_detail.referral_code_amount;
					this.influencer_code_percentage = this.pricing_detail.referral_code_percentage;

					this.influencer_code_percentage = this.influencer_code_percentage.replace(/\.00$/, '');
					this.tax_detailPercent = this.pricing_detail.tax_percent.replace(/\.00$/, '');
					this.tax_detailPercent = this.pricing_detail.tax_percent.replace(/\.00$/, '');

					this.getReferralCode();
					this.sellerAddress1();
					var totalCouponAmonut =0;
					totalCouponAmonut =  Number(this.pricing_detail.coupon1_amount) + Number(this.pricing_detail.coupon2_amount) + Number(this.pricing_detail.referral_code_amount);
					const subtotal = Number(this.pricing_detail.total_amount_subtotal);
					const couponAmount = Number(totalCouponAmonut);
					if (subtotal <= couponAmount) {
						console.log('checkout');
						this.pricing_detail.tax_amount = 0;
						this.pricing_detail.shipping_charge = 0;
						this.pricing_detail.grand_total = 0;




					}



				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
				this.router.navigate(['/']);
			});

	}

	// get offer data 
	getOfferCart(offerId): void {
		this.commonService.showLoader(); // show loader 

		this.buyerService.getOfferCart(offerId).subscribe( // call api for get offer data 
			(cartlistData) => {
				this.commonService.hideLoader(); // hide loader 

				if (cartlistData.status == 'success') { // check api data if sucess than 
					this.pricing_detail = cartlistData.data.pricing_detail; // assign price detail  value 
					this.tax_detail = cartlistData.data.tax_detail; // assign tax detail data 
				}

			}, err => {
				this.commonService.hideLoader(); // hide loader 
				this.commonService.errorhandler(err); // error handle 
				this.router.navigate(['/']); // navigate to home page 
			});

	}

	//user address list
	getUserAddress() {
		this.commonService.showLoader(); // show loader 

		this.buyerService.getUserAddressCheck().subscribe( //  call api for  get  user address 
			(useraddressList) => {
				this.commonService.hideLoader(); // hide loader 

				if (useraddressList.status == 'success') { // check response if success than 
					var keys = Object.keys(useraddressList.data); //Check data is empty object
					var len = keys.length;  //get length of empty object
					if (len === 0) {
						this.addresslistCount = 0;
					}

					this.addressList = useraddressList.data.address_list; // assign data 

					if (len != 0) {
						this.addressList.forEach(obj => {  //Used when user has deafult address and not selected any address in a session
							//get selected address of user
							let userAddress = this.authService.getUserAddress();
							if (userAddress == false) {
								if (obj.is_default == '1') {
									this.addressSelected = obj.addressID;
								}
							}
						});
					}
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}

	//on select address
	onAddressSelect(item, UserAddress) {
		localStorage.removeItem(environment.dataStore.userAddress); // remve address  from local storage 
		this.addressSelected = item;
		localStorage.setItem(environment.dataStore.userAddress, JSON.stringify(UserAddress)); // set  data in local storage 
	}


	// open Referralcode popup 
	openReferralCode() {
		$("#influencerModalFb").modal({ backdrop: 'static', keyboard: false });
	}

	// check  product is available or not 
	ValidateApi(type = "") {
		var currentUserId = this.authService.getCurrentUserId(); //current user ID

		this.buyerService.checkProduct(currentUserId, this.cartlistCount, type).subscribe( // call api for check product quantity is available or not 
			(productCheck) => {

				if (productCheck.flag == 0) {
					this.confirmationPopup(); // if product is not available  than open popup 
				} else {

					if (this.is_Offer == 1) { // if offer  is availbale than navigate to offer component 
						this.router.navigate(["/payment-option/offer/" + this.offer_id]);
					} else {
						this.router.navigate(["/payment-option"]); // navigate to  payment option  
					}

				}

			})
	}


	getDisputeTimeShow(dateTime) {

		return this.commonService.getDateTime2(dateTime);
	}

	//continue to payment page
	toPayment(isOffer) {


		if (this.pricing_detail.grand_total < 0.5) {
			this.commonService.toastError(this.translate.instant('CHECKOUT.price_issue'));
			return false;

		}

		var checkType = localStorage.getItem('pickup')


		if (checkType == '0' || checkType == null) {
			if (this.addressSelected == '' || this.addressSelected == undefined) {
				// this.commonService.toastError('Please select address');
				this.commonService.toastError(this.translate.instant('USER_DETAILS.please_add_address'));


			} else {
				let address = this.addressList.find((address) => {
					return address.addressID === this.addressSelected;
				})
				this.onAddressSelect(this.addressSelected, address);
				if (this.coupon_code_id == undefined) {
					var coupon = '0';
				} else {
					var coupon = this.coupon_code_id
				}
				const str = this.router.url;
				const regex = RegExp('/checkout/offer');

				if (isOffer == true) {
					this.ValidateApi('1');
				} else {
					this.ValidateApi()
				}
			}
		} else {

			if (isOffer == true) {
				this.ValidateApi('1');

			} else {
				this.ValidateApi()
			}
		}
	}

	hasError($event) {
		if ($event) {
			this.addressForm.patchValue({ phone_number: "1" });
		}
	}

	// function for coountry change 
	onCountryChange($event) {
		this.dial_code = `+${$event.dialCode}`;
		this.country = $event.name.split('(')[0];
		this.country_code = $event.iso2.toUpperCase();
	}

	// get phone number 
	getNumber($event) {
		this.addressForm.patchValue({ phone_number: $event.split(this.dial_code)[1] });
	}

	// function for validation (check number only )
	numberOnly(event): boolean {
		const charCode = (event.which) ? event.which : event.keyCode;
		if (charCode > 31 && (charCode < 48 || charCode > 57)) {
			return false;
		}
		return true;

	}

	// //set auto detect country
	// telInputObject(obj) {
	// 	$.getJSON('https://ipapi.co/json/', function (data) {
	// 		this.initialCountrySet = data.country_code.toLowerCase();
	// 		this.country_code = data.country_code;
	// 		this.dial_code = data.country_calling_code;
	// 		obj.setCountry(this.initialCountrySet);
	// 	});
	// }


	//set auto detect country
	telInputObject(obj) {

		// var initialCountrySet  = this.authService.getUserDetails().profile_country_code;

		// if(initialCountrySet == "" || initialCountrySet == null){
		// 	initialCountrySet  = localStorage.getItem('countryCode');	

		// }else{
		// 	initialCountrySet  = this.authService.getUserDetails().profile_country_code;

		// }
		var initialCountrySet = "cu"

		$.getJSON('https://ipapi.co/json/', function (data) {

			this.initialCountrySet = initialCountrySet;

			// this.initialCountrySet = data.country_code.toLowerCase();
			// this.country_code = data.country_code;
			// this.dial_code = data.country_calling_code;
			obj.setCountry(this.initialCountrySet);
		});
	}

	//Validate function 
	validateAllFormFields(formGroup: FormGroup) {
		Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}


	//open popup for delete 

	// when product is not available than 
	confirmation() {
		$('#deleteItem').modal('hide');
		if (this.is_Offer == 1) { //  offer list data 
			this.router.navigate(['/product-offers']);  // navigate to product offer list 
		} else {
			this.router.navigate(['/cart']); // navigate to cart list 

		}

	}

	// open popup for product is not available 
	confirmationPopup() {
		$('#deleteItem').modal('show');

	}




	// For get province list
	getProvinceList() {
		this.authService.getProvinceList()
			.subscribe(provinceData => {
				this.commonService.hideLoader();

				if (provinceData.status == 'success') {
					this.provinceList = provinceData.data.province;
				} else {
					this.provinceList = [];
				}

				console.log(' this.provinceList', this.provinceList);

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End

	//Add Address
	addAddress() {
		if (this.addressForm.valid) {


			this.commonService.showLoader();
			var formData = new FormData();
			formData.append('name', this.addressForm.get('name').value);
			formData.append('phone_number', this.addressForm.get('phone_number').value);
			formData.append('house_number', this.addressForm.get('house_number').value);
			// formData.append('street', this.addressForm.get('street').value);
			formData.append('city', this.addressForm.get('city').value);
			// formData.append('pincode', this.addressForm.get('pin_code').value);
			formData.append('dial_code', this.dial_code);
			formData.append('country', this.country.trim());
			formData.append('country_code', this.country_code);
			formData.append('add_province', this.addressForm.get('add_province').value);
			formData.append('municipalities', this.addressForm.get('add_municipalities').value);
			formData.append('type', '1');

			this.buyerService.addUserAddress(formData).subscribe((data) => {
				this.commonService.hideLoader();

				if (data.status == "fail") {
					this.commonService.toastError(data.message);
				} else {
					this.commonService.toastSuccess(data.message);
					this.getUserAddress();
					this.addresslistCount = 1;
					this.getUserProfile();
					this.resetForm();
					$('#addAddress').modal('hide');
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			// validate all form fields
			this.validateAllFormFields(this.addressForm);
		}
	}

	getUserProfile() {
		this.buyerService.getUserProfile().subscribe(data => {
			localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));
		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}
	// reset phone number form
	resetForm() {
		this.addressForm.reset();
		$('#phone_num').val('');
	}

	// input value get delivery option
	checkInput(event) {
		this.rdbValue = event.target.value;
		// alert(this.rdbValue);
		if (this.rdbValue == "pickup") {
			localStorage.setItem('pickup', '1');
			const str = this.router.url;
			const regex = RegExp('/checkout/offer');
			if (regex.test(str) == true) {
				this.isOffer = true;
				this.route.paramMap.subscribe(params => {
					var id = params.get('id');
					this.offer_id = id;
					this.getCartList();
				});

			} else {
				this.getCartList();
				this.isOffer = false;

			}
			this.getCartList();

		} else {
			localStorage.removeItem("pickup"); // remove pickup option from local storage 
			this.getCartList();
		}

		this.sellerAddress1();
	}

	// get provice id on change province 
	onAddProvinceChange(event: Event): void {
		const selectedAddProvinceID = (event.target as HTMLSelectElement).value;
		this.getAddMunicipalities(selectedAddProvinceID);
		this.addressForm.get('add_municipalities').setValue('');
	}
	// For get municipalities list 
	getAddMunicipalities(province_ids) {

		this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
			this.commonService.hideLoader();
			if (municipalitiesData.status == 'success') {
				this.municipalitiesAddDataList = municipalitiesData.data.municipalities;
			} else {
				this.municipalitiesAddDataList = [];
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}


}
