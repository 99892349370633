import { Component, OnInit } from '@angular/core';
import { faStar, faAngleDoubleRight, faCheck, faTimes, faCommentDots } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { SellerService } from '../services/seller.service';
import { AuthService } from '../../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-new-order-detail',
  templateUrl: './new-order-detail.component.html',
  styleUrls: ['./new-order-detail.component.css']
})
export class NewOrderDetailComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  faStar = faStar;
  faCommentDots = faCommentDots;
  faCheck = faCheck;
  faTimes = faTimes;
  orderId: any;
  orderDetail: any = {};
  orderAddress: any = {};
  coupon: any;
  couponList: any = [];
  shopService: any;
  coupon_code_id: string;
  coupon_disabled: boolean = true;
  apply_coupon_percentage: any;
  pickup = 0;

  Total: any = '';
  itemCount: string = '';
  subtotal: any = '';
  total_shipping_price: any = '';
  tax_percent: any = '';
  tax_amount: any = '';
  discounted_amount: any = '';
  discount_percent: any = '';
  subtotal_before_discount: any = '';
  free_shipping: string;
  currentUserId: any;
  cartlistCount: any;
  influencer_code_percentage: any;
  influencer_code_amount: any;
  apply_coupon_amount: any;
  assign_coupon_amount: any;
  assign_coupon_percentage: any;
  show_ref_pop: any;
  processing_fee: any;
  coupon2: any;
  is_used: any;
  influencer_code: any;
  tax_detailPercent: any;
  addrestype: any;
  house: any;
  date: any;
  responseCheck: any = 0;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private sellerService: SellerService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private router: Router,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | New Order Detail'); //For title

    this.activatedRoute.paramMap.subscribe(params => {
      this.orderId = params.get("id"); //For order id
      this.getNewOrderDetail(this.orderId); //For new order detail
    });

  }

  // For new order detail
  getNewOrderDetail(orderId) {
    this.commonService.showLoader();
    this.sellerService.getNewOrderDetail(orderId).subscribe(newOrderData => {
      this.commonService.hideLoader();
      if (newOrderData.status == 'success') {
        this.responseCheck =  1;
        this.orderDetail = newOrderData.data.order_detail;
        this.orderAddress = newOrderData.data.order_detail.order_address;
        var result =this.orderDetail.update_date.split('-');
        this.date  = result[0];

        if (this.orderDetail.assign_coupon_percentage != '0.00' || this.orderDetail.assign_coupon_percentage != '0') {
          this.coupon = this.orderDetail.assign_coupon_percentage.replace(/\.00$/, '');
        }

        if (this.orderDetail.apply_coupon_percentage != '0.00' || this.orderDetail.apply_coupon_percentage != '0') {
          this.coupon2 = this.orderDetail.apply_coupon_percentage.replace(/\.00$/, '');
        }


        if (newOrderData.data.order_detail.order_address == null) {
          this.addrestype = 1;
          this.orderAddress = newOrderData.data.order_detail.order_seller_address;
          this.house = "";

        } else {
          this.addrestype = 0;
          this.orderAddress = newOrderData.data.order_detail.order_address;
          this.house = this.orderAddress.house_number + ', ';

        }

        this.apply_coupon_amount = this.orderDetail.apply_coupon_amount;
        this.apply_coupon_percentage = this.orderDetail.apply_coupon_percentage;
        this.assign_coupon_amount = this.orderDetail.assign_coupon_amount;
        this.assign_coupon_percentage = this.orderDetail.assign_coupon_percentage;
        this.influencer_code_amount = this.orderDetail.influencer_code_amount;
        this.influencer_code_percentage = this.orderDetail.influencer_code_percentage;
        this.influencer_code_percentage = this.influencer_code_percentage.replace(/\.00$/, '');
        this.tax_detailPercent = this.orderDetail.tax_percent;
        this.processing_fee = this.orderDetail.transaction_fee_percent_amount * 1 + this.orderDetail.transaction_fee_flat * 1;
        this.processing_fee = this.processing_fee.toFixed(2);

      }
    }, err => {
      this.responseCheck =  0;

      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End


  
  cancelOrder(id){

		const formData = new FormData();
		formData.append('order_id', id);
		formData.append('cancel_by', "seller");
		formData.append('note', "");
		this.commonService.showLoader();  // show loader
		this.sellerService.cancelOrder(formData).subscribe(data => {

			if (data.status == 'success') {
				this.commonService.hideLoader();
				this.commonService.toastSuccess(data.message);
        this.getNewOrderDetail(this.orderId); //For new order detail

				// this.getMyOrderDetail(this.orderId); // get order details 
			}else{
				this.commonService.hideLoader();
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		})

	}


  confirmationOrderCancel(orderId, orderNumber) {
		Swal.fire({
		  title: this.translate.instant('MYORDER.are_you_sure'),
		  text: this.translate.instant('MYORDER.You_want_to_cancel_this_order') + orderNumber,
		  icon: 'warning',
		  showCancelButton: true,
		  confirmButtonText: this.translate.instant('FOOTER.ok_button'),
		  cancelButtonText: this.translate.instant('COMMON.cancel'),
		  reverseButtons: true
		}).then((result) => {
		  if (result.value) {
			  var id = parseFloat(orderId)
			  this.cancelOrder(orderId)
		  //   this.acceptReject(status, orderId, licenseStatus);
		  } 
		});
	 
  
	}

  // For Confirm order
  confirmOrder(status, orderId, orderNumber, licenseStatus) {

    if (status == '0') {
      Swal.fire({
        title: this.translate.instant('MYORDER.are_you_sure'),
        text: this.translate.instant('MYORDER.You_want_to_reject_this_order') + orderNumber,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('MYORDER.yes_reject_it'),
        cancelButtonText: this.translate.instant('MYORDER.no_keep_it'),
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.acceptReject(status, orderId, licenseStatus);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            this.translate.instant('SELLER_SIDE.cancelled'),
            this.translate.instant('MYORDER.your_order_is_safe'),
            'error'
          )
        }
      });
    } else {
      Swal.fire({
        title: this.translate.instant('MYORDER.are_you_sure'),
        text: this.translate.instant('MYORDER.you_want_to_accept_this_order') + orderNumber,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.translate.instant('MYORDER.yes_accept_it'),
        cancelButtonText: this.translate.instant('MYORDER.no_keep_it'),
        reverseButtons: true
      }).then((result) => {
        if (result.value) {
          this.acceptReject(status, orderId, licenseStatus);
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            this.translate.instant('SELLER_SIDE.cancelled'),
            this.translate.instant('MYORDER.your_order_not_accept'),
            'error'
          )
        }
      });
    }

  }
  // End


    // For order time 
	getOrderTimeShow(dateTime, string) {

		return this.commonService.getOrderTime(dateTime, string);
	}

	getDisputeTimeShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}
  // 
  // End

  // // For accept and reject order 
  // acceptReject(status, orderId, licenseStatus) {
  //   if (licenseStatus == '0' || licenseStatus == '2') {

  //     if (licenseStatus == '0') {
  //       this.commonService.toastError(this.translate.instant('NEW_ORDER.your_license_is_not_verified'));
  //       this.router.navigate(['seller/seller-account']);
  //     } else {
  //       this.commonService.toastError(this.translate.instant('NEW_ORDER.your_license_is_rejected'));
  //     }
  //   } else {
  //     let statusData = `status=${escape(status)}`;
  //     this.commonService.showLoader();
  //     this.sellerService.acceptReject(orderId, statusData).subscribe(orderData => {
  //       this.commonService.hideLoader();

  //       if (orderData.status == 'success') {
  //         this.commonService.toastSuccess(orderData.message);

  //         if (status == '0') {
  //           this.orderRemoveFirebase(orderId);
  //         }
  //         this.router.navigate(['seller/new-orders']);
  //       }
  //     }, err => {
  //       this.commonService.hideLoader();
  //       this.commonService.errorhandler(err);
  //     });

  //   }
  // }
  // // End



    // For accept & reject order
    acceptReject(status, orderId, licenseStatus) {


      if (licenseStatus == '0' && status == 1 || licenseStatus == '2' && status == 1) {
  
        if (licenseStatus == '0') {
          Swal.fire({
            text: this.translate.instant('NEW_ORDER.your_license_is_not_verified'),
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: this.translate.instant('FOOTER.ok_button'),
            reverseButtons: false
          }).then((result) => {
            if (result.value == true) {
              this.router.navigate(['seller/seller-account']);
  
            }
  
  
          })
  
  
        }
        else if (licenseStatus == '2' && status == 1) {
  
          Swal.fire({
            text: this.translate.instant('NEW_ORDER.your_license_is_not_verified'),
            icon: 'warning',
            showCancelButton: false,
            confirmButtonText: this.translate.instant('FOOTER.ok_button'),
            reverseButtons: false
          }).then((result) => {
            if (result.value == true) {
              this.commonService.hideLoader();

              this.router.navigate(['seller/seller-account']);
  
            }
  
  
          })
  
  
        }
  
        // else {
        //   this.commonService.toastError(this.translate.instant('NEW_ORDER.your_license_is_rejected'));
        // }
  
  
      } else {
  
        let statusData = `status=${escape(status)}`;
  
        this.commonService.showLoader();
        this.sellerService.acceptReject(orderId, statusData).subscribe(orderData => {
          this.commonService.hideLoader();
  
          if (orderData.status == 'success') {
            this.commonService.toastSuccess(orderData.message);
            this.router.navigate(['seller/new-orders']);

            }
        }, err => {
          this.commonService.hideLoader();
          this.commonService.errorhandler(err);
        });
  
      }
    }
    //

  // For firebase
  orderRemoveFirebase(orderId) {
    let orderData = {
      sellerId: this.authService.getCurrentUserId(),
      buyerId: this.orderDetail.ordered_by_user_id,
      orderId: orderId
    }
    this.firebaseService.orderRemoveFirebase(orderData);
  }
  // End

}
