import { AfterViewChecked, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { faAngleDoubleRight, faCheck, faCircle, faTimes, faUpload, faEdit, faTrashAlt, fas } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { SellerService } from '../services/seller.service';
import { ImageCroppedEvent, ImageTransform } from 'ngx-image-cropper';
import { Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { element } from 'protractor';
import { exit } from 'process';
import { trigger, state, style, animate, transition } from '@angular/animations';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '../../auth/auth.service';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';


declare const $: any;
declare const checkImageSize: any;
@Component({
	selector: 'app-add-product',
	templateUrl: './add-product.component.html',
	styleUrls: ['./add-product.component.css'],
	animations: [
		trigger('enterTrigger', [
			state('fadeIn', style({
				opacity: '1',
				// transform: 'translateY(50%)'
			})),
			transition('void => *', [style({ opacity: '0' }), animate('500ms')])
		])
	]
})
export class AddProductComponent implements OnInit, AfterViewChecked {
	faAngleDoubleRight = faAngleDoubleRight;
	faCheck = faCheck;
	faCircle = faCircle;
	faTimes = faTimes;
	faUpload = faUpload;
	faEdit = faEdit;
	faTrashAlt = faTrashAlt;
	currentDate = new Date();
	amountss;
	is_no_variants: any = 0;
	addProductForm: FormGroup;
	categoryList: any = [];
	comissionData: any = [];
	subCategoryList1: any = [];
	subCategoryList2: any = [];
	subCategoryList3: any = [];
	subCategoryList4: any = [];
	variantList: any = [];
	subCategoryLevel: number = 0;
	variantsList: any = [];
	ShowFilter = false;
	brandList: any = [];
	isWarrantyApply: boolean = true;
	warrantyList: any = [];
	minDate: any;
	shipChargeFlag: boolean = true;
	keyFeatureList: any = [];
	addPakageList: any = [];
	qtd: any = [];
	variantsValuesList: any = [];
	variantModel: any = {};
	finialVariantList: any = [];
	imageChangedEvent: any = '';
	croppedImage: any = '';
	cropImageShow: boolean = false;
	productId: any;
	disabled: boolean = false;
	sellingAmount;
	sellingAmount1;
	sellingAmountPercentage;
	sellingAmountPercentagev;
	selling_price1
	offerList
	offerListcomission
	editqtd: any = [];
	selectedImages: any = [];
	editVariantModel: any = {
		amount: "",
		sku: "",
		quantity: "",
		product_weight: "",
		showlist: "",
		offer_price: "",
		offer_price_with_admin_commission: "",
		offer_price_commission_percent: "",

		editqtd: [],
		selectedImages: []
	};
	scale = 1;
	transform: ImageTransform = {};
	transformgallery: ImageTransform = {};
	// showCropper = false;
	public selectedProvinces: number[] = [];
	ProvinceFieldValidation: any = [];

	offerv;
	selevtedInmg: [];
	editVariantindex: any;
	variantsEditValuesList: any = [];
	selectedVariantList: any = [];
	galleryImageChangedEvent: any = '';
	croppedGalleryImage: any = '';
	galleryList: any = [];
	gallery_image: any = [];
	offerVariantPrice;
	offerVariantPriceCommission
	offer_price_commision_percent;
	warehouseAddressList: any = [];
	sellerWarehouseId: any;

	videoUrl: any = "https://www.youtube.com/watch?v=xGpHfFf18Ns";
	// videoUrl:any="https://www.youtube.com/embed/tgbNymZ7vqY";
	provinceList: any = [];
	dropdownProvinceSettings: any = {};
	public value = "1";
	limitedOffer: boolean = false;
	returningDay: boolean = false;
	userDetail: any
	selling_price: any;
	gallery_image1: any = [];
	gallery_image_url: any;
	cod: any;
	globlepay: any;
	directpay: any;
	pickup: any;
	make_and_offer: any;
	is_add = 1;
	@ViewChild('f', { static: true }) form: any;
	@ViewChild('editF', { static: true }) editF: any;
	@ViewChild('iframe', { static: true }) iframe: ElementRef;
	editImage: any = [];
	provinceGetListForShipping: any = [];
	shippingIds: any[];
	shiipingChargesList: any;
	shippingListCount: number;
	provinceIds: any = [];
	provinceIdss: any = [];
	lastSel: any = 1;
	swalShow: any = 0;
	noVariantsImage: any = [];
	product_image: any = [];
	FImage: any;
	imageId: any = [];
	categoryType: any;
	showInputBlock: boolean = false;
	municipalitiesAddDataList: any = [];
	provinceIdForMunicipalities: any = [];
	dropdownMunicipalitiesSettings: any = {};
    warehouseAddressListCount: any;
    provinceIdFor: "";

	constructor(
		private router: Router,
		private formBuilder: FormBuilder,
		private commonService: CommonService,
		private sellerService: SellerService,
		private firebaseService: FirebaseService,
		private authService: AuthService,
		private translate: TranslateService
	) { translate.use(this.commonService.getLanguage());
		// Expose removeAddress to global scope
		(window as any).removeAddress = this.removeAddress.bind(this);
	 }

	ngAfterViewChecked(): void {
		this.dropdownProvinceSettings = {
			singleSelection: false,
			idField: 'provinceID',
			textField: 'name',
			itemsShowLimit: 2,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};

		this.dropdownMunicipalitiesSettings = {
			singleSelection: false,
			idField: 'id',
			idField2: 'province_id',
			textField: 'municipalityName', // This is the field that will display municipality and province names
			itemsShowLimit: 2,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};

	}

	marked = false;
	yes = false;




	ngOnInit() {
		this.commonService.scrollToTop();

		this.dropdownProvinceSettings = {
			singleSelection: false,
			idField: 'provinceID',
			textField: 'name',
			itemsShowLimit: 2,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};

		this.dropdownMunicipalitiesSettings = {
			singleSelection: false,
			idField: 'id',
			idField2: 'province_id',
			textField: 'municipalityName', // This is the field that will display municipality and province names
			itemsShowLimit: 2,
			allowSearchFilter: this.ShowFilter,
			selectAllText: this.translate.instant('HOME.selectAll'),
			unSelectAllText: this.translate.instant('HOME.unselectAll'),
		};
	
		this.commonService.scrollToTop();
		this.commonService.setTitle('Mercadoba | Add Product');

		this.minDate = this.commonService.getCurrentDateObject();
		const currentUser = JSON.parse(localStorage.getItem(environment.dataStore.userData));

		this.cod = currentUser.business_info.is_cod; //Cod
		this.pickup = currentUser.business_info.is_pickup; //Pickup
		this.globlepay = currentUser.business_info.id_global_cod; //Globel Pay
		this.directpay = currentUser.business_info.is_direct_pay; //Direct Pay
		this.make_and_offer = currentUser.business_info.make_and_offer; //Direct Pay

		// For add product form validation
		this.addProductForm = this.formBuilder.group({
			category: ['', Validators.required],
			subcategory1: ['', Validators.required],
			subcategory2: ['', Validators.required],
			subcategory3: ['', Validators.required],
			subcategory4: ['', Validators.required],
			isVariant: ['', Validators.required],
			name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
			condition: ['', Validators.required],
			brand: ['', Validators.required],
			stocks: [''],
			feature: [''],
			deliveryIn: ['', Validators.required],
			showTill: [''],
			startDAte: [''],
			endDAte: [''],
			make_offer: [''],

			warrantyApply: ['1'],
			warranty: ['', Validators.required],
			// regularPrice: ['', [Validators.required, Validators.pattern('^[0-9]{1,10}((\.[0-9]{1,2}))?$')]],
			regularPrice: ['', [Validators.required]],
			province: ['', [Validators.required]],
			municipalities: ['', [Validators.required]],
			shipCharge: ['1'],
			shipAmount: ['', [Validators.required]],

			offer_price: [''],
			days_of_return: [''],
			description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(5000)]],

			description_es: [''],
			featuredImage: ['', Validators.required],
			isVariantAdded: ['', Validators.required],
			return: ['', Validators.required],
			is_cod: [''],
			is_pickup: [''],
			is_direct_payment: [''],
			make_and_offer_option: [''],
			product_sku: ['', Validators.required],
			product_quantity: ['', Validators.required],
			product_weight: ['', Validators.required],
		});
		// End

		this.getCategoryList(); //For category
		this.getWarrantyList(); //For warranty
		this.getProvinceList(); //For province
		this.getComissionList(); //For commission
		this.getShippingList(); //For shipping price list
	}


	// For get municipalities list 
	getAddMunicipalities(province_ids, flag = "") {
		console.log('check ids new', province_ids);

		this.provinceIdFor = province_ids;

		this.authService.getMunicipalities(province_ids).subscribe(municipalitiesData => {
			console.log('municipalitiesData', municipalitiesData);
			this.commonService.hideLoader();

			if (municipalitiesData.status == 'success') {
				this.municipalitiesAddDataList = municipalitiesData.data.municipalities;
			setTimeout(() => {

				console.log('municipalitiesAddDataList',this.municipalitiesAddDataList);
				// this.getAddMunicipalities(filteredProvinceIds);
				if(flag == '1'){
					const result = this.municipalitiesAddDataList.map(({ id, municipalities }) => ({ id, municipalities }));
					// this.provinceGetListForShipping =[];
					// this.provinceGetListForShipping = result;

					console.log('result', result);
				}
				this.municipalitiesAddDataList = this.municipalitiesAddDataList.map(municipality => ({
					...municipality,
					municipalityName: `${municipality.municipalities} (${municipality.province_name})`
				}));
			  }, 100);
				// Assuming your data structure needs to have a combined field for display
				

			} else {
				this.municipalitiesAddDataList = [];
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});
	}

		




	// For province
	onMunicipalitiesItemSelect(e) {
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.addProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;
		this.provinceGetListForShipping.push(e);
	}

	removeMunicipalitiesById(ids: any, flag = "") {
		console.log('ids', ids);

		let provinceIdArrays = [];
		provinceIdArrays.push(...ids);  // Spread the elements of ids (if any) and push them into the array
		console.log('provinceIdArrays',provinceIdArrays);
		// get current municipalities ids 
		const currentSelection = this.addProductForm.get('municipalities').value;

		let provinceArrayIds = this.commonService.ArrayFilter(this.addProductForm.get('province').value, 3);
		// // Filter the municipalitiesAddDataList based on province_id not in ids
		// const filteredProvince = this.municipalitiesAddDataList.filter(municipality => !ids.includes(municipality.province_id));

		const filteredProvince = this.municipalitiesAddDataList.filter(municipality => {
			return municipality.province_id !== ids.toString(); // Convert ids to string for comparison

			// return !provinceIdArrays.includes(municipality.province_id);
		});

		console.log('provinceIdArrays',provinceIdArrays);

		console.log('filteredProvince',filteredProvince);

		// Filter the currentSelection based on the filteredSelection ids
		const filteredMunicipalities = currentSelection.filter(municipality =>
			filteredProvince.some(filtered => filtered.id === municipality.id)
		);
		// this.provinceGetListForShipping.splice(this.provinceGetListForShipping.findIndex(item => item.id === filteredMunicipalities), 1)


		console.log('filteredMunicipalities',filteredMunicipalities);


		// Set the filtered current selection back to the form
		this.addProductForm.get('municipalities').setValue(filteredMunicipalities);


		if(filteredProvince.length == 0){
			this.provinceIdForMunicipalities =[];
		}else{
			// Filter the provinces based on provinceIdForMunicipalities
			const filteredProvinceIds = filteredProvince
			.filter(val => this.provinceIdForMunicipalities.includes(val.province_id))
			.map(val => val.province_id);

			

			// Combine the filtered province IDs with existing ones (avoiding duplicates)
			this.provinceIdForMunicipalities = [...new Set([...this.provinceIdForMunicipalities, ...filteredProvinceIds])];

			// Call the getAddMunicipalities method with the updated list

			setTimeout(() => {
				// this.getAddMunicipalities(filteredProvinceIds);
				this.getAddMunicipalities(provinceArrayIds,'1');
			  }, 100);
			


		}
	}


	//For Province uncheck 
	onMunicipalitiesDeSelect(item: any, flag = '') {
		console.log(item, 'item');
		if (flag == '1') {
			this.removeMunicipalitiesById(item, '1')

		}
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.addProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;
		this.provinceGetListForShipping.splice(this.provinceGetListForShipping.findIndex(itemCheck => itemCheck.id === item.id), 1)


	}


	// For select all province multiselect
	onMunicipalitiesSelectAll(items: any) {
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.addProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;
		this.provinceGetListForShipping = [];
		this.provinceGetListForShipping = items;
	}
	// End

	// For unselect all province
	onMunicipalitiesDeSelectAll(items: any) {
		$("#clickShipID").val('1');
		this.getShippingList(); //For shipping price list
		const shipAmountCtrl = this.addProductForm.get('shipAmount');
		shipAmountCtrl.setValidators([Validators.required]);
		this.shipChargeFlag = true;
		this.provinceGetListForShipping = [];

	}


	// For province
	onItemSelect(e) {
		$("#clickShipID").val('1');
		this.ProvinceFieldValidation.push(e);
		this.provinceIdForMunicipalities.push(e.provinceID);
		var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();
		console.log('checkProvinceId', provinceIdForMunicipalities);
		this.getAddMunicipalities(provinceIdForMunicipalities)
	}
	// End




	resetDropdown() {
		this.addProductForm.get('municipalities').setValue([]);
		this.addProductForm.get('municipalities').setValue('');
	}




	//For Province uncheck 
	onDeSelect(item: any) {
		$("#clickShipID").val('1');
		this.ProvinceFieldValidation.splice(this.ProvinceFieldValidation.findIndex(item => item.provinceID === item.provinceID), 1)
		// this.resetDropdown();
		// this.removeMunicipalityById(item.provinceID)
		this.onMunicipalitiesDeSelect(item.provinceID, '1');
		this.municipalitiesAddDataList = [];
		this.provinceIdForMunicipalities.splice(this.provinceIdForMunicipalities.findIndex(item => item === item.provinceID), 1)
		var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();
		// this.getAddMunicipalities(provinceIdForMunicipalities);



		

		// this code for shipping price validation
		// $("#clickShipID").val('1');
		// this.getShippingList(); //For shipping price list
		// const shipAmountCtrl = this.addProductForm.get('shipAmount');
		// shipAmountCtrl.setValidators([Validators.required]);
		// this.shipChargeFlag = true;
		// this.provinceGetListForShipping.splice(this.provinceGetListForShipping.findIndex(item => item.provinceID === item.provinceID), 1)



	}


	// For select all province multiselect
	onSelectAll(items: any) {
		$("#clickShipID").val('1');
		this.ProvinceFieldValidation = [];
		this.ProvinceFieldValidation = items;
		// Ensure items is an array and map over it to extract provinceIDs
		this.provinceIdForMunicipalities = items.map((item: any) => item.provinceID);
		var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();
		this.getAddMunicipalities(provinceIdForMunicipalities);
	}


	// // For select all province multiselect
	// onSelectAll(items: any) {
	// 	console.log('itemsPreview', items)
	// 	this.provinceIdForMunicipalities =[];
	// 	this.provinceIdForMunicipalities.push(items.provinceID);
	// 	console.log('checkProvinceId', this.provinceIdForMunicipalities);

	// 	var provinceIdForMunicipalities = this.provinceIdForMunicipalities.join();
	// 	console.log('checkProvinceId', provinceIdForMunicipalities);
	// 	this.getAddMunicipalities(provinceIdForMunicipalities)
	// }

	//code for shipping price validation

	// $("#clickShipID").val('1');
	// this.getShippingList(); //For shipping price list
	// const shipAmountCtrl = this.addProductForm.get('shipAmount');
	// shipAmountCtrl.setValidators([Validators.required]);
	// this.shipChargeFlag = true;
	// this.provinceGetListForShipping = [];
	// this.provinceGetListForShipping = items;
	//}
	// End

	// For unselect all province
	onDeSelectAll(items: any) {
		$("#clickShipID").val('1');
		this.ProvinceFieldValidation = [];
		this.provinceIdForMunicipalities = [];
		this.municipalitiesAddDataList = [];
		this.resetDropdown();


		//code for shipping price validation
		// $("#clickShipID").val('1');
		// this.getShippingList(); //For shipping price list
		// const shipAmountCtrl = this.addProductForm.get('shipAmount');
		// shipAmountCtrl.setValidators([Validators.required]);
		// this.shipChargeFlag = true;
		// this.provinceGetListForShipping = [];

	}


	onlyNumber($event) {
		let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
		if ((keyCode < 48 || keyCode > 57) && keyCode !== 190) { // 46 is dot
			$event.preventDefault();
		}


	}



	changeZoom(stepSize) {

		let newScale = this.scale + parseFloat(stepSize);
		if (newScale < 0) {
			newScale = 0;
		}
		this.scale = newScale;
		this.transform = {
			...this.transform,
			scale: this.scale,
		};
	}

	changeZoomGallery(stepSize) {

		let newScale = this.scale + parseFloat(stepSize);
		if (newScale < 0) {
			newScale = 0;
		}
		this.scale = newScale;
		this.transformgallery = {
			...this.transform,
			scale: this.scale,
		};
	}



	// // For shipping charges limit set
	// checkNumberLimit(e) {
	// 	if (e.target.value.includes('.') && e.keyCode == 46) {

	// 		e.preventDefault();
	// 		return false
	// 	}

	// 	var valid = /^\d{0,7}(\.\d{0,2})?$/.test(e.target.value),
	// 		val = e.target.value;

	// 	if (!valid) {

	// 		// return false;
	// 		e.target.value = val.substring(0, val.length - 1);
	// 	}


	// }


	checkNumberLimit(e) {
		let val = e.target.value;
		
		// Get the key that is pressed
		let char = String.fromCharCode(e.which || e.keyCode);
	
		// Allow control keys like backspace, delete, arrows
		if (e.ctrlKey || e.metaKey || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
			return;
		}
	
		// Prevent entering multiple decimal points
		if (e.key === '.' && val.includes('.')) {
			e.preventDefault();
			return false;
		}
	
		// Only allow numbers and a single decimal point
		if (!/^\d*\.?\d*$/.test(val + e.key)) {
			e.preventDefault();
			return false;
		}
	
		// Allow input but validate the value on the fly
		setTimeout(() => {
			// Limit to 5 digits before the decimal point and 2 after the decimal point
			let regex = /^\d{0,5}(\.\d{0,2})?$/;
	
			if (!regex.test(e.target.value)) {
				e.target.value = val; // Restore previous valid value if the current one is invalid
			}
		}, 0);
	}
	
	




	// For shipping charges limit set
	checkNumberLimitForShipping(e) {

		let val = e.target.value;
		
		// Get the key that is pressed
		let char = String.fromCharCode(e.which || e.keyCode);
	
		// Allow control keys like backspace, delete, arrows
		if (e.ctrlKey || e.metaKey || ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
			return;
		}
	
		// Prevent entering multiple decimal points
		if (e.key === '.' && val.includes('.')) {
			e.preventDefault();
			return false;
		}
	
		// Only allow numbers and a single decimal point
		if (!/^\d*\.?\d*$/.test(val + e.key)) {
			e.preventDefault();
			return false;
		}
	
		// Allow input but validate the value on the fly
		setTimeout(() => {
			// Limit to 5 digits before the decimal point and 2 after the decimal point
			let regex = /^\d{0,2}(\.\d{0,2})?$/;
	
			if (!regex.test(e.target.value)) {
				e.target.value = val; // Restore previous valid value if the current one is invalid
			}
		}, 0);
	}


		
	restrictValue() {
		var input = $('#someid');
		var num = parseFloat(input.val());
	
		// Restrict to values greater than 0 and up to 10
		if (num <= 0) {
			input.val(''); // Clear input if it is 0 or negative
		} else if (num > 10) {
			// return false;
			input.val(10); // Cap the value at 10
			this.addProductForm.get('shipAmount').setValue(10);
		}
	}


	// For shipping charges limit set
	checkNumberLimitForSku(e) {


		if (e.target.value.length >= 50) {
			e.preventDefault();
			return false;
		}

	}
	// End

	// For quantity limit
	checkNumberLimitQuantity(e) {
		if (e.keyCode == 46) {
			e.preventDefault();
			return false
		}

		if (e.target.value.length >= 5 || e.keyCode == 45) {
			e.preventDefault();
			return false;
		}

	}
	// End

	//get province list
	getProvinceList() {
		this.authService.getProvinceList()
			.subscribe(provinceData => {
				this.commonService.hideLoader();
				if (provinceData.status == 'success') {
					this.provinceList = provinceData.data.province;
				} else {
					this.provinceList = [];
				}

			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}
	// End


	toggleVisibility(e) {
		this.marked = e.target.checked;
	}


	shippingCharge(e) {
		this.yes = e.target.checked;
	}

	// Product form validation
	get category(): any { return this.addProductForm.get('category'); }
	get subcategory1(): any { return this.addProductForm.get('subcategory1'); }
	get subcategory2(): any { return this.addProductForm.get('subcategory2'); }
	get subcategory3(): any { return this.addProductForm.get('subcategory3'); }
	get subcategory4(): any { return this.addProductForm.get('subcategory4'); }
	get isVariant(): any { return this.addProductForm.get('isVariant'); }
	get name(): any { return this.addProductForm.get('name'); }
	get condition(): any { return this.addProductForm.get('condition'); }
	get brand(): any { return this.addProductForm.get('brand'); }
	get deliveryIn(): any { return this.addProductForm.get('deliveryIn'); }
	get warranty(): any { return this.addProductForm.get('warranty'); }
	get regularPrice(): any { return this.addProductForm.get('regularPrice'); }
	get province(): any { return this.addProductForm.get('province'); }
	get municipalities(): any { return this.addProductForm.get('municipalities'); }
	get shipAmount(): any { return this.addProductForm.get('shipAmount'); }
	get offer_price(): any { return this.addProductForm.get('offer_price'); }
	get description(): any { return this.addProductForm.get('description'); }
	get description_es(): any { return this.addProductForm.get('description_es'); }
	get featuredImage(): any { return this.addProductForm.get('featuredImage'); }
	get isVariantAdded(): any { return this.addProductForm.get('isVariantAdded'); }
	get days_of_return(): any { return this.addProductForm.get('days_of_return'); }
	get return(): any { return this.addProductForm.get('return'); }
	get make_offer(): any { return this.addProductForm.get('make_offer'); }
	get product_sku(): any { return this.addProductForm.get('product_sku'); }
	get product_quantity(): any { return this.addProductForm.get('product_quantity'); }
	get product_weight(): any { return this.addProductForm.get('product_weight'); }
	// End






	// For add product step form 
	onNext(step) {

		console.log('hello check some validation', this.addProductForm.get('municipalities'));

		this.commonService.scrollToTop();


		let checkValidData: any = [];
		if (step == 2) { // for step 1 all validation check
			checkValidData = ['category', 'isVariant', 'brand'];
			if (this.subCategoryLevel == 1) {
				checkValidData.push('subcategory1');

				if (this.addProductForm.get('category').invalid || this.addProductForm.get('isVariant').invalid || this.addProductForm.get('brand').invalid || this.addProductForm.get('subcategory1').invalid) {
					this.singleValidationFields(this.addProductForm, checkValidData);
				} else {
					this.value = step;
				}

			} else if (this.subCategoryLevel == 2) {

				checkValidData.push('subcategory1', 'subcategory2');

				if (this.addProductForm.get('category').invalid || this.addProductForm.get('isVariant').invalid || this.addProductForm.get('brand').invalid || this.addProductForm.get('subcategory1').invalid || this.addProductForm.get('subcategory2').invalid) {
					this.singleValidationFields(this.addProductForm, checkValidData);
				} else {
					this.value = step;
				}

			} else if (this.subCategoryLevel == 3) {
				checkValidData.push('subcategory1', 'subcategory2', 'subcategory3');

				if (this.addProductForm.get('category').invalid || this.addProductForm.get('isVariant').invalid || this.addProductForm.get('brand').invalid || this.addProductForm.get('subcategory1').invalid || this.addProductForm.get('subcategory2').invalid || this.addProductForm.get('subcategory3').invalid) {
					this.singleValidationFields(this.addProductForm, checkValidData);
				} else {
					this.value = step;
				}

			} else if (this.subCategoryLevel == 4) {
				checkValidData.push('subcategory1', 'subcategory2', 'subcategory3', 'subcategory4');

				if (this.addProductForm.get('category').invalid || this.addProductForm.get('isVariant').invalid || this.addProductForm.get('brand').invalid || this.addProductForm.get('subcategory1').invalid || this.addProductForm.get('subcategory2').invalid || this.addProductForm.get('subcategory3').invalid || this.addProductForm.get('subcategory4').invalid) {
					this.singleValidationFields(this.addProductForm, checkValidData);
				} else {
					this.value = step;
				}

			} else {
				this.singleValidationFields(this.addProductForm, checkValidData)
			}
			if (this.selectedVariantList[0].is_no_varinat == 1) {
				this.is_no_variants = 1;

			} else {
				this.is_no_variants = 0;
			}




		}



		else if (step == 3) {


			this.getShippingList(); //For shipping price list

			var ship = $("#clickShipID").val();

			this.addProductForm.get('shipCharge').setValue(ship);
			var asd = this.addProductForm.get('startDAte').value; // get stat date 
			var end_date = this.addProductForm.get('endDAte').value; // get end date 


			if (this.addProductForm.get('product_sku').invalid || this.addProductForm.get('name').invalid || this.addProductForm.get('condition').invalid || this.addProductForm.get('deliveryIn').invalid || this.addProductForm.get('warranty').invalid || this.addProductForm.get('regularPrice').invalid || this.addProductForm.get('province').invalid || this.addProductForm.get('municipalities').invalid || this.addProductForm.get('shipAmount').invalid || this.addProductForm.get('offer_price').invalid || this.addProductForm.get('days_of_return').invalid) {

				this.singleValidationFields(this.addProductForm, ['product_sku', 'name', 'condition', 'deliveryIn', 'warranty', 'regularPrice', 'shipAmount', 'province', 'municipalities', 'offer_price', 'days_of_return']);
			} else {


				if (this.sellingAmount == 0) {
					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true
					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");
						}

						if (result.isDismissed) {

							this.value = '2';


						}
					})

				}

				// code for offer commision check 

				else if (this.offerListcomission == 0 && this.limitedOffer != false) {
					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true
					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							this.value = '2';


						}
					})



				}
				else {
					if (this.limitedOffer == true && this.addProductForm.get('regularPrice').value < this.addProductForm.get('offer_price').value) {
						this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.price_validation')); return false;

					}

					let startDate = (this.addProductForm.get('startDAte').value != "") ? this.commonService.dateFormatConverter(this.addProductForm.get('startDAte').value) : "";
					let endDate = (this.addProductForm.get('endDAte').value != "") ? this.commonService.dateFormatConverter(this.addProductForm.get('endDAte').value) : "";

					if ((this.limitedOffer == true && asd == "") || (this.limitedOffer == true && end_date == "")) {

						this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_date_is_required'));
						return false;


					} else {
						if (new Date(startDate) > new Date(endDate)) {

							this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_end_date_should_be_grater'));
							return false;
						} else {
							this.value = step;

						}
					}
					if (this.is_no_variants == 1) {
						this.value = '4';

					} else {

					}
				}
			}

		} else if (step == 4) {
			if (this.is_no_variants == 0) {
				if (this.addProductForm.get('isVariantAdded').invalid || this.finialVariantList.length == 0) {

					this.singleValidationFields(this.addProductForm, ['isVariantAdded']);
				} else {
					this.value = step;
				}
			}


		} else if (step == 5) {
			// this.noVariantsQuantity()


			if (this.is_no_variants == 1) {

				if (this.addProductForm.get('product_quantity').invalid) {
					this.singleValidationFields(this.addProductForm, ['product_quantity']);
					return false;
				}


			}


			if (this.addProductForm.get('featuredImage').invalid) {
				this.singleValidationFields(this.addProductForm, ['featuredImage']);
			}
			else if (this.addProductForm.get('description').invalid) {
				this.singleValidationFields(this.addProductForm, ['description']);
			}
			else if (typeof $('#KeyFeat').val() == 'undefined') {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.minimum_one_key_featured_is_required'));
				return false;
			} else {
				this.value = step;
			}

		}

	}
	// End


	rangeRediraction() {

		$('#rangeCommisionModal').modal('hide');
		var url = '/seller/seller-account/commission';

		setTimeout(() => {
			//   window.location.href = url;
			window.open(url, "_blank");

		}, 100);


	}

	// For add product step form prev button
	onPrev(step) {

		if (this.is_no_variants == 1 && step == '3') {
			this.value = '2';
		} else {
			this.value = step;
		}
	}

	// End

	// For product description 
	trimValue(formControl) {
		((typeof formControl.value) && formControl.value.trim() == "") ?
			formControl.setValue("") : formControl.setValue(formControl.value);
	}
	// End

	// For seller waranty
	checkWarranty($event) {
		const warrantyCtrl = this.addProductForm.get('warranty');
		if ($event.target.checked) {
			warrantyCtrl.setValidators(Validators.required);
			this.isWarrantyApply = true;
		} else {
			warrantyCtrl.clearValidators();
			this.isWarrantyApply = false;
			this.addProductForm.get('warranty').setValue('');
		}
		warrantyCtrl.updateValueAndValidity();
	}
	// End

	getShippingList() {
		this.shippingIds = [];
		// this.commonService.showLoader();
		var shipping_id = '';
		var offset = '';

		this.sellerService.shippingChargesget(shipping_id, offset).subscribe(
			(data) => {
				this.commonService.hideLoader();
				if (data.status == 'success') {
					this.shiipingChargesList = data.data

					if (this.shiipingChargesList.length == 0) {
						this.shippingListCount = 0;
					} else {
						this.shippingListCount = 1;
					}
				} else {
				}
			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});
	}

	// For shipping type value
	clickShip() {
		this.getShippingList(); //For shipping price list
		this.lastSel = $("#clickShipID option:selected").val();
	}
	// End

	// For change shipping type 
	changeShip($event, e) {
		this.getShippingList(); //For shipping price list

		this.provinceIdss = [];

		if (this.ProvinceFieldValidation.length == 0) {
			this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_province_first'));
			$("#clickShipID").val(this.lastSel);
			e.preventDefault();
			return false;
		}

		if (this.provinceGetListForShipping.length == 0) {
			this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_municipalities_first'));
			$("#clickShipID").val(this.lastSel);
			e.preventDefault();
			return false;
		}

		console.log('this.provinceGetListForShipping', this.provinceGetListForShipping);
		console.log('this.this.provinceIdss', this.provinceIdss);
		console.log('this.this.this.shiipingChargesLis', this.shiipingChargesList);

		this.provinceGetListForShipping.map(object => {
			this.provinceIdss.push(object.id);
		});

		var checkValue = this.shiipingChargesList.filter((val) => this.provinceIdss.includes(val.municipalityId));
		this.getShippingList(); //For shipping price list

		if (checkValue.length != this.provinceIdss.length && $event == 2) {

			Swal.fire({
				text: this.translate.instant('EDIT_PRODUCT.please_add_kg_shipping_price_for_all_province'),
				icon: 'warning',
				showCancelButton: true,
				cancelButtonText: this.translate.instant('COMMON.cancel'),
				confirmButtonText: this.translate.instant('ADD_PRODUCT.add_price'),
				reverseButtons: true
			}).then((result) => {
				if (result.isConfirmed) {
					var url = '/seller/seller-account/shipping';
					window.open(url, "_blank");

					// this.router.navigate(['/seller/seller-account/commission']);

				}
			})

			// this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_kg_shipping_price_for_all_province'));
			$("#clickShipID").val(this.lastSel);
			this.getShippingList(); //For shipping price list

			e.preventDefault();
			return false;
		}


		const shipAmountCtrl = this.addProductForm.get('shipAmount');
		if ($event == 1) {
			shipAmountCtrl.setValidators([Validators.required]);
			this.shipChargeFlag = true;
		} else {

			shipAmountCtrl.clearValidators();
			this.shipChargeFlag = false;
			this.addProductForm.get('shipAmount').setValue('');
		}

		shipAmountCtrl.updateValueAndValidity();
	}
	// End

	// For limited offer 
	makeLimitedOffer($event) {
		if (this.variantModel.offer_variant_price == undefined) {
			this.offerv = 0;
		}

		const offerCtrl = this.addProductForm.get('offer_price');
		if ($event.target.checked) {
			this.limitedOffer = true;
			offerCtrl.setValidators([Validators.required, Validators.pattern('^[0-9]{1,10}((\.[0-9]{1,2}))?$')]);
		} else {
			offerCtrl.clearValidators();
			this.limitedOffer = false;
			this.addProductForm.get('offer_price').setValue('');
			this.addProductForm.get('startDAte').setValue('');
			this.addProductForm.get('endDAte').setValue('');
			this.offerVariantPrice = '0';
			this.offerVariantPriceCommission = '0';
			this.editVariantModel.offer_variant_price = '0';
			this.variantModel.offer_variant_price = '0';
			this.editF.value.offer_variant_price = '0';
			this.offerListcomission = 0;
			this.offerList = 0;
		}
		offerCtrl.updateValueAndValidity();

	}
	// End

	// For return product 
	maximumDayReturning($event) {
		const returnctrl = this.addProductForm.get('days_of_return');
		if ($event.target.checked) {
			returnctrl.setValidators([Validators.required]);
			this.returningDay = true;
		} else {
			returnctrl.clearValidators();
			this.returningDay = false;
			this.addProductForm.get('days_of_return').setValue('');
		}
		returnctrl.updateValueAndValidity();
	}
	// End

	// Function for show product in varinats list
	changeshowList($event) {
		if ($event.target.checked) {
			this.variantModel.showlist = true;
		} else {
			this.variantModel.showlist = false;
		}
	}
	// End

	// Function for show product in varinats list edit product
	editChangeshowList($event) {
		if ($event.target.checked) {
			this.editVariantModel.showlist = true;
		} else {
			this.editVariantModel.showlist = false;
		}
	}
	// End

	getCategoryList() {
		this.sellerService.getCategoryList(1).subscribe(catData => {
			this.commonService.hideLoader();
			if (catData.status == 'success') {
				this.categoryList = catData.data.category;
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}


	getComissionList() {
		this.sellerService.getComissionList().subscribe(comissionData => {
			this.commonService.hideLoader();
			if (comissionData.status == 'success') {
				this.comissionData = comissionData.data.commission_range;
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}

	getWarrantyList() {
		this.sellerService.getWarrantyList().subscribe(data => {
			this.commonService.hideLoader();
			if (data.status == 'success') {
				this.warrantyList = data.data.warranty;
			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}

	encodeCategoryValue(category: any): string {
		return JSON.stringify({
			id: category.categoryID,
			category_type: category.category_type
		});
	}

	// For product category
	selectCategory(categoriesValues: any) {

		let foundItem = this.categoryList.find(item => item.categoryID === categoriesValues);
		this.categoryType = foundItem.category_type;

		if (this.categoryType == 'food') {
			this.addProductForm.get('condition').setValue(1);

		} else {
			this.addProductForm.get('condition').setValue('');

		}
		// const decodedValue = JSON.parse(categoriesValues);
		// this.categoryType = decodedValue.category_type

		// if(this.categoryType == 'food'){
		// 	this.addProductForm.get('condition').setValue(1);

		// }else{
		// 	this.addProductForm.get('condition').setValue('');

		// }

		let subCategoryList1Object: any = {};

		if (categoriesValues) {
			subCategoryList1Object = this.categoryList.find(function (entry) { return entry.categoryID === categoriesValues; });
			this.subCategoryList1 = subCategoryList1Object.sub_category;
			this.subCategoryLevel = 1;
			this.getVariantList(categoriesValues); // get variants list
			// this.getBrandList(id); // get brand list
			this.resetCategory(1);
		} else {
			subCategoryList1Object = {};
			this.subCategoryList1 = [];
			this.subCategoryLevel = 0;
			this.variantsList = [];
		}

		this.finialVariantList = [];
		this.addProductForm.get('isVariantAdded').setValue('');
		this.addProductForm.get('brand').setValue('');
	}
	// End


	// For variants
	clearVariant(value = "", key = '') {
		if (key != "") {
			value = "";
			this.sellingAmount = 0;
			this.sellingAmountPercentage = 0;
		}
		this.finialVariantList = [];
		this.addProductForm.get('isVariantAdded').setValue('');
	}
	// End

	// For clear variants offer 
	clearVariantOfferList(value = "", key = '') {
		if (key != "") {
			value = "";
			this.offerList = 0;
			this.offerListcomission = 0;
		}
	}
	// End

	// For clear variant
	clearVariantSellingPrice(value = "", key = '', ids = "") {
		if (key != "" && ids != '2') {
			value = "";
			this.sellingAmount1 = 0;
			this.sellingAmountPercentagev = 0;
		}

		if (key != "" && ids == '2') {

			value = "";
			this.sellingAmount1 = 0;

			this.editVariantModel.selling_price = 0;
			this.editVariantModel.selling_variant_commission = 0;
		}
	}
	// End

	// For clear offer
	clearOffer(value = "", key = '', ids = "") {
		if (key != "" && ids != '2') {
			value = "";
			this.offerVariantPrice = 0;
			this.offerVariantPriceCommission = 0;
		}
		if (key != "" && ids == '2') {
			value = "";
			this.editVariantModel.offer_price_with_admin_commission = 0;
			this.editVariantModel.offer_price_commision_percent = 0;
		}
	}
	// End

	// For offer commission add and edit Varinats
	offercommisoion($value = 0, key, ids) {

		if ($value <= 0) {
			$('#offerPriceClear').val('');
		}

		if ($value != 0) {
			var getAmount = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));

			if (getAmount.length != 0) {
				var asss = getAmount[0].commission_rate
				this.offerVariantPrice = getAmount;
				var Amt = getAmount.filter((val) => val.commission_type == 1)
				if (Amt != 0) {
					asss = Amt[0].commission_rate;

				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var offerVariantPrice = numberValue2 + numberValue1;
				this.offerVariantPrice = offerVariantPrice.toFixed(2);
				this.offerVariantPriceCommission = asss;

				if (ids == 2) {
					this.editVariantModel.offer_price_with_admin_commission = this.offerVariantPrice;
					this.editVariantModel.offer_price_commision_percent = this.offerVariantPriceCommission;
				}
			}
			else {

				if (key == '0' && ids != '2') {

					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true
					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) { // if swal is cancel then it's not redirect to any where 

							return false;

						}
					})

					// $('#rangeCommisionModal').modal('show');

					// this.offerVariantPrice = this.variantModel.offer_variant_price;
				} else {


					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {

						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;



						}
					})
					// $('#rangeCommisionModal').modal('show');

					// this.offerVariantPrice = this.editVariantModel.offer_variant_price;
				}

				if (key == '0' && ids == '2') {
					this.editVariantModel.offer_price_with_admin_commission = this.offerVariantPrice;
					this.editVariantModel.offer_price_commision_percent = this.offerVariantPriceCommission;
				} else {


					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})

					// $('#rangeCommisionModal').modal('show');

					// this.editVariantModel.offer_price_with_admin_commission = this.editVariantModel.offer_variant_price;
					// this.editVariantModel.offer_price_commision_percent = 0;
				}
				this.offerVariantPriceCommission = 0;
			}
		}

	}
	// End

	// For commission
	OfferPriceListcommisoion($value = "") {
		if (this.addProductForm.get('offer_price').value <= 0) {
			this.addProductForm.get('offer_price').setValue('');
		}

		if ($value != "") {
			var getAmount = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));
			if (getAmount.length != 0) {
				var asss = getAmount[0].commission_rate
				this.offerList = getAmount;
				var Amt = getAmount.filter((val) => val.commission_type == 1)

				if (Amt != 0) {
					asss = Amt[0].commission_rate;
				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var sellingAmount = numberValue2 + numberValue1;
				this.offerList = sellingAmount.toFixed(2);
				this.offerListcomission = asss;
			}
			else {

				//==========================offer price check comission ================================


				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true
				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';
						window.open(url, "_blank");

						// this.router.navigate(['/seller/seller-account/commission']);

					}
				})

				//===============================end offer price check condition  =================================



				this.offerList = this.addProductForm.get('offer_price').value;
				this.offerListcomission = 0;
			}
		}
	}
	// End

	// For shipping amount validation 
	shipAmountValidationerror() {
		if (this.addProductForm.get('shipAmount').value <= 0) {
			this.addProductForm.get('shipAmount').setValue('');
		}
	}
	// End

	// For commission
	commisoion($value = "") {
		if ($value != "") {
			const shipAmountCtrl = this.addProductForm.get('regularPrice');

			if (this.addProductForm.get('regularPrice').value <= 0) {
				this.addProductForm.get('regularPrice').setValue('');
			}
			var getAmount = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));

			if (getAmount.length != 0) {
				var asss = getAmount[0].commission_rate
				this.selling_price = getAmount;

				var Amt = getAmount.filter((val) => val.commission_type == 1)

				if (Amt != 0) {
					asss = Amt[0].commission_rate;
				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var sellingAmount = numberValue2 + numberValue1;
				this.sellingAmount = sellingAmount.toFixed(2);
				this.sellingAmountPercentage = asss;
			}
			else {

				this.swalShow == 1
				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true
				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';
						window.open(url, "_blank");

						// this.router.navigate(['/seller/seller-account/commission']);

					}
				})

				// this.sellingAmount = this.addProductForm.get('regularPrice').value;
				// this.sellingAmountPercentage = 0;
			}
		}
	}
	// End


	commisoionForAddVarinat($value = 0, key, ids) {

		if ($value <= 0) {
			$('#AddVariantPriceCler').val('');
		}

		if ($value != 0) {
			var getAmount1 = this.comissionData.filter((val) => Number($value) >= Number(val.minimum) && Number($value) <= Number(val.maximum));

			if (getAmount1.length != 0) {
				var asss = getAmount1[0].commission_rate
				this.selling_price1 = getAmount1;

				var Amt = getAmount1.filter((val) => val.commission_type == 1)

				if (Amt != 0) {
					asss = Amt[0].commission_rate;
				}

				const percentVal = this.calcPercent($value, asss);
				var numberValue1 = Number(percentVal);
				var numberValue2 = Number($value);
				var sellingAmount = numberValue2 + numberValue1;
				this.sellingAmount1 = sellingAmount.toFixed(2);
				this.sellingAmountPercentagev = asss;

				if (ids == 2) {
					this.editVariantModel.selling_price = this.sellingAmount1;
					this.editVariantModel.selling_variant_commission = this.sellingAmountPercentagev;
				}
			}
			else {
				if (key == '0' && ids != '2') {


					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})


					// $('#rangeCommisionModal').modal('show');  //  show model for error 

					// this.sellingAmount1 = this.variantModel.amount;
				} else {
					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {

						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})


					// $('#rangeCommisionModal').modal('show');

					// this.sellingAmount1 = this.editVariantModel.amount;
				}


				if (key == '0' && ids == '2') {
					this.editVariantModel.selling_price = this.sellingAmount1;
					this.editVariantModel.selling_variant_commission = this.sellingAmountPercentagev;
				} else {
					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})


					// $('#rangeCommisionModal').modal('show');

					// this.editVariantModel.selling_price = this.editVariantModel.amount;
					// this.editVariantModel.selling_variant_commission = 0;
				}
				this.sellingAmountPercentagev = 0;
			}
		}

	}






	calcPercent(num, percentage) {
		return num * (percentage / 100);
	}

	// For product category
	selectSubCategorys(id, level) {
		this.getBrandList(id);
	}

	selectSubCategory(id, level) {
		this.getBrandList(id);
		let subCategoryListObject: any = {};

		if (id) {
			switch (level) {
				case 2:
					subCategoryListObject = this.subCategoryList1.find(function (entry) { return entry.categoryID === id; });
					this.subCategoryList2 = subCategoryListObject.sub_category;
					if (this.subCategoryList2.length) {
						this.subCategoryLevel = Number(level);
					} else {
						this.subCategoryLevel = Number(level) - Number(1);
					}
					break;
				case 3:
					subCategoryListObject = this.subCategoryList2.find(function (entry) { return entry.categoryID === id; });
					this.subCategoryList3 = subCategoryListObject.sub_category;
					if (this.subCategoryList3.length) {
						this.subCategoryLevel = Number(level);
					} else {
						this.subCategoryLevel = Number(level) - Number(1);
					}
					break;
				case 4:
					subCategoryListObject = this.subCategoryList3.find(function (entry) { return entry.categoryID === id; });
					this.subCategoryList4 = subCategoryListObject.sub_category;
					if (this.subCategoryList4.length) {
						this.subCategoryLevel = Number(level);
					} else {
						this.subCategoryLevel = Number(level) - Number(1);
					}
					break;
			}

		} else {

			switch (level) {
				case 2:
					this.subCategoryList2 = [];
					this.subCategoryList3 = [];
					this.subCategoryList4 = [];
					this.subCategoryLevel = Number(level) - Number(1);
					break;
				case 3:
					this.subCategoryList3 = [];
					this.subCategoryList4 = [];
					this.subCategoryLevel = Number(level) - Number(1);
					break;
				case 4:
					this.subCategoryList4 = [];
					this.subCategoryLevel = Number(level) - Number(1);
					break;
			}
		}

		this.resetCategory(level);
	}

	resetCategory(level) {
		switch (level) {
			case 1:
				this.addProductForm.get('subcategory1').reset();
				this.addProductForm.get('subcategory2').reset();
				this.addProductForm.get('subcategory3').reset();
				this.addProductForm.get('subcategory4').reset();
				this.addProductForm.get('isVariant').reset();
				break;
			case 2:
				this.addProductForm.get('subcategory2').reset();
				this.addProductForm.get('subcategory3').reset();
				this.addProductForm.get('subcategory4').reset();
				break;
			case 3:
				this.addProductForm.get('subcategory3').reset();
				this.addProductForm.get('subcategory4').reset();
				break;
		}

	}
	// End

	// For variants add & remove function
	variantAddRemove(index, $event) {

		this.selectedVariantList = [];
		this.finialVariantList = [];

		// check condition for no variant
		if (this.variantsList[index].is_no_varinat == 1) {
			$('.removeVariant').removeClass('varinat');
			$(`.newVariants${index}`).addClass('varinat');
			this.variantsList.forEach(element => {

				if (element.is_no_varinat == 1 && element.select == false) {
					element.select = true;
				} else {
					element.select = false;

				}
			});
		} else {

			this.variantsList[index].select = ($event.target.checked) ? true : false;
			this.variantsList.forEach(function callback(element, indexs) {

				if (element.is_no_varinat == 1) {
					element.select = false;
					$(`.newVariants${indexs}`).removeClass('varinat');


				} else {
					$(`.newVariants${index}`).addClass('varinat');

				}
			});
		}

		this.variantsList.forEach(element => {
			if (element.select) {
				this.selectedVariantList.push(element);
			}
		});


		if (this.selectedVariantList.length > 0) {
			this.addProductForm.get('isVariant').setValue('1');
		} else {
			this.addProductForm.get('isVariant').setValue('');
		}
	}
	// End

	// For get variants list
	getVariantList(categoryId) {
		this.variantsList = [];

		this.sellerService.getVariantList(categoryId).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				data.data.variants_List.forEach(element => {
					element.select = false;
					this.variantsList.push(element);
				});

			}
		}, err => {
			this.commonService.errorhandler(err);
		});
	}
	// End

	// For get brands list
	getBrandList(categoryId) {
		this.sellerService.getBrandList(categoryId).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				this.brandList = data.data.brand_List;
			} else {
				this.brandList = [];
			}

		}, err => {
			this.commonService.errorhandler(err);
		});
	}
	// End

	// add key feature
	addKeyFeature(value) {
		if (value.trim() != "") {

			if (this.keyFeatureList.length >= 5) {
				Swal.fire(this.translate.instant('PROFILE.alert'), this.translate.instant('ADD_PRODUCT.max_text_key'));
				return false;
			} else {
				this.keyFeatureList.push(value);

			}
		}
	}
	// End

	getval(sel) {
		alert('hello');
		console.log(sel.value);
		if (sel.value) {
			this.showInputBlock = true;
		} else {
			this.showInputBlock = false;
		}
	}

	// Function to add province
	addProvince(value: number) {
		// this.selectedProvinces.push(value);

		if (value && this.selectedProvinces.indexOf(value) === -1) {
			if (this.selectedProvinces.length >= 8) {
				Swal.fire(this.translate.instant('PROFILE.alert'), this.translate.instant('ADD_PRODUCT.max_text_province'));
				return false;
			} else {
				this.selectedProvinces.push(value);
			}
		}
	}

	// Function to remove province
	removeProvince(index: number) {
		this.selectedProvinces.splice(index, 1);
	}

	// Function to get province name by ID
	getProvinceName(id: number): string {
		const province = this.provinceList.find(p => p.provinceID === id);
		return province ? province.name : '';
	}

	// add key feature
	addPakage(value) {
		if (value.trim() != "") {
			if (this.addPakageList.length >= 20) {
				Swal.fire(this.translate.instant('PROFILE.alert'), this.translate.instant('ADD_PRODUCT.max_text_pacakge'));
				return false;
			} else {
				this.addPakageList.push(value);

			}
		}
	}
	// End

	// remove key feature
	removeKey(index) {
		if (index > -1) {
			this.keyFeatureList.splice(index, 1);
		}
	}
	// End

	// remove key feature
	removeKey1(index) {
		if (index > -1) {
			this.addPakageList.splice(index, 1);
		}
	}
	// End

	// variants modal
	addVariantsModalOpen() {
		var totalLength = this.finialVariantList.length
		if (totalLength >= 10) {
			Swal.fire(this.translate.instant('PROFILE.alert'), this.translate.instant('ADD_PRODUCT.max_text'));
			return false;
		}

		this.form.reset();
		this.variantsValuesList = [];
		this.galleryList = [];
		this.gallery_image = [];
		this.is_add = 1;
		this.offerVariantPrice = 0;
		this.offerVariantPriceCommission = 0;
		this.sellingAmount1 = 0;
		this.sellingAmountPercentagev = 0;
		// $('#addVarNew').modal('show');
		$('#addVarNew').modal({
			backdrop: 'static',
			keyboard: false
		})

	}
	// End

	selectVariantsValue($event) {
		let variantSelArray = $event.target.value.split('-');

		if (variantSelArray[1] != '0') {

			let check = this.variantsValuesList.find((selectVal) => { return selectVal.variantID == variantSelArray[0] });

			let key = this.selectedVariantList.find((keyVal) => { return keyVal.variantID == variantSelArray[0] });

			let value = key.variant_value.find((valueVal) => { return valueVal.variantValueID == variantSelArray[1] });


			if (typeof check == "undefined") {

				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsValuesList.push(pushObject);

			} else {

				var stIndex = this.variantsValuesList.indexOf(check);

				if (stIndex > -1) {
					this.variantsValuesList.splice(stIndex, 1);
				}

				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsValuesList.push(pushObject);
			}

		}

	}

	// submit varinats form  and check all validation 
	onSubmitVariant() {

		let addVariant: any = {};
		this.gallery_image1 = this.gallery_image;
		// check form is valid or not and  check varinat lenghth and regular price and product variant price 
		if (this.form.valid && this.variantsValuesList.length == this.selectedVariantList.length && Number(this.addProductForm.get('regularPrice').value) >= Number(this.form.value.amount)) {


			if (this.sellingAmount1 == 0) { // check commission 
				// $('#rangeCommisionModal').modal('show'); // show popup for erroe message 
				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true,

				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';

						window.open(url, "_blank");

					}

					if (result.isDismissed) {

						return false;

					}
				})
			} else if (this.offerVariantPrice == 0 && this.limitedOffer != false) {
				// $('#rangeCommisionModal').modal('show');


				Swal.fire({
					text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
					icon: 'warning',
					showCancelButton: true,
					cancelButtonText: this.translate.instant('COMMON.cancel'),
					confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
					reverseButtons: true,

				}).then((result) => {
					if (result.isConfirmed) {
						var url = '/seller/seller-account/commission';

						window.open(url, "_blank");

					}

					if (result.isDismissed) {

						return false;


					}
				})
			} else if (this.gallery_image1.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;  // check  image 
			} else if (Number(this.addProductForm.get('offer_price').value) < Number(this.form.value.offer_variant_price)) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then_or_equal_to_offer_price')); return false;
			} else {


				addVariant.discountPercent = 0;
				addVariant.discountAmount = 0;

				addVariant.vatiantsData = this.variantsValuesList;
				addVariant.variant_image = this.gallery_image1;
				addVariant.amount = parseFloat(this.variantModel.amount).toFixed(2);
				addVariant.selling_price = this.sellingAmount1;
				addVariant.selling_variant_commission = this.sellingAmountPercentagev;

				this.offerv = this.form.value.offer_variant_price;

				if (this.variantModel.offer_variant_price == undefined || this.variantModel.offer_variant_price == '0') {
					this.offerv = 0;
				}

				addVariant.sku = this.variantModel.sku;
				addVariant.quantity = this.variantModel.quantity;
				addVariant.product_weight = this.variantModel.product_weight;
				addVariant.offer_price_with_admin_commission = this.offerVariantPrice;
				addVariant.offer_price_commission_percent = this.offerVariantPriceCommission;
				addVariant.offer_price = this.offerv;

				var show_list = (typeof this.variantModel.showlist == 'undefined') ? false : this.variantModel.showlist;
				if (show_list == true) {
					addVariant.showlist = 1;
				} else {
					addVariant.showlist = 0;
				}

				// this.checkVariantExist()
				if (addVariant.discountPercent == '100') {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.you_cant_add_product_with_discount'));
				} else if (!this.checkVariantExist()) {
					this.finialVariantList.push(addVariant);
					$('#addVarNew').modal('hide');
					this.addProductForm.get('isVariantAdded').setValue('1');
				} else {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.already_added_this_type_of_variant_combination'));
				}

			}
		}

		// if all validation is passed then  preceed next step 

		else {

			// if form is not valid then check all validation 

			if (this.form.value.amount == "" || this.form.value.amount == null || this.form.value.amount == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_is_required'));
				return false; // check amount is available or not 
			}


			//check make an offer on and offer price is available or not 
			else if ((this.limitedOffer && this.form.value.offer_variant_price == "") || (this.limitedOffer && this.form.value.offer_variant_price == null)) {
				this.commonService.toastError(this.translate.instant('ADD_PRODUCT.offer_amount_required')); return false;
			}

			// check variant sku 
			else if (this.form.value.sku == null || this.form.value.sku.trim() == "") {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.sku_is_required'));
				return false;
			}

			// check quantity
			else if (this.form.value.quantity == "" || this.form.value.quantity == null || this.form.value.quantity == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.quantity_is_required'));
				return false;
			}


			// else if (this.form.value.product_weight == "" || this.form.value.product_weight == null) {
			// 	this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.product_weight_is_required'));
			// }

			// check product regular price and product varinat price 
			else if (Number(this.addProductForm.get('regularPrice').value) < Number(this.form.value.amount)) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then'));
			}

			// check product offer price and varinat offer price 	
			else if (this.limitedOffer && Number(this.addProductForm.get('offer_price').value) < Number(this.form.value.offer_variant_price)) {
				this.commonService.toastError('EDIT_PRODUCT.amount_should_be_less_then_or_equal_to_offer_price'); return false;
			}
			// check varinats 
			else if (this.variantsValuesList.length != this.selectedVariantList.length) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_variant'));

			}
			// check gallery image 
			else if (this.gallery_image1.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;
			}

		}

	}


	checkVariantExist() {
		let finalarray = [];
		const isFlag = this.finialVariantList.some(element1 => {
			element1.vatiantsData.forEach(element2 => {

				this.variantsValuesList.forEach(element3 => {
					if (element3.variantID == element2.variantID && element3.variantValueID == element2.variantValueID) {
						finalarray.push(element3);
					}
				});
			});

			if (finalarray.length == this.selectedVariantList.length) {
				return true;
			}

			finalarray = [];

		});

		return isFlag;
	}

	deleteAddedVariant(index) {
		// alert(index);
		if (index > -1) {
			this.finialVariantList.splice(index, 1);
			if (this.finialVariantList.length == 0) {
				this.addProductForm.get('isVariantAdded').setValue('');
			}
		}
	}

	editvariants(index) {
		this.is_add = 0;
		this.editVariantModel.variant_image = [];
		this.editVariantindex = index;
		this.selevtedInmg = [];
		this.editqtd = [];
		this.variantsEditValuesList = [];
		this.editVariantModel.amount = this.finialVariantList[index].amount;
		this.editVariantModel.selling_price = this.finialVariantList[index].selling_price;
		this.editVariantModel.selling_variant_commission = this.finialVariantList[index].selling_variant_commission;
		this.editVariantModel.showlist = this.finialVariantList[index].showlist;
		this.editVariantModel.sku = this.finialVariantList[index].sku;
		this.editVariantModel.quantity = this.finialVariantList[index].quantity;
		this.editVariantModel.product_weight = this.finialVariantList[index].product_weight;
		this.editVariantModel.variant_image = this.finialVariantList[index].variant_image;
		this.editVariantModel.offer_variant_price = this.finialVariantList[index].offer_price;
		this.editVariantModel.offer_price_commision_percent = this.finialVariantList[index].offer_price_commission_percent;
		this.editVariantModel.offer_price_with_admin_commission = this.finialVariantList[index].offer_price_with_admin_commission;
		this.selevtedInmg = this.finialVariantList[index].variant_image;

		this.selectedVariantList.forEach(element1 => {
			element1.variant_value.forEach(element2 => {
				element2.select = false;
				this.finialVariantList[index].vatiantsData.forEach(element3 => {
					if (element3.variantValueID == element2.variantValueID) {
						element2.select = true;
						this.variantsEditValuesList.push(element3);
					}
				});

			});
		});

		// $('#editvariants').modal('show');
		$('#editvariants').modal({ backdrop: 'static', keyboard: false })
	}

	selectEditVariantsValue($event) {
		let variantEditSelArray = $event.target.value.split('-');

		if (variantEditSelArray[1] != '0') {

			let check = this.variantsEditValuesList.find((selectVal) => { return selectVal.variantID == variantEditSelArray[0] });

			let key = this.selectedVariantList.find((keyVal) => { return keyVal.variantID == variantEditSelArray[0] });

			let value = key.variant_value.find((valueVal) => { return valueVal.variantValueID == variantEditSelArray[1] });


			if (typeof check == "undefined") {

				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsEditValuesList.push(pushObject);

			} else {

				var stIndex = this.variantsEditValuesList.indexOf(check);

				if (stIndex > -1) {
					this.variantsEditValuesList.splice(stIndex, 1);
				}

				let pushObject = {
					'name': key.name,
					'variantID': key.variantID,
					'variant_value': value.variant_value,
					'variantValueID': value.variantValueID,
				}

				this.variantsEditValuesList.push(pushObject);
			}

		}

	}

	onSubmitEditVariant() {

		let editVariant: any = {};


		if (this.editF.valid && this.variantsEditValuesList.length == this.selectedVariantList.length && Number(this.addProductForm.get('regularPrice').value) >= Number(this.editF.value.amount)) {


			if (this.editVariantModel.variant_image.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;
			}
			if (this.limitedOffer == true) {
				if (this.editF.value.offer_variant_price == "" || this.editF.value.offer_variant_price == null) {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_amount_is_required')); return false;
				}

				if (Number(this.addProductForm.get('offer_price').value) < Number(this.editF.value.offer_variant_price)) {
					this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then_or_equal_to_offer_price')); return false;
				}
			}


			if (!this.checkEditVariantExist()) {


				if (this.sellingAmount1 == 0) { // check commission 
					// $('#rangeCommisionModal').modal('show'); // show popup for erroe message 
					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;

						}
					})
				} else if (this.offerVariantPrice == 0 && this.limitedOffer != false) {
					// $('#rangeCommisionModal').modal('show');


					Swal.fire({
						text: this.translate.instant('ADD_PRODUCT.warring_text_for_offer'),
						icon: 'warning',
						showCancelButton: true,
						cancelButtonText: this.translate.instant('COMMON.cancel'),
						confirmButtonText: this.translate.instant('ADD_PRODUCT.view_range'),
						reverseButtons: true,

					}).then((result) => {
						if (result.isConfirmed) {
							var url = '/seller/seller-account/commission';

							window.open(url, "_blank");

						}

						if (result.isDismissed) {

							return false;


						}
					})
				} else {


					if (this.editVariantModel.showlist == true) {
						this.editVariantModel.showlist = 1;
					} else {
						this.editVariantModel.showlist = 0;
					}

					editVariant.discountPercent = 0;
					editVariant.discountAmount = 0;

					editVariant.vatiantsData = this.variantsEditValuesList;
					editVariant.amount = parseFloat(this.editVariantModel.amount).toFixed(2);
					editVariant.selling_price = this.sellingAmount1;
					editVariant.selling_variant_commission = this.sellingAmountPercentagev;
					editVariant.sku = this.editVariantModel.sku;
					editVariant.quantity = this.editVariantModel.quantity;
					editVariant.product_weight = this.editVariantModel.product_weight;
					editVariant.showlist = this.editVariantModel.showlist;
					editVariant.variant_image = this.editVariantModel.variant_image;
					editVariant.offer_price = this.editVariantModel.offer_variant_price;
					editVariant.offer_price_commission_percent = this.offerVariantPriceCommission;
					editVariant.offer_price_with_admin_commission = this.offerVariantPrice;

					if (editVariant.discountPercent == '100') {
						this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.you_cant_add_product_with_discount'));
					} else {
						this.finialVariantList[this.editVariantindex] = editVariant;
						$('#editvariants').modal('hide');
					}
				}

			} else {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.already_added_this_type_of_variant_combination'));
			}

		} else {



			if (this.editF.value.amount == "" || this.editF.value.amount == null) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_is_required'));
			}


			else if (Number(this.addProductForm.get('regularPrice').value) < Number(this.editF.value.amount)) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.amount_should_be_less_then'));
			}


			else if ((this.limitedOffer && this.editF.value.offer_variant_price == "") || (this.limitedOffer && this.editF.value.offer_variant_price == null)) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.offer_amount_is_required')); return false;
			}

			else if (this.editF.value.sku == "" || this.editF.value.sku == null) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.sku_is_required'));
			}

			else if (this.editF.value.quantity == "" || this.editF.value.quantity == null) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.quantity_is_required'));
			}

			else if (this.editF.value.quantity.match('^[0-9]{1,10}((\.[0-9]{1,2}))?$') == null) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.quantity_must_be_vaild'));
			}

			// else if (this.editF.value.product_weight == "" || this.editF.value.product_weight == null) {
			// 	this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.product_weight_is_required'));
			// }
			else if (this.variantsEditValuesList.length != this.selectedVariantList.length) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_select_variant'));
			}


			else if (this.editVariantModel.variant_image.length == 0) {
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.please_add_variant_images')); return false;
			}

		}
	}


	checkNumberLimitWeight(e) {

		if (e.target.value.includes('.') && e.keyCode == 46) {

			e.preventDefault();
			return false
		}

		var valid = /^\d{0,4}(\.\d{0,2})?$/.test(e.target.value),
			val = e.target.value;

		if (!valid) {
			e.target.value = val.substring(0, val.length - 1);
		}

	}


	checkEditVariantExist() {
		let finalarray = [];

		const isFlag = this.finialVariantList.some((element1, key) => {

			element1.vatiantsData.forEach(element2 => {

				this.variantsEditValuesList.forEach(element3 => {
					if (element3.variantID == element2.variantID && element3.variantValueID == element2.variantValueID) {
						finalarray.push(element3);
					}
				});
			});

			if (finalarray.length == this.selectedVariantList.length && key != this.editVariantindex) {
				return true;
			}

			finalarray = [];

		});

		return isFlag;

	}

	featuredImgSelect() {
		setTimeout(() => {
			this.addProductForm.get('featuredImage').setValue('');
			this.croppedImage = "";
			this.cropImageShow = false;
		}, 1000);
	}

	// code for image cropper
	fileChangeEvent(event: any): void {
		this.imageChangedEvent = event;
	}

	imageCropped(event: ImageCroppedEvent) {
		this.croppedImage = event.base64;
	}

	imageLoaded() {
		// show cropper
		checkImageSize(this.imageChangedEvent.target, (res) => {
			if (res) {
				this.scale = 1;
				this.transform = {};

				// thistransform: ImageTransform = {};
				$('#imageCropper').modal('show');
			} else {
				this.addProductForm.get('featuredImage').setValue('');
				this.croppedImage = "";
				this.cropImageShow = false;
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.file_size_should_be_at_least_dimensions'));
			}
		});
	}

	loadImageFailed() {
		// show message
	}

	cropImage() {
		$('#imageCropper').modal('hide');
		this.cropImageShow = true;
	}


	// function for reset cropper 
	resetCropper() {
		this.addProductForm.get('featuredImage').setValue('');
		this.cropImageShow = false;
	}


	// check validation according to condition 
	singleValidationFields(formGroup: FormGroup, fields: Array<any>) {
		fields.forEach(field => {
			const control = formGroup.get(field);
			if (control instanceof FormControl) {
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {
				this.validateAllFormFields(control);
			}
		});
	}
	// function for form validation 
	validateAllFormFields(formGroup: FormGroup) {         //{1}
		Object.keys(formGroup.controls).forEach(field => {  //{2}
			const control = formGroup.get(field);             //{3}
			if (control instanceof FormControl) {             //{4}
				control.markAsTouched({ onlySelf: true });
			} else if (control instanceof FormGroup) {        //{5}
				this.validateAllFormFields(control);            //{6}
			}
		});
	}

	// check product form 
	checkKeyFeature() {





		if (this.addProductForm.get('featuredImage').valid && this.addProductForm.get('description').valid && this.addProductForm.get('description_es').valid) {


			if (this.is_no_variants == 1) {

				if (this.addProductForm.get('product_quantity').invalid) {
					this.singleValidationFields(this.addProductForm, ['product_quantity']);
					return false;
				}


			}


			if (typeof $('#KeyFeat').val() == 'undefined') {
				// return false;
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.minimum_one_key_featured_is_required'));
				return false;
			} else {
				$('#addProdConfirm').modal('show');
			}

		}

		else {
			// validate all form fields
			this.validateAllFormFields(this.addProductForm); //{7}
		}
	}


	addProductNew() {

		if (this.is_no_variants == 1) {
			this.noVarinatsDefault();

		}
		if (this.addProductForm.get('featuredImage').valid && this.addProductForm.get('description').valid && this.addProductForm.get('description_es').valid) {

			if (typeof $('#KeyFeat').val() == 'undefined') {
				return false;
			}
			// var block = this.croppedImage.split(";");
			// // Get the content type of the image
			// var contentTypes = block[0].split(":")[1];// In this case "image/png"
			// // get the real base64 content of the file
			// var realData = block[1].split(",")[1];
			// var blobImg = b64toBlob(realData, contentTypes, '');

			let showTillDate = (this.addProductForm.get('showTill').value != "") ? this.commonService.dateFormatConverter(this.addProductForm.get('showTill').value) : "";
			let startDAte1 = (this.addProductForm.get('startDAte').value != "") ? this.commonService.dateFormatConverter(this.addProductForm.get('startDAte').value) : "";
			let endDAte1 = (this.addProductForm.get('endDAte').value != "") ? this.commonService.dateFormatConverter(this.addProductForm.get('endDAte').value) : "";

			let subCategory: any = [];
			for (let index = 1; index <= this.subCategoryLevel; index++) {
				subCategory.push(this.addProductForm.get('subcategory' + index).value);
			}

			this.commonService.showLoader();
			let provinceArray = this.commonService.ArrayFilter(this.addProductForm.get('province').value, 3);
			let municipalitiesArray = this.commonService.ArrayFilter(this.addProductForm.get('municipalities').value, 4);
			var provinces = provinceArray.join();
			var municipalities = municipalitiesArray.join();
			const formData = new FormData();
			formData.append('name', this.addProductForm.get('name').value);
			formData.append('province', provinces);
			formData.append('municipalities', municipalities);
			formData.append('description', this.addProductForm.get('description').value);
			formData.append('description_es', this.addProductForm.get('description_es').value);
			formData.append('featured_image', this.FImage, 'featuredImage.png');
			formData.append('category', this.addProductForm.get('category').value);
			formData.append('sub_category', subCategory.join());
			formData.append('shipping_applied', this.addProductForm.get('shipCharge').value);
			formData.append('shipping_charge', this.addProductForm.get('shipAmount').value);
			formData.append('days_of_return', this.addProductForm.get('days_of_return').value);
			// formData.append('is_return', this.addProductForm.get('return').value);
			formData.append('is_return', (this.addProductForm.get('return').value) ? '1' : '0');
			formData.append('is_offer', (this.addProductForm.get('make_offer').value) ? '1' : '0');

			formData.append('regular_price', this.addProductForm.get('regularPrice').value);
			formData.append('in_stock', (this.addProductForm.get('stocks').value) ? '1' : '0');
			formData.append('is_featured', (this.addProductForm.get('feature').value) ? '1' : '0');
			formData.append('brand_id', this.addProductForm.get('brand').value);
			formData.append('condition_id', this.addProductForm.get('condition').value);
			formData.append('warranty_applied', (this.addProductForm.get('warrantyApply').value) ? '1' : '0');
			formData.append('warranty_duration', this.addProductForm.get('warranty').value);
			formData.append('show_till', showTillDate);
			formData.append('offer_start', startDAte1);
			formData.append('offer_price', this.addProductForm.get('offer_price').value);

			formData.append('offer_end', endDAte1);
			formData.append('delivery_in', this.addProductForm.get('deliveryIn').value);
			formData.append('key_features', JSON.stringify(this.keyFeatureList));
			formData.append('package', JSON.stringify(this.addPakageList));
			formData.append('variants', JSON.stringify(this.variantFormat(this.finialVariantList)));
			formData.append('is_cod', (this.addProductForm.get('is_cod').value) ? '1' : '0');
			formData.append('is_pickup', (this.addProductForm.get('is_pickup').value) ? '1' : '0');
			formData.append('is_direct_pay', (this.addProductForm.get('is_direct_payment').value) ? '1' : '0');
			formData.append('is_make_and_offer', (this.addProductForm.get('make_and_offer_option').value) ? '1' : '0');
			formData.append('sku', this.addProductForm.get('product_sku').value);
			formData.append('selling_price', this.sellingAmount);
			formData.append('selling_product_commission', this.sellingAmountPercentage);
			formData.append('is_no_variants', this.is_no_variants);
			formData.append('offer_selling_price', this.offerList);
			formData.append('offer_selling_price_commission_percent', this.offerListcomission);
			formData.append('product_image_json', JSON.stringify(this.imageId));

			formData.append('seller_warehouse_id', this.sellerWarehouseId);

			formData.forEach((value, key) => {
				console.log(key + " " + value)
			});


			// return false;
			this.sellerService.addProduct(formData).subscribe(data => {
				this.commonService.hideLoader();
				if (data.status == 'success') {
					$('#addProdConfirm').modal('hide');

					this.commonService.toastSuccess(data.message);
					this.productId = data.data.product_id;
					// this.value = "5";
					let productDataStore = {
						'product_id': this.productId.toString(),
						'name': this.addProductForm.get('name').value,
						'regular_price': this.addProductForm.get('regularPrice').value,
						'sale_price': "100",
						'sale_discount': "15",
						'shipping_charge': this.addProductForm.get('shipAmount').value,
						'offer_price': this.addProductForm.get('offer_price').value,
						'feature_image_url': data.data.product_detail.feature_image_url,
						'feature_image': data.data.product_detail.feature_image,
						'recently_deleted': 0,
					}

					// this.addOnFirebase(productDataStore); // add product details on firebase 
					this.router.navigate(['seller/my-products']);

				} else {
					this.value = "4";
					this.commonService.toastError(data.message);
				}

			}, err => {
				this.value = "4";
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
			});

		} else {
			// validate all form fields
			this.validateAllFormFields(this.addProductForm); //{7}
		}
	}


	// code for varinat form 

	variantFormat(variantList) {
		let array: any = [];

		variantList.forEach(element1 => {
			let variantIDArray: any = [];
			let variantValueIDArray: any = [];

			element1.vatiantsData.forEach(element2 => {
				variantIDArray.push(element2.variantID);
				variantValueIDArray.push(element2.variantValueID);
			});
			// fields for varinat form 
			let object = {
				"selling_variant_commission": element1.selling_variant_commission,
				"selling_price": element1.selling_price,
				"variant_image": element1.variant_image,
				"price": element1.amount,
				"discount_percent": element1.discountPercent.toString(),
				"discount_amount": element1.discountAmount.toString(),
				"sku": element1.sku,
				"quantity": element1.quantity,
				"product_weight": element1.product_weight,
				"show_in_list": element1.showlist,
				"variant_id": variantIDArray,
				"variant_value": variantValueIDArray,
				"offer_price_with_admin_commission": element1.offer_price_with_admin_commission,
				"offer_price": element1.offer_price,
				"offer_price_commission_percent": element1.offer_price_commission_percent,

			}

			array.push(object);
		});

		return array;
	}


	// gallery image cropper
	fileChangeEventGallery(event: any): void {
		// alert('hiiii');
		this.galleryImageChangedEvent = event;
	}

	GalleryImageCropped(event: ImageCroppedEvent) {
		this.croppedGalleryImage = event.base64;
	}

	// For product image upload
	GalleryImageLoaded() {
		// show cropper
		checkImageSize(this.galleryImageChangedEvent.target, (res) => {
			if (res) {
				$('#galleryImageCropper').modal('show');
			} else {
				$('#galleryImage').val('');
				$('#galleryImages').val('');
				this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.file_size_should_be_at_least_dimensions'));
			}
		});
	}
	// End


	loadGalleryImageFailed() {
		// show message
	}

	// For reset gallery 
	resetGalleryCropper() {
		$('#galleryImage').val('');
		$('#galleryImages').val('');

	}
	// End



	// Gallery image croper
	addProductMultiImage() {

		this.commonService.showLoader();
		var block = this.croppedImage.split(";");
		// Get the content type of the image
		var contentTypes = block[0].split(":")[1];// In this case "image/png"
		// get the real base64 content of the file
		var realData = block[1].split(",")[1];
		var blobImg = b64toBlob(realData, contentTypes, '');


		if (this.product_image.length == 0) {
			this.FImage = blobImg;

		}

		const formData = new FormData();
		// formData.append('gallery_image', blobImg, 'gallery.png');
		formData.append('featured_image', blobImg, 'featuredImage.png');
		formData.append('is_no_variants', this.is_no_variants);


		const featuredImage = this.addProductForm.get('featuredImage');


		this.sellerService.attachmentsProduct(this.productId, formData).subscribe(data => {
			this.commonService.hideLoader();

			featuredImage.clearValidators(); // clear validation
			featuredImage.updateValueAndValidity(); // upddate validation 

			if (data.status == 'success') {
				// this.commonService.toastSuccess(data.message);
				$('#imageCropper').modal('hide');
				// this.resetCropper();

				let imageObject = {
					'productAttachmentID': data.data.attachment_detail.gallery_image,
					'gallery_image_url': `${data.data.attachment_detail.gallery_image_url}${data.data.attachment_detail.gallery_image}`,
					'imageId': data.data.attachment_detail.imageId,
				}
				this.product_image.push(imageObject);
				this.imageId.push(data.data.attachment_detail.imageId);



				this.scale = 1;
				this.transform = {};

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	// Gallery image croper
	addProductImage() {


		this.commonService.showLoader();
		var block = this.croppedGalleryImage.split(";");
		// Get the content type of the image
		var contentTypes = block[0].split(":")[1];// In this case "image/png"
		// get the real base64 content of the file
		var realData = block[1].split(",")[1];
		var blobImg = b64toBlob(realData, contentTypes, '');

		const formData = new FormData();
		formData.append('gallery_image', blobImg, 'gallery.png');
		this.sellerService.attachments(this.productId, formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {

				this.commonService.toastSuccess(data.message);
				$('#galleryImageCropper').modal('hide');
				this.resetGalleryCropper();
				let imageObject = {
					'productAttachmentID': data.data.attachment_detail.gallery_image,
					'gallery_image_url': `${data.data.attachment_detail.gallery_image_url}${data.data.attachment_detail.gallery_image}`,
				}
				if (this.is_add == 1) {

					this.galleryList.push(imageObject);
					this.gallery_image.push(data.data.attachment_detail.gallery_image);

				}
				if (this.is_add == 0) {

					if (!this.finialVariantList[this.editVariantindex].variant_image.includes(data.data.attachment_detail.gallery_image)) {
						this.finialVariantList[this.editVariantindex].variant_image.push(data.data.attachment_detail.gallery_image)
					}
				}
				this.gallery_image_url = data.data.attachment_detail.gallery_image_url;
				this.scale = 1;
				this.transformgallery = {};

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End




	// Product images
	deleteProductImage(index, productAttachmentID) {
		// alert(index);
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('id', productAttachmentID);
		formData.append('is_no_variants', this.is_no_variants);
		this.sellerService.deleteProductGalleyImage(formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				// this.commonService.toastSuccess(data.message);
				this.product_image.splice(index, 1);
				this.imageId.splice(index, 1);

				if (this.product_image.length == 0) {
					this.FImage = "";
					this.addProductForm.get('featuredImage').setValue('');
					const featuredImage = this.addProductForm.get('featuredImage');
					featuredImage.setValidators([Validators.required]);
					featuredImage.updateValueAndValidity();



				}

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End


	// Product images
	deleteGalleryImage(index, productAttachmentID) {
		// alert(productAttachmentID);
		// alert(index);
		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('image', productAttachmentID);
		this.sellerService.deleteGalleyImage(formData).subscribe(data => {
			// this.sellerService.deleteGalleyImage(productAttachmentID).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				this.commonService.toastSuccess(data.message);
				this.galleryList.splice(index, 1);
				this.gallery_image.splice(index, 1);
				// this.gallery_image1.splice(index, 1);

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	// Variants modal close
	variantClose() {
		$('#editvariants').modal('hide');
	}
	// End

	// For remove product images
	deleteGalleryImageNew(index, productAttachmentID) {
		// alert(productAttachmentID);
		if (this.editVariantModel.variant_image.length == 1) {
			this.commonService.toastError(this.translate.instant('EDIT_PRODUCT.you_cant_delete_all_variant_images'));
			return false;
		}

		this.commonService.showLoader();
		const formData = new FormData();
		formData.append('image', productAttachmentID);
		this.sellerService.deleteGalleyImage(formData).subscribe(data => {
			this.commonService.hideLoader();

			if (data.status == 'success') {
				this.commonService.toastSuccess(data.message);
				this.galleryList.splice(index, 1);
				this.gallery_image.splice(index, 1);
				this.editVariantModel.variant_image.splice(index, 1);

			} else {
				this.commonService.toastError(data.message);
			}

		}, err => {
			this.commonService.hideLoader();
			this.commonService.errorhandler(err);
		});

	}
	// End

	// For firebase
	addOnFirebase(productData) {
		this.firebaseService.productAddFB(productData.product_id, productData)
	}
	// End

	// For video play
	playVideo(v = "") {
		var videoEmbed = this.getId(this.videoUrl)
		this.iframe.nativeElement.src = `//www.youtube.com/embed/${videoEmbed}`;

		$("#videoModal").modal({ backdrop: 'static', keyboard: false });
	}
	// End

	// For product demo video close
	closeVideo() {
		this.iframe.nativeElement.src = '';
		$("#videoModal").modal('hide');
	}
	// End

	getId(url) {
		const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
		const match = url.match(regExp);

		return (match && match[2].length === 11)
			? match[2]
			: null;
	}



	// quantity validation if no variants 
	noVariantsQuantity() {
		const product_quantity = this.addProductForm.get('product_quantity');
		if (this.is_no_variants == 1) {
			product_quantity.setValidators([Validators.required]);
		} else {
			product_quantity.clearValidators();
			this.addProductForm.get('product_quantity').setValue('');
		}
		product_quantity.updateValueAndValidity();
	}


	// End

	// set default variant in case of no varinat select 


	noVarinatsDefault() {


		var imange = this.addProductForm.get('featuredImage').value;
		const myArray = imange.split("\\");
		this.noVariantsImage.push(myArray[2]);


		let pushObject = {
			'name': this.selectedVariantList[0].name,
			'variantID': this.selectedVariantList[0].variantID,
			'variant_value': this.selectedVariantList[0].variant_value[0].variant_value,
			'variantValueID': this.selectedVariantList[0].variant_value[0].variantValueID,
		}

		this.variantsValuesList.push(pushObject);


		if (this.offerList == undefined) {
			this.offerList = 0
		}

		if (this.offerListcomission == undefined) {
			this.offerListcomission = 0
		}

		let addVariant: any = {};
		addVariant.discountPercent = 0;
		addVariant.discountAmount = 0;
		this.addProductForm.get('featuredImage')
		addVariant.vatiantsData = this.variantsValuesList;
		addVariant.variant_image = [];
		addVariant.amount = this.addProductForm.get('regularPrice').value;
		addVariant.selling_price = this.sellingAmount;
		addVariant.selling_variant_commission = this.sellingAmountPercentage;
		addVariant.sku = this.addProductForm.get('product_sku').value;
		addVariant.quantity = this.addProductForm.get('product_quantity').value;
		addVariant.product_weight = this.addProductForm.get('product_weight').value;
		addVariant.offer_price_with_admin_commission = this.offerList;
		addVariant.offer_price_commission_percent = this.offerListcomission;
		addVariant.offer_price = this.addProductForm.get('offer_price').value;
		addVariant.showlist = 1;
		this.finialVariantList.push(addVariant);


	}

	onCheckboxChange(event: Event): void {
		const checkbox = event.target as HTMLInputElement;
	
		if (checkbox.checked) {

			var formData = new FormData();
			// formData.append('provinces', this.editWarehouseAddressForm.get('address').value);

			this.sellerService.getSellerProfile().subscribe((data) => {

				formData.append('provinces', data.data.user_detail.user_address.province);
				formData.append('municipalities', data.data.user_detail.user_address.municipalities);
				
				this.sellerService.getWarehouseAddresses(formData).subscribe( // call api for get warehouse address 
					(warehouseAddressList) => {						
										
					this.commonService.hideLoader(); // hide loader 
					if (warehouseAddressList.status == 'success') {
	
						if (warehouseAddressList.data.warehouseAddress.length == 0) {
							this.warehouseAddressListCount = 0; // assign address count is 0 when list is empty
						}
						
						this.warehouseAddressList = warehouseAddressList.data.warehouseAddress; // assign warehouse address 

						// console.log('this.warehouseAddressList',this.warehouseAddressList);return false;
						
					}
				}, err => {
					this.commonService.hideLoader(); // hide loader 
					this.commonService.errorhandler(err); // handle error 
				});
			
			}, err => {
				this.commonService.hideLoader(); // hide loader 
				this.commonService.errorhandler(err); // handle error 
			});

		  // Open modal only when checkbox is checked
		  $('#warehouseAddressList').modal('show');
		} else {
		  // Optionally hide modal when unchecked
		  $('#warehouseAddressList').modal('hide');
		  $('#selectedAddressesContainer').html(''); // Clear the appended HTML
		}
	}

	onClickEdit(){

		const checkboxes = document.querySelectorAll('input[name="selectAddress"]:checked');

		const selectedData = Array.from(checkboxes).map((checkbox) => {
			const inputElement = checkbox as HTMLInputElement; // Cast to HTMLInputElement
			return {
				id: inputElement.value, // Get the ID
				warehouseAddress: inputElement.getAttribute('data-warehouseaddress'), // Get the warehouse address
				city: inputElement.getAttribute('data-city'), // Get the city
				country: inputElement.getAttribute('data-country'), // Get the country
				zipCode: inputElement.getAttribute('data-zipcode') // Get the zip code
			};
		});

		// Use selectedData to mark checkboxes in the popup as checked
		selectedData.forEach((data) => {
			const checkbox = document.getElementById(`selectAddress_${data.id}`) as HTMLInputElement;
			if (checkbox) {
				checkbox.checked = true; // Mark the checkbox as checked
			}
		});
			// Open modal only when checkbox is checked
			$('#warehouseAddressList').modal('show');
	}	
	
	addWarehouseAddresses() {
		// Get all checked checkboxes
		const checkboxes = document.querySelectorAll('input[name="selectAddress"]:checked');
	
		// Extract both ID and warehouse address values
		const selectedData = Array.from(checkboxes).map((checkbox) => {
			const inputElement = checkbox as HTMLInputElement; // Cast to HTMLInputElement
			return {
				id: inputElement.value, // Get the ID
				warehouseAddress: inputElement.getAttribute('data-warehouseaddress'), // Get the warehouse address
				city: inputElement.getAttribute('data-city'), // Get the city
				country: inputElement.getAttribute('data-country'), // Get the country
				zipCode: inputElement.getAttribute('data-zipcode') // Get the zip code
			};
		});
	
		if (selectedData.length > 0) {

			$('.btnEdit').show(); // This will show the div

			// Combine IDs if needed
			this.sellerWarehouseId = selectedData.map(item => item.id).join(',');
	
			console.log("Selected Data:", selectedData);
			console.log("Combined IDs:", this.sellerWarehouseId);
	
			// Hide the modal
			$('#warehouseAddressList').modal('hide');
	
			// Append data to HTML
			const container = document.getElementById('selectedAddressesContainer');
			container.innerHTML = ''; // Clear previous entries
	
			selectedData.forEach(data => {

				const html = `
					<label class="addListItem addList prAddList" id="address-${data.id}">
						<div class="addLabel labelWord">
							<p>${data.warehouseAddress}, ${data.city}, ${data.country}</p>
							<h5>${this.translate.instant('COMMON.business_zip_code')} :
								<b>${data.zipCode}</b>
							</h5>
							<button class="removeAddressBtn" onclick="removeAddress('${data.id}')">
								&#10005; <!-- Cross Icon -->
							</button>
						</div>
					</label>
				`;
				container.insertAdjacentHTML('beforeend', html);
			});
	
		}
		//  else {
		// 	// Show an error if no checkboxes are selected
		// 	this.commonService.toastError(this.translate.instant('USER_DETAILS.please_select_address'));
		// 	return false;
		// }
	}

	removeAddress(id: string) {
		const element = document.getElementById(`address-${id}`);
		if (element) {
			element.remove(); // Removes the specific div
		}

		// Uncheck the checkbox in the modal
		const checkbox = document.getElementById(`selectAddress_${id}`) as HTMLInputElement;
		if (checkbox) {
			checkbox.checked = false; // Uncheck the checkbox
		}

		const checkboxes = document.querySelectorAll('input[name="selectAddress"]:checked');
		const checkedCount = checkboxes.length; // This will give you the number of checked checkboxes

			if(checkedCount == 0){

				$('.toggleSwitch input').prop('checked', false); // Uncheck the checkbox
				$('.btnEdit').hide(); // This will hide the div
			}
		// console.log('checkedCount',typeof checkedCount);return false; 

	}
	
	uncheckAll() {
		// // Get all checkboxes with the name "selectAddress"
		const checkboxes = document.querySelectorAll('input[name="selectAddress"]:checked');
		
		// Uncheck each checkbox
		// checkboxes.forEach((checkbox: any) => {
		// 	checkbox.checked = false;
		// });
	
		// console.log("All checkboxes have been unchecked.");

        const checkedCount = checkboxes.length; // This will give you the number of checked checkboxes
		if(checkedCount == 0){

			$('.toggleSwitch input').prop('checked', false); // Uncheck the checkbox
		}
		else{

			$('.btnEdit').show(); // This will show the div
		}

	}	

}

function b64toBlob(b64Data, contentType, sliceSize) {
	contentType = contentType || '';
	sliceSize = sliceSize || 512;

	var byteCharacters = atob(b64Data);
	var byteArrays = [];

	for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
		var slice = byteCharacters.slice(offset, offset + sliceSize);

		var byteNumbers = new Array(slice.length);
		for (var i = 0; i < slice.length; i++) {
			byteNumbers[i] = slice.charCodeAt(i);
		}

		var byteArray = new Uint8Array(byteNumbers);

		byteArrays.push(byteArray);
	}

	var blob = new Blob(byteArrays, { type: contentType });
	return blob;
}

