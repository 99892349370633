import { BrowserModule, Title, HammerGestureConfig, HAMMER_GESTURE_CONFIG } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule,ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { environment } from '../environments/environment';
import { initializeApp } from "firebase/app";
initializeApp(environment.firebase);

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { httpInterceptorProviders } from './http-interceptors/index';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { Ng5SliderModule } from 'ng5-slider';
import { ToastrModule } from 'ngx-toastr';
import { NgxGalleryModule } from 'ngx-gallery'; 
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { NgxNumberSpinModule } from 'ngx-number-spin';
import { AngularFireMessagingModule } from '@angular/fire/messaging';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { AngularFireAuthModule } from '@angular/fire/auth';
import { MessagingService } from '../../service/messaging.service';
import { AsyncPipe } from '../../node_modules/@angular/common';

// import { getMessaging, getToken, onMessage } from "firebase/messaging";


// Firebase
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule } from '@angular/fire/storage';

//pipes
import { ExcerptPipe } from './pipes/excerpt.pipe';
import { NoCommaPipe } from './pipes/no-comma.pipe';
import { ExcerptCategoryPipe } from './pipes/excerpt-category.pipe';

// for social login
import { SocialLoginModule, AuthServiceConfig } from "angularx-social-login";
import { GoogleLoginProvider, FacebookLoginProvider } from "angularx-social-login";

//For Localization
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';

let config = new AuthServiceConfig([
  {
    id: GoogleLoginProvider.PROVIDER_ID,
    provider: new GoogleLoginProvider(environment.googleClientId)
  },
  {
    id: FacebookLoginProvider.PROVIDER_ID,
    provider: new FacebookLoginProvider(environment.FacebookClientId)
  }
]);

export function provideConfig() {
  return config;
}

export class CustomHammerConfig extends HammerGestureConfig  {
  overrides = {
      pinch: { enable: false },
      rotate: { enable: false }
  };
}

import { SellerModule } from './seller/seller.module';
import { BuyerModule } from './buyer/buyer.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ShopListComponent } from './shop-list/shop-list.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { SocialEmailComponent } from './social-email/social-email.component';
import { SellerReviewComponent } from './seller-review/seller-review.component';
import { ContactComponent } from './contact/contact.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { from } from 'rxjs';
import { SellComponent } from './sell/sell.component';
import { BuyerQuestionAnsComponent } from './buyer-question-ans/buyer-question-ans.component';
import { SupportComponent } from './support/support.component';
import { SupermarketComponent } from './supermarket/supermarket.component';
import { ViewallPageComponent } from './viewall-page/viewall-page.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';


// AoT requires an exported function for factories
export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/language/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    SignupComponent,
    PageNotFoundComponent,
    ShopListComponent,
    ProductDetailComponent,
    SocialEmailComponent,
    ExcerptPipe,
    NoCommaPipe,
    ExcerptCategoryPipe,
    SellerReviewComponent,
    ContactComponent,
    PrivacyPolicyComponent,
    TermConditionComponent,
    SellComponent,
    BuyerQuestionAnsComponent,
    SupportComponent,
    SupermarketComponent,
    ViewallPageComponent,
    ReturnPolicyComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SellerModule,
    BuyerModule,
    AppRoutingModule,
    FontAwesomeModule,
    Ng5SliderModule,
    CarouselModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
      AngularFireDatabaseModule,
      AngularFireAuthModule,
      AngularFireMessagingModule,
      AngularFireModule.initializeApp(environment.firebase),
    ToastrModule.forRoot({
      preventDuplicates: true,
    }),
    SocialLoginModule,
    NgxGalleryModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFireStorageModule,
    NgbModule,
    Ng2TelInputModule,
    NgMultiSelectDropDownModule,
    NgxNumberSpinModule,
    
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }) //For Localization
  ],
  providers: [
    Title,
    httpInterceptorProviders,
    httpInterceptorProviders,
    MessagingService,AsyncPipe,

    {
      provide: AuthServiceConfig,
      useFactory: provideConfig
    },
    { 
      provide: HAMMER_GESTURE_CONFIG, 
      useClass: CustomHammerConfig 
    }
  ],
  bootstrap: [AppComponent]
  
})
export class AppModule { }
