import { Component, OnInit } from '@angular/core';
import { faAngleDoubleRight, faStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as faStarBlank } from '@fortawesome/free-regular-svg-icons';
import { CommonService } from '../services/common.service';
import { ShopService } from '../services/shop.service';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-seller-review',
  templateUrl: './seller-review.component.html',
  styleUrls: ['./seller-review.component.css']
})
export class SellerReviewComponent implements OnInit {
  faAngleDoubleRight = faAngleDoubleRight;
  faStar = faStar;
  faStarBlank = faStarBlank;
  faStarHalfAlt = faStarHalfAlt;
  sellerId: any;
  sellerInfo: any = {};
  allReview: any = [];
  ratingCount: any = {};
  oneStar: string;
  twoStar: string;
  threeStar: string;
  fourStar: string;
  fiveStar: string;
  allRatingReviewCount: number = 0;
  avgRating;

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private shopService: ShopService,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('mercadoba | Seller Review');
    this.sellerId = this.activatedRoute.snapshot.paramMap.get("sellerId"); //Seller Id
    this.getSellerInfo(this.sellerId); //For seller info
  }

  // For rating range
  createRatingRange(number) {
    return this.commonService.createRatingRange(number);
  }
  // End

  // For blank rating
  createRatingBlank(number) {
    return this.commonService.createRatingBlank(number);
  }
  // End

  isFloat(number) {
    return this.commonService.isFloat(number);
  }



  getDisputeTimeShow(dateTime) {
		return this.commonService.getDisputeTimeShow(dateTime);
	}

  // For seller profile
  getSellerInfo(sellerId) {
    this.commonService.showLoader();

    this.shopService.getSellerInfo(sellerId).subscribe(sellerInfoData => {

      this.commonService.hideLoader();

      if (sellerInfoData.status == 'success') {
        this.sellerInfo = sellerInfoData.data.seller_info;
        this.allReview = sellerInfoData.data.rating.rating_review;
        this.allRatingReviewCount = sellerInfoData.data.rating.rating_review.length;
        this.ratingCount = sellerInfoData.data.rating.rating_count;
        this.avgRating = sellerInfoData.data.rating.rating_count.average_rating;
        this.oneStar = sellerInfoData.data.rating.rating_count.one_star;
        this.twoStar = sellerInfoData.data.rating.rating_count.two_star;
        this.threeStar = sellerInfoData.data.rating.rating_count.three_star;
        this.fourStar = sellerInfoData.data.rating.rating_count.four_star;
        this.fiveStar = sellerInfoData.data.rating.rating_count.five_star;
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

}
