import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './auth/auth.guard';
import { HomeComponent } from './home/home.component';
import { LoginComponent } from './login/login.component';
import { SignupComponent } from './signup/signup.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ShopListComponent } from './shop-list/shop-list.component';
import { SocialEmailComponent } from './social-email/social-email.component';
import { ProductDetailComponent } from './product-detail/product-detail.component';
import { SellerReviewComponent } from './seller-review/seller-review.component';
import { ContactComponent } from './contact/contact.component';
import { TermConditionComponent } from './term-condition/term-condition.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { SellComponent } from './sell/sell.component';
import { SupportComponent } from './support/support.component';
import { NewOrdersComponent } from './seller/new-orders/new-orders.component';
import { SupermarketComponent } from './supermarket/supermarket.component';
import { ViewallPageComponent } from './viewall-page/viewall-page.component';
import { ReturnPolicyComponent } from './return-policy/return-policy.component';

const routes: Routes = [
    { path: "", component: HomeComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: "login", component: LoginComponent },
    { path: "signup/buyer", component: SignupComponent },
    { path: "signup/seller", component: SignupComponent },
    { path: "social-email", component: SocialEmailComponent },
    { path: "shop", component: ShopListComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: "shop/:id", component: ShopListComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },

    { path: "supermarket/:name/:id", component: SupermarketComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: "combo/:name/:id", component: SupermarketComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },

    { path: "combo", component: SupermarketComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: "supermarket", component: SupermarketComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },

    { path: "view/offer", component: ViewallPageComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: "view/featured", component: ViewallPageComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: "view/promoted", component: ViewallPageComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },

    { path: 'shop/:category/:catId', component: ShopListComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: 'shop/:category/:subCategory/:subCatId', component: ShopListComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: 'shop/category', component: ShopListComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: 'product-detail/:product_name/:productId', component: ProductDetailComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: 'product-detail/:product_name/:productId/:variantId', component: ProductDetailComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },


    { path: 'seller-review/:sellerId', component: SellerReviewComponent, canActivate: [AuthGuard], data: { role: 'NotSeller' } },
    { path: 'seller-reviews/:sellerId', component: SellerReviewComponent, canActivate: [AuthGuard], data: { role: 'seller' } },
    { path: "contact", component: ContactComponent },
    { path: "termandcondition", component: TermConditionComponent },
    { path: "termandcondition/:en", component: TermConditionComponent },
    { path: "termandcondition/:es", component: TermConditionComponent },
    { path: "contact", component: ContactComponent },
    { path: "termandconditions", component: TermConditionComponent },
    { path: "termandconditions/:en", component: TermConditionComponent },
    { path: "termandconditions/:es", component: TermConditionComponent },
    { path: "support", component: SupportComponent },
    { path: "privacypolicy", component: PrivacyPolicyComponent },
    { path: "privacypolicy/:en", component: PrivacyPolicyComponent },
    { path: "privacypolicy/:es", component: PrivacyPolicyComponent },
    { path: "privacypolicys", component: PrivacyPolicyComponent },
    { path: "privacypolicys/:en", component: PrivacyPolicyComponent },
    { path: "privacypolicys/:es", component: PrivacyPolicyComponent },


    { path: "returnpolicy", component: ReturnPolicyComponent },
    { path: "returnpolicy/:en", component: ReturnPolicyComponent },
    { path: "returnpolicy/:es", component: ReturnPolicyComponent },
    { path: "returnpolicys", component: ReturnPolicyComponent },
    { path: "returnpolicys/:en", component: ReturnPolicyComponent },
    { path: "returnpolicys/:es", component: ReturnPolicyComponent },

    { path: "sell", component: SellComponent },
    { path: "**", component: PageNotFoundComponent },
    // { path:'**',component:NewOrdersComponent,canActivate:[AuthGuard], data: {role: 'seller'} },
    // { path:'**',component:HomeComponent,canActivate:[AuthGuard], data: {role: 'NotSeller'} },

];

@NgModule({
    imports: [RouterModule.forRoot(routes)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
