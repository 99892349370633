import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '../auth/auth.service';
import { CommonService } from '../services/common.service';


@Component({
  selector: 'app-sell',
  templateUrl: './sell.component.html',
  styleUrls: ['./sell.component.css']
})
export class SellComponent implements OnInit, AfterViewInit {
  show: any = 0;

  constructor(
    private authService: AuthService,
		private commonService: CommonService,

  ) { }
  ngAfterViewInit(): void {
    this.commonService.hideLoader();
  }

  ngOnInit() {
    this.commonService.showLoader();

    this.commonService.setTitle('Vende tus Productos en Línea en Cuba con Mercadoba | Llega a Más Clientes');

    this.commonService.getMetaTages('Vende tus Productos en Línea en Cuba con Mercadoba | Llega a Más Clientes','Conviértete en vendedor en nuestra plataforma Qvazon y alcanza a una audiencia en toda Cuba. Únete ahora y comienza a vender tus productos en línea. Expande tu negocio con nosotros.')


    if (this.authService.isLoggedIn()) {
      this.show = 1;
    }else{
      this.show = 0;

    }

  }

}
