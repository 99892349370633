import { AfterViewInit, Component, OnChanges, OnInit } from '@angular/core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { AuthService as SocialService } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';
import { FirebaseService } from '../services/firebase.service';
import { TranslateService } from '@ngx-translate/core';
import { faLessThanEqual } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'app-email-varification',
  templateUrl: './email-varification.component.html',
  styleUrls: ['./email-varification.component.css']
})
export class EmailVarificationComponent implements OnInit {
  userEmail: string;
  authToken: string;
  verifiyEmail: any;
  verifyPhone: any;
  profile: any;

  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private fb: FormBuilder,
    private socialService: SocialService,
    private firebaseService: FirebaseService,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {

    
    let userdata = JSON.parse(localStorage.getItem(environment.dataStore.userData));


    if(userdata.user_detail.is_email_verified == 1 && userdata.user_detail.is_phone_verified == 0 ){


      this.router.navigate(['/seller/number-verify']); return false;

    }

    Object.keys( userdata.business_info).length === 0 

    if(userdata.user_detail.is_phone_verified == 1 &&  Object.keys( userdata.business_info).length === 0 ){
      this.router.navigate(['/seller/add-business']); return false;


    }



    // LocalStorage signup email get
    
    if(userdata.user_detail.temp_email){
      this.userEmail = userdata.user_detail.temp_email;
    }else{
      this.userEmail = userdata.user_detail.email;
    }
    
  }

  // Email verification -----
  verifyEmail() {
    this.commonService.showLoader();

    this.authService.verifyEmail().subscribe((data) => {
      this.commonService.hideLoader();

      if (data.data.is_email_verified == 1) {
        let userdata = JSON.parse(localStorage.getItem(environment.dataStore.userData));

        userdata.user_detail.is_email_verified = 1;
				localStorage.setItem(environment.dataStore.userData, JSON.stringify(userdata));
        this.router.navigate(['seller/number-verify']);
        
      }
      this.commonService.toastSuccess(data.message);

    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

  // Resend Email verification -----
  resendEmail() {
    this.commonService.showLoader();

    this.authService.resendEmail().subscribe((data) => {
      this.commonService.hideLoader();

      this.commonService.toastSuccess(data.message);
    }, err => {
      this.commonService.errorhandler(err);
    });
  }



}
