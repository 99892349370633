import { Component, OnInit } from '@angular/core';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { BuyerService } from '../services/buyer.service';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-confirmation',
    templateUrl: './confirmation.component.html',
    styleUrls: ['./confirmation.component.css']
})
export class ConfirmationComponent implements OnInit {
    faAngleDoubleRight = faAngleDoubleRight;
    confirmationData: any;
    orderId: string;

    constructor(
        private commonService: CommonService,
        private buyerService: BuyerService,
        private router: Router,
        private translate: TranslateService,
        private activatedRoute: ActivatedRoute,

    ) { translate.use(this.commonService.getLanguage()); }

    ngOnInit() {
        this.commonService.scrollToTop();
        this.commonService.setTitle('Mercadoba | Payment');
        this.confirmationData = this.buyerService.confirmationProductData;
        this.orderId = this.activatedRoute.snapshot.paramMap.get("id");

        if (this.orderId == null) {
            this.confirmationData = this.buyerService.confirmationProductData;
        } else {
            this.confirmationData = JSON.parse(this.orderId);
        }
        this.checkConfirmData(this.confirmationData);
    }

    checkConfirmData(confirmationData) {
        if (confirmationData == '') {
            this.router.navigate(['/shop']);
        }
    }

}
