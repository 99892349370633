import { Component, OnChanges, OnInit } from '@angular/core';
import { faFacebookSquare } from '@fortawesome/free-brands-svg-icons';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../auth/auth.service';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseService } from '../services/firebase.service';

declare const $: any;


@Component({
  selector: 'app-number-verify',
  templateUrl: './number-verify.component.html',
  styleUrls: ['./number-verify.component.css']
})
export class NumberVerifyComponent implements OnInit {
  dial_code: string = '+1';
  country_code: string = 'US';
  initialCountrySet: string = 'US';
  countryName: string = 'United States';
  cancel_switching = 0;
  mobileVerifyForm: FormGroup;
  userDetailsData: any;

  // countryName: any;
  countName: any;
  countryCode: any;
  userType: string;
  isChecked: any = 1;
  getEmailData :any;
  userdata;

  constructor(
    private router: Router,
    private authService: AuthService,
    private commonService: CommonService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
    private firebaseService: FirebaseService,

  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    


    var userDetails = this.authService.getUserDetails();
    if(userDetails.switch_profile == "1" && userDetails.social_id != null){

      this.cancel_switching = 1;
    }else{

      this.cancel_switching = 0 ;

    }


    if(userDetails.is_email_verified == '1' && userDetails.is_phone_verified == '1'){
      this.router.navigate(['/seller/add-business']); return false;


    }

    const currentUrl = this.router.url;
    // this.userType = (currentUrl == "/seller/number-verify/") ? 'buyer' : 'seller';
    this.userType = (currentUrl == "/seller/number-verify/") ? 'seller' : 'buyer';
    this.mobileVerifyForm = this.formBuilder.group({
      country: ['United States', [Validators.required]],

      phone_number: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(15), Validators.pattern('[0-9]+')]],
    });

   

  }

  // show mail input value
  getValueShowNumber(event) {

    if (this.isChecked == 0) {
      this.isChecked = 1
    } else {
      this.isChecked = 0
    }

    localStorage.setItem('showNumber', JSON.stringify(this.isChecked));
  }
  // end code


  //set auto detect country
  telInputObject(obj) {

    // var initialCountrySet  = localStorage.getItem("countryCode");

    // if(initialCountrySet == "" ||  initialCountrySet == null ){
    //   initialCountrySet = "US"
    // }else{
    //   initialCountrySet = localStorage.getItem("countryCode");
    // }
    var initialCountrySet  = initialCountrySet = "US"

    this.initialCountrySet = initialCountrySet;

    $.getJSON('https://ipapi.co/json/', function (data) {

    this.initialCountrySet = initialCountrySet;

      // this.initialCountrySet = data.country_code.toLowerCase();
      // this.country_code = data.country_code;
      // this.dial_code = data.country_calling_code;
      obj.setCountry(this.initialCountrySet);
    });
  }


  get phone_number() { return this.mobileVerifyForm.get('phone_number'); }

// For update details on firebase
updateOnFirebase(userData) {
  let userDataStore = {
    'user_id': userData.user_detail.userID,
    'full_name': userData.user_detail.full_name,
    'email': userData.user_detail.email,
    'user_type': userData.user_detail.user_type,
    'seller_name': "",
    'buyer_name': userData.user_detail.full_name
  }

  this.firebaseService.userUpdateFB(userData.user_detail.userID, userDataStore)
}
// End
// For switch profile seller to buyer
switchProfileBuyer(userType) {
  this.commonService.showLoader();

  this.authService.switchProfile(userType).subscribe(profileData => {
    this.commonService.hideLoader();


    if (profileData.data.user_detail.switch_profile == 1) {

      // redirect to shop page
      localStorage.setItem(environment.dataStore.userData, JSON.stringify(profileData.data));

      this.updateOnFirebase(profileData.data);
      this.router.navigate(['/']);
    } else {
      // redirect to complete profile page
      // this.router.navigate(['/seller/complete-switch-profile']);
    }

  }, err => {
    this.commonService.hideLoader();
    this.commonService.errorhandler(err);
  });

}


checkChar(e) {
  e.preventDefault();
}
  // Country change 
  onCountryChange($event) {
    this.countryName = $event.name.split(' (')[0];
    this.dial_code = `+${$event.dialCode}`;
    this.mobileVerifyForm.patchValue({ country: $event.name.split('(')[0] });
    this.country_code = $event.iso2.toUpperCase();
    this.mobileVerifyForm.patchValue({ 'phone_number': "" });
    // this.mobileVerifyForm.patchValue({ 'mobile': "" });

    this.countName = localStorage.setItem('countryName', this.countryName);
    this.countryCode = localStorage.setItem('countryCode', this.country_code);
  }

  onKeyUp(event) {
    return false;
  }


  

  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }


  sendMobileOtp() {
    this.countryCode = localStorage.getItem('countryCode');
		if (this.mobileVerifyForm.valid) {
			const formData = new FormData();
      formData.append('dial_code', this.dial_code);
      formData.append('phone_number', this.mobileVerifyForm.get('phone_number').value);
      formData.append('is_phone_show', this.isChecked);
      formData.append('country', this.countryName);
			formData.append('country_code', this.countryCode);

			this.commonService.showLoader();

			this.authService.verifySendOtp(formData).subscribe(res => {
        console.log('check res', res.data);

				this.getEmailData = JSON.parse(localStorage.getItem(environment.dataStore.userData));
				this.commonService.hideLoader();
				// this.commonService.toastSuccess(res.message);
				this.mobileVerifyForm.reset();
				this.userDetailsData = res.data.user_detail;

				//  local storage data update
				this.getEmailData.user_detail.mobile_number = this.userDetailsData.phone_number;
				this.getEmailData.user_detail.is_phone_verified = "1";
				// this.getEmailData.user_detail.mobile_number = this.userdata.data.userDetail.phone_number;
				let userdatasetLocal = localStorage.setItem(environment.dataStore.userData, JSON.stringify(this.getEmailData));
				// email and mobile verified router navigate page
				if (this.userDetailsData.is_email_verified == 1 && this.userDetailsData.is_phone_verified == 1) {

					this.router.navigate(['seller/add-business']);
				}


			}, err => {
				this.commonService.hideLoader();
				this.commonService.errorhandler(err);
				// this.mobileVerifyForm.reset();
			});
		}else{
      this.validateAllFormFields(this.mobileVerifyForm); //{7}

		}
	}


  // send otp mobile number
  sendMobileOtpOld() {

    

    if (this.mobileVerifyForm.valid) {

    const formData = new FormData();
    formData.append('dial_code', this.dial_code);
    formData.append('phone_number', this.mobileVerifyForm.get('phone_number').value);
    formData.append('is_phone_show', this.isChecked);

    this.authService.otpSendMobile(formData).subscribe(data => {
      this.commonService.toastSuccess(data.message);
      var userDetails = localStorage.getItem('user_detail');
      let userdatasetLocal = localStorage.setItem('userDetails', JSON.stringify(data));

      // let userdatasetLocal1 = localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

      this.router.navigate(['seller/mobile-varification']);
    }, err => {
      this.commonService.errorhandler(err);
    });

  }else{
    this.validateAllFormFields(this.mobileVerifyForm); //{7}
  
  }
}
}
