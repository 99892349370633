import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from '../auth/auth.guard';
import { MyAccountComponent } from './my-account/my-account.component';
import { WishListComponent } from './wish-list/wish-list.component';
import { CartListComponent } from './cart-list/cart-list.component';
import { UserAddressComponent } from './user-address/user-address.component';
import { CardListComponent } from './card-list/card-list.component';
import { CheckoutComponent } from './checkout/checkout.component';
import { PaymentOptionComponent } from './payment-option/payment-option.component';
import { ConfirmationComponent } from './confirmation/confirmation.component';
import { MyOrdersComponent } from './my-orders/my-orders.component';
import { MyOrderDetailComponent } from './my-order-detail/my-order-detail.component';
import { ChatComponent } from './chat/chat.component';
import { OfferCartComponent } from './offer-cart/offer-cart.component';
import { EditAddressComponent } from './edit-address/edit-address.component';
import { CompleteSwitchProfileComponent } from './complete-switch-profile/complete-switch-profile.component';
import { DisputeComponent } from './dispute/dispute.component';
import { TrashChatComponent } from './trash-chat/trash-chat.component';
import { ProductOffersComponent } from './product-offers/product-offers.component';
import { ProductOffersDetailsComponent } from './product-offers-details/product-offers-details.component';
import { QuestionAnswerComponent } from './question-answer/question-answer.component';



const routes: Routes = [
  { path:'my-account', component:MyAccountComponent,canActivate:[AuthGuard], data: {role: 'buyer'}},
  { path:'my-account/:tab', component:MyAccountComponent,canActivate:[AuthGuard], data: {role: 'buyer'}},
  { path:'wish-list', component:WishListComponent,canActivate:[AuthGuard], data: {role: 'buyer'}},
  { path:'cart', component:CartListComponent,canActivate:[AuthGuard], data: {role: 'buyer'}},
  { path:'edit-address', component:EditAddressComponent,canActivate:[AuthGuard], data: {role: 'buyer'}},
  { path:'user-address', component:UserAddressComponent,canActivate:[AuthGuard], data: {role: 'buyer'}},
  { path:'user-address/offer', component:UserAddressComponent,canActivate:[AuthGuard], data: {role: 'buyer'}},
  { path:'card-list',component:CardListComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'checkout',component:CheckoutComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'checkout/offer/:id',component:CheckoutComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'payment-option',component:PaymentOptionComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'payment-option/offer/:id',component:PaymentOptionComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'confirmation',component:ConfirmationComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'confirmation/:id',component:ConfirmationComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'my-orders',component:MyOrdersComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'my-order-detail/:id',component:MyOrderDetailComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'offer-cart/:id',component:OfferCartComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'chat',component:ChatComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'chat/product/:productId',component:ChatComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'chat/order/:orderId',component:ChatComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'complete-switch-profile',component:CompleteSwitchProfileComponent},
  { path:'dispute/:id',component:DisputeComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'trash-chat',component:TrashChatComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  // { path:'product-offers',component:ProductOffersComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'product-offers',component:ProductOffersComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  { path:'question-answer/:id',component:QuestionAnswerComponent, data: {role: 'buyer'} },
  

  { path:'product-offers-details/:productID/:variantID/:offerItemID',component:ProductOffersDetailsComponent,canActivate:[AuthGuard], data: {role: 'buyer'} },
  
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class BuyerRoutingModule { }
