import { Component, OnInit } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { BuyerService } from '../services/buyer.service';
import { Router, ActivatedRoute } from '@angular/router';
import { environment } from '../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-offer-cart',
  templateUrl: './offer-cart.component.html',
  styleUrls: ['./offer-cart.component.css']
})
export class OfferCartComponent implements OnInit {
  faTrashAlt = faTrashAlt
  faAngleDoubleRight = faAngleDoubleRight;
  offerId:any;
  offerData:any={};
  pricing_detail:any = {};
  tax_detail:any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService:CommonService,
    private buyerService:BuyerService,
    private router:Router,
    private translate: TranslateService
  ) {  translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Offer Cart');
    this.offerId = this.activatedRoute.snapshot.paramMap.get("id");
    this.getOfferCart();
  }

  getOfferCart():void{
    this.commonService.showLoader();

    this.buyerService.getOfferCart(this.offerId).subscribe(
      (cartlistData)=>{
      this.commonService.hideLoader();

      if(cartlistData.status == 'success'){
        
        var variantName = cartlistData.data.offer_item_list.variant.map(function (item) {
          return item.variant_value.variant_value
        });

        this.offerData= cartlistData.data.offer_item_list; 
        this.offerData.variantSize = variantName[0];
        this.offerData.variantColor = variantName[1];

        this.pricing_detail = cartlistData.data.pricing_detail;
        this.tax_detail = cartlistData.data.tax_detail;
        
      }else{
        this.offerData = {};
        this.router.navigate(['/']);
      }
    
    },err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
      this.router.navigate(['/']);
    });

  }

  //Redirect from cart page to add address page
  address(){
    localStorage.setItem(environment.dataStore.offerId,JSON.stringify(this.offerId));
    this.router.navigate(['/checkout/offer']);
  }

}
