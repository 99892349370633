import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import { faAngleDoubleRight } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { BuyerService } from '../services/buyer.service';
import { TranslateService } from '@ngx-translate/core';
import { HeaderBuyerComponent } from '../../common/header-buyer/header-buyer.component';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-wish-list',
  templateUrl: './wish-list.component.html',
  styleUrls: ['./wish-list.component.css']
})
export class WishListComponent implements OnInit, AfterViewInit {
  faTrashAlt = faTrashAlt
  faAngleDoubleRight = faAngleDoubleRight;
  limitOfWishlist: number = 12;
  offsetOfWishlist: number = 0;
  wishList = [];
  wishlistCount: number;
  productloader: boolean = true;
  @ViewChild('header', { static: true }) header: HeaderBuyerComponent;

  constructor(
    private commonService: CommonService,
    private buyerService: BuyerService,
    private translate: TranslateService,
    private router: Router,

  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {

    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Wish List');
    this.getUserWishList(this.limitOfWishlist, this.offsetOfWishlist); //For wishlist
    this.header.getWishCount();// for decrease card count

  }

  ngAfterViewInit(): void {
  }

  // Wishlist load more button
  wishlistLoadMore() { //load more 
    this.offsetOfWishlist = this.offsetOfWishlist + Number(this.limitOfWishlist);
    this.getUserWishList(this.limitOfWishlist, this.offsetOfWishlist);
  }
  // End

  // Get wishlist list
  getUserWishList(limit, offset) {


    this.commonService.showLoader();


    // if (this.offsetOfWishlist == 0) {
    //   this.commonService.showLoader();
    // } else {
    //   // this.productloader = false;
    // }

    this.buyerService.getUserWishlist(limit, offset).subscribe(
      (wishlistData) => {




        // this.productloader = true;
        this.commonService.hideLoader();

        if (wishlistData.status == 'success') {

          if (wishlistData.data.data_found) {

            if (this.offsetOfWishlist == 0) { // check refresh then show starting
              this.wishList = wishlistData.data.user_wishlist;
               wishlistData.data.user_wishlist.forEach(element => {
                element.discount_percent = Math.round(element.discount_percent);

                // this.wishList.push(element);
               })
              this.wishlistCount = wishlistData.data.total_records; //total product          
            } else {
              this.wishlistCount = wishlistData.data.total_records; //total product           
              wishlistData.data.user_wishlist.forEach(element => {
                element.discount_percent = Math.round(element.discount_percent);

                this.wishList.push(element);
              });

            }



          } else {
            this.wishList = [];
            this.wishlistCount = 0;
          }

        } else {
          this.wishList = [];
          this.wishlistCount = 0;
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
  }


     // End
     redirectFunction(detail, name,id1){
      var name1 = (name.split(' ').join(''));
  
      var url = encodeURIComponent(name1).replace(/[!'()*]/g, function (c) {
        return '-' + c.charCodeAt(0).toString(16);
      });
  
      var removeSpace = (url.split('%').join('-'));
      var validUrl = detail+'/'+removeSpace+'/'+id1;
      this.router.navigate([validUrl]);
    
      }
  

  //remove wishlist item
  removewishlist(wishlistId, productName, index) {
    this.commonService.showLoader();

    const formData = new FormData();
    formData.append('wishlistID', wishlistId);

    this.buyerService.deleteWish(formData).subscribe((data) => {

      this.commonService.hideLoader();
      if (data.status == "fail") {
        this.commonService.toastError(data.message);
      } else {


        // this.commonService.toastSuccess(data.message);
        this.wishList.splice(index, 1);


        // this.offsetOfWishlist = Number(this.offsetOfWishlist) - Number(1);
        this.getUserWishList(this.limitOfWishlist, this.offsetOfWishlist);
        this.header.getWishCount();// for decrease card count

        // this.wishlistCount = Number(this.wishlistCount) - Number(1);
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

}
