import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderBuyerComponent } from 'src/app/common/header-buyer/header-buyer.component';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FirebaseService as BuyerFbService } from '../../buyer/services/firebase.service';
import { ShopService } from '../../services/shop.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { SellerService } from '../services/seller.service';
import { BuyerService } from 'src/app/buyer/services/buyer.service';

declare const $: any;

@Component({
  selector: 'app-question-answers',
  templateUrl: './question-answers.component.html',
  styleUrls: ['./question-answers.component.css']
})
export class QuestionAnswersComponent implements OnInit {
  @ViewChild('header', { static: true }) header: HeaderBuyerComponent;
  productId: any;
  variantId: any;
  offer_id: any;
  productDetail: any;
  questionAnswer: any;
  answerForm: FormGroup;
  currentUserId: any;

  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private buyerFbService: BuyerFbService,
    private shopService: ShopService,
    private formBuilder: FormBuilder,
    private buyerService: BuyerService,
    private sellerService: SellerService,
  ) { }

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      this.productId = params.get("productId"); //For product Id
      this.offer_id = params.get("offerItem_id"); //For offer Id
      this.currentUserId = this.authService.getCurrentUserId(); //current user ID
      this.getProductDetail(this.productId, this.currentUserId); //For product detail

      // this.getProductDetail(this.productId, this.variantId, this.offer_id); //For product detail
    });

    // Questions & answer form validation
    this.answerForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
    });
    // End

  }

  // Questions and answer description 
  get description() { return this.answerForm.get('description'); }

  // For check form valid
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  // End

  // Add questions form
  addAnsWer(id) {
    if (this.answerForm.valid) {
      this.commonService.showLoader();
      const formData = new FormData();
      formData.append('product_id', this.productDetail.productID);
      formData.append('description', $(`#ans_${id}`).val());
      formData.append('question_id', id);

      this.shopService.askQuestions(formData).subscribe((res) => {
        this.commonService.hideLoader();
        this.commonService.toastSuccess(res.message);
        this.getProductDetail(this.productId, this.currentUserId); //For product detail
        // this.getProductDetail(this.productId, this.authService.getCurrentUserId(), this.offer_id);
        // window.location.reload();
        this.answerForm.reset();

      }, err => {
        this.commonService.errorhandler(err);
        this.commonService.hideLoader();
        this.answerForm.reset();
      });
    } else {
      this.validateAllFormFields(this.answerForm); //{7}
    }

  }
  // End


  getDisputeTimeShow(dateTime) {
		return this.commonService.getDateTime2(dateTime);
	}

  //Get product Detail
  getProductDetail(productId, id) {
    this.commonService.showLoader();
    // this.sellerService.productOfferDetails(productId, variantId, offer_id).subscribe((productDetailData) => {
      this.shopService.getProductDetail(productId, this.currentUserId).subscribe((productDetailData) => {


      this.commonService.hideLoader();
      if (productDetailData.status == 'success') {
        this.commonService.scrollToTop();

        this.productDetail = productDetailData.data.product_detail;
        this.questionAnswer = this.productDetail.question;

      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

}
