import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { Router } from '@angular/router';
import { BuyerService } from '../services/buyer.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { FirebaseService } from '../services/firebase.service';
import { ImageCroppedEvent } from 'ngx-image-cropper';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const checkLicenseImageSize: any;

@Component({
  selector: 'app-complete-switch-profile',
  templateUrl: './complete-switch-profile.component.html',
  styleUrls: ['./complete-switch-profile.component.css']
})
export class CompleteSwitchProfileComponent implements OnInit {
  faCheck = faCheck;
  faTimes = faTimes;
  addBusinessForm: FormGroup;
  userDetail: any;
  country_code: string = 'CU';
  initialCountrySet: string = 'cu';
  ShowFilter = false;
  disabled = false;
  dropdownProvinceSettings: any = {};
  provinceList: any = [];
  selectedItems = [];
  imageChangedEvent: any = '';
  croppedImage: any = 'assets/img/common/image_placeholder.png';
  cropImageShow: boolean = false;

  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private buyerService: BuyerService,
    private commonService: CommonService,
    private router: Router,
    private firebaseService: FirebaseService,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Add Bussiness');

    this.dropdownProvinceSettings = {
      singleSelection: false,
      idField: 'provinceID',
      textField: 'name',
      itemsShowLimit: 1,
      allowSearchFilter: this.ShowFilter
    };

    this.userDetail = this.authService.getUserDetails(); //For user details

    this.initialCountrySet = this.userDetail.profile_country_code.toLowerCase();
    this.country_code = this.userDetail.profile_country_code;

    // For business form validation
    this.addBusinessForm = this.fb.group({
      business_name: ['', [Validators.required, Validators.minLength(2), Validators.maxLength(100)]],
      // business_license: ['',Validators.required],
      business_address: ['',],
      business_address_lat: ['',],
      business_address_long: ['',],
      country: [this.userDetail.country, [Validators.required]],
      province: ['', [Validators.required]],
      licenseImage: ['', [Validators.required]],
    });
    // End

    this.getProvinceList(); // For province list get
    this.selectedProvinceShow(); //For show selected province
  }

  // For get province list
  getProvinceList() {
    this.authService.getProvinceList()
      .subscribe(provinceData => {
        this.commonService.hideLoader();

        if (provinceData.status == 'success') {
          this.provinceList = provinceData.data.province;
        } else {
          this.provinceList = [];
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
  }
  // End

  // For country change 
  onCountryChange($event) {
    this.addBusinessForm.patchValue({ country: $event.name.split('(')[0] });
    this.country_code = $event.iso2.toUpperCase();
  }
  // End

  onKeyUp(event) {
    return false;
  }

  // For business licence image 
  onSelectedFile(event) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
      this.addBusinessForm.get('business_license').setValue(file);

    }
  }
  // En

  handleAddressChange(address): void {
    this.addBusinessForm.get('business_address').setValue(address.formatted_address);
    this.addBusinessForm.get('business_address_lat').setValue(address.geometry.location.lat());
    this.addBusinessForm.get('business_address_long').setValue(address.geometry.location.lng());
  }

  // For business detail form validation
  get business_name() { return this.addBusinessForm.get('business_name'); }
  // get business_license() { return this.addBusinessForm.get('business_license'); }
  get country() { return this.addBusinessForm.get('country'); }
  get province() { return this.addBusinessForm.get('province'); }
  get licenseImage() { return this.addBusinessForm.get('licenseImage'); }
  // End

  // For selected province
  selectedProvinceShow() {
    let selectedArray = [];
    this.selectedItems = selectedArray;
    this.addBusinessForm.patchValue({
      province: this.selectedItems,
    });
  }
  // End

  // For check form validation
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  // End

  // For submit business info 
  businessForm() {

    if (this.addBusinessForm.valid) {

      this.commonService.showLoader();

      var block = this.croppedImage.split(";");
      // Get the content type of the image
      var contentTypes = block[0].split(":")[1];// In this case "image/png"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1];
      var blobImg = b64toBlob(realData, contentTypes, '');

      let provinceArray = this.commonService.ArrayFilter(this.addBusinessForm.get('province').value, 3);
      let province = provinceArray.join();

      const formData = new FormData();
      formData.append('business_name', this.addBusinessForm.get('business_name').value);
      formData.append('address', this.addBusinessForm.get('business_address').value);
      formData.append('country', this.addBusinessForm.get('country').value);
      formData.append('province', province);
      formData.append('redirect_from', '1');
      formData.append('country_code', this.country_code);
      formData.append('license', blobImg, 'licenseImage.png');
      // formData.append('license', this.addBusinessForm.get('business_license').value);

      this.buyerService.addBizInfo(formData).subscribe(data => {
        this.commonService.hideLoader();

        if (data.status == 'success') {
          this.commonService.toastSuccess(data.message);

          localStorage.setItem(environment.dataStore.userData, JSON.stringify(data.data));

          this.updateOnFirebase(data.data);

          this.router.navigate(['seller/subscription/signup']);

        } else {
          this.commonService.toastError(data.message);
        }

      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });

    } else {
      // validate all form fields
      this.validateAllFormFields(this.addBusinessForm); //{7}
    }

  }
  // End

  // For Update data on firebase
  updateOnFirebase(userData) {
    let userDataStore = {
      'user_id': userData.user_detail.userID,
      'full_name': userData.user_detail.full_name,
      'email': userData.user_detail.email,
      'user_type': userData.user_detail.user_type,
      'business_name': userData.business_info.business_name,
      'seller_name': userData.user_detail.full_name,
    }

    this.firebaseService.userUpdateFB(userData.user_detail.userID, userDataStore)
  }
  // End

  // Code for image cropper
  featuredImgSelect() {
    setTimeout(() => {
      this.addBusinessForm.get('licenseImage').setValue('');
      this.croppedImage = "assets/img/common/image_placeholder.png";
      this.cropImageShow = false;
    }, 1000);
  }

  fileChangeEvent(event: any): void {
    this.imageChangedEvent = event;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }


  imageLoaded() {
    // show cropper
    checkLicenseImageSize(this.imageChangedEvent.target, (res) => {
      if (res) {
        $('#imageCropper').modal('show');
      } else {
        this.addBusinessForm.get('licenseImage').setValue('');
        this.croppedImage = "assets/img/common/image_placeholder.png";
        this.cropImageShow = false;
        this.commonService.toastError(this.translate.instant('PROFILE.file_size_should_be_at_least_dimensions'));
      }
    });
  }

  loadImageFailed() {
    // show message
  }

  cropImage() {
    $('#imageCropper').modal('hide');
    this.cropImageShow = true;
  }


  resetCropper() {
    this.addBusinessForm.get('licenseImage').setValue('');
    this.cropImageShow = false;
    this.croppedImage = 'assets/img/common/image_placeholder.png';
  }
  // End

}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}