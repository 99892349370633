import { Component, OnInit, HostListener, Inject, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { faPhoneAlt, faSearch, faShoppingCart, faUser, faBars } from '@fortawesome/free-solid-svg-icons';
import { faHeart } from '@fortawesome/free-regular-svg-icons';
import { AuthService } from '../../auth/auth.service';
import { Router } from '@angular/router';
import { FirebaseService } from '../../services/firebase.service';
import { environment } from '../../../environments/environment';
import { CommonService } from '../../services/common.service';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/services/authentication.service';

declare const checkOnline: any;
declare const setValue: any;
declare const $: any;
declare const getTimeZone: any;
@Component({
  selector: 'app-header-seller',
  templateUrl: './header-seller.component.html',
  styleUrls: ['./header-seller.component.css']
})
export class HeaderSellerComponent implements OnInit {
  faPhoneAlt = faPhoneAlt;
  faSearch = faSearch;
  faHeartLine = faHeart;
  faShoppingCart = faShoppingCart;
  faUser = faUser;
  faBars = faBars;
  toggle: boolean = true;
  name: any = "";
  searchData: any = "";
  notificationList: any = [];
  notificationCount: string;
  siteUrl = environment.siteUrl;
  offset: any;
  adminnoId: any;
  language: any = [
    { img: '/assets/img/common/us.webp', language: 'English', code: 'en' },
    { img: '/assets/img/common/spain.webp', language: 'Spanish', code: 'es' }
  ]
  selectedLanguage: string = 'Spanish';
  selectedLanguageImage: string = '/assets/img/common/spain.webp';
  selectedLanguageCode: string = 'es';
  @Output() setSearchText = new EventEmitter<string>();

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService,
    private router: Router,
    private firebaseService: FirebaseService,
    private commonService: CommonService,
    private translate: TranslateService,
    private authenticationService: AuthenticationService,

  ) { }

  ngOnInit() {
    this.offset = getTimeZone();
    $('.dropdown').on('show.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideDown(300);
    });

    $('.dropdown').on('hide.bs.dropdown', function (e) {
      $(this).find('.dropdown-menu').first().stop(true, true).slideUp(200);
    });

    if (this.authService.isLoggedIn()) {
      this.getNotificationList();
      this.name = this.authService.getUserDetails().full_name;
      checkOnline(this.firebaseService.getDataBase(), this.authService.getCurrentUserId());
    }

    const str = this.router.url;
    const regex = RegExp('/seller/my-products');
    const regex2 = RegExp('/seller/my-products/');
    if (regex.test(str) == false) {
      this.searchData = ""
      this.authService.searchTextStore = "";
    } else if (regex2.test(str) == true) {
      this.searchData = ""
      this.authService.searchTextStore = "";
    } else {
      this.searchData = this.authService.searchTextStore;
    }

    //For language
    if (this.authService.isLoggedIn() == true) {

      if (this.authService.getLanguage() == 'es') {
        this.selectedLanguage = 'Spanish';
        this.selectedLanguageImage = '/assets/img/common/spain.webp';
        this.selectedLanguageCode = this.authService.getLanguage();

      } else if (this.authService.getLanguage() == 'en') {
        this.selectedLanguage = 'English';
        this.selectedLanguageImage = '/assets/img/common/us.webp';
        this.selectedLanguageCode = this.authService.getLanguage();
      }

      this.translate.use(this.authService.getLanguage());
    }

  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (window.pageYOffset > 50) {
      let element = document.getElementById('navbar');
      element.classList.add('sticky');
    } else {
      let element = document.getElementById('navbar');
      element.classList.remove('sticky');
    }
  }

  change() {
    this.toggle = !this.toggle;
  }

  searchTimer: any;
  doneSearchInterval: any = 800;

  // Search filter
  search() {

    if (this.searchData.length != 0) {

      if (this.searchData.trim()) {
        const str = this.router.url;
        const regex = RegExp('seller/my-products');
        if (regex.test(str) == false) {

          clearTimeout(this.searchTimer);
          this.searchTimer = setTimeout(() => {
            this.router.navigate(['/seller/my-products']);
            this.authService.searchTextStore = this.searchData;
          }, this.doneSearchInterval);

        }

        this.setSearchText.next(this.searchData);
      }

    } else {
      const str = this.router.url;
      const regex = RegExp('seller/my-products');
      if (regex.test(str) == false) {

        clearTimeout(this.searchTimer);
        this.searchTimer = setTimeout(() => {
          this.router.navigate(['/seller/my-products']);
          this.authService.searchTextStore = this.searchData;
        }, this.doneSearchInterval);

      }

      this.setSearchText.next(this.searchData);
    }
  }
  // End

  // For check user auth
  get isLoggedIn() {
    return (this.authService.isLoggedIn()) ? 1 : 0;
  }//End

  navigate(path) {
    this.router.navigate([path]);
  }

  //get notification list
  getNotificationList() {
    if (this.authService.isLoggedIn()) {
      this.authService.getNotifictionList().subscribe(notificationData => {
        if (notificationData.status == 'success' && notificationData.data.data_found) {
          this.notificationList = notificationData.data.notification;
          this.notificationCount = (notificationData.data.count < 10) ? notificationData.data.count : "9+";
        } else {
          this.notificationCount = "0";
        }
      });

    }
  }
  // End 

  //Read Notification
  readNotification(notificationId, notifiy) {
    console.log('notifiy', notifiy);
    var noType = notifiy.notification_type;



    if (noType == 'Order Placed') {
      this.router.navigate(['/seller/new-order-detail/', notifiy.reference_id]);

    }
    if (noType == 'product_approved') {
        this.router.navigate(['/seller/my-product-detail/', notifiy.reference_id]);
    }

    if (noType == 'Order Seller Received' || noType == 'Order Return In Process' || noType == 'Order Return Request' || noType == 'Order Delivered' || noType == 'Order Inzone' || noType == 'Order Packed' || noType == 'Order Shipped' || noType == 'Order Accepted' || noType == 'Order Refund Initiated' || noType == 'Order Picked up Buyer' || noType == 'Refund' || noType == 'Order Refunded' || noType == 'Cancel Order') {
      this.router.navigate(['/seller/my-order-detail/', notifiy.reference_id]);

    }

    else if (noType == 'Dispute Created' || noType == 'Dispute Closed') {
      this.router.navigate(['/seller/my-order-detail/', notifiy.reference_id]);

    } else if (noType == 'Dispute Created' || noType == 'Dispute Closed') {
      this.router.navigate(['/seller/my-order-detail/', notifiy.reference_id]);
    } else if (noType == 'Commision') {
      this.router.navigate(['/seller/seller-account']);
    } else if (noType == 'Offer accepted' || noType == 'Offer Counter' || noType == 'Make an Offer') {
      this.router.navigate(['/seller/product-offers-details/1/1/', notifiy.reference_id]);
    } else if (noType == 'Admin') {
      this.adminnoId = notifiy.notificationID
      $('#adminNotification').modal('show');
    }

    else if (noType == '' || noType == null) {
      this.router.navigate(['/']);
    }

    else if (noType == 'Seller Review') {
      this.router.navigate(['/seller/seller-review/', notifiy.reference_id]);
    }
    else if (noType == 'License Approved' || noType == 'License Disapproved') {
      this.router.navigate(['/seller/seller-account']);
    }


    else if (noType == 'withdraw_request_rejected' || noType == 'withdraw_request_accepted') {
      this.router.navigate(['/seller/wallet-detail/', notifiy.reference_id]);
    }

   

    if (notifiy.is_read == 0) {
      const formData = new FormData();
      formData.append('notification_id', notificationId);

      this.authService.readNotification(formData).subscribe(notificationData => {
        if (notificationData.status == 'success') {
          this.getNotificationList();
        }

      });
    }

  }
  // End Function

  getTimeAgo(dateTime) {
    return this.commonService.chatGroupTime(dateTime, '1');
  }


   // For time get
   getTimeAgoNew(dateTime) {
    return this.commonService.chatGroupTimeNotification(dateTime, '1');
  }
  convertTZ(date, tzString) {
    return new Date((typeof date === "string" ? new Date(date) : date).toLocaleString("en-US", { timeZone: tzString }));
  }

  // Function for logout user
  logout() {
    this.authService.logoutWeb().subscribe();
    this.authenticationService.SignOut();
    this.firebaseService.setOffline(this.authService.getCurrentUserId());
    this.authService.logout();
    setValue('senderUserId', "");
    setValue('chatNode', "");
    this.router.navigate(['/login']);
  }
  // End

  //Select language from header dropdown
  selectLanguage(languagecode, image, language) {
    this.selectedLanguage = language;
    this.selectedLanguageImage = image;
    this.selectedLanguageCode = languagecode;
    let JSONDatas =
    {
      "langauge": this.selectedLanguage,
      "image": this.selectedLanguageImage,
      "code": this.selectedLanguageCode
    };

    const formData = new FormData();
    formData.append('language', this.selectedLanguageCode);
    this.authService.changeLanguage(formData).subscribe(data => {


      if (data.status == 'success') {
        let userDetail = this.authService.getFullUserDetails();
        userDetail.user_detail.device_language = data.data.device_language;
        userDetail.user_detail.user_language = data.data.device_language;
        localStorage.setItem(environment.dataStore.userData, JSON.stringify(userDetail));
        localStorage.setItem(environment.dataStore.language, JSON.stringify(JSONDatas));
        window.location.reload();
      }

    });

  }

  // Function for menu open & close
  menuOpen() {
    let body = document.getElementsByTagName('body')[0];
    let withcalc = document.getElementById("menuMobile");
    let menuOverlay = document.getElementById("menuOverlay");

    body.classList.add("overflow");
    withcalc.classList.add("withcalc");
    menuOverlay.classList.add("overlay-show");
  }
  menuClose() {
    let body = document.getElementsByTagName('body')[0];
    let withcalc = document.getElementById("menuMobile");
    let menuOverlay = document.getElementById("menuOverlay");

    body.classList.remove("overflow");
    withcalc.classList.remove("withcalc");
    menuOverlay.classList.remove("overlay-show");
  }
  // End

}
