import { Component, OnInit, OnDestroy } from '@angular/core';
import { faEllipsisV, faSearch, faArrowLeft, faMicrophone, faPaperPlane, faImages, faCheck, faTimes, faCheckDouble, faRedo } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { AuthService } from '../../auth/auth.service';
import { SellerService } from '../services/seller.service';
import { AngularFireStorage } from '@angular/fire/storage';
import { finalize } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';
import { NgForm } from '@angular/forms';
import { environment } from '../../../environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;
declare const scrollDown: any;
declare const checkOnline: any;
declare const setValue: any;

@Component({
  selector: 'app-trash-chat',
  templateUrl: './trash-chat.component.html',
  styleUrls: ['./trash-chat.component.css']
})
export class TrashChatComponent implements OnInit, OnDestroy {

  faEllipsisV = faEllipsisV;
  faSearch = faSearch;
  faArrowLeft = faArrowLeft;
  faMicrophone = faMicrophone;
  faPaperPlane = faPaperPlane;
  faImages = faImages;
  faCheck = faCheck;
  faTimes = faTimes;
  faRedo = faRedo;
  faCheckDouble = faCheckDouble;
  sellerId: any;
  sellerName: any;
  buyerId: any;
  chatType: any;
  productId: any;
  orderId: any;
  chatHistoryList: any = [];
  chatData: any = {};
  chatList: any = [];
  offer: any = {};
  message: any;
  lastTimeStamp: any = {};
  blockMessage: any;
  chatBoxAllow: any;
  blockByMe: any;
  firebaseToken: any = '';
  imagePreview: any;
  counterPrice: any;
  orderListSubscription: Subscription;
  productListSubscription: Subscription;
  chatHistoryListFinial: any = [];
  currentChatData: any = {};
  chatDataFilter: any = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private commonService: CommonService,
    private router: Router,
    private sellerService: SellerService,
    private authService: AuthService,
    private firebaseService: FirebaseService,
    private storage: AngularFireStorage,
    private translate: TranslateService
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Chat');
    this.sellerId = this.authService.getCurrentUserId();
    this.sellerName = this.authService.getUserDetails().full_name;
    checkOnline(this.firebaseService.getDataBase(), this.authService.getCurrentUserId());

    $(function () {
      if ($(window).width() <= 640) {
        $(".chatHistoryItem").click(function () {
          $(".sideHistory").css({ "flex": "0 0 0%", "-webkit-flex": "0 0 0%" });
          $(".conversation").css({ "flex": "0 0 100%", "-webkit-flex": "0 0 100%" });
        });
        $(".chatBack").click(function () {
          $(".sideHistory").css({ "flex": "0 0 100%", "-webkit-flex": "0 0 100%" });
          $(".conversation").css({ "flex": "0 0 0%", "-webkit-flex": "0 0 0%" });
        });
      }
    });

    const body = document.getElementsByTagName('html')[0];
    body.classList.add('chatComponent');

    this.chatType = '1' // no 
    setValue('chatNode', "");

    this.getChatHistory();
  }

  ngOnDestroy(): void {
    Swal.close();

		$('#imgFullViewModal').modal('hide');
		$('#makeAnOfferModal').modal('hide');
    const body = document.getElementsByTagName('html')[0];
    body.classList.remove('chatComponent');
    setValue('chatNode', "");

    if (this.chatType == '2') {
      this.orderListSubscription.unsubscribe();
    } else if (this.chatType == '3') {
      this.productListSubscription.unsubscribe();
    }
  }

  getTimeAgo(timestamp) {
    return this.commonService.chatHistoryTimeAgo(timestamp);
  }

  imagePreviewModal(imageSrc) {
    $('#imgFullViewModal').modal('show');
    this.imagePreview = imageSrc;
  }

  getChatHistory() {

    if (this.chatType == '2') { // for order history

      let chatData = {
        sellerId: this.sellerId
      }

      this.firebaseService.getChatHistoryList(chatData).subscribe(chatData => {
        this.chatHistoryList = chatData;
        this.chatHistoryList.sort(this.sortFunction);
      });

    } else if (this.chatType == '3') { // for product history

      let chatData = {
        sellerId: this.sellerId
      }

      this.firebaseService.getChatHistoryList(chatData).subscribe(chatData => {
        this.chatHistoryList = chatData;
        this.chatHistoryList.sort(this.sortFunction);
      });

    } else {
      let chatData = {
        sellerId: this.sellerId
      }

      this.firebaseService.getChatHistoryList(chatData).subscribe(chatData => {
        this.chatHistoryList = chatData;
        this.chatHistoryList.sort(this.sortFunction);
        this.filterShowHistoryList();
      });
    }

  }

  sortFunction(a, b) {
    if (a['timestamp'] === b['timestamp']) {
      return 0;
    }
    else {
      return (a['timestamp'] < b['timestamp']) ? 1 : -1;
    }
  }

  changeChat(chatHistory) {
    if (chatHistory.chat_type == 'order') {

      if (this.chatType == '2') {
        this.orderListSubscription.unsubscribe();
      } else if (this.chatType == '3') {
        this.productListSubscription.unsubscribe();
      }

      this.chatType = '2';
      this.buyerId = chatHistory.key.split('-')[1];
      this.getOrderDetail(chatHistory.history_id, chatHistory);

    } else if (chatHistory.chat_type == 'product') {

      if (this.chatType == '2') {
        this.orderListSubscription.unsubscribe();
      } else if (this.chatType == '3') {
        this.productListSubscription.unsubscribe();
      }

      this.chatType = '3';
      this.buyerId = chatHistory.key.split('-')[1];
      this.getProductDetail(chatHistory.history_id, chatHistory);

    }
  }

  getOrderDetail(orderId, chatHistory) {
    this.commonService.showLoader();

    this.sellerService.getMyOrderDetail(orderId).subscribe(myOrderData => {
      this.commonService.hideLoader();

      if (myOrderData.status == 'success') {
        this.chatData = myOrderData.data.order_detail;

        setValue('chatNode', `${this.sellerId}-${this.buyerId}-${this.chatData.orderID}-O`);

        this.getFirebaseToken();
        this.getChatList(chatHistory);
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
      this.router.navigate(['/']);
    });
  }

  getProductDetail(productId, chatHistory) {
    this.commonService.showLoader();

    this.sellerService.getProductDetail(productId, '0').subscribe(productData => {
      this.commonService.hideLoader();

      if (productData.status == 'success') {
        this.chatData = productData.data.product_detail;

        this.firebaseService.getBuyerDetails(this.buyerId).subscribe(data => {
          let usr;
          usr = data.payload.val();
          this.chatData.buyer_image = usr.avatar;
          this.chatData.full_name = usr.full_name;
        });

        setValue('chatNode', `${this.sellerId}-${this.buyerId}-${this.chatData.productID}-P`);

        this.getFirebaseToken();
        this.getChatList(chatHistory);
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
      this.router.navigate(['/']);
    });
  }

  getFirebaseToken() {
    this.firebaseService.getFirebaseToken(this.buyerId).subscribe(userData => {
      let token;
      if (userData.payload.val()) {
        token = userData.payload.val();
        this.firebaseToken = token.firebase_token;
      }
    });
  }

  getChatList(chatHistory) {

    this.currentChatData = chatHistory;

    if (this.chatType == '2') { // get order chat

      let chatData = {
        sellerId: this.sellerId,
        buyerId: this.buyerId,
        orderId: this.chatData.orderID,
        lastTimeStamp: this.lastTimeStamp,
        deleteTimestamp: chatHistory.delete_timestamp,
        deleteForeverTimestamp: chatHistory.delete_forever_timestamp,
      }

      this.orderListSubscription = this.firebaseService.getTrashOrderChatList(chatData).subscribe(chatData => {
        this.chatList = chatData;
        scrollDown();

        this.commonService.timeStore = "";
        // Append Today, date
        this.chatList.forEach(element => {
          element.centerTime = this.commonService.chatGroupTime(element.timestamp);
        });

      });

    } else if (this.chatType == '3') { // get product chat

      let chatData = {
        sellerId: this.sellerId,
        buyerId: this.buyerId,
        productId: this.chatData.productID,
        lastTimeStamp: this.lastTimeStamp,
        deleteTimestamp: chatHistory.delete_timestamp,
        deleteForeverTimestamp: chatHistory.delete_forever_timestamp,
      }

      this.productListSubscription = this.firebaseService.getTrashProductChatList(chatData).subscribe(chatData => {
        this.chatList = chatData;
        scrollDown();

        this.commonService.timeStore = "";
        // Append Today, date
        this.chatList.forEach(element => {
          element.centerTime = this.commonService.chatGroupTime(element.timestamp);
        });

      });

      this.firebaseService.getProductOffer(chatData).subscribe(offerData => {
        this.offer = {};
        if (offerData.key == 'offer') {
          this.offer = offerData.payload.val();
        }
      });

    }

  }

  filterShowHistoryList() {
    this.chatHistoryListFinial = [];

    this.chatHistoryList.forEach(element => {

      if (element.chat_type == "product") {
        let node = `${element.seller_id}-${element.buyer_id}-${element.history_id}`;

        this.firebaseService.getProductChatData(node).subscribe(data => {
          this.chatDataFilter = data.payload.val();


          if (this.chatDataFilter["delete"] !== undefined && this.chatDataFilter["delete"][`${element.seller_id}`] !== undefined && this.chatDataFilter["delete"][`${element.seller_id}`]['timestamp'] > this.chatDataFilter["delete_forever"][`${element.seller_id}`]['timestamp']) {
            element.delete_timestamp = this.chatDataFilter["delete"][`${element.seller_id}`]['timestamp'];
            element.delete_forever_timestamp = this.chatDataFilter["delete_forever"][`${element.seller_id}`]['timestamp'];
            this.chatHistoryListFinial.push(element);
          }

        });

      } else {

        let node = `${element.seller_id}-${element.buyer_id}-${element.history_id}`;

        this.firebaseService.getOrderChatData(node).subscribe(data => {
          this.chatDataFilter = data.payload.val();
          if (this.chatDataFilter["delete"] !== undefined && this.chatDataFilter["delete"][`${element.seller_id}`] !== undefined && this.chatDataFilter["delete"][`${element.seller_id}`]['timestamp'] > this.chatDataFilter["delete_forever"][`${element.seller_id}`]['timestamp']) {
            element.delete_timestamp = this.chatDataFilter["delete"][`${element.seller_id}`]['timestamp'];
            element.delete_forever_timestamp = this.chatDataFilter["delete_forever"][`${element.seller_id}`]['timestamp'];
            this.chatHistoryListFinial.push(element);
          }

        });

      }

    });

    this.chatHistoryListFinial.sort(this.sortFunction);

  }


  deleteForever() {
    Swal.fire({
      title: this.translate.instant('MYORDER.are_you_sure'),
      text: this.translate.instant('CHAT.you_want_to_delete_chat'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('SELLER_SIDE.yes_delete_it'),
      cancelButtonText: this.translate.instant('SELLER_SIDE.no_keep_it'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteChatData();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('SELLER_SIDE.cancelled'),
          this.translate.instant('CHAT.your_chat_is_safe'),
          this.translate.instant('MYORDER.error_txt')
        )
      }
    });
  }

  deleteChatData() {

    if (this.chatType == '2' && this.chatList.length != 0) { // order chat

      let chatData = {
        sellerId: this.sellerId,
        buyerId: this.buyerId,
        orderId: this.chatData.orderID,
        timestamp: this.currentChatData.delete_timestamp,
      }

      this.firebaseService.deleteForeverChat(chatData, '2').then(data => {
        this.ngOnInit();
      });

    } else if (this.chatType == '3' && this.chatList.length != 0) { // product chat

      let chatData = {
        sellerId: this.sellerId,
        buyerId: this.buyerId,
        productId: this.chatData.productID,
        timestamp: this.currentChatData.delete_timestamp,
      }

      this.firebaseService.deleteForeverChat(chatData, '3').then(data => {
        this.ngOnInit();
      });
    }

  }

  deleteRestore() {
    Swal.fire({
      title: this.translate.instant('MYORDER.are_you_sure'),
      text: this.translate.instant('CHAT.you_want_to_restore_deleted_chat'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('CHAT.yes_restore_it'),
      cancelButtonText: this.translate.instant('CHAT.no_keep_it'),
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        this.deleteRestoreSure();
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('SELLER_SIDE.cancelled'),
          this.translate.instant('CHAT.your_chat_is_safe'),
          this.translate.instant('MYORDER.error_txt')
        )
      }
    });
  }

  deleteRestoreSure() {

    if (this.chatType == '2' && this.chatList.length != 0) { // order chat

      let chatData = {
        sellerId: this.sellerId,
        buyerId: this.buyerId,
        orderId: this.chatData.orderID,
        timestamp: this.currentChatData.delete_forever_timestamp,
      }

      this.firebaseService.deleteRestore(chatData, '2').then(data => {
        this.ngOnInit();
      });

    } else if (this.chatType == '3' && this.chatList.length != 0) { // product chat

      let chatData = {
        sellerId: this.sellerId,
        buyerId: this.buyerId,
        productId: this.chatData.productID,
        timestamp: this.currentChatData.delete_forever_timestamp,
      }

      this.firebaseService.deleteRestore(chatData, '3').then(data => {
        this.ngOnInit();
      });
    }

  }


}
