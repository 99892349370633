import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderBuyerComponent } from 'src/app/common/header-buyer/header-buyer.component';
import { CommonService } from '../../services/common.service';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { FirebaseService as BuyerFbService } from '../../buyer/services/firebase.service';
import { ShopService } from '../../services/shop.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { BuyerService } from 'src/app/buyer/services/buyer.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-question-answer',
  templateUrl: './question-answer.component.html',
  styleUrls: ['./question-answer.component.css']
})


export class QuestionAnswerComponent implements OnInit {
  @ViewChild('header', { static: true }) header: HeaderBuyerComponent;
  productId: any;
  variantId: any;
  productDetail: any;
  questionAnswer: any;
  productRegularPrice: any;
  currentUserId: any;


  constructor(
    private commonService: CommonService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private buyerFbService: BuyerFbService,
    private shopService: ShopService,
    private formBuilder: FormBuilder,
    private buyerService: BuyerService,
    private translate: TranslateService,
  ) { translate.use(this.commonService.getLanguage()); }

  ngOnInit() {

    this.activatedRoute.paramMap.subscribe(params => {
      this.productId = params.get("id"); //For product Id
      this.currentUserId = this.authService.getCurrentUserId(); //current user ID
      this.getProductDetail(this.productId, this.currentUserId); //For product detail
    });
  }

  getDisputeTimeShow(dateTime) {

		return this.commonService.getDateTime2(dateTime);
	}
  //Get product Detail
  getProductDetail(productId, currentUserId) {

    this.commonService.showLoader();
    this.shopService.getProductDetail(productId, currentUserId).subscribe((productDetailData) => {


      this.commonService.hideLoader();
      if (productDetailData.status == 'success') {
        this.commonService.scrollToTop();

        this.productDetail = productDetailData.data.product_detail;
        this.questionAnswer = this.productDetail.question;
      } else {

        this.productDetail = [];
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }

}
