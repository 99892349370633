import { Component, OnInit, ViewChild } from '@angular/core';
import { HeaderBuyerComponent } from 'src/app/common/header-buyer/header-buyer.component';
import { faAngleDoubleRight, faStar, faStarHalfAlt, faShoppingCart, faCheck } from '@fortawesome/free-solid-svg-icons';
import { CommonService } from '../../services/common.service';
import { NgxGalleryOptions, NgxGalleryImage, NgxGalleryAnimation } from 'ngx-gallery';
import { faCommentDots, faStar as faStarBlank, faHeart } from '@fortawesome/free-regular-svg-icons';
import { environment } from '../../../environments/environment';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { AuthService } from '../../auth/auth.service';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { BuyerService } from 'src/app/buyer/services/buyer.service';
import { SellerService } from '../services/seller.service';
import { FnParam } from '@angular/compiler/src/output/output_ast';
import { FirebaseService } from '../services/firebase.service';
import { FirebaseService as BuyerFbService } from '../../buyer/services/firebase.service';
import { ShopService } from '../../services/shop.service';
import { FormBuilder, Validators, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

declare const $: any;

@Component({
  selector: 'app-product-offers-details',
  templateUrl: './product-offers-details.component.html',
  styleUrls: ['./product-offers-details.component.css']
})
export class ProductOffersDetailsComponent implements OnInit {
  @ViewChild('header', { static: true }) header: HeaderBuyerComponent;

  faAngleDoubleRight = faAngleDoubleRight;
  faStar = faStar;
  faStarBlank = faStarBlank;
  faStarHalfAlt = faStarHalfAlt;
  faShoppingCart = faShoppingCart;
  faCheck = faCheck;
  offerList: any = [];
  limit;
  offset;
  galleryOptions: NgxGalleryOptions[];
  productId: string = '';
  variantId: string = '';
  productDetail: any = {};
  currentUserId: any;
  similarProduct: any = [];
  galleryImages: any = [];
  galleryImage: any = [];
  variants: any = [];
  variantListData: any = [];
  variant: string = '';
  offerPrice: any;
  firebaseToken: any = '';
  keyFeatureList: any = [];
  productPrice: any = 0;
  productPriceTemp: any = 0;
  discount_percent: any = 0;
  productVariantID: any = 0;
  is_wishlist: any = 0;
  is_qvazon_warranty_applied: any = 0;
  qvazon_warranty_percent: any = 0;
  isCorrectVariantCombination: boolean = false;
  qvazon_warranty_use: any = 0;
  seller_province: any = [];
  productRegularPrice: any = 0;
  previewHide: boolean = true;
  imagesPreview: any;
  questionAnswer: any;
  variantIdGet: any;
  variant_price: any;
  varQuantity: any;
  packages: any;
  third: any[];
  isPackage: boolean = false;
  axz: any;
  fruits: any = [];
  fruits_new: any = [];
  variantsnew: any = [];
  ids: any = [];
  product_variant_id: any;
  checked: false;
  productVariantIDWithVAriant: any;
  productOfferPrice: any;
  productSellingPrice: any;
  discountPercent: any;
  offerData: any = [];
  offerDataPrice: any;
  variantDataForCard;
  variant_id;
  variantDAtaList: any = [];
  flag = 0;
  key = "0";
  variantsList: any;
  offerCounterList: any;
  offerPriceGet: any;
  productPric: any;
  offerIdGet: any;
  CounterForm: FormGroup;
  regularPrice: any;
  offerrPrice: any;
  myProductCount: number;
  answerForm: FormGroup;
  offer_id: string = '';
  buyerDetails: any;
  showQuestionAnsButton: any;
  remaingQuestion: any;


  ProductSliderOptions: OwlOptions = {
    loop: false,
    margin: 30,
    items: 1,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    nav: true,
    dots: false,
    navSpeed: 700,
    autoWidth:true,
    navText: ['<i class="las la-angle-left"></i>', '<i class="las la-angle-right"></i>'],
    responsive: {
      0: {
        items: 1,
        autoWidth: false,
      },
      480: {
        items: 2
      },
      767: {
        items: 3
      },
      992: {
        items: 4
      }
    }
  }
  offerDataRecord: any;
  falg: number;
  quantityRe: any;
  questionAnswerCount: any;
  questionAnswerLength: boolean = false;




  constructor(
    private commonService: CommonService,
    private buyerService: BuyerService,
    private sellerService: SellerService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private firebaseService: FirebaseService,
    private buyerFbService: BuyerFbService,
    private shopService: ShopService,
    private formBuilder: FormBuilder,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.commonService.scrollToTop();
    this.commonService.setTitle('Mercadoba | Product-offer');

    this.activatedRoute.queryParams.subscribe(params => {
    });
    this.setConfigImageGallery();
    this.getOfferList();

    this.activatedRoute.paramMap.subscribe(params => {
      this.productId = params.get("productID"); //For product ID
      this.variantId = params.get("variantID"); //For variant ID
      this.offer_id = params.get("offerItem_id"); //For offer ID
      this.galleryImages = [];
      this.getProductDetail(this.productId, this.variantId, this.offer_id);
    });

    // Counter form validation
    this.CounterForm = this.formBuilder.group({
      counter_price: ['', [Validators.required, Validators.maxLength(6)]]
    });
    // End

    // Questions & Answer form validation
    this.answerForm = this.formBuilder.group({
      description: ['', [Validators.required, Validators.minLength(5), Validators.maxLength(200)]],
    });
    // End

  }

  // counter form
  get counter_price(): any { return this.CounterForm.get('counter_price'); }

  // Questions and answer dexcription 
  get description() { return this.answerForm.get('description'); }



  setConfigImageGallery() {
    this.galleryOptions = [
      {
        width: '350px',
        height: '550px',
        thumbnailsColumns: 4,
        imageAnimation: NgxGalleryAnimation.Slide,
        previewZoom: true,
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: '600px',
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 300,
        preview: false
      }
    ];
  }

  //Get product Detail
  getProductDetail(productId, variantId, offer_id) {
    this.commonService.showLoader();
    this.sellerService.productOfferDetails(productId, variantId, offer_id).subscribe((productDetailData) => {


      this.commonService.hideLoader();
      if (productDetailData.status == 'success') {
        this.commonService.scrollToTop();

        this.productDetail = productDetailData.data.product_detail;
        this.buyerDetails = this.productDetail.buyer_details;
        this.quantityRe = this.buyerDetails.quantity;
        this.offerDataRecord = this.productDetail.variant_list[0];

        this.productPrice = productDetailData.data.product_detail.regular_price;
        this.productRegularPrice = productDetailData.data.product_detail.regular_price;
        this.questionAnswer = this.productDetail.question;
        this.variants = this.productDetail.variants_options;
        this.variantsList = this.productDetail.variant_list;
        this.offerCounterList = this.productDetail.offer_counter_list;
        this.myProductCount = this.offerCounterList.length;
        this.variantsnew = this.productDetail.variants_options;
        // this.productOfferPrice = this.productDetail.offer_price_with_admin_commission;

        // this.productSellingPrice = this.productDetail.selling_price;
        this.offerData = productDetailData.data.product_detail.variant_list[0];
        this.productSellingPrice = this.offerData.selling_price;
        this.productOfferPrice = this.offerData.offer_price_with_admin_commission;



        this.discountPercent = Math.round(this.offerData.discount_percent);
        this.offerDataPrice = this.offerData.product_offer_price;

        if (this.questionAnswer.length != 0) {
          this.questionAnswerLength = true
        }


        this.similarProduct = this.productDetail.similar_products;
        // this.promotedProduct = this.productDetail.similar_products;
        this.keyFeatureList = (this.productDetail.key_features) ? JSON.parse(this.productDetail.key_features) : [];
        this.packages = (this.productDetail.packages) ? JSON.parse(this.productDetail.packages) : [];
        if (this.packages.length == 0) {

          this.isPackage = false;

        } else {
          this.isPackage = true;

        }

        // questions and answer button coun/
        if (this.questionAnswer.length <= 4) {
          this.showQuestionAnsButton = 0

        } else {
          this.showQuestionAnsButton = 1
          this.remaingQuestion = this.questionAnswer.length - 4;
        }

        var filtered = this.questionAnswer.filter(function (value, index, arr) {
          return index <= 3;

        });

        this.questionAnswer = filtered;
        // END

        this.seller_province = this.productDetail.seller_province.split(',');
        this.productDetail.variant_list.forEach(element1 => {
          let newKey = "";
          this.variantIdGet = element1.productVariantID;
          this.variant_price = element1.variant_product_price;
          this.varQuantity = element1.quantity;

          element1.variant_value.forEach(element2 => {
            newKey += element2.variantValueID;
          });

          element1.variantValuesString = newKey;
        });

        if (this.similarProduct.length > 4) {
          this.ProductSliderOptions.loop = true;
        }


        if (this.similarProduct.length > 4) {
          this.ProductSliderOptions.loop = true;
        }

        console.log('this.productDetail', this.productDetail);
        if(this.productDetail.is_no_variants == 0){
          this.galleryImage = this.productDetail.variant_list[0].variant_image;

        }else{
          this.galleryImage = this.productDetail.gallery_images;

        }

        console.log('dgeydgegd',this.galleryImage )

        this.variantDataForCard = this.productDetail.variant_list[0];


        this.variant_id = this.productDetail.variant_list[0].productVariantID;



        var image;
        this.productDetail.variant_list.forEach(element => {

          var image = JSON.parse(element.image_json);
          element.image1 = image[0];
          this.variantDAtaList.push(element);

        });

        if( this.productDetail.is_no_variants == 0){
          this.galleryImage.forEach(element => {
            let galleryImagesData = {
              small: `${element.variant_image_url}${element.variant_image}`,
              medium: `${element.variant_image_url}${element.variant_image}`,
              big: `${element.variant_image_url}${element.variant_image}`
            };
            this.galleryImages.push(galleryImagesData);
  
  
          });
  
        }else{
          this.galleryImage.forEach(element => {
            let galleryImagesData = {
              small: `${element.gallery_image_url}${element.gallery_image}`,
              medium: `${element.gallery_image_url}${element.gallery_image}`,
              big: `${element.gallery_image_url}${element.gallery_image}`
            };
            this.galleryImages.push(galleryImagesData);
  
  
          });
        }

        console.log('neha',this.galleryImages);

      
        // this.setVariantData();

      } else {

        this.productDetail = [];
      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }


  getTillShow(dateTime) {

		return this.commonService.getDisputeTimeShow(dateTime);
	}
	getDisputeTimeShow(dateTime) {

		return this.commonService.getDateTime2(dateTime);
	}
  // End
  see_chages(){
    var url = '/seller/seller-account/shipping';
    window.open(url, "_blank");
  }

  	// For shipping charges limit set
	checkNumberLimit(e) {
		if (e.target.value.includes('.') && e.keyCode == 46) {
			e.preventDefault();
			return false
		}
		var valid = /^\d{0,7}(\.\d{0,2})?$/.test(e.target.value),
        val = e.target.value;
    
    if(!valid){
         e.target.value = val.substring(0, val.length - 1);
    }

		// if (e.target.value.length > 10 || e.keyCode == 45) {
		// 	e.preventDefault();
		// 	return false;
		// }

	}

  // For product delivery duration
  getDeliveryDuration(key) {
    switch (key) {
      case '1':
        return "1 Day";
        break;
      case '2':
        return "2 Days";
        break;
      case '3':
        return "3 Days";
        break;
      case '4':
        return "7 Days";
        break;
      case '5':
        return "More than 7 days";
        break;
    }
  }
  // End

  // For variant data 
  variantDataaa(index) {
    this.variantDAtaList = [];
    this.galleryImages = [];
    this.variant_id = this.productDetail.variant_list[index].productVariantID;
    this.variantDataForCard = this.productDetail.variant_list[index];
    this.galleryImage = this.productDetail.variant_list[index].variant_image;


    var image;
    this.productDetail.variant_list.forEach(element => {

      var image = JSON.parse(element.image_json);
      element.image1 = image[0];
      this.variantDAtaList.push(element);

    });




    this.galleryImage.forEach(element => {
      let galleryImagesData = {
        small: `${element.variant_image_url}${element.variant_image}`,
        medium: `${element.variant_image_url}${element.variant_image}`,
        big: `${element.variant_image_url}${element.variant_image}`
      };
      this.galleryImages.push(galleryImagesData);


    });
  }
  // End

  // For get offer list
  getOfferList() {
    this.commonService.showLoader();
    this.limit = 12;
    this.offset = 0;
    this.buyerService.productOffer(this.limit, this.offset).subscribe(offerData => {

      this.commonService.hideLoader();
      if (offerData.status == 'success') {
        this.offerList = offerData.data.offerList;


      }
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }
  // End

  // For firebase token
  getFirebaseToken() {
    this.buyerFbService.getFirebaseToken(this.productDetail.seller_id).subscribe(userData => {
      let token;
      if (userData.payload.val()) {
        token = userData.payload.val();
        this.firebaseToken = token.firebase_token;
      }
    });
  }
  // End


  firstMessageSend() {
    let chatData = {
      sellerId: this.productDetail.seller_id,
      buyerId: this.currentUserId,
      buyerName: this.authService.getUserDetails().full_name,
      productId: this.productDetail.productID,
      productName: this.productDetail.name,
      firebaseToken: this.firebaseToken,
      isOffer: 1,
      message: this.translate.instant('SHOP.you_have_new_offer_request')
    }

    this.buyerFbService.sendProductMsg(chatData);
  }

  goToChatDashborad(productId, sellerId) {
    // For check user is login or not
    if (this.authService.isLoggedIn()) {
      this.router.navigate(['seller/chat/product/', productId, sellerId]);
    } else {
      this.commonService.toastWarning(this.translate.instant('SHOP.please_login_before_chat_with_seller'));
      this.router.navigate(['/login']);
    }

  }

  setViewCount() {
    if (this.authService.isLoggedIn()) {
      const formData = new FormData();
      formData.append('product_id', this.productId);
      formData.append('user_type', 'buyer');

      this.shopService.viewCount(formData).subscribe((data) => {
        this.commonService.hideLoader();
        if (data.status == "fail") {
          this.commonService.toastError(data.message);
        }
      }, err => {
        this.commonService.errorhandler(err);
      });

    }
  }

  // For variant color
  getValueColor(variantsss, index, e, varnm) {

    // return false;
    this.flag = 0;
    var id;
    var product_variant_id;
    var ind = index + 1;

    if (this.variants.length != ind) {

      id = this.variants[ind].variantID;
      product_variant_id = ""

      if (product_variant_id == undefined) {
        product_variant_id = 0;
      }

      this.key = '1';
      this.fruits = [];
      $(`.sp${index}`).removeClass('specialClass');
      this.commonService.showLoader();

      if (this.authService.isLoggedIn()) {
        var userId = this.currentUserId
      } else {
        userId = 0;
      }
      this.buyerService.getVariant(this.productId, variantsss, id, product_variant_id, userId).subscribe((res) => {
        this.axz = res.data
        this.axz.map((val) => {
          val.variant_value.map(async (valuee) => {
            this.fruits.push(valuee);
          });

        })

        var result = this.fruits.reduce((unique, o) => {
          if (!unique.some(obj => obj.variantValueID === o.variantValueID && obj.variantValueID === o.variantValueID)) {
            unique.push(o);
          }
          return unique;
        }, []);


        this.fruits = result;
        this.variants[ind].variant_value = this.fruits;
        this.commonService.hideLoader();

      }, err => {
        this.commonService.errorhandler(err);
      });


    } else {

      if (this.productDetail.variants_options.length == 1) {

        this.product_variant_id = this.productDetail.variant_list[varnm].productVariantID;
        this.flag = 1;

      } else {
        this.fruits.map((values) => {
          if (values.variantValueID == variantsss) {
            this.product_variant_id = values.product_variant_id;
          }
        })
      }





      if (this.product_variant_id == undefined) {
        return false;
      }



      $(`.sp${index}`).removeClass('specialClass');

      this.flag = 1;

      id = "";
      if (this.authService.isLoggedIn()) {
        var userId = this.currentUserId
      } else {
        userId = 0;
      }

      this.buyerService.getVariant(this.productId, variantsss, id, this.product_variant_id, userId).subscribe((productDetailData) => {
        this.productVariantIDWithVAriant = productDetailData.data[0];
        if (productDetailData.status == 'success') {
          this.commonService.scrollToTop();
          this.galleryImages = [];
          this.galleryImage = this.productVariantIDWithVAriant.variant_image;
          this.productOfferPrice = this.productVariantIDWithVAriant.offer_price_with_admin_commission;
          this.productSellingPrice = this.productVariantIDWithVAriant.selling_price;
          this.discountPercent = Math.round(this.productVariantIDWithVAriant.discount_percent);


          this.galleryImage.forEach(element => {
            let galleryImagesData = {
              small: `${element.variant_image_url}${element.variant_image}`,
              medium: `${element.variant_image_url}${element.variant_image}`,
              big: `${element.variant_image_url}${element.variant_image}`
            };
            this.galleryImages.push(galleryImagesData);


          });

          this.getFirebaseToken();
          // this.setVariantData();

          this.setViewCount();
        } else {

          this.productDetail = [];
        }



      }, err => {
        this.commonService.errorhandler(err);
        // this.makeOfferForm.reset();
      });




    }


  }
  // End

  // For check form valid
  validateAllFormFields(formGroup: FormGroup) {         //{1}
    Object.keys(formGroup.controls).forEach(field => {  //{2}
      const control = formGroup.get(field);             //{3}
      if (control instanceof FormControl) {             //{4}
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {        //{5}
        this.validateAllFormFields(control);            //{6}
      }
    });
  }
  // End


  // For rating review
  createRatingRange(number) {
    return this.commonService.createRatingRange(number);
  }

  createRatingBlank(number) {
    return this.commonService.createRatingBlank(number);
  }

  isFloat(number) {
    return this.commonService.isFloat(number);
  }
  // End

  // Add questions form
  addAnsWer(id) {

    if (this.answerForm.valid) {
      this.commonService.showLoader();
      const formData = new FormData();
      formData.append('product_id', this.productDetail.productID);
      formData.append('description', $(`#ans_${id}`).val());
      formData.append('question_id', id);

      this.shopService.askQuestions(formData).subscribe((res) => {
        this.commonService.hideLoader();
        this.commonService.toastSuccess(res.message);
        this.getProductDetail(this.productId, this.authService.getCurrentUserId(), this.offer_id);
        // window.location.reload();
        this.answerForm.reset();

      }, err => {
        this.commonService.errorhandler(err);
        this.commonService.hideLoader();
        this.answerForm.reset();
      });
    } else {
      this.validateAllFormFields(this.answerForm); //{7}

    }

  }
  // End

  // Offer status actions
  offerActions(offerId, flag, regularPrice, offerPrice) {
    this.offerIdGet = offerId;
    this.regularPrice = regularPrice;
    this.offerrPrice = offerPrice;

    this.falg = 1;

    this.offerPriceGet = offerPrice;
    this.offerIdGet = offerId;
    this.quantityRe = this.productDetail.buyer_details.quantity;
    this.offerDataRecord = this.productDetail.variant_list[0];
    this.commonService.showLoader();
    const formData = new FormData();
    formData.append('offer_id', offerId);
    formData.append('status', flag);


    // Accept
    if (flag == 1) {
      this.shopService.offerAction(formData).subscribe(res => {
        this.commonService.toastSuccess(res.message);
        this.getProductDetail(this.productId, this.variantId, this.offer_id);
        this.commonService.hideLoader();
      }, err => {
        this.commonService.hideLoader();
        this.commonService.errorhandler(err);
      });
    }

    // Reject
    if (flag == 2) {
      this.shopService.offerAction(formData).subscribe(res => {
        this.commonService.toastSuccess(res.message);
        this.getProductDetail(this.productId, this.variantId, this.offer_id);
        this.commonService.hideLoader();
      }, err => {
        this.commonService.hideLoader();
        this.getProductDetail(this.productId, this.variantId, this.offer_id);
        this.commonService.errorhandler(err);
      });
    }

    // counter 
    if (flag == 4) {

      this.CounterForm.reset();

      this.commonService.hideLoader();
      $('#counterModal').modal('show');
    }
  }
  // End

  // Counter price/
  counterPrice(flag) {
    if (this.CounterForm.valid) {

    this.commonService.showLoader();
    if (this.CounterForm.get('counter_price').value <= 0) {
      this.commonService.hideLoader();
      this.commonService.toastError(this.translate.instant('PROFILE.counter_price')); return false;
    }

    const formData = new FormData();
    formData.append('offer_id', this.offerIdGet);
    formData.append('status', flag);
    formData.append('counter_price', this.CounterForm.get('counter_price').value);

    this.shopService.offerAction(formData).subscribe(res => {
      this.commonService.toastSuccess(res.message);
      $('#counterModal').modal('hide');
      this.CounterForm.reset();
      this.getProductDetail(this.productId, this.variantId, this.offer_id);
      this.commonService.hideLoader();
    }, err => {
      this.commonService.hideLoader();
      this.commonService.errorhandler(err);
    });
  }else{
    this.validateAllFormFields(this.CounterForm); //{7}

  }
  }
  // End

  // For checkbox change 
  onCheckboxChange(e) {
    if (e.target.checked) {
      this.qvazon_warranty_use = 1;
      let prAmount = Number(Number(this.productPrice) * Number(this.qvazon_warranty_percent) / 100);
      this.productPrice = Number(Number(this.productPrice) + Number(prAmount)).toFixed(2);
    } else {
      this.qvazon_warranty_use = 0;
      this.productPrice = this.productPriceTemp;
    }
  }
  // End


}
